import qs from "qs";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArticles } from "../../actions/article";
import Meta from "../../components/layout/Meta";
import ArticleCards from "../../components/lists/ArticleCards";
import Paginate from "../../components/util/Paginate";
import { getPureMessage, IntlText } from "../../components/util/Text";
import JamboArticle from "../../components/views/JamboArticle";

class IndexScreen extends PureComponent {
  state = {
    top: null,
    queryParam: {
      timing: null,
    },
    articles: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
  };

  componentWillMount = () => {
    const param = qs.parse(this.props.location.search.replace("?", ""));
    const page = param.page ? param.page : 1;
    this.reload(page);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.location.search != nextProps.location.search) {
      const param = qs.parse(nextProps.location.search.replace("?", ""));
      const page = param.page ? param.page : 1;
      this.reload(page);
    }
  };

  reload = (page) => {
    this.props.actions
      .searchArticles({
        all: true,
        pagesize: 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            top:
              value.data != null && value.data.length > 0
                ? value.data[0]
                : null,
          });
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchArticles({
        pagesize: 24,
        page: page,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            articles: value.data,
            meta: value.response.data.meta,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div className="uk-section">
        <div className="uk-container">
          <Meta
            title={getPureMessage("articles.meta")}
            description={getPureMessage("articles.description")}
          />
          <h1 className="uk-heading-mark uk-margin-remove">
            <IntlText id="articles.title" />
          </h1>
          {this.state.top && (
            <div className="uk-margin-large">
              <JamboArticle article={this.state.top} />
            </div>
          )}
          <div className="uk-margin-large">
            <ArticleCards articles={this.state.articles} />
            <div className="uk-margin-medium">
              <Paginate meta={this.state.meta} />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchArticles: (...param) => dispatch(searchArticles(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
