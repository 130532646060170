import qs from "qs";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchNews } from "../../actions/news";
import NewsCards from "../../components/lists/NewsCards";
import Paginate from "../../components/util/Paginate";

class IndexScreen extends PureComponent {
  state = {
    news: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
  };

  componentWillMount = () => {
    const param = qs.parse(this.props.location.search.replace("?", ""));
    const page = param.page ? param.page : 1;
    this.reload(page);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.location.search != nextProps.location.search) {
      const param = qs.parse(nextProps.location.search.replace("?", ""));
      const page = param.page ? param.page : 1;
      this.reload(page);
    }
  };

  reload = (page) => {
    this.props.actions
      .searchNews({
        pagesize: 12,
        page: page,
        order: "desc",
        sort: "posted_at",
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            news: value.data,
            meta: value.response.data.meta,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div className="uk-section">
        <div className="uk-container">
          <h1 className="uk-heading-mark uk-margin-small">News</h1>
          <div className="uk-margin-large">
            <NewsCards news={this.state.news} />
            <div className="uk-margin-medium">
              <Paginate meta={this.state.meta} />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchNews: (...param) => dispatch(searchNews(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
