import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArtworks } from "../../actions/artwork";
import { setCondition } from "../../actions/cache";
import ArtworkFilterForm from "../../components/forms/ArtworkFilterForm";
import Meta from "../../components/layout/Meta";
import ArtworkCards from "../../components/lists/ArtworkCards";
import { Button } from "../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../components/util/Text";

class ArtworksScreen extends PureComponent {
  state = {
    queryParam: {
      sort_method: "recommend",
    },
    artworks: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.readMore(true);
    }
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    const param = Object.assign({}, this.state.queryParam, {
      gallery_id: this.props.gallery.id,
      pagesize: 24,
      sales_priority: true,
      page: this.state.meta.current_page + 1,
    });

    this.props.actions
      .searchArtworks(param)
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState(
            {
              artworks:
                this.state.artworks && !refresh
                  ? this.state.artworks.concat(value.data)
                  : value.data,
              meta: value.response.data.meta,
              loading: false,
            },
            () => {
              this.props.actions.setCondition(this.state);
            },
          );
        }
      })
      .catch((error) => console.log(error));
  };

  onSearch = (values) => {
    this.setState(
      {
        queryParam: values,
        artworks: null,
        meta: {
          current_page: 0,
        },
      },
      () => {
        this.readMore(true);
      },
    );
  };

  render = () => {
    return (
      <div>
        <Meta
          title={
            getPureMessage("galleries.artworks.meta") + this.props.gallery.name
          }
          description={this.props.gallery.description}
          image={this.props.gallery.thumnail_url}
        />
        <h1 className="uk-heading-mark uk-margin-remove uk-h2">
          <IntlText
            id="galleries.artworks.title"
            values={{ name: this.props.gallery.name }}
          />
        </h1>
        <span className="uk-text-muted">
          {this.state.meta.total != null && (
            <IntlText
              id="galleries.artworks.count"
              values={{ num: this.state.meta.total }}
            />
          )}
        </span>

        <div className="uk-margin-medium">
          <ArtworkFilterForm
            initialValues={this.state.queryParam}
            onChange={this.onSearch}
          />
        </div>

        <div className="uk-margin-medium">
          <ArtworkCards artworks={this.state.artworks} />
        </div>

        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-medium uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
                ga={{
                  category: "button",
                  action: "gallery_artworks",
                  label: "more",
                }}
              >
                <IntlText id="forms.more" />
              </Button>
            </div>
          )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtworksScreen),
);
