import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArtists } from "../../../actions/gallery/artist";
import { search as searchArtworks } from "../../../actions/gallery/artwork";
import { add } from "../../../actions/gallery/show";
import { showAlert } from "../../../actions/message";
import ShowForm from "../../../components/forms/gallery/ShowForm";
import MultiSelectBox from "../../../components/inputs/MultiSelectBox";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { IntlText } from "../../../components/util/Text";
import ArtistPart from "../../../components/views/ArtistPart";
import ArtworkPart from "../../../components/views/ArtworkPart";
import { GALLERY_TRIAL } from "../../../constants/option";
import { Path, PathGenerator } from "../../../constants/path";
import { Notification } from "../../../utils/notification";

class AddScreen extends PureComponent {
  state = {
    selectedArtists: [],
    artists: [],
    selectedArtworks: [],
    artworks: null,
  };

  componentWillMount = () => {
    this.props.actions
      .searchArtists({ gallery_id: this.props.gallery.id, pagesize: -1 })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({ artists: value.data });
        }
      })
      .catch((error) => console.log(error));
  };

  componentDidMount = () => {
    if (
      this.props.gallery.status == GALLERY_TRIAL &&
      moment(this.props.gallery.trial_end_at) < moment()
    ) {
      this.props.actions.showAlert(
        <IntlText id="util.upgrade.title" />,
        <IntlText id="util.upgrade.message" />,
        () => {
          this.props.history.push(
            PathGenerator.getLocalPath(Path.gallery.edit.url),
          );
        },
        <IntlText id="util.upgrade.ok" />,
      );
    }
  };

  onSelectArtists = (ids) => {
    this.setState({
      selectedArtists: [].concat(ids),
    });
    if (ids.length > 0) {
      this.props.actions
        .searchArtworks({
          gallery_id: this.props.gallery.id,
          artist_ids: ids,
          pagesize: 10000,
        })
        .then(({ value, action }) => {
          if (value.status < 300) {
            this.setState({ artworks: value.data });
          }
        })
        .catch((error) => console.log(error));
    } else {
      this.setState({ artworks: null, selectedArtworks: [] });
    }
  };

  onSelectArtworks = (ids) => {
    this.setState({
      selectedArtworks: [].concat(ids),
    });
  };

  onAdd = (values) => {
    const param = Object.assign(
      {},
      {
        artist_id: this.state.selectedArtists,
        artwork_id: this.state.selectedArtworks,
      },
      values,
    );
    return this.props.actions.add(param);
  };

  onAdded = (data) => {
    Notification.successIntl("util.success.add");
    this.props.history.push(
      PathGenerator.getLocalPath(Path.gallery.shows.edit.url, {
        showId: data.id,
      }),
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-medium" data-uk-grid>
          <div className="uk-width-expand">
            <h1>
              <IntlText id="gallery.show.title" />
            </h1>
          </div>
          <div className="uk-width-medium@s uk-text-right">
            <ChangeEditLang />
          </div>
        </div>

        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.show.artist" />
          </h2>
          <MultiSelectBox
            options={this.state.artists}
            itemRenderer={(item) => {
              return (
                <ArtistPart
                  editing={false}
                  artist={item}
                  lang={this.props.language.edit}
                />
              );
            }}
            keyExtractor={(item) => item.id}
            onChange={this.onSelectArtists}
          />
        </div>
        {this.state.artworks && (
          <div className="uk-margin-medium">
            <h2 className="uk-heading-mark">
              <IntlText id="gallery.show.artwork" />
            </h2>
            <MultiSelectBox
              options={this.state.artworks}
              itemRenderer={(item) => {
                return (
                  <ArtworkPart
                    editing={false}
                    artwork={item}
                    lang={this.props.language.edit}
                  />
                );
              }}
              keyExtractor={(item) => item.id}
              onChange={this.onSelectArtworks}
            />
          </div>
        )}
        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.show.info" />
          </h2>
          <ShowForm onSubmit={this.onAdd} onSuccess={this.onAdded} />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showAlert: (...param) => dispatch(showAlert(...param)),
      add: (...param) => dispatch(add(...param)),
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      searchArtists: (...param) => dispatch(searchArtists(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddScreen),
);
