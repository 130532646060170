import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";

class Article extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const article = !this.props.article.title
      ? Formatter.mergeDesc(
          this.props.article,
          this.props.article.article_descs,
          lang,
        )
      : this.props.article;
    return (
      <div>
        <Link
          className="uk-link-reset uk-padding-small@s uk-padding-ssmall uk-display-block uk-background-muted"
          to={PathGenerator.getLocalPath(Path.articles.view.url, {
            articleId: article.id,
          })}
          ga={{
            category: "link",
            action: "article_card",
            label: "article_" + article.id,
          }}
        >
          <div className="uk-grid-small" data-uk-grid>
            <div className="uk-width-1-1@s uk-width-auto">
              <div className="uk-width-1-1@s uk-width-small uk-height-medium@s uk-height-thumnail uk-image-wrapper">
                <Image small src={article.thumnail_url} alt={article.title} />
              </div>
            </div>

            <div className="uk-width-expand">
              <h3 className="uk-margin-ssmall uk-height-lines-3 uk-h4">
                {article.title}
              </h3>
              <p className="uk-margin-remove uk-text-muted">
                <small>
                  {article.posted_at
                    ? moment(article.posted_at).format("YYYY.MM.DD")
                    : "-"}
                </small>
              </p>
              <p className="uk-margin-remove uk-text-muted">
                <small>{article.editor_name ? article.editor_name : "-"}</small>
              </p>
            </div>
          </div>
        </Link>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
