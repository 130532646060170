import gallery from "./screens/gallery";
import fair from "./screens/fair";
import collector from "./screens/collector";

import views from "./components/views";
import forms from "./components/forms";
import lists from "./components/lists";
import util from "./components/util";
import details from "./components/details";
import option from "./components/option";
import common from "./common";
import screens from "./screens/index";

const messages = {
  gallery: gallery,
  fair: fair,
  collector: collector,
  forms: forms,
  views: views,
  lists: lists,
  details: details,
  util: util,
  option: option,
  ...screens,
  ...common,
};

export default messages;
