const messages = {
  fairs: {
    no: "No Fairs.",
  },
  informations: {
    no: "No Information.",
    post_date: "Post Date",
    thumnail: "Thumnail",
    description: "Description",
    action: "Action",
  },
  sales: {
    no: "No sales information.",
    date: "Date",
    count: "Count",
    sales: "Sales($)",
    revenue: "Revenue($)",
  },
  purchases: {
    no: "No purchase informations",
    date: "Date",
    artwork: "Artwork",
    collector: "Collector",
    messages: "Messages",
    from_collector: "From collctor",
    from_gallery: "From gallery",
    confirm: "Confirm",
  },
  articles: {
    no: "No Articles.",
    post_date: "Post Date",
    thumnail: "Thumnail",
    description: "Description",
    action: "Action",
    public: "Public",
    private: "Private",
  },
  shows: {
    no: "No Shows.",
  },
  artworks: {
    no: "No Artworks.",
  },
  artists: {
    no: "No Artist.",
  },
  attends: {
    no: "No Attends.",
  },
  galleries: {
    no: "No Galleries.",
  },
  histories: {
    no: "No Histories.",
  },
  results: {
    no: "Nothing is found.",
  },
  news: {
    no: "No News.",
  },
  likes: {
    no: "Nothing is liked.",
  },
  photos: {
    no: "No photo",
  },
  banners: {
    no: "No banner.",
  },
};

export default messages;
