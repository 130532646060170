import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { mediums, prices, sizes } from "../../../constants/option";
import Select from "../../inputs/Select";
import { getPureMessage, IntlText } from "../../util/Text";

class ArtworkFilterForm extends PureComponent {
  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        action={this.props.action}
        method="post"
      >
        <div className="uk-flex-middle uk-grid-small" data-uk-grid>
          <div className="uk-width-auto@s">
            <div className="uk-grid-collapse uk-flex-middle" data-uk-grid>
              {this.props.sections && this.props.sections.length >= 2 && (
                <React.Fragment>
                  <div className="uk-width-auto@s">
                    <span className="uk-text-bold uk-margin-small-right">
                      <IntlText id="forms.attr.artwork_filter.by_section" />
                    </span>
                  </div>
                  <div className="uk-width-small@s uk-width-1-2 uk-margin-small-right">
                    <Field
                      name="section_id"
                      placeholder={getPureMessage(
                        "forms.attr.artwork_filter.section",
                      )}
                      component={Select}
                      className="uk-form-small uk-form-clear"
                      noError
                      options={
                        this.props.sections &&
                        this.props.sections.map((section) => {
                          return { label: section.label, value: section.id };
                        })
                      }
                    />
                  </div>
                </React.Fragment>
              )}
              <div className="uk-width-auto@s">
                <span className="uk-text-bold uk-margin-small-right">
                  <IntlText id="forms.attr.artwork_filter.by_gallery" />
                </span>
              </div>
              <div className="uk-width-small@s uk-width-1-2">
                <Field
                  name="gallery_id"
                  placeholder={getPureMessage(
                    "forms.attr.artwork_filter.choose",
                  )}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  noError
                  options={
                    this.props.galleries &&
                    this.props.galleries.map((gallery) => {
                      return { label: gallery.name, value: gallery.id };
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="uk-width-expand@s">
            <div className="uk-grid-collapse uk-flex-middle" data-uk-grid>
              <div className="uk-width-auto@s">
                <span className="uk-text-bold uk-margin-small-right">
                  <IntlText id="forms.attr.artwork_filter.by_artwork" />
                </span>
              </div>
              <div className="uk-width-small@s uk-width-1-3">
                <Field
                  name="size"
                  placeholder={getPureMessage("forms.attr.artwork_filter.size")}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  noError
                  options={sizes}
                />
              </div>
              <div className="uk-width-small@s uk-width-1-3">
                <Field
                  name="price"
                  placeholder={getPureMessage(
                    "forms.attr.artwork_filter.price",
                  )}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  noError
                  options={prices}
                />
              </div>
              <div className="uk-width-small@s uk-width-1-3">
                <Field
                  name="medium"
                  placeholder={getPureMessage(
                    "forms.attr.artwork_filter.medium",
                  )}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  options={mediums}
                  noError
                />
              </div>
            </div>
          </div>

          <div className="uk-width-1-1">
            <div className="uk-grid-collapse uk-flex-middle" data-uk-grid>
              <div className="uk-width-auto@s">
                <span className="uk-text-bold uk-margin-small-right">
                  <IntlText id="forms.attr.artwork_filter.by_artist" />
                </span>
              </div>
              <div className="uk-width-small@s uk-width-1-2">
                <Field
                  name="artist_id"
                  placeholder={getPureMessage(
                    "forms.attr.artwork_filter.choose",
                  )}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  noError
                  options={
                    this.props.artists &&
                    this.props.artists.map((artist) => {
                      return { label: artist.name, value: artist.id };
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "fair-artwork-filter-form",
  // enableReinitialize : true
})(ArtworkFilterForm);
