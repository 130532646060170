import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { languages, listedStatus, openStatus } from "../../../constants/option";
import AutoComplete from "../../inputs/AutoComplete";
import DatetimePicker from "../../inputs/DatetimePicker";
import Select from "../../inputs/Select";
import Textarea from "../../inputs/Textarea";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import * as normalize from "../../util/Normalizer";
import { IntlText } from "../../util/Text";
import * as validate from "../../util/Validator";

class FairForm extends Component {
  state = {
    loading: false,
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  renderBasic = () => {
    return (
      <div className="uk-margin">
        <h3>
          <IntlText id="forms.attr.fair.basic" />
        </h3>
        <div className="uk-margin-small">
          <Field
            name="title"
            type="text"
            component={TextInput}
            labelId="forms.attr.fair.title"
            validate={[validate.required]}
            required
          />
        </div>

        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-1">
            <Field
              name="description"
              component={Textarea}
              labelId="forms.attr.fair.description"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-width-1-2@s">
            <Field
              name="schedule"
              component={Textarea}
              labelId="forms.attr.fair.schedule"
            />
          </div>
          <div className="uk-width-1-2@s">
            <div className="uk-margin-small">
              <Field
                name="ticket_info"
                component={Textarea}
                labelId="forms.attr.fair.ticket"
                style={{
                  height: 100,
                }}
              />
            </div>
            <div className="uk-margin-small">
              <Field
                name="ticket_url"
                type="url"
                component={TextInput}
                labelId="forms.attr.fair.ticket_url"
              />
            </div>
          </div>
        </div>

        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-medium">
            <Field
              name="place"
              component={AutoComplete}
              labelId="forms.attr.place"
              options={this.props.constant.places.map((place) => {
                return {
                  label: place.name,
                  value: place.id,
                };
              })}
            />
          </div>
          <div className="uk-width-expand">
            <Field
              name="address"
              type="text"
              component={TextInput}
              labelId="forms.attr.address"
            />
          </div>
        </div>

        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-3@s">
            <Field
              name="not_listed"
              component={Select}
              labelId="forms.attr.not_listed"
              validate={[validate.required]}
              options={listedStatus}
              required
            />
          </div>
          <div className="uk-width-1-3@s">
            <Field
              name="only_started"
              component={Select}
              labelId="forms.attr.only_started"
              validate={[validate.required]}
              options={openStatus}
              required
            />
          </div>
          <div className="uk-width-1-3@s">
            <Field
              name="slug"
              type="text"
              component={TextInput}
              labelId="forms.attr.slug"
            />
          </div>
          <div className="uk-width-1-1">
            <Field
              name="v_pass"
              component={Textarea}
              labelId="forms.attr.v_pass"
            />
          </div>
          <div className="uk-width-1-3@s">
            <Field
              name="start_at"
              type="date"
              component={DatetimePicker}
              labelId="forms.attr.start_at"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-width-1-3@s">
            <Field
              name="end_at"
              type="date"
              component={DatetimePicker}
              labelId="forms.attr.end_at"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-width-1-3@s">
            <Field
              name="vip_start_at"
              type="date"
              component={DatetimePicker}
              labelId="forms.attr.vip_start_at"
            />
          </div>
        </div>

        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-3@s">
            <Field
              name="primary_lang"
              component={Select}
              labelId="forms.attr.primary_lang"
              validate={[validate.required]}
              options={languages}
              required
            />
          </div>
          <div className="uk-width-1-3@s">
            <Field
              name="hp"
              type="url"
              component={TextInput}
              labelId="forms.attr.hp"
              validate={[validate.url]}
            />
          </div>
          <div className="uk-width-1-3@s">
            <Field
              name="tel"
              type="tel"
              component={TextInput}
              labelId="forms.attr.tel"
              validate={[validate.tel]}
              normalize={normalize.numberOnly}
            />
          </div>
          <div className="uk-width-1-3@s">
            <Field
              name="ogp_url"
              type="url"
              component={TextInput}
              labelId="forms.attr.ogp_url"
              validate={[validate.url]}
            />
          </div>
        </div>
      </div>
    );
  };

  renderReception = () => {
    return (
      <div className="uk-margin">
        <h3>
          <IntlText id="forms.attr.fair.reception" />
        </h3>
        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-2@s">
            <Field
              name="reception_schedule"
              type="text"
              component={TextInput}
              labelId="forms.attr.fair.reception_schedule"
            />
          </div>

          <div className="uk-width-1-2@s">
            <Field
              name="reception_address"
              type="text"
              component={TextInput}
              labelId="forms.attr.fair.reception_address"
            />
          </div>

          <div className="uk-width-1-2@s">
            <Field
              name="reception_fee"
              type="text"
              component={TextInput}
              labelId="forms.attr.fair.reception_fee"
            />
          </div>
        </div>
      </div>
    );
  };

  renderCommittee = () => {
    return (
      <div className="uk-margin">
        <h3>
          <IntlText id="forms.attr.fair.committee_title" />
        </h3>
        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-2@s">
            <div className="uk-margin-ssmall">
              <Field
                name="organizer"
                type="text"
                component={TextInput}
                labelId="forms.attr.fair.organizer"
              />
            </div>
            <div className="uk-margin-ssmall">
              <Field
                name="chief_committee"
                type="text"
                component={TextInput}
                labelId="forms.attr.fair.chief_committee"
              />
            </div>
          </div>
          <div className="uk-width-1-2@s">
            <Field
              name="committee"
              component={Textarea}
              labelId="forms.attr.fair.committee"
            />
          </div>
        </div>
      </div>
    );
  };

  renderPartner = () => {
    return (
      <div className="uk-margin">
        <h3>
          <IntlText id="forms.attr.fair.partner" />
        </h3>
        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-2@s">
            <div className="uk-margin-small">
              <Field
                name="supporter"
                type="text"
                component={TextInput}
                labelId="forms.attr.fair.supporter"
              />
            </div>
            <div className="uk-margin-small">
              <Field
                name="sponsorship"
                type="text"
                component={TextInput}
                labelId="forms.attr.fair.sponsorship"
              />
            </div>
          </div>
          <div className="uk-width-1-2@s">
            <Field
              name="cooperator"
              component={Textarea}
              labelId="forms.attr.fair.cooperator"
            />
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        {this.renderBasic()}

        {this.renderReception()}

        {this.renderCommittee()}

        {this.renderPartner()}

        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    constant: state.constant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default reduxForm({
  form: "fair-form",
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(FairForm));
