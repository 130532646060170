export const GalleryPath = {
  url: "/:lang/gallery",
  auth: {
    url: "/:lang/gallery/auth",
    register: {
      url: "/:lang/gallery/auth/register",
    },
    login: {
      url: "/:lang/gallery/auth/login",
    },
    forget: {
      url: "/:lang/gallery/auth/forget",
    },
    reset: {
      url: "/:lang/gallery/auth/reset",
    },
    card: {
      url: "/:lang/gallery/auth/card/:galleryId",
    },
  },
  top: {
    url: "/:lang/gallery",
  },
  artists: {
    url: "/:lang/gallery/artists",
    add: {
      url: "/:lang/gallery/artists/add",
    },
    edit: {
      url: "/:lang/gallery/artists/:artistId/edit",
    },
  },
  artworks: {
    url: "/:lang/gallery/artworks",
    add: {
      url: "/:lang/gallery/artworks/add",
    },
    edit: {
      url: "/:lang/gallery/artworks/:artworkId/edit",
    },
  },
  shows: {
    url: "/:lang/gallery/shows",
    add: {
      url: "/:lang/gallery/shows/add",
    },
    edit: {
      url: "/:lang/gallery/shows/:showId/edit",
    },
  },
  informations: {
    url: "/:lang/gallery/informations",
  },
  articles: {
    url: "/:lang/gallery/articles",
    edit: {
      url: "/:lang/gallery/articles/:articleId/edit",
    },
  },
  fairs: {
    url: "/:lang/gallery/fairs",
    edit: {
      url: "/:lang/gallery/fairs/:attendId/edit",
    },
  },
  edit: {
    url: "/:lang/gallery/edit",
  },
  locations: {
    url: "/:lang/gallery/locations",
  },
  transaction: {
    purchases: {
      url: "/:lang/gallery/transaction/purchases",
    },
    sales: {
      url: "/:lang/gallery/transaction/sales",
    },
  },
};
