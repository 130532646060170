import React, { PureComponent } from "react";
import {Field, formValueSelector, reduxForm, SubmissionError} from "redux-form";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import {getPureMessage, IntlText} from "../../util/Text";
import * as validate from "../../util/Validator";
import Select from "../../inputs/Select";
import {connect} from "react-redux";

const IS_QUALIFIED_INVOICE_ISSUER_YES = '1'
const IS_QUALIFIED_INVOICE_ISSUER_NO = '0'

const validateQualifiedInvoiceIssuerId = (value) => {
  const regex = /^T[0-9]{13}$/;
  if (!value || !regex.test(value)) {
    return getPureMessage('forms.attr.bank.qualified_invoice_issuer_id_error')
  }
  return undefined;
};

class BankForm extends PureComponent {
  state = {
    loading: false,
  };

onSubmit = (values) => {
  this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };



  componentDidMount() {

  }

  render = () => {
    const selector = formValueSelector('gallery-bank-Form');

    const { isQualifiedInvoiceIssuer } = this.props;
    const isInvoiceIssued = String(isQualifiedInvoiceIssuer) === IS_QUALIFIED_INVOICE_ISSUER_YES
    console.log('isInvoiceIssued')
    console.log(isInvoiceIssued)
    console.log('isQualifiedInvoiceIssuer')
    console.log(isQualifiedInvoiceIssuer)

    selector(this.state, 'first')



    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-2@s">
            <Field
              name="bank_name"
              type="text"
              component={TextInput}
              labelId="forms.attr.bank.name"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-width-1-2@s">
            <Field
              name="bank_branch"
              type="text"
              component={TextInput}
              labelId="forms.attr.bank.branch"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-width-1-2@s">
            <Field
              name="bank_holder"
              type="text"
              component={TextInput}
              labelId="forms.attr.bank.holder"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-width-1-2@s">
            <Field
              name="bank_number"
              type="text"
              component={TextInput}
              labelId="forms.attr.bank.number"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-width-1-2@s">
            <Field
                name="is_qualified_invoice_issuer"
                component={Select}
                labelId="forms.attr.bank.is_qualified_invoice_issuer"
                validate={[validate.required]}
                required
                placeholder={getPureMessage('forms.attr.bank.is_qualified_invoice_issuer_default')}
                options={[
                  { value: IS_QUALIFIED_INVOICE_ISSUER_YES, label: getPureMessage('forms.attr.bank.is_qualified_invoice_issuer_yes') },
                  { value: IS_QUALIFIED_INVOICE_ISSUER_NO, label: getPureMessage('forms.attr.bank.is_qualified_invoice_issuer_no') }
                ]}
            />
          </div>
          <div className="uk-width-1-2@s">
            {isInvoiceIssued &&
                (<Field
                  name="qualified_invoice_issuer_id"
                  type="text"
                  component={TextInput}
                  labelId="forms.attr.bank.qualified_invoice_issuer_id"
                  validate={[validateQualifiedInvoiceIssuerId]}
                  required
              />)
            }
          </div>
        </div>

        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>
      </form>
    );
  };
}

const selector = formValueSelector('gallery-bank-Form');

const mapStateToProps = (state) => {
  return {
    isQualifiedInvoiceIssuer: selector(state, 'is_qualified_invoice_issuer'),
  };
};

export default connect(mapStateToProps)(
    reduxForm({
      form: 'gallery-bank-Form',
      enableReinitialize: true,
    })(BankForm)
);
