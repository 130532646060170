import { INQUIRY_ARTWORK, PURCHASE_ARTWORK } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";

export const inquiry = (artworkId, param) => {
  return (dispatch) => {
    return {
      type: INQUIRY_ARTWORK,
      payload: () => {
        return Api.post(EndPoints.artworks(artworkId, "inquiry"), param);
      },
    };
  };
};

export const purchase = (artworkId, param) => {
  return (dispatch) => {
    return {
      type: PURCHASE_ARTWORK,
      payload: () => {
        return Api.post(EndPoints.artworks(artworkId, "purchase"), param);
      },
    };
  };
};
