import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setCondition } from "../../actions/cache";
import { search } from "../../actions/gallery";
import KeywordLargeForm from "../../components/forms/KeywordLargeForm";
import Meta from "../../components/layout/Meta";
import GalleryCards from "../../components/lists/GalleryCards";
import { Button } from "../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../components/util/Text";

class IndexScreen extends Component {
  state = {
    galleries: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
    place: null,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.readMore(true);
    }
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        page: this.state.meta.current_page + 1,
        keyword: this.state.keyword,
        pagesize: 24,
        sort_method: "has_thumnail",
        excludes: this.state.galleries
          ? this.state.galleries.map((a) => a.id).join(",")
          : "0",
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState(
            {
              galleries:
                this.state.galleries && !refresh
                  ? this.state.galleries.concat(value.data)
                  : value.data,
              meta: value.response.data.meta,
              loading: false,
            },
            () => {
              this.props.actions.setCondition(this.state);
            },
          );
        }
      })
      .catch((error) => console.log(error));
  };

  onSearch = (value) => {
    this.setState(
      {
        keyword: value.keyword,
        galleries: null,
        meta: {
          current_page: 0,
        },
      },
      () => {
        this.readMore(true);
      },
    );
  };

  render = () => {
    return (
      <div>
        <Meta
          title={getPureMessage("galleries.title")}
          description={getPureMessage("galleries.description")}
        />
        <div className="">
          <h1 className="uk-heading-mark uk-margin-remove">
            <IntlText id="galleries.title" />
          </h1>
          {this.state.meta.total != null && (
            <span className="uk-text-muted">
              <IntlText
                id="galleries.count"
                values={{ num: this.state.meta.total }}
              />
              {this.state.place && (
                <IntlText
                  id="galleries.near"
                  values={{ place: this.state.place }}
                />
              )}
            </span>
          )}
        </div>
        <div className="uk-margin-medium">
          {/*<LocationForm onChange={ this.onSearch } />*/}
          <KeywordLargeForm onSubmit={this.onSearch} />
        </div>
        <div className="uk-margin-medium">
          <GalleryCards galleries={this.state.galleries} />
        </div>
        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-medium uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
                ga={{ category: "button", action: "galleries", label: "more" }}
              >
                <IntlText id="forms.more" />
              </Button>
            </div>
          )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
