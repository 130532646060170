const messages = {
  purchase: {
    contacted: "Contacted",
    agreed: "Agreed",
    paid: "Paid",
    canceled: "Canceled",
    error: "Error",
  },
  status: {
    private: "Private",
    public: "Published",
  },
  sales_status: {
    editing: "Editing",
    not_sale: "Not for sale",
    contact: "Contact(Hide Price)",
    for_sale: "Contact(Open Price)",
    available: "For Sale",
    sold: "Sold",
  },
  fair_only_status: {
    open: "Open",
    fair_only: "Fair Only",
    fair_vip_only: "Fair VIP Only",
  },
};

export default messages;
