import {
  SEARCH_ARTISTS,
  GET_ARTIST,
  ADD_ARTIST,
  EDIT_ARTIST,
  ATTACH_ARTIST,
  DETACH_ARTIST,
  DELETE_ARTIST_IMAGE,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param = {}) => {
  return (dispatch) => {
    return {
      type: SEARCH_ARTISTS,
      payload: () => {
        return Api.get(EndPoints.galleryArtists(), param);
      },
    };
  };
};

export const get = (artistId) => {
  return (dispatch) => {
    return {
      type: GET_ARTIST,
      payload: () => {
        return Api.get(EndPoints.galleryArtists(artistId));
      },
    };
  };
};

export const add = (data) => {
  return (dispatch) => {
    return {
      type: ADD_ARTIST,
      payload: () => {
        return Api.post(EndPoints.galleryArtists(), data);
      },
    };
  };
};

export const edit = (artistId, data) => {
  return (dispatch) => {
    return {
      type: EDIT_ARTIST,
      payload: () => {
        return Api.put(EndPoints.galleryArtists(artistId), data);
      },
    };
  };
};

export const attach = (artistId, data = {}) => {
  return (dispatch) => {
    return {
      type: ATTACH_ARTIST,
      payload: () => {
        return Api.put(EndPoints.galleryArtists(artistId, "treat"), data);
      },
    };
  };
};

export const detach = (artistId) => {
  return (dispatch) => {
    return {
      type: DETACH_ARTIST,
      payload: () => {
        return Api.delete(EndPoints.galleryArtists(artistId, "treat"));
      },
    };
  };
};

export const deleteImage = (articleId) => {
  return (dispatch) => {
    return {
      type: DELETE_ARTIST_IMAGE,
      payload: () => {
        return Api.delete(EndPoints.galleryArtists(articleId, "image"));
      },
    };
  };
};
