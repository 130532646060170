const messages = {
  layout: {
    menu: {
      dashboard: "ダッシュボード",
      artists: "アーティスト",
      all_artists: "アーティスト一覧",
      add_artist: "アーティスト追加",
      artworks: "作品",
      all_artworks: "作品一覧",
      add_artwork: "作品追加",
      shows: "展示",
      all_shows: "展示一覧",
      add_show: "展示追加",
      info_and_article: "Pick Up・記事",
      informations: "Pick Up",
      articles: "記事",
      fairs: "フェア",
      all_fairs: "全てのフェア",
      transactions: "購買・取引",
      purchases: "購入情報",
      sales: "販売履歴",
      setting: "設定",
      edit_profile: "プロフィール",
      term: "利用規約",
      manual: "マニュアル",
      location: "住所情報",
      sign_out: "ログアウト",
      preview: "ギャラリーページをプレビュー",
    },
  },
  auth: {
    login: {
      header: "Gallery's Login",
      ifyouforget: "パスワードをお忘れの場合は、",
      resethere: "こちらからリセットします。",
      donthave: "Art Scenesのアカウントがない場合は",
      contact: "お問い合わせフォームよりご連絡ください。",
      contactus: "問い合わせる",
      term: "ギャラリー様向け利用規約",
      agree: "同意する",
    },
    reset: {
      header: "パスワードリセット",
      back: "ログイン画面へ戻る",
      completed: "リセットが完了しました。",
      please: "新しいパスワードで再度ログインしてください。",
    },
    forget: {
      header: "パスワードリセット",
      message:
        "ご登録いただいたメールアドレスを入力してください。メールが届かなかった場合は、異なるメールアドレスでのご登録がないかご確認ください。",
      back: "ログイン画面へ戻る",
      completed: "リセット用メールを送信しました",
      please:
        "ご入力されたメールアドレスにリセット用リンクを送信しました。記載された内容に従って、パスワードの再設定を行ってください。メールが届かなかった場合は、異なるメールアドレスでのご登録がないかご確認ください。",
    },
  },
  dashboard: {
    title: "ダッシュボード",
    plan: "プラン",
    invitedFair: "招待されたフェア",
    transaction: "購入申請",
    information: "新着情報",
    newRequest: "{num}件の購入申請が届いています。",
    nothing: "新着の購入申請はありません。",
    check: "確認する",
  },
  edit: {
    title: "プロフィールの編集",
    gallery_info: "ギャラリー情報",
    settings: "設定",
    payment: "入金口座",
    images: "画像",
    cover: "カバー画像",
    logo: "ロゴ",
    authorize: "アカウント情報",
    addcontact: "連絡先の追加",
    unsubscribe: "退会をご希望の場合はこちらからご連絡ください。",
    contact: "問い合わせフォーム",
    plan: "プラン",
  },
  location: {
    title: "住所情報",
    add_location: "住所の追加",
    edit_location: "住所の編集",
    no: "住所は登録されていません",
  },
  sales: {
    title: "売上管理",
    monthly: "今月の売上",
    purchase: "購入件数",
    sales: "売上($)",
    revenue: "利益($)",
    history: "過去の売上",
  },
  purchases: {
    title: "購入情報",
    new: "新しい購入申請",
    past: "過去の購入申請",
    confirm: "購入申請を確定しますか？",
    by_admins: "購入の管理はArt Scenes運営側で対応いたします",
  },
  fairs: {
    title: "フェア一覧",
  },
  fair: {
    view_detail: "フェアの詳細",
    thumnail: "ギャラリー画像",
    artworks: "出展する作品",
    image: "画像",
    description: "詳細",
    actions: "アクション",
    section: "展示するセクション",
    add: "展示作品を追加",
    edit: "展示作品を編集",
  },
  articles: {
    title: "記事一覧",
    add: "記事の新規作成",
  },
  article: {
    title: "記事の編集",
    basic: "記事情報",
    thumnail: "トップ画像",
    description: "本文",
  },
  informations: {
    title: "Pick Up",
    add: "Pick Upの追加",
    edit: "Pick Upの編集",
    thumnail: "サムネイル",
  },
  shows: {
    title: "展示一覧",
  },
  show: {
    title: "展示の新規登録",
    edit_title: "展示の編集",
    artist: "アーティストを選択",
    artwork: "作品を選択",
    info: "展示情報",
    images: "画像",
    photos: "写真",
  },
  artworks: {
    title: "作品一覧",
    add_title: "作品の新規登録",
    edit_title: "作品の編集",
    artist: "アーティストの選択",
    recommend: "こちらの作品をお探しですか？",
    sale_info: "販売情報",
    images: "作品画像",
  },
  artists: {
    title: "アーティスト一覧",
    add_title: "アーティストの新規登録",
    edit_title: "アーティストの編集",
    profile: "プロフィール",
    thumnail: "サムネイル画像",
    recommend: "こちらのアーティストをお探しですか？",
    new_artist: "新しいアーティスト",
  },
};

export default messages;
