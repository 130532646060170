import {
  GET_GALLERY,
  APPROVE_TERM,
  UPDATE_GALLERY_DATA,
  UPDATE_GALLERY_DESC,
  UPDATE_GALLERY_AUTH,
  UPDATE_GALLERY_PAYMENT,
  UPDATE_GALLERY_CONTACT_EMAIL ,
  DELETE_GALLERY_IMAGE,
  REGISTER_GALLERY_CARD,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";
import { setData } from "./auth";

export const getData = () => {
  return (dispatch) => {
    return {
      type: GET_GALLERY,
      payload: () => {
        return Api.get(EndPoints.gallery()).then((response) => {
          if (response.status < 300) {
            dispatch(setData(response.data));
          }
          return response;
        });
      },
    };
  };
};

export const updateData = (data) => {
  return (dispatch) => {
    return {
      type: UPDATE_GALLERY_DATA,
      payload: () => {
        return Api.put(EndPoints.gallery(), data);
      },
    };
  };
};

export const updateDesc = (data) => {
  return (dispatch) => {
    return {
      type: UPDATE_GALLERY_DESC,
      payload: () => {
        return Api.put(EndPoints.gallery("desc"), data);
      },
    };
  };
};

export const updateAuth = (data) => {
  return (dispatch) => {
    return {
      type: UPDATE_GALLERY_AUTH,
      payload: () => {
        return Api.put(EndPoints.gallery("auth"), data);
      },
    };
  };
};

export const updateContactEmail = (data) => {
  return (dispatch) => {
    return {
      type: UPDATE_GALLERY_CONTACT_EMAIL,
      payload: () => {
        return Api.put(EndPoints.gallery("contact_email"), data);
      },
    };
  };
};

export const updatePayment = (data) => {
  return (dispatch) => {
    return {
      type: UPDATE_GALLERY_PAYMENT,
      payload: () => {
        return Api.put(EndPoints.gallery("payment"), data);
      },
    };
  };
};

export const approve = () => {
  return (dispatch) => {
    return {
      type: UPDATE_GALLERY_PAYMENT,
      payload: () => {
        return Api.put(EndPoints.gallery("approve"));
      },
    };
  };
};

export const deleteImage = (param) => {
  return (dispatch) => {
    return {
      type: DELETE_GALLERY_IMAGE,
      payload: () => {
        return Api.delete(EndPoints.gallery("image"), param);
      },
    };
  };
};

export const registerCard = (galleryId, data) => {
  return (dispatch) => {
    return {
      type: REGISTER_GALLERY_CARD,
      payload: () => {
        return Api.put(EndPoints.gallery("payment") + "/" + galleryId, data);
      },
    };
  };
};
