import {
  POST_GALLERY_LOGIN,
  POST_GALLERY_LOGOUT,
  SET_GALLERY_DATA,
  FORGET_GALLERY_PASSWORD,
  RESET_GALLERY_PASSWORD,
} from "../../constants/type";
import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const login = (data) => {
  return (dispatch) => {
    return {
      type: POST_GALLERY_LOGIN,
      payload: () => {
        return Api.post(EndPoints.gallery("auth"), data).then((response) => {
          if (response.status < 300) {
            dispatch(setData(response.data));
          }
          return response;
        });
      },
    };
  };
};

export const forget = (data) => {
  return (dispatch) => {
    return {
      type: FORGET_GALLERY_PASSWORD,
      payload: () => {
        return Api.post(EndPoints.gallery("reset"), data);
      },
    };
  };
};

export const reset = (data) => {
  return (dispatch) => {
    return {
      type: RESET_GALLERY_PASSWORD,
      payload: () => {
        return Api.put(EndPoints.gallery("reset"), data);
      },
    };
  };
};

export const logout = () => {
  return (dispatch) => {
    return {
      type: POST_GALLERY_LOGOUT,
      payload: () => {
        return Api.delete(EndPoints.gallery("auth"));
      },
    };
  };
};

export const setData = (data) => {
  return {
    type: SET_GALLERY_DATA,
    data: data,
  };
};
