import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Path } from "../../constants/path";
import NotFoundScreen from "../other/NotFoundScreen";
import AlipayScreen from "./AlipayScreen";
import IndexScreen from "./IndexScreen";
import InquiriedScreen from "./InquiriedScreen";
import ThanksScreen from "./ThanksScreen";
import ViewScreen from "./ViewScreen";

class BaseScreen extends PureComponent {
  render = () => {
    return (
      <div className="uk-section">
        <div className="uk-container">
          <Switch>
            <Route exact path={Path.artworks.url} component={IndexScreen} />
            <Route exact path={Path.artworks.view.url} component={ViewScreen} />
            <Route
              exact
              path={Path.artworks.view.thanks.url}
              component={ThanksScreen}
            />
            <Route
              exact
              path={Path.artworks.view.inquiried.url}
              component={InquiriedScreen}
            />
            <Route
              exact
              path={Path.artworks.view.alipay.url}
              component={AlipayScreen}
            />
            <Route component={NotFoundScreen} />
          </Switch>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseScreen),
);
