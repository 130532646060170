import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArticles } from "../../actions/article";
import { setCondition } from "../../actions/cache";
import Meta from "../../components/layout/Meta";
import ArticleCards from "../../components/lists/ArticleCards";
import { Button } from "../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../components/util/Text";

class ArticlesScreen extends PureComponent {
  state = {
    articles: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.readMore(true);
    }
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .searchArticles({
        fair_id: this.props.fair.id,
        page: this.state.meta.current_page + 1,
        sort: "posted_at",
        order: "desc",
        pagesize: 24,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            articles:
              this.state.articles && !refresh
                ? this.state.articles.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
          this.props.actions.setCondition(this.state);
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <Meta
          title={getPureMessage("fairs.articles.meta") + this.props.fair.title}
          description={this.props.fair.description}
          image={this.props.fair.thumnail_url}
        />
        <h1 className="uk-heading-mark uk-margin-remove uk-h2">
          <IntlText
            id="fairs.articles.title"
            values={{ title: this.props.fair.title }}
          />
        </h1>
        <div className="uk-margin-medium">
          <ArticleCards articles={this.state.articles} />
        </div>

        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-medium uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
                ga={{
                  category: "button",
                  action: "fair_articles",
                  label: "more",
                }}
              >
                <IntlText id="forms.more" />
              </Button>
            </div>
          )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchArticles: (...param) => dispatch(searchArticles(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArticlesScreen),
);
