import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Path } from "../../constants/path";
import ContactScreen from "./ContactScreen";
import EstimatedDeliveryChargeScreen from "./EstimatedDeliveryChargeScreen";
import LawScreen from "./LawScreen";
import NotFoundScreen from "./NotFoundScreen";
import PrivacyScreen from "./PrivacyScreen";
import GalleryTermScreen from "./term/GalleryScreen";
import TermScreen from "./TermScreen";

class BaseScreen extends PureComponent {
  render = () => {
    return (
      <div className="uk-section">
        <div className="uk-container uk-container-small">
          <Switch>
            <Route
              exact
              path={Path.other.contact.url}
              component={ContactScreen}
            />
            <Route exact path={Path.other.term.url} component={TermScreen} />
            <Route exact path={Path.other.law.url} component={LawScreen} />
            <Route
              exact
              path={Path.other.estimated_delivery_charge.url}
              component={EstimatedDeliveryChargeScreen}
            />
            <Route
              exact
              path={Path.other.privacy.url}
              component={PrivacyScreen}
            />

            <Route
              exact
              path={Path.other.term.gallery.url}
              component={GalleryTermScreen}
            />
            <Route component={NotFoundScreen} />
          </Switch>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseScreen),
);
