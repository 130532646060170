import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchAttend } from "../../actions/gallery/attend";
import { logout } from "../../actions/gallery/auth";
import { showConfirm } from "../../actions/message";
import Meta from "../../components/layout/Meta";
import { Link } from "../../components/util/Clickable";
import { IntlText } from "../../components/util/Text";
import { ExternalPath, Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import AddArtistScreen from "./artists/AddScreen";
import EditArtistScreen from "./artists/EditScreen";
import ArtistsScreen from "./artists/IndexScreen";
import AddArtworkScreen from "./artworks/AddScreen";
import EditArtworkScreen from "./artworks/EditScreen";
import ArtworksScreen from "./artworks/IndexScreen";
import EditFairScreen from "./fairs/EditScreen";
import FairsScreen from "./fairs/IndexScreen";
import ArticlesScreen from "./media/ArticlesScreen";
import EditArticleScreen from "./media/EditArticleScreen";
import InformationsScreen from "./media/InformationsScreen";
import EditScreen from "./other/EditScreen";
import LocationsScreen from "./other/LocationsScreen";
import TopScreen from "./other/TopScreen";
import AddShowScreen from "./shows/AddScreen";
import EditShowScreen from "./shows/EditScreen";
import ShowsScreen from "./shows/IndexScreen";
import PurchasesScreen from "./transaction/PurchasesScreen";
import SalesScreen from "./transaction/SalesScreen";

declare var UIkit;

class BaseScreen extends PureComponent {
  state = {
    attends: [],
  };

  logout = () => {
    this.props.actions.showConfirm(
      <IntlText id="gallery.layout.menu.sign_out" />,
      null,
      (confirmed) => {
        if (confirmed) {
          this.props.actions
            .logout()
            .then(({ value, action }) => {
              if (value.status < 300) {
                this.props.history.push(
                  PathGenerator.getLocalPath(Path.gallery.auth.login.url),
                );
              }
            })
            .catch((error) => console.log(error));
        }
      },
    );
  };

  componentDidMount = () => {
    this.props.actions
      .searchAttend({
        start_from: new Date(),
      })
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            attends: value.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.sidebar) {
      UIkit.offcanvas(this.sidebar).hide();
    }
  };

  renderSidebar = () => {
    return (
      <div>
        <ul className="uk-nav-default uk-list uk-list-divider" data-uk-nav>
          <li className="">
            <Link className="" to={Path.gallery.top.url}>
              <IntlText id="gallery.layout.menu.dashboard" />
            </Link>
          </li>
          <li className="uk-parent">
            <a className="" href="#">
              <IntlText id="gallery.layout.menu.artists" />
            </a>
            <ul className="uk-nav-sub uk-margin-remove">
              <li>
                <Link className="" to={Path.gallery.artists.url}>
                  <span data-uk-icon="icon:users;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.all_artists" />
                  </span>
                </Link>
              </li>
              <li>
                <Link className="" to={Path.gallery.artists.add.url}>
                  <span data-uk-icon="icon:plus;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.add_artist" />
                  </span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="uk-parent">
            <a className="" href="#">
              <IntlText id="gallery.layout.menu.artworks" />
            </a>
            <ul className="uk-nav-sub uk-margin-remove">
              <li>
                <Link className="" to={Path.gallery.artworks.url}>
                  <span data-uk-icon="icon:thumbnails;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.all_artworks" />
                  </span>
                </Link>
              </li>
              <li>
                <Link className="" to={Path.gallery.artworks.add.url}>
                  <span data-uk-icon="icon:plus;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.add_artwork" />
                  </span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="uk-parent">
            <a className="" href="#">
              <IntlText id="gallery.layout.menu.shows" />
            </a>
            <ul className="uk-nav-sub uk-margin-remove">
              <li>
                <Link className="" to={Path.gallery.shows.url}>
                  <span data-uk-icon="icon:home;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.all_shows" />
                  </span>
                </Link>
              </li>
              <li>
                <Link className="" to={Path.gallery.shows.add.url}>
                  <span data-uk-icon="icon:plus;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.add_show" />
                  </span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="uk-parent">
            <a className="" href="#">
              <IntlText id="gallery.layout.menu.info_and_article" />
            </a>
            <ul className="uk-nav-sub uk-margin-remove">
              <li>
                <Link className="" to={Path.gallery.informations.url}>
                  <span data-uk-icon="icon:info;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.informations" />
                  </span>
                </Link>
              </li>
              <li>
                <Link className="" to={Path.gallery.articles.url}>
                  <span data-uk-icon="icon:copy;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.articles" />
                  </span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="uk-parent">
            <a className="" href="#">
              <IntlText id="gallery.layout.menu.fairs" />
            </a>
            <ul className="uk-nav-sub uk-margin-remove">
              <li>
                <Link className="" to={Path.gallery.fairs.url}>
                  <IntlText id="gallery.layout.menu.all_fairs" />
                </Link>
              </li>
              {this.state.attends &&
                this.state.attends.map((attend, index) => {
                  const fair = Formatter.mergeDesc(
                    attend.fair,
                    attend.fair.fair_descs,
                    this.props.language.view,
                  );
                  if (fair == null) {
                    return;
                  }
                  return (
                    <li key={index}>
                      <Link
                        className=""
                        to={PathGenerator.getLocalPath(
                          Path.gallery.fairs.edit.url,
                          { attendId: attend.id },
                        )}
                      >
                        <span data-uk-icon="icon: location;" />
                        <span className="uk-margin-ssmall-left">
                          {fair.title}
                        </span>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
          <li
            className={
              "uk-parent " +
              (this.props.gallery.purchases_count > 0 && "uk-open")
            }
          >
            <a className="" href="#">
              <IntlText id="gallery.layout.menu.transactions" />
            </a>
            <ul className="uk-nav-sub uk-margin-remove">
              <li>
                <Link className="" to={Path.gallery.transaction.purchases.url}>
                  <span className="uk-margin-ssmall-right">
                    <IntlText id="gallery.layout.menu.purchases" />
                  </span>
                  {this.props.gallery.purchases_count > 0 && (
                    <span className="uk-badge">
                      {this.props.gallery.purchases_count}
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link className="" to={Path.gallery.transaction.sales.url}>
                  <IntlText id="gallery.layout.menu.sales" />
                </Link>
              </li>
            </ul>
          </li>
          <li className="uk-parent">
            <a className="" href="#">
              <IntlText id="gallery.layout.menu.setting" />
            </a>
            <ul className="uk-nav-sub uk-margin-remove">
              <li>
                <Link className="" to={Path.gallery.edit.url}>
                  <span data-uk-icon="icon: user;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.edit_profile" />
                  </span>
                </Link>
              </li>
              <li>
                <Link className="" to={Path.gallery.locations.url}>
                  <span data-uk-icon="icon: location;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.location" />
                  </span>
                </Link>
              </li>
              <li>
                <Link href={ExternalPath.manual} target="_blank">
                  <span data-uk-icon="icon: link;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText
                      id="gallery.layout.menu.manual"
                      style="white-space: pre;"
                    />
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  className=""
                  to={Path.other.term.gallery.url}
                  target="_blank"
                >
                  <span data-uk-icon="icon:file-text;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="gallery.layout.menu.term" />
                  </span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="">
            <Link className="" onClick={this.logout}>
              <IntlText id="gallery.layout.menu.sign_out" />
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  render = () => {
    return (
      <div className="gallery_admin">
        <Meta title="For Gallery" />
        <div className="uk-grid-collapse uk-grid-match" data-uk-grid>
          <div
            className="uk-width-medium@m uk-background-secondary uk-light uk-visible@m"
            style={{ minHeight: "calc(100vh - 56px)" }}
          >
            <div className="uk-padding@s uk-padding-small">
              <div>{this.renderSidebar()}</div>
            </div>
          </div>
          <div className="uk-width-expand">
            <div className="uk-padding@s uk-padding-small">
              <Switch>
                <Route
                  exact
                  path={Path.gallery.top.url}
                  component={TopScreen}
                />
                <Route
                  exact
                  path={Path.gallery.edit.url}
                  component={EditScreen}
                />
                <Route
                  exact
                  path={Path.gallery.locations.url}
                  component={LocationsScreen}
                />
                <Route
                  exact
                  path={Path.gallery.transaction.purchases.url}
                  component={PurchasesScreen}
                />
                <Route
                  exact
                  path={Path.gallery.transaction.sales.url}
                  component={SalesScreen}
                />

                <Route
                  exact
                  path={Path.gallery.artists.url}
                  component={ArtistsScreen}
                />
                <Route
                  exact
                  path={Path.gallery.artists.add.url}
                  component={AddArtistScreen}
                />
                <Route
                  exact
                  path={Path.gallery.artists.edit.url}
                  component={EditArtistScreen}
                />

                <Route
                  exact
                  path={Path.gallery.artworks.url}
                  component={ArtworksScreen}
                />
                <Route
                  exact
                  path={Path.gallery.artworks.add.url}
                  component={AddArtworkScreen}
                />
                <Route
                  exact
                  path={Path.gallery.artworks.edit.url}
                  component={EditArtworkScreen}
                />

                <Route
                  exact
                  path={Path.gallery.shows.add.url}
                  component={AddShowScreen}
                />
                <Route
                  exact
                  path={Path.gallery.shows.edit.url}
                  component={EditShowScreen}
                />
                <Route
                  exact
                  path={Path.gallery.shows.url}
                  component={ShowsScreen}
                />

                <Route
                  exact
                  path={Path.gallery.informations.url}
                  component={InformationsScreen}
                />
                <Route
                  exact
                  path={Path.gallery.articles.url}
                  component={ArticlesScreen}
                />
                <Route
                  exact
                  path={Path.gallery.articles.edit.url}
                  component={EditArticleScreen}
                />

                <Route
                  exact
                  path={Path.gallery.fairs.url}
                  component={FairsScreen}
                />
                <Route
                  exact
                  path={Path.gallery.fairs.edit.url}
                  component={EditFairScreen}
                />
              </Switch>
            </div>
          </div>
        </div>
        <div
          id="gallery-menu"
          data-uk-offcanvas="flip: true; overlay: true"
          ref={(sidebar) => (this.sidebar = sidebar)}
        >
          <div className="uk-offcanvas-bar">
            <button
              className="uk-offcanvas-close"
              type="button"
              data-uk-close
            ></button>
            {this.renderSidebar()}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showConfirm: (...data) => dispatch(showConfirm(...data)),
      logout: () => dispatch(logout()),
      searchAttend: (...param) => dispatch(searchAttend(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseScreen),
);
