import {
  SEARCH_ARTWORKS,
  GET_ARTWORK,
  ADD_ARTWORK,
  EDIT_ARTWORK,
  SALE_ARTWORK,
  UNSALE_ARTWORK,
  DELETE_ARTWORK_IMAGE,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param = {}) => {
  return (dispatch) => {
    return {
      type: SEARCH_ARTWORKS,
      payload: () => {
        return Api.get(EndPoints.galleryArtworks(), param);
      },
    };
  };
};

export const get = (artworkId) => {
  return (dispatch) => {
    return {
      type: GET_ARTWORK,
      payload: () => {
        return Api.get(EndPoints.galleryArtworks(artworkId));
      },
    };
  };
};

export const add = (data) => {
  return (dispatch) => {
    return {
      type: ADD_ARTWORK,
      payload: () => {
        return Api.post(EndPoints.galleryArtworks(), data);
      },
    };
  };
};

export const edit = (artworkId, data) => {
  return (dispatch) => {
    return {
      type: EDIT_ARTWORK,
      payload: () => {
        return Api.put(EndPoints.galleryArtworks(artworkId), data);
      },
    };
  };
};

export const sale = (artworkId, data = {}) => {
  return (dispatch) => {
    return {
      type: SALE_ARTWORK,
      payload: () => {
        return Api.put(EndPoints.galleryArtworks(artworkId, "sale"), data);
      },
    };
  };
};

export const unsale = (artworkId) => {
  return (dispatch) => {
    return {
      type: UNSALE_ARTWORK,
      payload: () => {
        return Api.delete(EndPoints.galleryArtworks(artworkId, "sale"));
      },
    };
  };
};

export const deleteImage = (artworkId, artworkImageId) => {
  return (dispatch) => {
    return {
      type: DELETE_ARTWORK_IMAGE,
      payload: () => {
        return Api.delete(
          EndPoints.galleryArtworkImages(artworkId, artworkImageId),
        );
      },
    };
  };
};
