import React, { Component } from "react";
import Geocode from "react-geocode";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);

const GMap = withScriptjs(
  withGoogleMap((props) => {
    return (
      <GoogleMap defaultZoom={14} defaultCenter={props.center}>
        {props.isMarkerShown && <Marker position={props.center} />}
      </GoogleMap>
    );
  }),
);

class WidthMap extends Component {
  state = {
    address: null,
    center: null,
  };

  componentDidMount = () => {
    this.geoCode(this.props.address);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.state.address == nextProps.address) {
      return;
    }
    this.geoCode(nextProps.address);
  };

  geoCode = (address) => {
    if (address == null) {
      return;
    }
    this.setState({
      address: address,
    });
    Geocode.fromAddress(address).then(
      (response) => {
        this.setState({
          center: response.results[0].geometry.location,
        });
      },
      (error) => {
        console.error(error);
      },
    );
  };

  render = () => {
    if (!this.state.center) {
      return null;
    }
    return (
      <GMap
        googleMapURL={
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.REACT_APP_GOOGLE_KEY +
          "&v=3.exp&libraries=geometry,drawing,places"
        }
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `300px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        center={this.state.center}
        isMarkerShown={this.props.isMarkerShown}
      />
    );
  };
}

export default WidthMap;
