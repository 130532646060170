const messages = {
  plan: {
    current_id: "<small>Your current plan is </small> {plan_name} ({price})",
    current_card: "Current Card",
    expired: "Expired",
    edit_payment: "Edit Payment",
    caution_header:
      'Your trial account will be expired at <span className="uk-text-bold">{date}</span>',
    caution_text:
      "If your account is expired, you will restrict to add artist, artwork, information, article, contact and sell collectors.<br/>We recommend to upgrade within this trial term.<br/>{plan_name}: Initial {init_price}, {price} ( If you contract in this year(2018), you will be free for three months )",
    caution_button: "Add credit card & Upgrade to basic plan.",
    trial: "Trial",
    free: "Free",
    basic: "Basic Plan",
    unit: " yen / month",
    init_unit: "yen",
    initial: "Initial",
  },
  location: {
    address: "Address",
    tel: "Tel",
    hp: "Homepage",
    email: "Email",
  },
  fair: {
    artworks: "Artworks",
  },
  button: {
    edit: "Edit",
    delete: "Delete",
  },
};

export default messages;
