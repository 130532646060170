const messages = {
  layout: {
    more: "More",
    search: "フリーワード検索",
    contents: "Contents",
    terms: "Terms",
    term_of_use: "利用規約",
    privacy: "プライバシーポリシー",
    law: "特定商取引法に関する記載",
    partner: "Partnering w Art Scenes",
    login: {
      gallery: "ギャラリー ログイン",
      fair: "フェア ログイン",
      medium: "メディア ログイン",
    },
    about_menu: "Art Scenes",
    about: "サービス概要",
    contact: "お問い合わせ",
    estimated_delivery_charge: "配送料の目安",
    company: "運営会社",
    recently: "閲覧履歴",
    collectors: "For Collectors",
    collector_login: "コレクター ログイン",
    likes: "Likes",
    footer_text:
      "Art Scenesはアートコレクターやギャラリーなどアートに関わる全ての人のためのWebサービスです。<br/>世界中で流通しているアートの情報収集や購入はもちろん、アーティストや作品・展示・ギャラリーなどアートに関するあらゆる情報を手に入れることができます。",
  },

  menu: {
    overview: "OVERVIEW",
    shows: "SHOWS",
    artworks: "ARTWORKS",
    artists: "ARTISTS",
    articles: "ARTICLES",
    contact: "CONTACT",
    galleries: "GALLERIES",
    info: "INFO",
    history: "HISTORY",
    photos: "PHOTOS",
  },

  root: {
    news: "ニュース",
    fairs: "注目のフェア",
    artworks: "販売中の作品",
    shows: "新着展示",
    articles: "新着記事",
  },

  artworks: {
    count: "{num} 作品",
    search: "詳細検索",
    title: "全ての作品",
    description:
      "Art Scenesは世界中のアート作品を掲載するWebサービスです。Art Scenesを通して世界中のアート作品のなかからお気に入りの1枚を見つけ、購入することができます。",
    overview: "概要",
    other_by_artists: "{artists}のその他の作品",
    other_by_gallery: "{gallery}のその他の作品",
    artists: "アーティスト - {title}",
    gallery: "ギャラリー - {title}",
    inquiry: {
      title: "お問い合わせ有難うございます",
      lead: "入力いただいたメールアドレスに確認メールを送付いたします。<br/>メールの内容をご確認ください。<br/>30分以内を目安にメールが届かない場合は、お手数ですが",
      next_step: "Next Step",
      next_lead: "返信をお待ちください",
      will_send: "から1週間以内を目安に連絡が届きます。",
      not_get: "もし連絡がない場合は、お手数ですが",
      contact_us: "Art Scenesへご連絡ください。",
      sorry: "申し訳ございません",
      soldout: "この作品はすでに販売されていません。",
      back: "作品ページへ戻る",
      artwork: "問合せ頂いた作品",
    },
    alipay: {
      invaild: "お支払い情報が無効です<br/>再度お申し込みください。",
      checking:
        "支払い情報を確認しています...<br/>ページを閉じずにお待ちください。",
      back: "作品ページへ戻る",
    },
    thanks: {
      title: "ご購入有難うございます",
      lead: "入力いただいたメールアドレスに確認メールを送付いたします。<br/>メールの内容をご確認ください。<br/>30分以内を目安にメールが届かない場合は、お手数ですが",
      next_step: "Next Step",
      next_lead: "購入リクエストの承認をお待ちください",
      will_send: "から1週間以内を目安に連絡が届きます。",
      not_get: "もし連絡がない場合は、お手数ですが",
      contact_us: "Art Scenesへご連絡ください。",
      sorry: "申し訳ございません",
      soldout: "この作品はすでに販売されていません。",
      back: "作品ページへ戻る",
      artwork: "問合せ頂いた作品",
    },
  },
  galleries: {
    title: "全てのギャラリー",
    description:
      "Art Scenesは世界中のギャラリーを掲載するWebサービスです。Art Scenesを通して世界中のギャラリーの中からお気に入りのアート作品を探し、購入することができます。",
    count: "{num} ギャラリー",
    near: ",{place}",
    about: "{name}の概要",
    show: "展示一覧 - {name}",
    fair: "出展したフェア - {name}",
    represented_artists: "代表するアーティスト - {name}",
    articles_about: "関連記事 - {name}",
    shows: {
      meta: "展示一覧 - ",
      title: "展示一覧 - {name}",
      current: "開催中の展示 - {name}",
      upcoming: "開催予定の展示 - {name}",
      past: "過去の展示 - {name}",
    },
    artworks: {
      meta: "作品一覧 - ",
      title: "作品一覧 - {name}",
      count: "{num} 作品",
    },
    artists: {
      meta: "アーティスト一覧 - ",
      represents: "代表するアーティスト - {name}",
      available: "全てのアーティスト - {name}",
    },
    articles: {
      meta: "記事一覧 - ",
      title: "{name}関する記事",
    },
    contact: {
      meta: "お問い合わせ ",
      title: "お問い合わせ",
      location: "施設情報 - {name}",
    },
  },
  fairs: {
    title: "全てのフェア",
    description:
      "Art Scenesは世界中のフェアを掲載するWebサービスです。Art Scenesを通して世界中のフェアの中からお気に入りのアート作品を探し、購入することができます。",
    current: "開催中のフェア",
    past_upcomming: "開催予定・開催済みのフェア",
    past: "開催済みのフェア",
    upcoming: "開催予定のフェア",
    overview: {
      about: "{title}の概要",
      schedule: "スケジュール",
      place: "アクセス",
      articles: "{title} に関する記事",
      related: "{title} に関連するフェア",
      photos: "{title} の写真",
    },
    galleries: {
      meta: "出展ギャラリー - ",
      title: "出展ギャラリー - {title}",
    },
    artworks: {
      meta: "出展作品 - ",
      title: "出展作品 - {title}",
      display: "表示切替え :　",
      default: "デフォルト",
      gallery: "ギャラリー",
    },
    photos: {
      meta: "写真一覧 - ",
      title: "写真一覧 - {title}",
    },
    articles: {
      meta: "関連記事 - ",
      title: "関連記事 - {title}",
    },
    info: {
      meta: "開催概要 - ",
      title: "開催概要 - {title}",
      buy: "チケットを購入する",
      reception: "レセプション情報 - {title}",
      committee: "実行委員会 - {title}",
      partner: "パートナー - {title}",
      information: "その他の情報 - {title}",
    },
  },
  artists: {
    meta: "全てのアーティスト",
    title: "全てのアーティスト",
    description:
      "Art Scenesは世界中のアーティストを掲載するWebサービスです。Art Scenesを通して世界中のアーティストの中からお気に入りのアート作品を探し、購入することができます。",
    feature: "FEATURED ARTISTS",
    overview: {
      biography: "このアーティストについて - {name}",
      history: "来歴 - {name}",
      artworks: "{name} の作品一覧",
    },
    artworks: {
      meta: "の作品一覧",
      title: "作品一覧 - {name}",
    },
    history: {
      meta: "の来歴",
      title: "来歴 - {name}",
      shows: "展示",
      fairs: "フェア",
    },
    articles: {
      meta: "に関する記事",
      title: "記事一覧 - {name}",
    },
    shows: {
      meta: "の参加する展示・フェア",
      current: "開催中の展示・フェア - {name}",
      upcoming: "開催予定の展示・フェア - {name}",
      past: "開催済みの展示・フェア - {name}",
    },
  },
  shows: {
    meta: "全ての展示",
    title: "全ての展示",
    description:
      "Art Scenesは世界中の作品展示に関する情報を掲載するWebサービスです。Art Scenesを通して世界中の展示の中からお気に入りのアート作品を探し、購入することができます。",
    count: "{count} 件 {place}",
    inplace: ", {place}",
    current: "開催中: {count} 件 {place}",
    upcoming: "開催予定: {count} 件 {place}",
    past: "開催済み: {count} 件 {place}",
    view: {
      about: "概要 - {title}",
      artworks: "出展作品 - {title}",
      artists: "出展アーティスト - {title}",
      others: "{gallery}の展示一覧",
      photos: "写真 - {title}",
    },
  },
  articles: {
    meta: "全ての記事",
    title: "全ての記事",
    description:
      "Art Scenesは世界中のアートに関する記事を掲載するWebサービスです。Art Scenesの掲載する記事を通してお気に入りのアート作品を探し、購入することができます。",
    related: "関連記事",
  },
  others: {
    term: "利用規約",
    estimated_delivery_charge: {
      title: "配送料の目安",
      text: "Art Scenesのアート作品は、「送料無料」と記載されていない場合は、基本的に送料抜きの価格で表示されており、送料は着払いでお届けしております。<br />本ページでは、地域別の配送料を各配送業社で比較し、 最高額を「目安」として提示しています。<br />そのため、実際の配送料には多少変動が生じる場合がございますので、 ご了承ください。<br /><br />送料無料の場合は、すぐに発送の手続きを進めます。<br />着払いの場合はご注文やお問い合わせの後に、 送料についてご連絡いたします。配送料にご納得いただけない場合は、 すでにご注文されていても決済 / 発送は行われず、 ご注文はキャンセルとなります。<br />アート作品は梱包の仕方でサイズが変化したり、 保険料の変動など、 配送料が複雑なため、 送料の自動計算ができないことご了承ください。",
      table_title: "配送料 / 値段",
      prefecture: {
        hokkaido: "北海道",
        tohoku: "東北",
        kanto: "関東",
        chubu: "信越, 北陸, 東海",
        kansai: "関西",
        chugoku: "中国, 四国",
        kyushu: "九州",
        okinawa: "沖縄",
      },
      size: {
        table_title: "サイズ",
        total_dimension: {
          title: "3辺合計",
          sixty: "60cmまで",
          eighty: "80cmまで",
          hundred: "100cmまで",
          one_hundred_forty: "140cmまで",
          one_hundred_eighty: "180cmまで",
          two_hundred_twenty: "220cmまで",
          two_hundred_sixty: "260cmまで",
        },
        weight: {
          title: "重量",
          two: "2kgまで",
          five: "5kgまで",
          ten: "10kgまで",
          twenty: "20kgまで",
          sixty: "60kgまで",
          hundred: "100kgまで",
          hundred_eighty: "180kgまで",
        },
        sixty: "60サイズ",
        eighty: "80サイズ",
        one_hundred_forty: "140サイズ",
        one_hundred_eighty: "180サイズ",
        two_hundred_twenty: "220サイズ",
        two_hundred_sixty: "260サイズ",
      },
    },
    privacy: "プライバシーポリシー",
    law: "特定商取引法に関する記載",
    contact: {
      meta: "問合せフォーム",
      title: "お問い合わせ",
      lead: "お気軽にお問い合わせください。",
      about: "Art Scenesの詳細に興味のある方",
      register: "ギャラリーとして登録をご希望の方",
    },
    search: {
      meta: "検索結果 ",
      keyword: '"{keyword}"は検索できません',
      more: "検索文字数を増やしてください。",
      result: "検索結果 ",
      count: "{count}件見つかりました",
    },
    likes: {
      title: "お気に入り",
    },
  },
};

export default messages;
