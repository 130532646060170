import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { FirstImage } from "../util/Image";
import { LikeButton } from "../util/Like";
import { IntlText } from "../util/Text";

class Show extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const show = !this.props.show.title
      ? Formatter.mergeDesc(this.props.show, this.props.show.show_descs, lang)
      : this.props.show;
    return (
      <div className="uk-position-relative uk-padding-right uk-padding-remove@s">
        <Link
          className="uk-link-reset uk-display-block"
          to={PathGenerator.getLocalPath(Path.shows.view.url, {
            showId: show.id,
          })}
          ga={{ category: "link", action: "show", label: "show_" + show.id }}
        >
          <div className="uk-grid-small" data-uk-grid>
            <div className="uk-width-auto uk-width-1-1@s">
              <div className="uk-position-relative">
                <div
                  className={
                    "uk-image-wrapper uk-width-1-1@s uk-width-small uk-height-thumnail " +
                    (this.props.small
                      ? "uk-height-medium@s"
                      : "uk-height-large@s")
                  }
                >
                  <FirstImage
                    srcs={show.show_images}
                    finder={(image) => image.url}
                    alt={show.title}
                    full={this.props.small ? false : true}
                  />
                </div>
                {!this.props.editing && (
                  <div className="uk-position-xsmall uk-position-bottom-right uk-visible@s">
                    <LikeButton type="show" data={show} />
                  </div>
                )}
              </div>
            </div>
            <div className="uk-width-expand">
              <h3 className="uk-margin-remove ">{show.title}</h3>
              {show.galleries && (
                <p className="uk-margin-remove">
                  {show.galleries
                    .map((gallery) => {
                      return Formatter.mergeDesc(
                        gallery,
                        gallery.gallery_descs,
                        lang,
                      ).name;
                    })
                    .join("/")}
                </p>
              )}
              <p className="uk-margin-remove uk-text-muted">
                <span>
                  {show.start_at &&
                    moment(show.start_at).format("YYYY.MM.DD ddd")}
                </span>
                <span> - </span>
                <span>
                  {show.end_at && moment(show.end_at).format("YYYY.MM.DD ddd")}
                </span>
              </p>
            </div>
          </div>
        </Link>
        {!this.props.editing && (
          <div className="uk-position-bottom-right uk-hidden@s">
            <LikeButton type="show" data={show} />
          </div>
        )}
        {this.props.editing && (
          <div className="uk-margin-small uk-text-center">
            <Link
              className="uk-button uk-button-default"
              to={PathGenerator.getLocalPath(Path.gallery.shows.edit.url, {
                showId: show.id,
              })}
            >
              <IntlText id="forms.edit" />
            </Link>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Show);
