const messages = {
  word: {
    show: "Show",
    artwork: "Artwork",
    fair: "Fair",
    artworks: "Artworks",
    galleries: "Galleries",
    fairs: "Fairs",
    artists: "Artists",
    shows: "Shows",
    articles: "Articles",
    view_all:
      '<span><span className="uk-visible@s">VIEW </span><span>ALL</span></span>',
    view_all_text: "VIEW ALL",
  },
  contact: {
    thanks: "Thank you for contact !",
    lead: "We will reply by email or tel within two or three business days.",
  },
  meta: {
    name: "Art Scenes",
    title: "Art Scenes | Find and collect your favorite art",
    description:
      "Art Scenes is the web service for art collectors and galleries.You can find, buy and collect your favorite art.We post the information of artworks, galleries, artists, art fairs, articles, museums.",
  },
};

export default messages;
