import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchFair } from "../../../actions/gallery/attend";
import FairsList from "../../../components/lists/Fairs";
import { IntlText } from "../../../components/util/Text";
import { Path, PathGenerator } from "../../../constants/path";

class IndexScreen extends PureComponent {
  state = {
    fairs: [],
  };

  componentWillMount = () => {
    this.props.actions
      .searchFair()
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            fairs: value.data.map((attend) => {
              attend.fair.attend = attend;
              return attend.fair;
            }),
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="gallery.fairs.title" />
        </h1>
        <div className="uk-margin-large">
          <FairsList
            fairs={this.state.fairs}
            editing={true}
            onClick={(fair) =>
              this.props.history.push(
                PathGenerator.getLocalPath(Path.gallery.fairs.edit.url, {
                  attendId: fair.attend.id,
                }),
              )
            }
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    gallery: state.auth.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchFair: (...param) => dispatch(searchFair(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
