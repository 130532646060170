import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { editAuth, getData } from "../../../actions/collector/index";
import AuthForm from "../../../components/forms/collector/AuthForm";
import { Link } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import { Path } from "../../../constants/path";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class EditAuthScreen extends PureComponent {
  state = {
    editing: {},
  };

  componentWillMount = () => {
    this.setState({
      editing: this.props.collector,
    });
  };

  onSubmitAuth = (values) => {
    return this.props.actions.editAuth(values);
  };

  onSuccessAuth = (data) => {
    Notification.successIntl("util.success.update");
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="collector.edit_auth.title" />
        </h1>
        <div className="uk-margin-medium">
          <AuthForm
            initialValues={{ email: this.state.editing.email }}
            onSuccess={this.onSuccessAuth}
            onSubmit={this.onSubmitAuth}
          />
        </div>
        <hr />
        <div className="uk-margin-medium uk-text-center">
          <Link
            className="uk-button uk-button-default"
            to={Path.collector.top.url}
          >
            <IntlText id="forms.back" />
          </Link>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    collector: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getData: () => dispatch(getData()),
      editAuth: (...param) => dispatch(editAuth(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditAuthScreen),
);
