import React, { PureComponent } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import Textarea from "../../inputs/Textarea";
import { Button } from "../../util/Clickable";
import { IntlText } from "../../util/Text";
import * as validate from "../../util/Validator";

class PurchaseRequestForm extends PureComponent {
  state = {
    loading: false,
  };

  onDeny = () => {
    var values = {
      gallery_message: this.form.gallery_message.value,
    };
    this.setState({
      loading: true,
    });
    return this.props.onDeny(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return this.props.onConfirm(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  render = () => {
    return (
      <form
        ref={(form) => (this.form = form)}
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-margin-small">
          <Field
            name="gallery_message"
            component={Textarea}
            labelId="forms.attr.gallery_message"
            validate={[validate.required]}
            required
          />
        </div>

        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin-small uk-text-center">
          <Button
            className="uk-button uk-button-danger uk-margin-small-right"
            type="button"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "deny" }}
            loading={this.state.loading ? 1 : 0}
            onClick={this.onDeny}
          >
            <IntlText id="forms.reject" />
          </Button>
          <Button
            className="uk-button uk-button-default uk-margin-small-left"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "confirm" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.confirm" />
          </Button>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "gallery-purchase-request-form",
  enableReinitialize: true,
})(PurchaseRequestForm);
