import React, { Component } from "react";
import Helmet from "react-helmet";
import { addLocaleData, IntlProvider } from "react-intl";
import en from "react-intl/locale-data/en";
import ja from "react-intl/locale-data/ja";
import zh from "react-intl/locale-data/zh";
import { connect } from "react-redux";
import LoadingBar from "react-redux-loading-bar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getAll as getCategories } from "../actions/category";
import { setEditLang, setViewLang } from "../actions/language";
import { getAll as getPlaces } from "../actions/place";
import AdminHeader from "../components/layout/AdminHeader";
import Analytics from "../components/layout/Analytics";
import FairHeader from "../components/layout/FairHeader";
import Footer from "../components/layout/Footer";
import GalleryHeader from "../components/layout/GalleryHeader";
import Header from "../components/layout/Header";
import MediumHeader from "../components/layout/MediumHeader";
import Alert from "../components/util/Alert";
import Confirm from "../components/util/Confirm";
import Histories from "../components/util/Histories";
import LikePops from "../components/util/LikePops";
import { Path } from "../constants/path";
import { getMessage } from "../langs/messages";
import { Location } from "../utils/location";
import AdminAuthScreen from "./admin/AuthScreen";
import AdminTopScreen from "./admin/BaseScreen";
import ArticlesScreen from "./articles/BaseScreen";
import ArtistsScreen from "./artists/BaseScreen";
import ArtworksScreen from "./artworks/BaseScreen";
import CollectorAuthScreen from "./collector/AuthScreen";
import CollectorTopScreen from "./collector/BaseScreen";
import FairAuthScreen from "./fair/AuthScreen";
import FairTopScreen from "./fair/BaseScreen";
import FairsScreen from "./fairs/BaseScreen";
import FairViewScreen from "./fairs/CustomViewScreen";
import GalleriesScreen from "./galleries/BaseScreen";
import GalleryAuthScreen from "./gallery/AuthScreen";
import GalleryTopScreen from "./gallery/BaseScreen";
import LandingScreen from "./LandingScreen";
import MediumAuthScreen from "./medium/AuthScreen";
import MediumTopScreen from "./medium/BaseScreen";
import NewsScreen from "./news/BaseScreen";
import OtherScreen from "./other/BaseScreen";
import LikesScreen from "./other/LikesScreen";
import NotFoundScreen from "./other/NotFoundScreen";
import SearchScreen from "./other/SearchScreen";
import RedirectScreen from "./RedirectScreen";
import RootScreen from "./RootScreen";
import ShowsScreen from "./shows/BaseScreen";

addLocaleData([...ja, ...en, ...zh]);

class App extends Component {
  constructor(props) {
    super(props);

    const lang = Location.getLang();
    this.state = {
      locale: lang,
      messages: getMessage(lang),
      htmlLang: Location.getHTMLLang(lang),
    };
    props.actions.setViewLang(lang);
  }

  componentWillMount = () => {
    this.props.actions.getCategories().catch((error) => console.log(error));
    this.props.actions.getPlaces().catch((error) => console.log(error));
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.language.view != nextProps.language.view) {
      this.setState({
        locale: nextProps.language.view,
        messages: getMessage(nextProps.language.view),
        htmlLang: Location.getHTMLLang(nextProps.language.view),
      });
    }
  };

  render = () => {
    return (
      <IntlProvider locale={this.state.locale} messages={this.state.messages}>
        <Router>
          <div
            className={
              "uk-position-relative uk-offcanvas-content lang-" +
              this.state.locale
            }
          >
            <Switch>
              <Route path={Path.gallery.url} component={GalleryHeader} />
              <Route path={Path.fair.url} component={FairHeader} />
              <Route path={Path.admin.url} component={AdminHeader} />
              <Route path={Path.medium.url} component={MediumHeader} />
              <Route component={Header} />
            </Switch>

            <LoadingBar
              className="uk-position-fixed uk-position-z-index"
              style={{
                top: "0px",
                width: "100%",
                backgroundColor: "rgba(195, 213, 0, 0.4)",
                height: "56px",
                zIndex: 1000,
              }}
            />

            <div id="main">
              <Switch>
                <Route
                  exact
                  path={Path.langing.url}
                  component={RedirectScreen}
                />
                <Route exact path={Path.about.url} component={LandingScreen} />

                <Route exact path={Path.root.url} component={RootScreen} />
                <Route exact path={Path.search.url} component={SearchScreen} />
                <Route exact path={Path.likes.url} component={LikesScreen} />
                <Route path={Path.other.url} component={OtherScreen} />

                {/* ギャラリー向け*/}
                <Route
                  path={Path.gallery.auth.url}
                  component={GalleryAuthScreen}
                />
                <Route
                  path={Path.gallery.top.url}
                  component={GalleryTopScreen}
                />

                {/* フェア向け*/}
                <Route path={Path.fair.auth.url} component={FairAuthScreen} />
                <Route path={Path.fair.top.url} component={FairTopScreen} />

                {/* コレクター向け*/}
                <Route
                  path={Path.collector.auth.url}
                  component={CollectorAuthScreen}
                />
                <Route
                  path={Path.collector.top.url}
                  component={CollectorTopScreen}
                />

                {/* Admin向け*/}
                <Route path={Path.admin.auth.url} component={AdminAuthScreen} />
                <Route path={Path.admin.top.url} component={AdminTopScreen} />

                {/* Medium向け*/}
                <Route
                  path={Path.medium.auth.url}
                  component={MediumAuthScreen}
                />
                <Route path={Path.medium.url} component={MediumTopScreen} />

                {/* User向け*/}
                <Route path={Path.galleries.url} component={GalleriesScreen} />
                <Route path={Path.fairs.url} component={FairsScreen} />
                <Route path={Path.artworks.url} component={ArtworksScreen} />
                <Route path={Path.artists.url} component={ArtistsScreen} />
                <Route path={Path.shows.url} component={ShowsScreen} />
                <Route path={Path.articles.url} component={ArticlesScreen} />
                <Route path={Path.news.url} component={NewsScreen} />

                {/* other */}
                <Route path={Path.original.url} component={FairViewScreen} />
                <Route component={NotFoundScreen} />
              </Switch>
            </div>
            <Switch>
              <Route exact path={Path.root.url} />
              <Route exact path={Path.langing.url} />
              <Route path={Path.other.url} />
              <Route path={Path.about.url} />
              <Route path={Path.admin.url} />
              <Route path={Path.medium.url} />
              <Route path={Path.gallery.url} />
              <Route path={Path.fair.url} />
              <Route component={Histories} />
            </Switch>
            <div id="footer">
              <Switch>
                <Route path={Path.admin.auth.url} component={Footer} />
                <Route path={Path.admin.url} />
                <Route path={Path.gallery.auth.url} component={Footer} />
                <Route path={Path.gallery.url} />
                <Route path={Path.fair.auth.url} component={Footer} />
                <Route path={Path.fair.url} />
                <Route path={Path.medium.auth.url} component={Footer} />
                <Route path={Path.medium.url} />
                <Route component={Footer} />
              </Switch>
            </div>
            <LikePops />
            <Alert />
            <Confirm />
            <Analytics />
            <Helmet htmlAttributes={{ lang: this.state.htmlLang }} />
          </div>
        </Router>
      </IntlProvider>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    request: state.request,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setViewLang: (lang) => dispatch(setViewLang(lang)),
      setEditLang: (lang) => dispatch(setEditLang(lang)),
      getCategories: () => dispatch(getCategories()),
      getPlaces: () => dispatch(getPlaces()),
    },
    dispatch,
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
