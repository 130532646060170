import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Meta from "../../components/layout/Meta";
import { getPureMessage, IntlHTML, IntlText } from "../../components/util/Text";

class EstimatedDeliveryChargeScreen extends PureComponent {
  render = () => {
    return (
      <div>
        <Meta
          title={getPureMessage("others.estimated_delivery_charge.title")}
        />
        <h1 className="uk-heading-mark uk-margin-remove">
          <IntlText id="others.estimated_delivery_charge.title" />
        </h1>
        <table class="uk-table uk-table-divider">
          <thead>
            <tr>
              <th>
                <IntlText id="others.estimated_delivery_charge.table_title" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.sixty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.eighty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.one_hundred_forty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.one_hundred_eighty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.two_hundred_twenty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.two_hundred_sixty" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <IntlText id="others.estimated_delivery_charge.prefecture.hokkaido" />
              </th>
              <td>1400</td>
              <td>1800</td>
              <td>2300</td>
              <td>4500</td>
              <td>7000</td>
              <td>11300</td>
            </tr>
            <tr>
              <th>
                <IntlText id="others.estimated_delivery_charge.prefecture.tohoku" />
              </th>
              <td>1100</td>
              <td>1300</td>
              <td>2000</td>
              <td>3700</td>
              <td>5300</td>
              <td>8700</td>
            </tr>
            <tr>
              <th>
                <IntlText id="others.estimated_delivery_charge.prefecture.kanto" />
              </th>
              <td>1000</td>
              <td>1200</td>
              <td>1900</td>
              <td>2800</td>
              <td>3800</td>
              <td>5900</td>
            </tr>
            <tr>
              <th>
                <IntlText id="others.estimated_delivery_charge.prefecture.chubu" />
              </th>
              <td>1000</td>
              <td>1200</td>
              <td>1900</td>
              <td>3200</td>
              <td>4900</td>
              <td>7800</td>
            </tr>
            <tr>
              <th>
                <IntlText id="others.estimated_delivery_charge.prefecture.kansai" />
              </th>
              <td>1100</td>
              <td>1300</td>
              <td>2000</td>
              <td>3500</td>
              <td>4900</td>
              <td>7800</td>
            </tr>
            <tr>
              <th>
                <IntlText id="others.estimated_delivery_charge.prefecture.chugoku" />
              </th>
              <td>1200</td>
              <td>1400</td>
              <td>2200</td>
              <td>3900</td>
              <td>5700</td>
              <td>8800</td>
            </tr>
            <tr>
              <th>
                <IntlText id="others.estimated_delivery_charge.prefecture.kyushu" />
              </th>
              <td>1400</td>
              <td>1600</td>
              <td>2300</td>
              <td>4600</td>
              <td>7100</td>
              <td>11200</td>
            </tr>
            <tr>
              <th>
                <IntlText id="others.estimated_delivery_charge.prefecture.okinawa" />
              </th>
              <td>1400</td>
              <td>1900</td>
              <td>3600</td>
              <td>7900</td>
              <td>12600</td>
              <td>21300</td>
            </tr>
          </tbody>
        </table>
        <table class="uk-table uk-table-divider">
          <thead>
            <tr>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.table_title" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.total_dimension.title" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.weight.title" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>60</td>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.total_dimension.sixty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.weight.two" />
              </th>
            </tr>
            <tr>
              <td>80</td>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.total_dimension.eighty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.weight.five" />
              </th>
            </tr>
            <tr>
              <td>100</td>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.total_dimension.hundred" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.weight.ten" />
              </th>
            </tr>
            <tr>
              <td>140</td>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.total_dimension.one_hundred_forty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.weight.twenty" />
              </th>
            </tr>
            <tr>
              <td>180</td>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.total_dimension.one_hundred_eighty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.weight.sixty" />
              </th>
            </tr>
            <tr>
              <td>220</td>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.total_dimension.two_hundred_twenty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.weight.hundred" />
              </th>
            </tr>
            <tr>
              <td>260</td>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.total_dimension.two_hundred_sixty" />
              </th>
              <th>
                <IntlText id="others.estimated_delivery_charge.size.weight.hundred_eighty" />
              </th>
            </tr>
          </tbody>
        </table>
        <div className="uk-margin-large">
          <IntlHTML id="others.estimated_delivery_charge.text" />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EstimatedDeliveryChargeScreen),
);
