const messages = {
  purchase: {
    contacted: "Contacted",
    agreed: "Agreed",
    paid: "Paid",
    canceled: "Canceled",
    error: "Error",
  },
  status: {
    private: "Private",
    public: "Published",
  },
  sales_status: {
    editing: "Editing",
    not_sale: "Not for sale",
    contact: "Contact",
    for_sale: "Contact(Open Price)",
    available: "For Sale",
    sold: "Sold",
  },
  fair_only_status: {
    open: "Open",
    fair_only: "Fair Only",
    fair_vip_only: "Fair VIP Only",
  },
  sorts: {
    recommend: "Recommend",
    recent: "Recently Update",
    higher: "Most Expensive",
    lower: "Least Expensive",
    older: "Creation Year (desc)",
    newer: "Creation Year (asc)",
  },
  mediums: {
    painting: "Painting",
    japanese: "Japanese painting",
    print: "Prints & Multiples",
    paper: "Works on Paper",
    sculpture: "Sculpture",
    photo: "Photography",
    media: "Media art",
    mixed: "Mixed media",
    design: "Design",
    jewelry: "Jewelry",
    other: "Other",
  },
};

export default messages;
