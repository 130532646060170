import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";

class News extends Component {
  getUrl = () => {
    if (this.props.news.article_id) {
      return PathGenerator.getLocalPath(Path.news.view.url, {
        newsId: this.props.news.article_id,
      });
    } else if (this.props.news.artist_id) {
      return PathGenerator.getLocalPath(Path.artists.view.url, {
        artistId: this.props.news.artist_id,
      });
    } else if (this.props.news.artwork_id) {
      return PathGenerator.getLocalPath(Path.artworks.view.url, {
        artworkId: this.props.news.artwork_id,
      });
    } else if (this.props.news.fair_id) {
      return PathGenerator.getLocalPath(Path.fairs.view.url, {
        fairId: this.props.news.fair_id,
      });
    } else if (this.props.news.show_id) {
      return PathGenerator.getLocalPath(Path.shows.view.url, {
        showId: this.props.news.show_id,
      });
    } else if (this.props.news.gallery_id) {
      return PathGenerator.getLocalPath(Path.galleries.view.url, {
        galleryId: this.props.news.gallery_id,
      });
    }
  };

  renderContent = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const news = !this.props.news.title
      ? Formatter.mergeDesc(this.props.news, this.props.news.news_descs, lang)
      : this.props.news;
    return (
      <div data-uk-grid className="uk-grid-small">
        <div className="uk-width-1-1@s uk-width-small">
          <div className="uk-height-medium@s uk-height-1-1 uk-image-wrapper">
            <Image
              src={news.thumnail_url}
              alt={news.title}
              className="uk-border-muted"
            />
          </div>
        </div>
        <div className="uk-width-expand">
          <h3 className="uk-h4 uk-margin-remove uk-height-lines-2">
            {news.title}
          </h3>
          <p className="uk-margin-remove uk-text-muted">
            {moment(news.posted_at).format("YYYY/MM/DD")}
          </p>
        </div>
      </div>
    );
  };

  render = () => {
    if (!this.props.news.link_url) {
      return (
        <Link
          className="uk-link-reset uk-display-block"
          to={this.getUrl()}
          ga={{
            category: "link",
            action: "news",
            label: "news_" + this.props.news.id,
          }}
        >
          {this.renderContent()}
        </Link>
      );
    } else {
      return (
        <a
          className="uk-link-reset uk-display-block"
          href={this.props.news.link_url}
          ga={{
            category: "link",
            action: "news",
            label: "news_" + this.props.news.id,
          }}
        >
          {this.renderContent()}
        </a>
      );
    }
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
