import {
  SET_COLLECTOR_DATA,
  MERGE_COLLECTOR,
  RESET_COLLECTOR_SOURCE,
  POST_COLLECTOR_LOGIN,
  POST_COLLECTOR_LOGOUT,
  FORGET_COLLECTOR_PASSWORD,
  RESET_COLLECTOR_PASSWORD,
  ADD_COLLECTOR,
  VALIDATE_COLLECTOR,
  REFRESH_COLLECTOR,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";
import stores from "../../stores";

export const setData = (data) => {
  return {
    type: SET_COLLECTOR_DATA,
    data: data,
  };
};

export const mergeData = (data) => {
  return {
    type: MERGE_COLLECTOR,
    data: data,
  };
};

export const resetSource = () => {
  return {
    type: RESET_COLLECTOR_SOURCE,
  };
};

export const register = (data) => {
  return (dispatch) => {
    return {
      type: ADD_COLLECTOR,
      payload: () => {
        return Api.post(EndPoints.collector(), data).then((response) => {
          if (response.status < 300) {
            const likes = stores.getState().like.data;
            if (likes != null && likes.length > 0) {
              Api.put(EndPoints.collector("likes"), {
                likes: likes,
                collector_id: response.data.id,
              }).catch((error) => console.log(error));
            }
          }
          return response;
        });
      },
    };
  };
};

export const validate = (data) => {
  return (dispatch) => {
    return {
      type: VALIDATE_COLLECTOR,
      payload: () => {
        return Api.put(EndPoints.collector("validate"), data).then(
          (response) => {
            if (response.status < 300) {
              dispatch(setData(response.data));
            }
            return response;
          },
        );
      },
    };
  };
};

export const refresh = () => {
  return (dispatch) => {
    return {
      type: REFRESH_COLLECTOR,
      payload: () => {
        return Api.post(EndPoints.collector("refresh")).then((response) => {
          return response;
        });
      },
    };
  };
};

export const login = (data) => {
  return (dispatch) => {
    return {
      type: POST_COLLECTOR_LOGIN,
      payload: () => {
        return Api.post(EndPoints.collector("auth"), data).then((response) => {
          if (response.status < 300) {
            dispatch(setData(response.data));
          }
          return response;
        });
      },
    };
  };
};

export const logout = () => {
  return (dispatch) => {
    return {
      type: POST_COLLECTOR_LOGOUT,
      payload: () => {
        return Api.delete(EndPoints.collector("auth"));
      },
    };
  };
};

export const forget = (data) => {
  return (dispatch) => {
    return {
      type: FORGET_COLLECTOR_PASSWORD,
      payload: () => {
        return Api.post(EndPoints.collector("reset"), data);
      },
    };
  };
};

export const reset = (data) => {
  return (dispatch) => {
    return {
      type: RESET_COLLECTOR_PASSWORD,
      payload: () => {
        return Api.put(EndPoints.collector("reset"), data);
      },
    };
  };
};
