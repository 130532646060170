import { GET_CATEGORIES } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";

export const getAll = () => {
  return (dispatch) => {
    return {
      type: GET_CATEGORIES,
      payload: () => {
        return Api.get(EndPoints.categories());
      },
    };
  };
};
