import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Button, Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { IntlText } from "../util/Text";

class Gallery extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const gallery = !this.props.gallery.name
      ? Formatter.mergeDesc(
          this.props.gallery,
          this.props.gallery.gallery_descs,
          lang,
        )
      : this.props.gallery;
    const enGalleryName = !this.props.gallery.name
      ? Formatter.mergeDesc(
          this.props.gallery,
          this.props.gallery.gallery_descs,
          "en",
        ).name
      : this.props.gallery.name;
    const defaultGallery = Formatter.defalutDesc(
      this.props.gallery,
      this.props.gallery.gallery_descs,
    );

    var location =
      gallery.locations && gallery.locations.length > 0
        ? gallery.locations[0]
        : null;
    if (location) {
      location = Formatter.mergeDesc(location, location.location_descs, lang);
    }

    return (
      <div>
        <Link
          to={PathGenerator.getLocalPath(Path.galleries.view.url, {
            galleryId: gallery.id,
          })}
          className="uk-link-reset uk-display-block uk-position-relative uk-margin-small"
          ga={{
            category: "link",
            action: "gallery_part",
            label: "gallery_" + gallery.id,
          }}
        >
          <div className="uk-margin-small uk-height-medium uk-image-wrapper">
            <Image
              src={
                gallery.attend && gallery.attend.thumnail_url
                  ? gallery.attend.thumnail_url
                  : gallery.thumnail_url
              }
              alt={gallery.name}
            />
          </div>
          <div className="uk-margin-small">
            <h3 className="uk-margin-remove">{gallery.name}</h3>
            <p className="uk-margin-remove uk-text-muted ">
              {enGalleryName}
              <br />
              {location && location.place}
            </p>
          </div>
        </Link>
        {this.props.editing && (
          <div className="uk-margin-small uk-text-center">
            <Button
              className="uk-button uk-button-default"
              onClick={() => {
                this.props.onClick && this.props.onClick(gallery);
              }}
            >
              {this.props.buttonText ? (
                this.props.buttonText
              ) : (
                <IntlText id="forms.add" />
              )}
            </Button>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
