import React from "react";
import noImage from "../../assets/images/material/noimage.jpg";

const threthold = 640;

export const Image = (props) => {
  var style = props.style ? props.style : {};
  if (props.color != null) {
    style.backgroundColor = props.color;
  }

  var src;
  if (props.src == null) {
    src = noImage;
  } else {
    src = imageUrl(props.src);
  }

  if (props.src != null && props.full) {
    var altSrc = fullUrl(src);
    return (
      <span className="uk-display-inline-block uk-position-relative">
        <img
          style={style}
          className={props.className}
          onClick={props.onClick}
          src={src}
          alt={props.alt}
        />
        {window.innerWidth >= threthold && (
          <img
            className="uk-position-center uk-position-z-index"
            src={altSrc}
            alt={props.alt}
          />
        )}
      </span>
    );
  } else if (props.src != null && !props.noSmall) {
    src = window.innerWidth >= threthold? src : smallUrl(src);
  }
  return (
    <img
      style={style}
      className={props.className}
      onClick={props.onClick}
      src={src}
      alt={props.alt}
    />
  );
};

export const FirstImage = (props) => {
  const src =
    props.srcs && props.srcs.length > 0 ? props.finder(props.srcs[0]) : null;
  return <Image {...props} src={src} />;
};

export const imageUrl = (url) => {
  if (url == null) {
    return null;
  }
  return process.env.REACT_APP_STORAGE_PREFIX + "/" + url;
};

export const fullUrl = (url) => {
  const array = url.split(".");
  return array
    .map((path, index) => {
      return index != array.length - 2 ? path : path + "@full";
    })
    .join(".");
};

export const smallUrl = (url) => {
  const array = url.split(".");
  return array
    .map((path, index) => {
      return index != array.length - 2 ? path : path + "@small";
    })
    .join(".");
};
