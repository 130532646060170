import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { IntlText } from "../util/Text";
import GalleryCard from "../views/GalleryCard";

class GalleryCards extends Component {
  render = () => {
    if (this.props.galleries == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.galleries.length <= 0) {
      return (
        <div>
          <IntlText id="lists.galleries.no" />
        </div>
      );
    }

    return (
      <div data-uk-grid className="uk-child-width-1-2@s uk-grid-large">
        {this.props.galleries.map((gallery, index) => {
          return (
            <div key={index}>
              <GalleryCard gallery={gallery} />
            </div>
          );
        })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GalleryCards),
);
