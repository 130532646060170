//
export const SHOW_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const SHOW_CONFIRM = "SHOW_CONFIRM";

//
export const POST_CONTACT = "POST_CONTACT";

//
export const SEARCH_CONTENT = "SEARCH_CONTENT";
export const SET_CONDITION = "SET_CONDITION";

//
export const SET_VIEW_LANG = "SET_VIEW_LANG";
export const SET_EDIT_LANG = "SET_EDIT_LANG";

//
export const GET_PLACES = "GET_PLACES";
export const GET_CATEGORIES = "GET_CATEGORIES";

//
export const POST_GALLERY_LOGOUT = "POST_GALLERY_LOGOUT";
export const POST_GALLERY_LOGIN = "POST_GALLERY_LOGIN";
export const SET_GALLERY_DATA = "SET_GALLERY_DATA";
export const FORGET_GALLERY_PASSWORD = "FORGET_GALLERY_PASSWORD";
export const RESET_GALLERY_PASSWORD = "RESET_GALLERY_PASSWORD";
export const APPROVE_TERM = "APPROVE_TERM";

//
export const SEARCH_GALLERIES = "SEARCH_GALLERIES";
export const GET_GALLERY = "GET_GALLERY";
export const UPDATE_GALLERY_DATA = "UPDATE_GALLERY_DATA";
export const UPDATE_GALLERY_DESC = "UPDATE_GALLERY_DESC";
export const UPDATE_GALLERY_AUTH = "UPDATE_GALLERY_AUTH";
export const UPDATE_GALLERY_CONTACT_EMAIL = "UPDATE_GALLERY_CONTACT_EMAIL";
export const UPDATE_GALLERY_PAYMENT = "UPDATE_GALLERY_PAYMENT";
export const DELETE_GALLERY_IMAGE = "DELETE_GALLERY_IMAGE";
export const REGISTER_GALLERY_CARD = "REGISTER_GALLERY_CARD";

//
export const GET_LOCATIONS = "GET_LOCATIONS";
export const ADD_LOCATION = "ADD_LOCATION";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";

//
export const GET_ARTIST = "GET_ARTIST";
export const GET_QUOTES = "GET_QUOTES";
export const SEARCH_ARTISTS = "SEARCH_ARTISTS";
export const ADD_ARTIST = "ADD_ARTIST";
export const EDIT_ARTIST = "EDIT_ARTIST";
export const ATTACH_ARTIST = "ATTACH_ARTIST";
export const DETACH_ARTIST = "DETACH_ARTIST";
export const DELETE_ARTIST_IMAGE = "DELETE_ARTIST_IMAGE";

//
export const SEARCH_ARTWORKS = "SEARCH_ARTWORKS";
export const GET_ARTWORK = "GET_ARTWORK";
export const ADD_ARTWORK = "ADD_ARTWORK";
export const EDIT_ARTWORK = "EDIT_ARTWORK";
export const SALE_ARTWORK = "SALE_ARTWORK";
export const UNSALE_ARTWORK = "UNSALE_ARTWORK";
export const DELETE_ARTWORK_IMAGE = "DELETE_ARTWORK_IMAGE";

//
export const SEARCH_SHOWS = "SEARCH_SHOWS";
export const GET_SHOW = "GET_SHOW";
export const ADD_SHOW = "ADD_SHOW";
export const EDIT_SHOW = "EDIT_SHOW";
export const DELETE_SHOW = "DELETE_SHOW";
export const DELETE_SHOW_IMAGE = "DELETE_SHOW_IMAGE";

//
export const GET_INFORMATIONS = "GET_INFORMATIONS";
export const ADD_INFORMATION = "ADD_INFORMATION";
export const EDIT_INFORMATION = "EDIT_INFORMATION";
export const DELETE_INFORMATION = "DELETE_INFORMATION";
export const DELETE_INFORMATION_IMAGE = "DELETE_INFORMATION_IMAGE";

//
export const SEARCH_ARITCLES = "SEARCH_ARITCLES";
export const GET_ARTICLE = "GET_ARTICLE";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const DELETE_ARTICLE_IMAGE = "DELETE_ARTICLE_IMAGE";

//
export const POST_FAIR_LOGOUT = "POST_FAIR_LOGOUT";
export const POST_FAIR_LOGIN = "POST_FAIR_LOGIN";
export const SET_FAIR_DATA = "SET_FAIR_DATA";
export const FORGET_FAIR_PASSWORD = "FORGET_FAIR_PASSWORD";
export const RESET_FAIR_PASSWORD = "RESET_FAIR_PASSWORD";

//
export const GET_FAIR_DATA = "GET_FAIR_DATA";
export const UPDATE_FAIR = "UPDATE_FAIR";
export const UPDATE_FAIR_AUTH = "UPDATE_FAIR_AUTH";
export const DELETE_FAIR_IMAGE = "DELETE_FAIR_IMAGE";

//
export const SEARCH_ATTENDS = "SEARCH_ATTENDS";
export const GET_ATTEND = "GET_ATTEND";
export const ADD_ATTENDS = "ADD_ATTENDS";
export const EDIT_ATTEND = "EDIT_ATTEND";
export const DELETE_ATTEND = "DELETE_ATTEND";
export const DELETE_ATTEND_IMAGE = "DELETE_ATTEND_IMAGE";
//
export const GET_EXHIBITIONS = "GET_EXHIBITIONS";
export const ADD_EXHIBITION = "ADD_EXHIBITION";
export const EDIT_EXHIBITION = "EDIT_EXHIBITION";
export const DELETE_EXHIBITION = "DELETE_EXHIBITION";

//
export const GET_RELATES = "GET_RELATES";
export const ADD_RELATE = "ADD_RELATE";
export const DELETE_RELATE = "DELETE_RELATE";

//
export const SEARCH_FAIRS = "SEARCH_FAIRS";
export const GET_FAIR = "GET_FAIR";

//
export const GET_SECTIONS = "GET_SECTIONS";
export const ADD_SECTION = "ADD_SECTION";
export const EDIT_SECTION = "EDIT_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";

//
export const POST_MEDIUM_LOGIN = "POST_MEDIUM_LOGIN";
export const POST_MEDIUM_LOGOUT = "POST_MEDIUM_LOGOUT";
export const SET_MEDIUM_DATA = "SET_MEDIUM_DATA";
//
export const GET_MEDIUM = "GET_MEDIUM";
export const EDIT_MEDIUM = "EDIT_MEDIUM";
export const EDIT_MEDIUM_AUTH = "EDIT_MEDIUM_AUTH";

//
export const POST_ADMIN_LOGIN = "POST_ADMIN_LOGIN";
export const POST_ADMIN_LOGOUT = "POST_ADMIN_LOGOUT";
export const SET_ADMIN_DATA = "SET_ADMIN_DATA";
//
export const GET_ADMIN = "GET_ADMIN";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const EDIT_ADMIN_AUTH = "EDIT_ADMIN_AUTH";

//
export const GET_NEWS = "GET_NEWS";
export const GET_NEW = "GET_NEW";
export const ADD_NEWS = "ADD_NEWS";
export const EDIT_NEWS = "EDIT_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const DELETE_NEWS_IMAGE = "DELETE_NEWS_IMAGE";

//
export const ADD_ARTWORK_HISTORY = "ADD_ARTWORK_HISTORY";
export const CLEAR_HISTORY = "CLEAR_HISTORY";

//
export const INQUIRY_ARTWORK = "INQUIRY_ARTWORK";
export const PURCHASE_ARTWORK = "PURCHASE_ARTWORK";

//
export const SEARCH_PURCHASES = "SEARCH_PURCHASES";
export const CONFIRM_PURCHASE = "CONFIRM_PURCHASE";
export const DENY_PURCHASE = "DENY_PURCHASE";
export const GET_SUMMARIES = "GET_SUMMARIES";

//
export const SET_COLLECTOR_DATA = "SET_COLLECTOR_DATA";
export const RESET_COLLECTOR_SOURCE = "RESET_COLLECTOR_SOURCE";

//
export const POST_COLLECTOR_LOGIN = "POST_COLLECTOR_LOGIN";
export const POST_COLLECTOR_LOGOUT = "POST_COLLECTOR_LOGOUT";
export const FORGET_COLLECTOR_PASSWORD = "FORGET_COLLECTOR_PASSWORD";
export const RESET_COLLECTOR_PASSWORD = "RESET_COLLECTOR_PASSWORD";
export const ADD_COLLECTOR = "ADD_COLLECTOR";
export const VALIDATE_COLLECTOR = "VALIDATE_COLLECTOR";
export const REFRESH_COLLECTOR = "REFRESH_COLLECTOR";
//
export const GET_COLLECTOR_DATA = "GET_COLLECTOR_DATA";
export const UPDATE_COLLECTOR = "UPDATE_COLLECTOR";
export const UPDATE_COLLECTOR_AUTH = "UPDATE_COLLECTOR_AUTH";
export const DELETE_COLLECTOR_IMAGE = "DELETE_COLLECTOR_IMAGE";
export const GET_COLLECTOR_LIKES = "GET_COLLECTOR_LIKES";
export const MERGE_COLLECTOR = "MERGE_COLLECTOR";

//
export const ADD_LIKE = "ADD_LIKE";
export const CLEAR_LIKE = "CLEAR_LIKE";
export const SET_LIKES = "SET_LIKES";
export const CHECK_LIKES = "CHECK_LIKES";

//
export const SEARCH_PHOTOS = "SEARCH_PHOTOS";
export const EDIT_PHOTO = "EDIT_PHOTO";
export const DELETE_PHOTO = "DELETE_PHOTO";

//
export const GET_BANNER = "GET_BANNER";
export const ADD_BANNER = "ADD_BANNER";
export const EDIT_BANNER = "EDIT_BANNER";
export const SORT_BANNER = "SORT_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";
