const messages = {
  plan: {
    current_id: "<small>Your current plan is </small> {plan_name} ({price})",
    current_card: "Current Card",
    expired: "Expired",
    edit_payment: "Edit Payment",
    caution_header:
      'Your trial account will be expired at <span className="uk-text-bold">{date}</span>',
    caution_text:
      "If your account is expired, you will restrict to add artist, artwork, information, article, contact and sell collectors.<br/>We recommend to upgrade within this trial term.<br/>{plan_name}: Initial {init_price}, {price}",
    caution_button: "Add credit card & Upgrade to basic plan.",
    trial: "Trial",
    free: "Free",
    basic: "Basic Plan",
    unit: " yen / month",
    init_unit: "yen",
    initial: "Initial",
    campaign: {
      release:
        "※You signed up release campaign. (Free when you add artworks or shows until {campaign_end_at})",
    },
  },
  location: {
    address: "Address",
    tel: "Tel",
    hp: "Homepage",
    email: "Email",
  },
  fair: {
    artworks: "Artworks",
  },
  purchase: {
    title: "Purchase this artwork",
    lead_contact: "If you want to get more information,",
    contact: "Contact",
    contact_title: "Contact gallery",
    form: "Purchase form",
    ifyouhaveaccount: "If you have Art Scenes account,",
    clickme: "Login Here",
    notlogin: "Contact without signing in",
    ifyoudonthaveaccount: "If you don't have Art Scenes account,",
    tax: "(tax included)",
  },
};

export default messages;
