import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { IntlText, LinedText } from "../util/Text";

class NewsList extends Component {
  render = () => {
    if (this.props.news == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.news.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.news.no" />
          </h3>
        </div>
      );
    }

    return (
      <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-striped">
          <thead>
            <tr>
              <th className="uk-table-shrink">Post Date</th>
              <th className="uk-table-shrink">Thumnail</th>
              <th className="uk-table-expand">Description</th>
              {this.props.editing && (
                <th className="uk-table-shrink">Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.props.news.map((news, key) => {
              const data = Formatter.mergeDesc(
                news,
                news.news_descs,
                this.props.lang ? this.props.lang : this.props.language.view,
              );
              return (
                <tr key={key}>
                  <td>
                    <div className="uk-width-small">
                      {data.posted_at &&
                        moment(data.posted_at).format("YYYY-MM-DD")}
                    </div>
                  </td>
                  <td>
                    <Image
                      className="uk-width-medium"
                      src={data.thumnail_url}
                    />
                  </td>
                  <td>
                    <h3 className="uk-margin-small">{data.title}</h3>
                    <LinedText
                      className="uk-margin-small"
                      text={data.description}
                    />
                  </td>
                  {this.props.editing && (
                    <td>
                      <div className="uk-width-small uk-text-center">
                        <Link
                          className="uk-icon-button uk-button-default uk-margin-small-right"
                          data-uk-icon="icon:pencil;"
                          onClick={() => {
                            this.props.onEdit && this.props.onEdit(data);
                          }}
                        />
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewsList),
);
