import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Link } from "../util/Clickable";
import { FirstImage } from "../util/Image";
import { IntlText } from "../util/Text";

class Histories extends Component {
  render = () => {
    if (
      this.props.history.artworks == null ||
      this.props.history.artworks == 0
    ) {
      return null;
    }
    return (
      <div className="uk-section uk-section-large">
        <div className="uk-container">
          <h2 className="uk-heading-mark">
            <IntlText id="layout.recently" />
          </h2>
          <div className="uk-margin-medium uk-flex-list">
            {this.props.history.artworks
              .slice()
              .reverse()
              .map((artwork, index) => {
                return (
                  <div key={index}>
                    <Link
                      className="uk-link-reset"
                      to={PathGenerator.getLocalPath(Path.artworks.view.url, {
                        artworkId: artwork.id,
                      })}
                      ga={{
                        category: "link",
                        action: "history",
                        label: "artwork_" + artwork.id,
                      }}
                      target="_blank"
                    >
                      <FirstImage
                        noCover
                        className="uk-height-1-1"
                        srcs={artwork.artwork_images}
                        finder={(image) => image.url}
                      />
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    history: state.history,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Histories),
);
