import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Meta from "../../components/layout/Meta";
import {
  getPureMessage,
  IntlText,
  LinedText,
} from "../../components/util/Text";
import { laws } from "../../constants/laws";

class LawScreen extends PureComponent {
  render = () => {
    return (
      <div>
        <Meta title={getPureMessage("others.law")} />
        <h1 className="uk-heading-mark uk-margin-remove">
          <IntlText id="others.law" />
        </h1>
        <div className="uk-margin-large">
          <LinedText text={laws[this.props.language.view]} />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LawScreen),
);
