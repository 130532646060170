import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { get } from "../../actions/artist";
import { Link } from "../../components/util/Clickable";
import { Image } from "../../components/util/Image";
import { LikeDetail } from "../../components/util/Like";
import { Loading } from "../../components/util/Loading";
import NotFound from "../../components/util/NotFound";
import { FollowMe } from "../../components/util/SNS";
import { IntlText } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import ArticlesScreen from "./ArticlesScreen";
import ArtworksScreen from "./ArtworksScreen";
import HistoryScreen from "./HistoryScreen";
import OverviewScreen from "./OverviewScreen";
import ShowsScreen from "./ShowsScreen";

const urls = [
  {
    id: "menu.overview",
    title: "OVERVIEW",
    url: (artistId) =>
      PathGenerator.getLocalPath(Path.artists.view.url, { artistId: artistId }),
  },
  {
    id: "menu.artworks",
    title: "ARTWORKS",
    url: (artistId) =>
      PathGenerator.getLocalPath(Path.artists.view.artworks.url, {
        artistId: artistId,
      }),
  },
  {
    id: "menu.history",
    title: "HISTORY",
    url: (artistId) =>
      PathGenerator.getLocalPath(Path.artists.view.history.url, {
        artistId: artistId,
      }),
  },
  {
    id: "menu.articles",
    title: "ARTICLES",
    url: (artistId) =>
      PathGenerator.getLocalPath(Path.artists.view.articles.url, {
        artistId: artistId,
      }),
  },
  {
    id: "menu.shows",
    title: "SHOWS",
    url: (artistId) =>
      PathGenerator.getLocalPath(Path.artists.view.shows.url, {
        artistId: artistId,
      }),
  },
];

class ViewScreen extends PureComponent {
  state = {
    artist: null,
  };

  componentWillMount = () => {
    this.reload(this.props.match.params.artistId);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.match.params.artistId != nextProps.match.params.artistId) {
      this.reload(nextProps.match.params.artistId);
    }
  };

  componentDidUpdate = () => {
    setTimeout(() => {
      if (this.state.artist != null) {
        this.menuScroll();
      }
    }, 0);
  };

  menuScroll = () => {
    if (!this.scroll) {
      return;
    }

    Array.from(this.scroll.children[0].children).forEach((element) => {
      if (element.getAttribute("active")) {
        this.active = element;
      }
    });
    if (!this.active) {
      return;
    }
    const left =
      this.active.offsetLeft +
      this.active.offsetWidth / 2 -
      window.innerWidth / 2;
    this.scroll.scrollLeft = left;
  };

  reload = (artistId) => {
    this.setState({
      artist: null,
    });
    this.props.actions.get(artistId).then(({ value }) => {
      if (value.status > 300) {
        return this.setState({
          artist: false,
        });
      }
      this.setState({
        artist: value.data,
      });
    });
  };

  render = () => {
    if (this.state.artist == null) {
      return <Loading />;
    } else if (!this.state.artist) {
      return <NotFound />;
    }

    const artist = Formatter.mergeDesc(
      this.state.artist,
      this.state.artist.artist_descs,
      this.props.language.view,
    );

    const isTop = urls[0].url(artist.id) == this.props.location.pathname;

    var defTitle = artist.name;
    var subTitle = null;
    if (this.props.language.view != "en") {
      let desc = this.state.artist.artist_descs.find((desc) => {
        return desc.lang == "en";
      });
      subTitle = desc.name;
    }
    return (
      <div>
        <div className="uk-margin-medium uk-flex-middle" data-uk-grid>
          <div className="uk-width-auto@s uk-width-1-1 uk-text-center">
            <div className="uk-thumnail-circle uk-image-wrapper">
              <Image src={artist.thumnail_url} alt={artist.name} />
            </div>
          </div>
          <div className="uk-width-expand uk-text-center uk-text-left@s">
            <div className="uk-margin-small">
              {isTop ? (
                <h1 className="uk-h1 uk-margin-ssmall">{defTitle}</h1>
              ) : (
                <span className="uk-h1 uk-margin-ssmall">{defTitle}</span>
              )}
              {subTitle && <h4 className="uk-margin-ssmall">{subTitle}</h4>}
              <div className="uk-margin-ssmall">
                <span className="uk-h3 uk-text-mincho">
                  {artist.birth_place && <span>{artist.birth_place} </span>}
                  {artist.birth_year && <span>{artist.birth_year} - </span>}
                  {artist.death_year && <span>{artist.death_year}</span>}
                </span>
              </div>
            </div>
            <div className="uk-margin-small">
              <LikeDetail type="artist" data={artist} />
            </div>
          </div>
        </div>

        <div
          className="uk-margin-medium uk-grid-min"
          data-uk-grid="margin: uk-margin-min;"
        >
          {urls.map((url, index) => {
            return (
              <div key={index} className="uk-width@m uk-width-1-3">
                <Link
                  className="uk-button uk-button-clear uk-width-1-1 uk-padding-remove"
                  to={url.url(artist.id)}
                  active={
                    this.props.location.pathname == url.url(artist.id)
                      ? "true"
                      : null
                  }
                  ga={{ category: "link", action: "artist", label: url.title }}
                >
                  <IntlText id={url.id} />
                </Link>
              </div>
            );
          })}
        </div>

        <div
          className="uk-position-fixed uk-position-bottom  uk-width-1-1 uk-hidden@s uk-background-white uk-position-z-index uk-overflow-auto uk-scrollbar-hidden"
          data-uk-scrollspy="cls: uk-animation-slide-bottom;"
          ref={(scroll) => (this.scroll = scroll)}
          onLoad={this.menuScroll}
        >
          <div
            className="uk-grid-collapse uk-view-menu-base"
            data-uk-grid
            style={{ flexWrap: "nowrap" }}
          >
            {urls.map((url, index) => {
              const isActive =
                this.props.location.pathname == url.url(artist.id);
              return (
                <div
                  key={index}
                  className="uk-width-auto"
                  active={isActive ? "true" : null}
                >
                  <Link
                    className="uk-button uk-button-clear uk-width-1-1 uk-padding-small-horizontal"
                    to={url.url(artist.id)}
                    active={isActive ? "true" : null}
                    ga={{
                      category: "link",
                      action: "artist",
                      label: url.title,
                    }}
                  >
                    <IntlText id={url.id} />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        <div className="uk-margin-medium">
          <Switch>
            <Route
              exact
              path={Path.artists.view.url}
              component={() => <OverviewScreen artist={artist} />}
            />
            <Route
              exact
              path={Path.artists.view.artworks.url}
              component={() => <ArtworksScreen artist={artist} />}
            />
            <Route
              exact
              path={Path.artists.view.history.url}
              component={() => <HistoryScreen artist={artist} />}
            />
            <Route
              exact
              path={Path.artists.view.articles.url}
              component={() => <ArticlesScreen artist={artist} />}
            />
            <Route
              exact
              path={Path.artists.view.shows.url}
              component={() => <ShowsScreen artist={artist} />}
            />
          </Switch>
        </div>
        <div className="uk-margin-medium">
          <FollowMe />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (param) => dispatch(get(param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewScreen),
);
