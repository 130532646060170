import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search } from "../../../actions/gallery/artist";
import {
  add,
  sale,
  search as searchArtwork,
} from "../../../actions/gallery/artwork";
import { showAlert } from "../../../actions/message";
import ArtworkForm from "../../../components/forms/gallery/ArtworkForm";
import SaleForm from "../../../components/forms/gallery/SaleForm";
import MultiSelect from "../../../components/inputs/MultiSelect";
import ArtworksList from "../../../components/lists/Artworks";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { IntlText } from "../../../components/util/Text";
import { GALLERY_TRIAL } from "../../../constants/option";
import { Path, PathGenerator } from "../../../constants/path";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class AddScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      artists: [],
      loading: false,
      selectedIds: [],
      candidates: [],
      editing: {},
      previous: null,
    };
  }

  componentWillMount = () => {
    this.props.actions
      .search({
        gallery_id: this.props.gallery.id,
        all: true,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            artists: value.data.map((artist) => {
              const desc = artist.artist_descs.find((desc) => {
                return desc.lang == this.props.language.edit;
              });
              return { value: artist.id, label: desc ? desc.name : "No Name" };
            }),
          });
        }
      })
      .catch((error) => console.log(error));
  };

  componentDidMount = () => {
    if (
      this.props.gallery.status == GALLERY_TRIAL &&
      moment(this.props.gallery.trial_end_at) < moment()
    ) {
      this.props.actions.showAlert(
        <IntlText id="util.upgrade.title" />,
        <IntlText id="util.upgrade.message" />,
        () => {
          this.props.history.push(
            PathGenerator.getLocalPath(Path.gallery.edit.url),
          );
        },
        <IntlText id="util.upgrade.ok" />,
      );
    }
  };

  selectArtist = (selectedIds) => {
    if (selectedIds == null || selectedIds.length == 0) {
      this.setState({
        selectedIds: [],
        candidates: [],
      });
      return;
    }
    this.setState({
      selectedIds: selectedIds,
      candidates: null,
    });
    this.props.actions
      .searchArtwork({
        artist_ids: selectedIds,
        not_gallery_id: this.props.gallery.id,
        keyword: this.state.previous,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          return this.setState({
            candidates: value.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onChangeName = (title) => {
    this.setState({
      previous: title,
    });
    setTimeout(() => {
      if (this.state.previous != title) {
        return;
      }
      this.setState({
        candidates: null,
      });
      this.props.actions
        .searchArtwork({
          artist_ids: this.state.selectedIds,
          not_gallery_id: this.props.gallery.id,
          keyword: title,
        })
        .then(({ value, action }) => {
          if (value.status < 300) {
            return this.setState({
              candidates: value.data,
            });
          }
        })
        .catch((error) => console.log(error));
    }, 1000);
  };

  onSelectArtwork = (artwork) => {
    this.setState(
      {
        editing: artwork,
      },
      () => {
        UIkit.modal(this.saleModal, { container: true }).show();
      },
    );
  };

  onAdd = (values) => {
    const param = Object.assign({}, values, {
      artist_id: this.state.selectedIds,
    });
    return this.props.actions.add(param);
  };

  onAdded = (data) => {
    UIkit.modal(this.saleModal).hide();
    Notification.successIntl("util.success.add");
    this.props.history.push(
      PathGenerator.getLocalPath(Path.gallery.artworks.edit.url, {
        artworkId: data.id,
      }),
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-large" data-uk-grid>
          <div className="uk-width-expand">
            <h1>
              <IntlText id="gallery.artworks.add_title" />
            </h1>
          </div>
          <div className="uk-width-medium@s uk-text-right">
            <ChangeEditLang />
          </div>
        </div>
        <div className="uk-margin-large" data-uk-grid>
          <div className="uk-width-expand">
            <div className="uk-margin-medium">
              <h2>
                <IntlText id="gallery.artworks.artist" />
              </h2>
              <MultiSelect
                placeholder="Artists"
                options={this.state.artists}
                onChange={this.selectArtist}
              />
            </div>
            {this.state.selectedIds && this.state.selectedIds.length > 0 && (
              <div className="uk-margin-medium">
                <h2>
                  <IntlText id="gallery.artworks.add_title" />
                </h2>
                <div className="uk-margin">
                  <ArtworkForm
                    onSubmit={this.onAdd}
                    onSuccess={this.onAdded}
                    onChangeName={this.onChangeName}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="uk-width-large@s">
            <h3>
              <IntlText id="gallery.artworks.recommend" />
            </h3>
            <div className="uk-margin">
              <ArtworksList
                artworks={this.state.candidates}
                onClick={this.onSelectArtwork}
              />
            </div>
          </div>
        </div>
        <div ref={(saleModal) => (this.saleModal = saleModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="gallery.artworks.sale_info" />
              </h2>
            </div>
            <div className="uk-modal-body">
              <SaleForm
                onSubmit={(values) => {
                  return this.props.actions.sale(this.state.editing.id, values);
                }}
                onSuccess={this.onAdded}
                initialValues={this.state.editing}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showAlert: (...param) => dispatch(showAlert(...param)),
      search: (param) => dispatch(search(param)),
      searchArtwork: (param) => dispatch(searchArtwork(param)),
      add: (...param) => dispatch(add(...param)),
      sale: (...param) => dispatch(sale(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddScreen),
);
