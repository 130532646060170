import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

class TopScreen extends PureComponent {
  state = {};

  componentWillMount = () => {};

  render = () => {
    return (
      <div>
        <h1>Admin Page</h1>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    admin: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopScreen),
);
