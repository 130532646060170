import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getData } from "../../../actions/fair/index";
import { addAll } from "../../../actions/fair/invite";
import { search } from "../../../actions/gallery.js";
import { showConfirm } from "../../../actions/message";
import SearchForm from "../../../components/forms/SearchForm";
import GalleriesList from "../../../components/lists/Galleries";
import { Button } from "../../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../../components/util/Text";
import Gallery from "../../../components/views/Gallery";
import { Path, PathGenerator } from "../../../constants/path";
import { Notification } from "../../../utils/notification";

class AddScreen extends PureComponent {
  state = {
    fair: {},
    candidates: [],
    selecteds: [],
    meta: {
      current_page: 0,
      last_page: null,
    },
    keyword: null,
    loading: false,
  };

  componentWillMount = () => {
    this.readMore(true);
  };

  onSearch = (values) => {
    this.setState({ keyword: values.keyword });
    const param = Object.assign({}, values, {
      not_fair_id: this.props.fair.id,
    });
    return this.props.actions.search(param);
  };

  onSearched = (data, response) => {
    this.setState({
      candidates: data.filter((gallery) => {
        return !this.state.selecteds.find((select) => {
          return select.id == gallery.id;
        });
      }),
      meta: response.data.meta,
    });
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        not_fair_id: this.props.fair.id,
        keyword: this.state.keyword,
        page: this.state.meta.current_page + 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          const newGalleries = value.data.filter((gallery) => {
            return !this.state.selecteds.find((select) => {
              return select.id == gallery.id;
            });
          });
          this.setState({
            candidates:
              this.state.candidates && !refresh
                ? this.state.candidates.concat(newGalleries)
                : newGalleries,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onSelect = (gallery) => {
    this.setState({
      candidates: this.state.candidates.filter((gal) => gal.id != gallery.id),
      selecteds: this.state.selecteds.concat([gallery]),
    });
  };

  onUnselect = (gallery) => {
    this.setState({
      candidates: this.state.candidates.concat([gallery]),
      selecteds: this.state.selecteds.filter((gal) => gal.id != gallery.id),
    });
  };

  onInvite = () => {
    this.props.actions.showConfirm(
      getPureMessage("fair.invites.add_confirm"),
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }

        const ids = this.state.selecteds.map((select) => select.id);
        this.props.actions
          .addAll({ gallery_id: ids })
          .then(({ value }) => {
            if (value.status < 300) {
              Notification.successIntl("util.success.add");
              this.props.history.push(
                PathGenerator.getLocalPath(Path.fair.invites.url),
              );
            }
          })
          .catch((error) => console.log(error));
      },
    );
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="fair.invites.add_title" />
        </h1>
        <div className="uk-margin-small">
          <SearchForm onSubmit={this.onSearch} onSuccess={this.onSearched} />
        </div>
        <div className="uk-margin-medium" data-uk-grid>
          <div className="uk-width-expand">
            <h2>
              <IntlText id="fair.invites.galleries" />
            </h2>
            <div className="uk-margin">
              <GalleriesList
                editing
                size={2}
                galleries={this.state.candidates}
                onClick={this.onSelect}
              />
            </div>
            {this.state.meta.last_page &&
              this.state.meta.last_page > this.state.meta.current_page && (
                <div className="uk-margin uk-text-center">
                  <Button
                    className="uk-button uk-button-default"
                    onClick={() => this.readMore(false)}
                    loading={this.state.loading ? 1 : 0}
                  >
                    <IntlText id="forms.more" />
                  </Button>
                </div>
              )}
          </div>
          <div className="uk-width-large@s">
            <h2>
              <IntlText id="fair.invites.select" />
            </h2>
            <div className="uk-margin">
              {this.state.selecteds &&
                this.state.selecteds.map((gallery, index) => {
                  return (
                    <div key={index} className="uk-margin-small">
                      <Gallery
                        gallery={gallery}
                        editing
                        onClick={this.onUnselect}
                        buttonText={<IntlText id="fair.invites.remove" />}
                      />
                    </div>
                  );
                })}
            </div>
            <div className="uk-margin uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={this.onInvite}
                disabled={
                  !this.state.selecteds || this.state.selecteds.length == 0
                }
              >
                <IntlText id="fair.invites.invite" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    fair: state.auth ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getData: () => dispatch(getData()),
      search: (param) => dispatch(search(param)),
      addAll: (param) => dispatch(addAll(param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddScreen),
);
