import { SEARCH_PHOTOS, EDIT_PHOTO, DELETE_PHOTO } from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: SEARCH_PHOTOS,
      payload: () => {
        return Api.get(EndPoints.fairPhotos(), param);
      },
    };
  };
};

export const edit = (photoId, param) => {
  return (dispatch) => {
    return {
      type: EDIT_PHOTO,
      payload: () => {
        return Api.put(EndPoints.fairPhotos(photoId), param);
      },
    };
  };
};

export const deletePhoto = (photoId) => {
  return (dispatch) => {
    return {
      type: DELETE_PHOTO,
      payload: () => {
        return Api.delete(EndPoints.fairPhotos(photoId));
      },
    };
  };
};
