import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Loading } from "../util/Loading";
import { IntlText } from "../util/Text";

class SalesList extends PureComponent {
  render = () => {
    if (this.props.sales == null) {
      return <Loading />;
    } else if (this.props.sales.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.sales.no" />
          </h3>
        </div>
      );
    }

    return (
      <table className="uk-table uk-table-striped">
        <thead>
          <tr>
            <th className="uk-table-shrink">
              <IntlText id="lists.sales.date" />
            </th>
            <th className="uk-table-expand">
              <IntlText id="lists.sales.count" />
            </th>
            <th className="uk-table-expand">
              <IntlText id="lists.sales.sales" />
            </th>
            <th className="uk-table-expand">
              <IntlText id="lists.sales.revenue" />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.sales.map((sale, index) => {
            return (
              <tr key={index}>
                <td>
                  <div className="uk-width-small">
                    {moment(
                      sale.paid_year + "-" + sale.paid_month + "-01",
                    ).format("YYYY/MM")}
                  </div>
                </td>
                <td>
                  <span className="uk-h4">{sale.purchase_count}</span>
                </td>
                <td>
                  <span className="uk-h4">{Number(sale.sales).toFixed(2)}</span>
                </td>
                <td>
                  <span className="uk-h4">
                    {Number(sale.sales * (1 - this.props.margin)).toFixed(2)}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SalesList),
);
