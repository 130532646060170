import {
  GET_INFORMATIONS,
  ADD_INFORMATION,
  EDIT_INFORMATION,
  DELETE_INFORMATION,
  DELETE_INFORMATION_IMAGE,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: GET_INFORMATIONS,
      payload: () => {
        return Api.get(EndPoints.galleryInformations(), param);
      },
    };
  };
};

export const add = (data) => {
  return (dispatch) => {
    return {
      type: ADD_INFORMATION,
      payload: () => {
        return Api.post(EndPoints.galleryInformations(), data);
      },
    };
  };
};

export const edit = (infoId, data) => {
  return (dispatch) => {
    return {
      type: EDIT_INFORMATION,
      payload: () => {
        return Api.put(EndPoints.galleryInformations(infoId), data);
      },
    };
  };
};

export const deleteInfo = (infoId) => {
  return (dispatch) => {
    return {
      type: DELETE_INFORMATION,
      payload: () => {
        return Api.delete(EndPoints.galleryInformations(infoId));
      },
    };
  };
};

export const deleteImage = (infoId) => {
  return (dispatch) => {
    return {
      type: DELETE_INFORMATION_IMAGE,
      payload: () => {
        return Api.delete(EndPoints.galleryInformations(infoId, "image"));
      },
    };
  };
};
