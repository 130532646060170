import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { languages } from "../../constants/option";
import { imageUrl } from "../util/Image";
import { getPureMessage } from "../util/Text";

class Meta extends Component {
  componentDidMount = () => {};

  getDef = () => {
    return getPureMessage("meta");
  };

  getTitle = () => {
    const def = this.getDef();
    return this.props.title != null
      ? this.props.title + " | " + def.title
      : def.title;
  };

  getDescription = () => {
    const def = this.getDef();
    return this.props.description != null
      ? this.props.description
      : def.description;
  };

  getImage = () => {
    if (this.props.image == null) {
      return "https://art-scenes.net/images/ogp_1.jpg";
    } else if (this.props.image.indexOf("/") == 0) {
      return process.env.REACT_APP_URL + this.props.image;
    } else {
      return imageUrl(this.props.image);
    }
  };

  getUrl = () => {
    return this.props.url != null
      ? this.props.url
      : process.env.REACT_APP_URL + this.props.history.location.pathname;
  };

  render = () => {
    const regEx = new RegExp("^/" + this.props.language.view + "");
    const def = this.getDef();
    return (
      <div>
        <Helmet
          title={this.getTitle()}
          description={this.getDescription()}
          meta={[
            { name: "description", content: this.getDescription() },
            { name: "twitter:card", content: "summary_large_image" },
            { name: "twitter:title", content: this.getTitle() },
            { name: "twitter:description", content: this.getDescription() },
            { name: "twitter:image", content: this.getImage() },

            { property: "fb:app_id", content: "" },
            { property: "og:title", content: this.getTitle() },
            { property: "og:site_name", content: def.name },
            { property: "og:type", content: "website" },
            { property: "og:url", content: this.getUrl() },
            { property: "og:image", content: this.getImage() },
            { property: "og:description", content: this.getDescription() },
          ]}
        >
          {languages.map((lang, key) => {
            if (lang.iso) {
              const url =
                process.env.REACT_APP_URL +
                this.props.location.pathname.replace(regEx, "/" + lang.value) +
                this.props.location.search;
              return (
                <link
                  key={key}
                  rel="alternate"
                  href={url}
                  hrefLang={lang.value}
                />
              );
            }
          })}
        </Helmet>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Meta));
