import React, { PureComponent } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import TextInput from "../inputs/TextInput";
import { Button } from "../util/Clickable";
import { IntlText } from "../util/Text";
import * as validate from "../util/Validator";

class ForgetForm extends PureComponent {
  state = {
    loading: false,
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(async ({ value, action }) => {
      if (value.status != 200) {
        const _sleep = (ms) =>
          new Promise((resolve) => setTimeout(resolve, ms));
        await _sleep(4000);
      }
      this.setState(
        {
          loading: false,
        },
        () => {
          this.props.onSuccess &&
            this.props.onSuccess(value.data, value.response);
        },
      );
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-margin-small">
          <Field
            name="email"
            type="email"
            component={TextInput}
            labelId="forms.attr.email"
            validate={[validate.required, validate.email]}
            required
          />
        </div>
        <div className="uk-margin-small uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "forget-form",
  enableReinitialize: true,
})(ForgetForm);
