import {
  POST_GALLERY_LOGIN,
  POST_GALLERY_LOGOUT,
  SET_GALLERY_DATA,
  POST_FAIR_LOGIN,
  POST_FAIR_LOGOUT,
  SET_FAIR_DATA,
  POST_ADMIN_LOGIN,
  POST_ADMIN_LOGOUT,
  SET_ADMIN_DATA,
  SET_COLLECTOR_DATA,
  RESET_COLLECTOR_SOURCE,
  POST_COLLECTOR_LOGIN,
  POST_COLLECTOR_LOGOUT,
  VALIDATE_COLLECTOR,
  REFRESH_COLLECTOR,
  MERGE_COLLECTOR,
  POST_MEDIUM_LOGIN,
  POST_MEDIUM_LOGOUT,
  SET_MEDIUM_DATA,
} from "../constants/type";
import Immutable from "immutable";

const initStatus = {
  data: {},
  access_token: null,
  role: null,
  source: null,
};

export default function authReducer(state = initStatus, action = {}) {
  switch (action.type) {
    case POST_GALLERY_LOGIN + "_FULFILLED":
      if (action.payload.status > 300) {
        return initStatus;
      }
      return {
        role: "gallery",
        access_token: action.payload.data.access_token,
        data: action.payload.data,
      };

    case POST_FAIR_LOGIN + "_FULFILLED":
      if (action.payload.status > 300) {
        return initStatus;
      }
      return {
        role: "fair",
        access_token: action.payload.data.access_token,
        data: action.payload.data,
      };

    case POST_ADMIN_LOGIN + "_FULFILLED":
      if (action.payload.status > 300) {
        return initStatus;
      }
      return {
        role: "admin",
        access_token: action.payload.data.access_token,
        data: action.payload.data,
      };

    case POST_MEDIUM_LOGIN + "_FULFILLED":
      if (action.payload.status > 300) {
        return initStatus;
      }
      return {
        role: "medium",
        access_token: action.payload.data.access_token,
        data: action.payload.data,
      };

    case POST_COLLECTOR_LOGIN + "_FULFILLED":
    case VALIDATE_COLLECTOR + "_FULFILLED":
    case REFRESH_COLLECTOR + "_FULFILLED":
      if (action.payload.status > 300) {
        return initStatus;
      }
      return {
        role: "collector",
        access_token: action.payload.data.access_token,
        data: action.payload.data,
      };

    case POST_MEDIUM_LOGOUT + "_FULFILLED":
    case POST_ADMIN_LOGOUT + "_FULFILLED":
    case POST_FAIR_LOGOUT + "_FULFILLED":
    case POST_GALLERY_LOGOUT + "_FULFILLED":
    case POST_COLLECTOR_LOGOUT + "_FULFILLED":
      return initStatus;

    case SET_ADMIN_DATA:
    case SET_MEDIUM_DATA:
    case SET_FAIR_DATA:
    case SET_GALLERY_DATA:
    case SET_COLLECTOR_DATA:
      return Immutable.fromJS(state)
        .updateIn(["data"], (v) => {
          return action.data;
        })
        .updateIn(["source"], (v) => {
          return action.data ? action.data.source : null;
        })
        .toJS();

    case MERGE_COLLECTOR:
      return (
        Immutable.fromJS(state)
          // .updateIn(["role"], v => "collector" )
          .mergeIn(["data"], action.data)
          .updateIn(["source"], (v) => {
            return action.data.source;
          })
          .toJS()
      );

    case RESET_COLLECTOR_SOURCE:
      return Immutable.fromJS(state)
        .updateIn(["source"], (v) => {
          return null;
        })
        .toJS();

    default:
      return state;
  }
}
