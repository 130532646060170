import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { forget } from "../../../actions/collector/auth";
import { showAlert } from "../../../actions/message";
import logo from "../../../assets/images/logo/black.png";
import ForgetForm from "../../../components/forms/ForgetForm";
import Meta from "../../../components/layout/Meta";
import { Link } from "../../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../../components/util/Text";
import { Path } from "../../../constants/path";

class ForgetScreen extends PureComponent {
  submit = (values) => {
    return this.props.actions.forget(values);
  };

  onSuccess = (data) => {
    this.props.actions.showAlert(
      <IntlText id="collector.auth.forget.completed" />,
      <IntlText id="collector.auth.forget.please" />,
    );
  };

  render = () => {
    return (
      <div>
        <Meta title={getPureMessage("collector.auth.forget.header")} />
        <div className="uk-text-center">
          <img src={logo} className="uk-width-medium" />
        </div>
        <h1 className="uk-text-center">
          <IntlText id="collector.auth.forget.header" />
        </h1>
        <div className="uk-margin-large uk-text-center">
          <div className="uk-width-large@s uk-text-left uk-display-inline-block">
            <IntlText id="fair.auth.forget.message" />
            <div className="uk-margin-large">
              <ForgetForm onSubmit={this.submit} onSuccess={this.onSuccess} />
            </div>
            <hr />
            <div className="uk-margin-large uk-text-center">
              <Link
                className="uk-button-default uk-button"
                to={Path.collector.auth.login.url}
                ga={{
                  category: "button",
                  action: "collector_auth_forget",
                  label: "back",
                }}
              >
                <IntlText id="collector.auth.forget.back" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      forget: (data) => dispatch(forget(data)),
      showAlert: (...param) => dispatch(showAlert(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgetScreen),
);
