const messages = {
  alert: {
    ok: "OK",
  },
  confirm: {
    ok: "OK",
    cancel: "キャンセル",
    delete: "削除する",
    title: {
      delete: "本当に削除してよろしいですか？",
    },
  },
  success: {
    add: "追加しました",
    update: "更新しました",
    delete: "削除しました",
    get: "取得しました",
  },
  error: {
    add: "追加に失敗しました",
    update: "更新に失敗しました",
    delete: "削除に失敗しました",
    get: "取得に失敗しました",
  },
  upload_image: {
    drop: "画像をドラッグ&ドロップ",
    or: "または",
    select: "選択します",
    compressing: "圧縮中 ...",
  },
  sns: {
    lead: "Art Scenesをフォローして<br/>作品・アーティスト・展示など最新情報をチェックしよう",
    button: "Art Scenesにいいね",
  },
  not_found: {
    message: "ページが存在しません",
    back: "トップページへ戻る",
  },
  like_pops: {
    title: "最新情報をチェック",
    lead: "<span class='uk-text-nowrap'>Art Scenesに無料で会員登録すると、<br/>Likeしたアーティストや作品・展示の最新情報を<br/>メールでお届けいたします!</span>",
    thanks: "ありがとうございます",
    next: "{email}をチェックして本登録へ進みましょう！",
  },
  change_edit_lang: {
    title: "編集中の言語",
  },
  upgrade: {
    title: "トライアル期間が終了しました",
    message:
      "作品・アーティスト・展示情報等の追加には有料プランへのアップグレードが必要です。",
    ok: "アップグレード",
  },
};

export default messages;
