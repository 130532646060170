import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { LikeButton } from "../util/Like";

class GalleryCard extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const gallery = !this.props.gallery.name
      ? Formatter.mergeDesc(
          this.props.gallery,
          this.props.gallery.gallery_descs,
          lang,
        )
      : this.props.gallery;

    var location =
      gallery.locations && gallery.locations.length > 0
        ? gallery.locations[0]
        : null;
    if (location != null) {
      location = Formatter.mergeDesc(location, location.location_descs, lang);
    }

    return (
      <Link
        to={PathGenerator.getLocalPath(Path.galleries.view.url, {
          galleryId: gallery.id,
        })}
        className="uk-link-reset uk-display-block uk-position-relative"
        ga={{
          category: "link",
          action: "gallery_card",
          label: "gallery_" + gallery.id,
        }}
      >
        <div className="uk-width-1-1 uk-height-large@m uk-height-medium uk-image-wrapper">
          <Image src={gallery.thumnail_url} alt={gallery.name} />
        </div>
        <div
          className="uk-position-bottom uk-overlay uk-overlay-gradient uk-light"
          style={{ height: 60 }}
        >
          <div data-uk-grid className="uk-grid-small" style={{ marginTop: 16 }}>
            <div className="uk-width-auto">
              <div className="uk-thumnail-small uk-image-wrapper">
                <Image src={gallery.logo_url} alt={gallery.name} />
              </div>
            </div>
            <div className="uk-width-expand uk-padding-right">
              <h3 className="uk-margin-remove uk-h3">{gallery.name}</h3>
              {location && <p className="uk-margin-remove">{location.place}</p>}
            </div>
          </div>
        </div>
        <div className="uk-position-xsmall uk-position-bottom-right">
          <LikeButton type="gallery" data={gallery} />
        </div>
      </Link>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GalleryCard);
