import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { IntlText } from "../util/Text";
import ArtworkView from "../views/Artwork";

class ArtworkCardsList extends Component {
  render = () => {
    if (this.props.artworks == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.artworks.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.artworks.no" />
          </h3>
        </div>
      );
    }

    return (
      <div
        className={
          "uk-child-width-1-2 " +
          (this.props.column != 2 ? "uk-child-width-1-3@m" : "")
        }
        data-uk-grid="masonry: true"
      >
        {this.props.artworks.map((artwork, key) => {
          return (
            <div
              key={key}
              className={
                this.props.max && key > this.props.max - 1 ? "uk-visible@m" : ""
              }
            >
              <ArtworkView
                artwork={artwork}
                lang={
                  this.props.editing
                    ? this.props.language.edit
                    : this.props.language.view
                }
                editing={this.props.editing}
                buttonClass={this.props.buttonClass}
                buttonText={
                  this.props.buttonText && this.props.buttonText(artwork)
                }
                onClick={this.props.onClick}
              />
            </div>
          );
        })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtworkCardsList),
);
