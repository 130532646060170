import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchGalleries } from "../../actions/gallery";
import { Button } from "../../components/util/Clickable";
import { IntlText } from "../../components/util/Text";
import { Formatter } from "../../utils/formatter";
import Gallery from "../../components/details/Gallery";
import { Loading } from "../../components/util/Loading";

class ArtworksScreenByGallery extends Component {
  state = {
    galleries: null,
    loading: false,
    meta: {
      current_page: 0,
      last_page: null,
    },
  };

  componentWillMount = () => {
    this.setState({ loading: true });
    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .searchGalleries({
        fair_id: this.props.fair_id,
        section_id: this.state.section_id,
        page: this.state.meta.current_page + 1,
        pagesize: 8,
        excludes: this.state.galleries
          ? this.state.galleries.map((a) => a.id).join(",")
          : "0",
        sort: "attend",
        order: "asc",
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            galleries: this.state.galleries
              ? this.state.galleries.concat(value.data)
              : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div className="uk-margin-medium">
        {this.state.loading && <Loading />}
        {this.state.galleries?.map((gallery) => {
          return (
            <Gallery
              gallery={Formatter.mergeDesc(
                gallery,
                gallery.gallery_descs,
                this.props.language.view,
              )}
              fair_id={this.props.fair_id}
              key={gallery.id}
            />
          );
        })}
        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-medium uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
                ga={{
                  category: "button",
                  action: "fair_artworks",
                  label: "more",
                }}
              >
                <IntlText id="forms.more" />
              </Button>
            </div>
          )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchGalleries: (...param) => dispatch(searchGalleries(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtworksScreenByGallery),
);
