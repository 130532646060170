import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setEditLang, setViewLang } from "../../actions/language";
import { showConfirm } from "../../actions/message";
import white from "../../assets/images/logo/white.svg";
import { ExternalPath, Path } from "../../constants/path";
import { languageStr, Location } from "../../utils/location";
import Logger from "../../utils/logger";
import { Link } from "../util/Clickable";
import { IntlHTML, IntlText } from "../util/Text";

class Footer extends Component {
  state = {
    editLang: false,
  };

  componentWillMount = () => {
    this.setUser(this.props);
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.auth.data != null &&
      (nextProps.auth.data == null ||
        (this.props.auth.data.id != nextProps.auth.data.id &&
          this.props.auth.role != nextProps.auth.role))
    ) {
      this.setUser(nextProps);
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    const language = Location.getCandidateLanguage();
    if (language != this.props.language.view) {
      setTimeout(() => {
        if (language != this.props.language.view) {
          this.setState({ editLang: true });
        }
      }, 2000);
    }
  };

  setUser = (props) => {
    if (props.auth.data != null) {
      Logger.set({ userId: props.auth.data.id, role: props.auth.role });
    }
  };

  componentDidUpdate = (nextProps, prevState) => {
    if (
      nextProps.location.pathname != this.props.location.pathname ||
      nextProps.location.search != this.props.location.search
    ) {
      setTimeout(() => {
        if (this.props.history.action != "POP") {
          window.scrollTo(0, 0);
        }
      });
    }
  };

  onCloseLang = () => {
    this.setState({ editLang: false });
  };

  onChangeLang = () => {
    const language = Location.getCandidateLanguage();
    this.props.actions.setViewLang(language);
    this.props.actions.setEditLang(language);
    this.setState({ editLang: false }, () => {
      const regEx = new RegExp("^/(" + languageStr + ")");
      const url =
        this.props.location.pathname.replace(regEx, "/" + language) +
        this.props.location.search;
      window.location.href = url;
    });
  };

  render = () => {
    return (
      <footer>
        {this.state.editLang && (
          <div
            className="uk-position-fixed uk-position-small uk-position-bottom uk-text-center"
            data-uk-scrollspy="cls:uk-animation-slide-bottom;"
            style={{ zIndex: 1020 }}
          >
            <div className="uk-card uk-card-secondary uk-padding-ssmall uk-display-inline-block uk-width-large@s uk-text-left">
              <p className="uk-margin-ssmall">{Location.isChangeLang()}</p>
              <div className="uk-margin-ssmall uk-text-right">
                <button
                  className="uk-button uk-button-small uk-button-default uk-margin-ssmall-right"
                  onClick={this.onCloseLang}
                >
                  Cancel
                </button>
                <button
                  className="uk-button uk-button-small uk-button-default"
                  onClick={this.onChangeLang}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="uk-section uk-section-small">
          <div className="uk-container uk-text-center">
            <a data-uk-scroll data-uk-icon="icon: chevron-up; ratio: 2;" />
          </div>
        </div>
        <div className="uk-section uk-section-secondary">
          <div className="uk-container">
            <div className="uk-margin-medium" data-uk-grid>
              <div className="uk-width-auto@s">
                <Link
                  to={Path.root.url}
                  ga={{ category: "link", action: "footer", label: "top" }}
                >
                  <img className="uk-width-medium" src={white} />
                </Link>
              </div>
              <div className="uk-width-expand">
                <small>
                  <IntlHTML id="layout.footer_text" />
                </small>
              </div>
            </div>
            <div className="uk-margin-large" data-uk-grid>
              <div className="uk-width-1-4@s uk-width-1-1">
                <h3 className="uk-text-muted uk-text-mincho">
                  <IntlText id="layout.contents" />
                </h3>
                <div className="uk-margin-small">
                  <Link
                    to={Path.artworks.url}
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "artworks",
                    }}
                  >
                    <IntlText id="word.artworks" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.artists.url}
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "artists",
                    }}
                  >
                    <IntlText id="word.artists" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.galleries.url}
                    className="uk-button uk-button-text"
                  >
                    <IntlText id="word.galleries" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.fairs.url}
                    className="uk-button uk-button-text"
                    ga={{ category: "link", action: "footer", label: "fairs" }}
                  >
                    <IntlText id="word.fairs" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.shows.url}
                    className="uk-button uk-button-text"
                    ga={{ category: "link", action: "footer", label: "shows" }}
                  >
                    <IntlText id="word.shows" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.articles.url}
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "articles",
                    }}
                  >
                    <IntlText id="word.articles" />
                  </Link>
                </div>
              </div>
              <div className="uk-width-1-4@s uk-width-1-1">
                <h3 className="uk-text-muted uk-text-mincho">
                  <IntlText id="layout.collectors" />
                </h3>
                <div className="uk-margin-small">
                  <Link
                    to={Path.collector.auth.login.url}
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "collector_login",
                    }}
                  >
                    <IntlText id="layout.collector_login" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.likes.url}
                    className="uk-button uk-button-text"
                    ga={{ category: "link", action: "footer", label: "likes" }}
                  >
                    <IntlText id="layout.likes" />
                  </Link>
                </div>
                <h3 className="uk-text-muted uk-text-mincho">
                  <IntlText id="layout.terms" />
                </h3>
                <div className="uk-margin-small">
                  <Link
                    to={Path.other.term.url}
                    className="uk-button uk-button-text"
                    ga={{ category: "link", action: "footer", label: "term" }}
                  >
                    <IntlText id="layout.term_of_use" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.other.privacy.url}
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "privacy",
                    }}
                  >
                    <IntlText id="layout.privacy" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.other.law.url}
                    className="uk-button uk-button-text"
                    ga={{ category: "link", action: "footer", label: "law" }}
                  >
                    <IntlText id="layout.law" />
                  </Link>
                </div>
              </div>
              <div className="uk-width-1-4@s uk-width-1-1 uk-separator@s">
                <h3 className="uk-text-muted uk-text-mincho">
                  <IntlText id="layout.partner" />
                </h3>
                <div className="uk-margin-small">
                  <Link
                    to={Path.gallery.auth.login.url}
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "for_gallery",
                    }}
                  >
                    <IntlText id="layout.login.gallery" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.fair.auth.login.url}
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "for_fair",
                    }}
                  >
                    <IntlText id="layout.login.fair" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.medium.auth.login.url}
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "for_medium",
                    }}
                  >
                    <IntlText id="layout.login.medium" />
                  </Link>
                </div>
              </div>
              <div className="uk-width-1-4@s uk-width-1-1">
                <h3 className="uk-text-muted uk-text-mincho">
                  <IntlText id="layout.about_menu" />
                </h3>
                {/* <div className="uk-margin-small">
                  <Link to={Path.about.url} className="uk-button uk-button-text" ga={{category: 'link', action: 'footer', label: 'about'}}>
                    <IntlText id="layout.about" />
                  </Link>
                </div> */}
                <div className="uk-margin-small">
                  <Link
                    to={Path.other.contact.url}
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "contact",
                    }}
                  >
                    <IntlText id="layout.contact" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    to={Path.other.estimated_delivery_charge.url}
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "estimated_delivery_charge",
                    }}
                  >
                    <IntlText id="layout.estimated_delivery_charge" />
                  </Link>
                </div>
                <div className="uk-margin-small">
                  <Link
                    href={ExternalPath.company}
                    target="_blank"
                    className="uk-button uk-button-text"
                    ga={{
                      category: "link",
                      action: "footer",
                      label: "company",
                    }}
                  >
                    <IntlText id="layout.company" />
                  </Link>
                </div>
                <div className="uk-margin-medium uk-grid-small" data-uk-grid>
                  <div className="uk-width-auto">
                    <Link
                      href={ExternalPath.facebook}
                      target="_blank"
                      className="uk-button uk-button-text"
                      ga={{
                        category: "link",
                        action: "footer",
                        label: "facebook",
                      }}
                      data-uk-icon="icon:facebook;ratio:1.2;"
                    ></Link>
                  </div>
                  <div className="uk-width-auto">
                    <Link
                      href={ExternalPath.twitter}
                      target="_blank"
                      className="uk-button uk-button-text"
                      ga={{
                        category: "link",
                        action: "footer",
                        label: "twitter",
                      }}
                      data-uk-icon="icon:twitter;ratio:1.2;"
                    ></Link>
                  </div>
                  <div className="uk-width-auto">
                    <Link
                      href={ExternalPath.instagram}
                      target="_blank"
                      className="uk-button uk-button-text"
                      ga={{
                        category: "link",
                        action: "footer",
                        label: "instagram",
                      }}
                      data-uk-icon="icon:instagram;ratio:1.2;"
                    ></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-background-secondary uk-light">
          <p className="uk-text-center uk-margin-remove">
            <Link
              target="_blank"
              href={ExternalPath.company}
              ga={{
                category: "footer",
                action: "to_company",
                label: "footer",
                nonInteraction: true,
              }}
            >
              <small className="uk-text-mincho uk-text-small">
                ©2018 株式会社TODOROKI 東京都公安委員会許可 第305502007261号
              </small>
            </Link>
          </p>
        </div>
      </footer>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showConfirm: (...param) => dispatch(showConfirm(...param)),
      setViewLang: (...param) => dispatch(setViewLang(...param)),
      setEditLang: (...param) => dispatch(setEditLang(...param)),
    },
    dispatch,
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
