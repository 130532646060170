import {
  SEARCH_PURCHASES,
  CONFIRM_PURCHASE,
  DENY_PURCHASE,
  GET_SUMMARIES,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param = {}) => {
  return (dispatch) => {
    return {
      type: SEARCH_PURCHASES,
      payload: () => {
        return Api.get(EndPoints.galleryPurchases(), param);
      },
    };
  };
};

export const confirm = (purchaseId, param) => {
  return (dispatch) => {
    return {
      type: SEARCH_PURCHASES,
      payload: () => {
        return Api.put(EndPoints.galleryPurchases(purchaseId), param);
      },
    };
  };
};

export const deny = (purchaseId, param) => {
  return (dispatch) => {
    return {
      type: SEARCH_PURCHASES,
      payload: () => {
        return Api.delete(EndPoints.galleryPurchases(purchaseId), param);
      },
    };
  };
};

export const summary = (param = {}) => {
  return (dispatch) => {
    return {
      type: GET_SUMMARIES,
      payload: () => {
        return Api.get(EndPoints.galleryPurchases("summary"), param);
      },
    };
  };
};
