import { SEARCH_ARTISTS, GET_ARTIST, GET_QUOTES } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";
import { setCache, getCache } from "./cache";

export const search = (param = {}) => {
  return (dispatch) => {
    return {
      type: SEARCH_ARTISTS,
      payload: () => {
        return Api.get(EndPoints.artists(), param);
      },
    };
  };
};

export const get = (artistId, param = {}) => {
  return (dispatch) => {
    return {
      type: GET_ARTIST,
      payload: () => {
        const key = "artists/" + artistId;
        const promise = new Promise((resolve, reject) => {
          const data = getCache(key);
          if (data) {
            return resolve(data);
          }
          Api.get(EndPoints.artists(artistId), param)
            .then((result) => {
              if (result.status < 300) {
                dispatch(setCache(key, result));
              }
              resolve(result);
            })
            .catch((error) => reject(error));
        });
        return promise;
      },
    };
  };
};

export const getQuotes = (artistId, param) => {
  return (dispatch) => {
    return {
      type: GET_QUOTES,
      payload: () => {
        return Api.get(EndPoints.artists(artistId, "quotes"), param);
      },
    };
  };
};
