import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import ArtworkCards from "../lists/ArtworkCards";
import { Link } from "../util/Clickable";
import { LikeButton } from "../util/Like";
import { LinedText } from "../util/Text";

class Artist extends Component {
  state = {
    showAll: false,
  };

  onShowAll = () => {
    if (this.state.showAll) {
      return;
    }
    this.setState({
      showAll: true,
    });
  };

  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const artist = Formatter.mergeDesc(
      this.props.artist,
      this.props.artist.artist_descs,
      lang,
    );
    return (
      <div>
        <div className="uk-margin-medium">
          <h3 className="uk-margin-small-right uk-underline">
            <Link
              className="uk-link-reset"
              to={PathGenerator.getLocalPath(Path.artists.view.url, {
                artistId: artist.id,
              })}
              ga={{
                category: "link",
                action: "artist_detail",
                label: "artist_" + artist.id,
              }}
            >
              {artist.name}
            </Link>
          </h3>
          <span className="uk-h4 uk-text-muted">
            <span className="uk-margin-ssmall-right">{artist.birth_place}</span>
            {artist.birth_year && <span>{artist.birth_year}</span>}
            {(artist.birth_year || artist.death_year) && <span> - </span>}
            {artist.death_year && <span>{artist.death_year}</span>}
          </span>
          <div className="uk-margin-small" onClick={this.onShowAll}>
            {!this.state.showAll && artist.biography ? (
              <LinesEllipsis
                text={artist.biography}
                maxLine="3"
                ellipsis="...ShowAll"
                trimRight
                basedOn="letters"
              />
            ) : (
              <LinedText text={artist.biography} />
            )}
          </div>
          {!this.props.editing && (
            <div className="uk-margin-small uk-text-right">
              <LikeButton type="artist" data={artist} />
            </div>
          )}
        </div>

        {artist.creates && (
          <div className="uk-margin-medium">
            <ArtworkCards
              max={2}
              artworks={artist.creates.map((create) => {
                return create.artwork;
              })}
            />
          </div>
        )}
        {this.props.editing && (
          <div className="uk-margin-medium uk-text-center">
            <Link
              className="uk-button uk-button-default"
              to={PathGenerator.getLocalPath(Path.gallery.artists.edit.url, {
                artistId: artist.id,
              })}
            >
              Edit
            </Link>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Artist);
