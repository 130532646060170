import Immutable from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArtists } from "../../../actions/gallery/artist";
import { search as searchArtworks } from "../../../actions/gallery/artwork";
import {
  deletePhoto,
  edit as editPhoto,
  search as searchPhotos,
} from "../../../actions/gallery/photo";
import {
  deleteImage,
  deleteShow,
  edit,
  get,
} from "../../../actions/gallery/show";
import { showConfirm } from "../../../actions/message";
import ShowForm from "../../../components/forms/gallery/ShowForm";
import PhotoForm from "../../../components/forms/PhotoForm";
import MultiSelectBox from "../../../components/inputs/MultiSelectBox";
import UploadImage from "../../../components/inputs/UploadImage";
import PhotoList from "../../../components/lists/Photos";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Button, Link } from "../../../components/util/Clickable";
import { Image } from "../../../components/util/Image";
import { getPureMessage, IntlText } from "../../../components/util/Text";
import ArtistPart from "../../../components/views/ArtistPart";
import ArtworkPart from "../../../components/views/ArtworkPart";
import { EndPoints } from "../../../constants/endpoints";
import { Path } from "../../../constants/path";
import { Formatter } from "../../../utils/formatter";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class EditScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: null,
      editing: {},
      selectedArtists: [],
      selectedArtworks: [],
      loading: false,
      artists: [],
      artworks: [],
      photos: null,
      editingPhoto: {},
      meta: {
        current_page: 0,
        last_page: null,
      },
    };
  }

  componentWillMount = () => {
    this.props.actions
      .get(this.props.match.params.showId)
      .then(({ value, action }) => {
        if (value.status < 300) {
          const editing = Formatter.mergeDesc(
            value.data,
            value.data.show_descs,
            this.props.language.edit,
          );
          this.setState(
            {
              show: value.data,
              editing: editing,
              selectedArtists: value.data.artists
                ? value.data.artists.map((artist) => artist.id)
                : [],
              selectedArtworks: value.data.artworks
                ? value.data.artworks.map((artwork) => artwork.id)
                : [],
            },
            () => {
              this.props.actions
                .searchArtists({
                  gallery_id: this.props.gallery.id,
                  pagesize: -1,
                })
                .then(({ value, action }) => {
                  if (value.status < 300) {
                    this.setState({
                      artists: value.data,
                    });
                  }
                })
                .catch((error) => console.log(error));

              this.props.actions
                .searchArtworks({
                  gallery_id: this.props.gallery.id,
                  artist_ids: this.state.selectedArtists,
                  pagesize: 10000,
                })
                .then(({ value, action }) => {
                  if (value.status < 300) {
                    this.setState({
                      artworks: value.data,
                    });
                  }
                })
                .catch((error) => console.log(error));

              this.readMore(true);
            },
          );
        }
      })
      .catch((error) => console.log(error));
  };

  readMore = (refresh) => {
    this.props.actions
      .searchPhotos({
        show_id: this.props.match.params.showId,
        page: this.state.meta.current_page + 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            photos:
              this.state.photos && !refresh
                ? this.state.photos.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onChangeLang = (lang) => {
    const editing = Formatter.mergeDesc(
      this.state.show,
      this.state.show.show_descs,
      lang,
    );
    this.setState({
      editing: editing,
    });
  };

  onSelectArtists = (ids) => {
    this.setState({
      selectedArtists: [].concat(ids),
    });
    if (ids.length > 0) {
      this.props.actions
        .searchArtworks({
          gallery_id: this.props.gallery.id,
          artist_ids: ids,
          pagesize: 10000,
        })
        .then(({ value, action }) => {
          if (value.status < 300) {
            this.setState({ artworks: value.data });
          }
        })
        .catch((error) => console.log(error));
    } else {
      this.setState({ artworks: null, selectedArtworks: [] });
    }
  };

  onSelectArtworks = (ids) => {
    this.setState({
      selectedArtworks: [].concat(ids),
    });
  };

  onEdit = (values) => {
    const param = Object.assign({}, values, {
      artist_id: this.state.selectedArtists,
      artwork_id: this.state.selectedArtworks,
    });
    return this.props.actions.edit(this.state.show.id, param);
  };

  onEdited = (data) => {
    Notification.successIntl("util.success.update");
    this.setState({
      show: data,
    });
  };

  onDelete = () => {
    this.props.actions.showConfirm(
      getPureMessage("util.confirm.title.delete"),
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }
        this.props.actions
          .deleteShow(this.state.show.id)
          .then(({ value, status }) => {
            Notification.successIntl("util.success.delete");
            this.props.history.push(Path.gallery.shows.url);
          })
          .catch((error) => console.log(error));
      },
    );
  };

  onUploaded = (data) => {
    this.state.show.show_images.push(data);
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["show"], (v) => this.state.show)
        .toJS(),
    );
    Notification.successIntl("util.success.update");
  };

  onUploadError = () => {
    Notification.errorIntl("util.error.update");
  };

  onDeleteImage = (image) => {
    this.props.actions.showConfirm(
      getPureMessage("util.confirm.title.delete"),
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }
        this.props.actions
          .deleteImage(this.state.show.id, image.id)
          .then(({ value, action }) => {
            if (value.status < 300) {
              this.setState(
                Immutable.fromJS(this.state)
                  .updateIn(["show", "show_images"], (v) =>
                    this.state.show.show_images.filter(
                      (img) => img.id != image.id,
                    ),
                  )
                  .toJS(),
              );
              Notification.successIntl("util.success.delete");
            }
          })
          .catch((error) => console.log(error));
      },
    );
  };

  //photo
  onUploadedPhoto = (data) => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["photos"], (v) => {
          return [data].concat(this.state.photos);
        })
        .toJS(),
    );
    Notification.successIntl("util.success.update");
  };

  onEditPhoto = (photo) => {
    var data = Formatter.mergeDesc(
      photo,
      photo.photo_descs,
      this.props.language.edit,
    );
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["editingPhoto", "id"], (v) => data.id)
        .updateIn(["editingPhoto", "description"], (v) => data.description)
        .toJS(),
      () => {
        UIkit.modal(this.editPhotoModal, { container: false }).show();
      },
    );
  };

  onEditedPhoto = (data) => {
    UIkit.modal(this.editPhotoModal).hide();
    Notification.successIntl("util.success.update");
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["photos"], (v) => {
          return this.state.photos.map((photo) => {
            if (photo.id != data.id) {
              return photo;
            } else {
              return data;
            }
          });
        })
        .toJS(),
    );
  };

  onDeletePhoto = (photo) => {
    this.props.actions.showConfirm(
      <IntlText id="util.confirm.title.delete" />,
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }

        this.props.actions
          .deletePhoto(this.props.match.params.showId, photo.id)
          .then(({ value }) => {
            if (value.status < 300) {
              this.setState({
                photos: this.state.photos.filter((at) => {
                  return at.id != photo.id;
                }),
              });
              Notification.successIntl("util.success.delete");
            }
          })
          .catch((error) => console.log(error));
      },
    );
  };

  renderEditor = () => {
    return (
      <div data-uk-grid>
        <div className="uk-width-expand">
          <div className="uk-margin-medium">
            <h2 className="uk-heading-mark">
              <IntlText id="gallery.show.artist" />
            </h2>
            <MultiSelectBox
              options={this.state.artists}
              selected={this.state.selectedArtists}
              itemRenderer={(item) => {
                return (
                  <ArtistPart
                    editing={false}
                    artist={item}
                    lang={this.props.language.edit}
                  />
                );
              }}
              keyExtractor={(item) => item.id}
              onChange={this.onSelectArtists}
            />
          </div>
          {this.state.selectedArtists && this.state.selectedArtists.length > 0 && (
            <div>
              <h2 className="uk-heading-mark">
                <IntlText id="gallery.show.artwork" />
              </h2>
              <MultiSelectBox
                options={this.state.artworks}
                selected={this.state.selectedArtworks}
                itemRenderer={(item) => {
                  return (
                    <ArtworkPart
                      editing={false}
                      artwork={item}
                      lang={this.props.language.edit}
                    />
                  );
                }}
                keyExtractor={(item) => item.id}
                onChange={this.onSelectArtworks}
              />
            </div>
          )}
          <div className="uk-margin-medium">
            <h2 className="uk-heading-mark">
              <IntlText id="gallery.show.info" />
            </h2>
            <ShowForm
              ref={(editForm) => (this.editForm = editForm)}
              onSubmit={this.onEdit}
              onSuccess={this.onEdited}
              initialValues={this.state.editing}
            />
          </div>
        </div>

        <div className="uk-width-large@s">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.show.images" />
          </h2>
          <div className="uk-margin-small">
            <UploadImage
              url={EndPoints.galleryShowImages(this.state.show.id)}
              onSuccess={this.onUploaded}
              onError={this.onUploadError}
            />
          </div>
          {this.state.show.show_images &&
            this.state.show.show_images.map((image, key) => {
              return (
                <div key={key} className="uk-margin-small uk-position-relative">
                  <Image noSmall className="uk-width-1-1" src={image.url} />
                  <div className="uk-position-small uk-position-top-right">
                    <Button
                      className="uk-icon-button uk-button-danger"
                      data-uk-icon="icon:close;"
                      onClick={() => this.onDeleteImage(image)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-large" data-uk-grid>
          <div className="uk-width-expand">
            <h1>
              <IntlText id="gallery.show.edit_title" />
            </h1>
          </div>
          <div className="uk-width-medium@s uk-text-right">
            <ChangeEditLang onChange={this.onChangeLang} />
          </div>
        </div>

        <div className="uk-margin-large">
          {(() => {
            if (!this.state.artists || !this.state.show) {
              return (
                <div className="uk-text-center">
                  <span data-uk-spinner="ratio: 3"></span>
                </div>
              );
            } else {
              return this.renderEditor();
            }
          })()}
        </div>

        <hr />
        <div className="uk-margin-large">
          <h2 className="uk-heading-mark uk-margin-medium">
            <IntlText id="gallery.show.photos" />
          </h2>
          <div className="uk-margin-medium">
            <UploadImage
              url={EndPoints.showPhotos(this.props.match.params.showId)}
              onSuccess={this.onUploadedPhoto}
              onError={this.onUploadError}
            />
          </div>
          <div className="uk-margin-medium">
            <PhotoList
              photos={this.state.photos}
              onEdit={this.onEditPhoto}
              onDelete={this.onDeletePhoto}
            />
          </div>
          {this.state.meta.last_page &&
            this.state.meta.last_page > this.state.meta.current_page && (
              <div className="uk-margin-medium uk-text-center">
                <Button
                  className="uk-button uk-button-default"
                  onClick={() => this.readMore(false)}
                  loading={this.state.loading ? 1 : 0}
                >
                  <IntlText id="forms.more" />
                </Button>
              </div>
            )}
        </div>

        <hr />
        <div className="uk-margin-large uk-text-center">
          <Link className="uk-button uk-button-danger" onClick={this.onDelete}>
            <IntlText id="forms.delete" />
          </Link>
        </div>

        <div
          ref={(editPhotoModal) => (this.editPhotoModal = editPhotoModal)}
          data-uk-modal
        >
          <div className="uk-modal-dialog uk-margin-auto-vertical uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h3>
                <IntlText id="fair.photos.edit" />
              </h3>
            </div>
            <div className="uk-modal-body">
              <PhotoForm
                onSubmit={(values) =>
                  this.props.actions.editPhoto(
                    this.props.match.params.showId,
                    this.state.editingPhoto.id,
                    values,
                  )
                }
                onSuccess={this.onEditedPhoto}
                initialValues={this.state.editingPhoto}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (param) => dispatch(get(param)),
      edit: (...param) => dispatch(edit(...param)),
      deleteShow: (...param) => dispatch(deleteShow(...param)),
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      searchArtists: (...param) => dispatch(searchArtists(...param)),
      deleteImage: (...param) => dispatch(deleteImage(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
      searchPhotos: (...param) => dispatch(searchPhotos(...param)),
      editPhoto: (...param) => dispatch(editPhoto(...param)),
      deletePhoto: (...param) => dispatch(deletePhoto(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditScreen),
);
