import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { sorts } from "../../constants/option";
import Select from "../inputs/Select";
import { IntlText } from "../util/Text";

class ArtworkSortForm extends PureComponent {
  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        action={this.props.action}
        method="post"
      >
        <div className="uk-grid-small uk-flex-middle" data-uk-grid>
          <div className="uk-width-auto">
            <span className="uk-text-bold">
              <IntlText id="forms.sort_by" />
            </span>
          </div>
          <div className="uk-width-medium">
            <Field
              name="sort_method"
              component={Select}
              className="uk-form-small uk-background-white"
              noError
              notNull
              options={sorts}
            />
          </div>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "artwork-sort-form",
  // enableReinitialize : true
})(ArtworkSortForm);
