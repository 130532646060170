import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import ClearInput from "../inputs/ClearInput";
import { Button } from "../util/Clickable";

class KeywordForm extends PureComponent {
  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        action={this.props.action}
        method="post"
      >
        <div className="uk-grid-collapse uk-flex-middle" data-uk-grid>
          <div className="uk-width-expand">
            <Field
              name="keyword"
              type="text"
              component={ClearInput}
              className="uk-form-small uk-input-keyword"
              placeholder="artist, gallery, etc..."
            />
          </div>
          <div className="uk-width-auto">
            <Button
              className="uk-button uk-background-clear"
              type="submit"
              ga={{ category: this.props.form, action: "submit" }}
              style={{ padding: 9 }}
            >
              <span data-uk-icon="icon:search;" />
            </Button>
          </div>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "keyword-form",
  enableReinitialize: true,
})(KeywordForm);
