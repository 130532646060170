import Immutable from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArtworks } from "../../../actions/gallery/artwork";
import { search as searchFair } from "../../../actions/gallery/attend";
import {
  add,
  deleteImage,
  deleteInfo,
  edit,
  search,
} from "../../../actions/gallery/information";
import { search as searchShows } from "../../../actions/gallery/show";
import { showConfirm } from "../../../actions/message";
import InformationForm from "../../../components/forms/gallery/InformationForm";
import Thumnail from "../../../components/inputs/Thumnail";
import InformationsList from "../../../components/lists/Informations";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Button, Link } from "../../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../../components/util/Text";
import { EndPoints } from "../../../constants/endpoints";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class InformationsScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      informations: null,
      meta: {
        current_page: 0,
        last_page: null,
      },
      loading: false,
      artworks: [],
      shows: [],
      fairs: [],
      editing: {},
    };
  }

  componentWillMount = () => {
    this.readMore(true);
    this.props.actions
      .searchShows({
        gallery_id: this.props.gallery.id,
        pagesize: 10000,
      })
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            shows: value.data.map((s) => {
              const desc = s.show_descs.find((desc) => {
                return (desc.lang = this.props.language.edit);
              });
              return { value: s.id, label: desc ? desc.title : null };
            }),
          });
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchArtworks({
        gallery_id: this.props.gallery.id,
        pagesize: 10000,
      })
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            artworks: value.data.map((a) => {
              const desc = a.artwork_descs.find((desc) => {
                return (desc.lang = this.props.language.edit);
              });
              return { value: a.id, label: desc ? desc.title : null };
            }),
          });
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchFair()
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState(
            {
              fairs: value.data.map((attend) => {
                const desc = attend.fair.fair_descs.find((desc) => {
                  return (desc.lang = this.props.language.edit);
                });
                return {
                  value: attend.fair.id,
                  label: desc ? desc.title : null,
                };
              }),
            },
            () => {
              console.log(this.state);
            },
          );
        }
      })
      .catch((error) => console.log(error));
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        gallery_id: this.props.gallery.id,
        page: this.state.meta.current_page + 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            informations:
              this.state.informations && !refresh
                ? this.state.informations.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onDelete = (information) => {
    this.props.actions.showConfirm(
      getPureMessage("util.confirm.title.delete"),
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }

        this.props.actions
          .deleteInfo(information.id)
          .then(({ value, action }) => {
            if (value.status < 300) {
              this.setState({
                informations: this.state.informations.filter((info) => {
                  return info.id != information.id;
                }),
              });
              Notification.successIntl("util.success.delete");
            }
          })
          .catch((error) => console.log(error));
      },
    );
  };

  onAdded = (values) => {
    UIkit.modal(this.addModal).hide();
    this.addForm.wrappedInstance.props.reset();
    this.setState(
      {
        informations: [values].concat(this.state.informations),
      },
      () => {
        Notification.successIntl("util.success.update");
      },
    );
  };

  onEdit = (information) => {
    this.setState(
      {
        editing: information,
      },
      () => {
        UIkit.modal(this.editModal, { container: false }).show();
      },
    );
  };

  onEdited = (values) => {
    UIkit.modal(this.editModal).hide();
    this.setState(
      {
        informations: this.state.informations.map((info) => {
          if (info.id != values.id) {
            return info;
          }
          return values;
        }),
      },
      () => {
        Notification.successIntl("util.success.update");
      },
    );
  };

  onDeleteImage = () => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["editing", "thumnail_url"], (v) => null)
        .toJS(),
    );
    this.props.actions
      .deleteImage(this.state.editing.id)
      .then(({ value }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.delete");
          this.setState(
            Immutable.fromJS(this.state)
              .updateIn(["informations"], (v) =>
                this.state.informations.map((info) => {
                  return info.id != value.data.id ? info : value.data;
                }),
              )
              .toJS(),
          );
        }
      })
      .catch((error) => console.log(error));
  };

  onUploaded = (data) => {
    this.setState({
      editing: data,
      informations: this.state.informations.map((info) => {
        return info.id != data.id ? info : data;
      }),
    });
    Notification.successIntl("util.success.update");
  };

  onUploadError = () => {
    Notification.successIntl("util.error.update");
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-small" data-uk-grid>
          <div className="uk-width-auto">
            <h1>
              <IntlText id="gallery.informations.title" />
            </h1>
          </div>
          <div className="uk-width-expand">
            <Link
              className="uk-button uk-button-default"
              onClick={() => {
                UIkit.modal(this.addModal, { container: false }).show();
              }}
            >
              <IntlText id="forms.add" />
              <span
                className="uk-margin-small-left"
                data-uk-icon="icon:plus; ratio:0.8;"
              />
            </Link>
          </div>
          <div className="uk-width-medium@s uk-width-1-1 uk-text-right">
            <ChangeEditLang />
          </div>
        </div>

        <div className="uk-margin-large">
          <InformationsList
            lang={this.props.language.edit}
            editing={true}
            informations={this.state.informations}
            onDelete={this.onDelete}
            onEdit={this.onEdit}
          />
        </div>

        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-large uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
              >
                <IntlText id="forms.more" />
              </Button>
            </div>
          )}

        <div ref={(addModal) => (this.addModal = addModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="gallery.informations.add" />
              </h2>
            </div>
            <div className="uk-modal-body">
              <InformationForm
                ref={(addForm) => (this.addForm = addForm)}
                form="information-add-form"
                onSubmit={(values) => this.props.actions.add(values)}
                onSuccess={this.onAdded}
                shows={this.state.shows}
                artworks={this.state.artworks}
                fairs={this.state.fairs}
              />
            </div>
          </div>
        </div>

        <div
          ref={(editModal) => (this.editModal = editModal)}
          data-uk-modal
          className="uk-modal-container"
        >
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="gallery.informations.edit" />
              </h2>
            </div>
            <div className="uk-modal-body">
              <div data-uk-grid>
                <div className="uk-width-expand">
                  <InformationForm
                    form="information-edit-form"
                    onSubmit={(values) =>
                      this.props.actions.edit(this.state.editing.id, values)
                    }
                    onSuccess={this.onEdited}
                    shows={this.state.shows}
                    artworks={this.state.artworks}
                    fairs={this.state.fairs}
                    initialValues={this.state.editing}
                  />
                </div>
                <div className="uk-width-large">
                  <h3>
                    <IntlText id="gallery.informations.thumnail" />
                  </h3>
                  <div className="uk-margin-small">
                    <Thumnail
                      url={EndPoints.galleryInformations(
                        this.state.editing.id,
                        "image",
                      )}
                      src={this.state.editing.thumnail_url}
                      onSuccess={this.onUploaded}
                      onDelete={this.onDeleteImage}
                      keyExtractor={(value) => value.thumnail_url}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
      add: (...param) => dispatch(add(...param)),
      edit: (...param) => dispatch(edit(...param)),
      deleteInfo: (...param) => dispatch(deleteInfo(...param)),
      deleteImage: (...param) => dispatch(deleteImage(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
      searchShows: (...param) => dispatch(searchShows(...param)),
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      searchFair: (...param) => dispatch(searchFair(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InformationsScreen),
);
