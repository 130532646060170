const messages = {
  alert: {
    ok: "OK",
  },
  confirm: {
    ok: "OK",
    cancel: "Cancle",
    delete: "Delete",
    title: {
      delete: "Do you realy delete?",
    },
  },
  success: {
    add: "Add Succeeded",
    update: "Update Succeeded",
    delete: "Delete Succeeded",
    get: "Get Succeeded",
  },
  error: {
    add: "Add Failed",
    update: "Update Failed",
    delete: "Delete Failed",
    get: "Get Failed",
  },
  upload_image: {
    drop: "Drop new image",
    or: "or",
    select: "Select.",
    compressing: "Compressing ...",
  },
  sns: {
    lead: "Follow Art Scenes<br/>and get latest news of Artist, Artwork, Gallery and Fair,",
    button: "Like Art Scenes",
  },
  not_found: {
    message: "Not Found",
    back: "Go Back Top",
  },
  like_pops: {
    title: "Check Latest News!",
    lead: "If you wish to subscribe to the Art Scenes newsletter for free, you will receive all the latest news about your favorite artists and artworks !",
    thanks: "Thank you for subscribing!",
    next: "Check your email accout and validate your account. : {email}",
  },
  change_edit_lang: {
    title: "Editing language",
  },
  upgrade: {
    title: "Trial is ended",
    message: "You have to upgrade for adding artists, artworks and shows.",
    ok: "Upgrade now",
  },
};

export default messages;
