const messages = {
  menu: {
    signin: "Sign In",
    mypage: "My page",
    likes: "Likes",
    signout: "Sign out",
  },
  auth: {
    login: {
      header: "Login",
      ifyouforget: "If you have forgotten your password, ",
      resethere: "reset it here.",
      donthave: "You don't have an account?",
      to_register: "Register New Account",
      term: "Terms for gallery",
      agree: "Agree",
    },
    register: {
      header: "Register Account",
      back: "Back to login",
      have: "If you have an account,",
    },
    reset: {
      header: "Reset Password",
      back: "Back to login",
      completed: "Reset Completed",
      please: "Please login with new password.",
    },
    forget: {
      header: "Reset Password",
      message:
        "Please enter your email address below. If you don't receive the email, please check if you have registered with a different address.",
      back: "Back to login",
      completed: "Send reset mail",
      please:
        "We have sent you an email with a link to reset your password. Please reset your password by following the instructions in the email. If you don't receive the email, please check if you have registered with a different address.",
    },
    thanks: {
      header: "Thank you for signing up!",
      back: "Back to top",
      lead: "Please check your email account and validate your accuont.",
    },
    validate: {
      header: "Account Setting",
      completed: "Registeration is completed!",
    },
  },
  title: "For Collectors",
  mypage: {
    profile: "Profile",
    authorization: "Account Setting",
    likes: "Likes",
    ifwantunsubscribe: "If you want to unsubscribe,",
    contact: "please contact us.",
  },
  edit_auth: {
    title: "Account Setting",
  },
  edit: {
    title: "Profile Setting",
  },
};

export default messages;
