import { getPureMessage } from "../langs/messages";

declare var UIkit;

export class Notification {
  static success(message) {
    UIkit.notification(message, {
      status: "success",
      pos: "bottom-left",
      timeout: 5000,
    });
  }

  static error(message) {
    UIkit.notification(message, {
      status: "danger",
      pos: "bottom-left",
      timeout: 5000,
    });
  }

  static successIntl(id) {
    this.success(getPureMessage(id));
  }

  static errorIntl(id) {
    this.error(getPureMessage(id));
  }
}
