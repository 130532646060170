import {
  POST_ADMIN_LOGIN,
  POST_ADMIN_LOGOUT,
  SET_ADMIN_DATA,
} from "../../constants/type";
import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const login = (data) => {
  return (dispatch) => {
    return {
      type: POST_ADMIN_LOGIN,
      payload: () => {
        return Api.post(EndPoints.admin("auth"), data).then((response) => {
          if (response.status < 300) {
            dispatch(setData(response.data));
          }
          return response;
        });
      },
    };
  };
};

export const logout = () => {
  return (dispatch) => {
    return {
      type: POST_ADMIN_LOGOUT,
      payload: () => {
        return Api.delete(EndPoints.admin("auth"));
      },
    };
  };
};

export const setData = (data) => {
  return {
    type: SET_ADMIN_DATA,
    data: data,
  };
};
