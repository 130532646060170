import React, { PureComponent } from "react";
import CKEditor from "react-ckeditor-component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Api from "../../utils/api";
import Logger from "../../utils/logger";

class EditorInput extends PureComponent {
  onClick = () => {
    Logger.event(this.props.ga);
  };

  onChange = (evt) => {
    var newContent = evt.editor.getData();
    this.props.onChange && this.props.onChange(newContent);
    this.props.input &&
      this.props.input.onChange &&
      this.props.input.onChange(newContent);
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;
    return (
      <div>
        <label className="uk-form-label uk-text-nowrap">
          {this.props.label}
          <wbr />
          {(() => {
            if (this.props.required) {
              return <small className="uk-text-danger"> ※require</small>;
            }
          })()}
        </label>
        <div className="uk-form-controls">
          <CKEditor
            ref={(editor) => (this.editor = editor)}
            activeClass="uk-editor"
            content={this.props.value}
            events={{
              change: this.onChange,
              fileUploadRequest: this.onfileUpload,
            }}
            scriptUrl={process.env.REACT_APP_URL + "/lib/ckeditor/ckeditor.js"}
            config={{
              height: 700,
              language: this.props.language.view,
              extraPlugins:
                "font,simage,colorbutton,justify,youtube,fakeobjects,dialogui,dialog,iframe",
              allowedContent: true,
              format_tags: "p;h2;h3;h4;h5;h6",
              toolbar: [
                { name: "styles", items: ["Format", "FontSize"] },
                { name: "colors", items: ["TextColor", "BGColor"] },
                { name: "clipboard", items: ["Undo", "Redo"] },
                {
                  name: "basicstyles",
                  items: [
                    "Bold",
                    "Italic",
                    "Underline",
                    "Strike",
                    "Subscript",
                    "Superscript",
                    "-",
                    "CopyFormatting",
                    "RemoveFormat",
                  ],
                },
                {
                  name: "paragraph",
                  items: [
                    "NumberedList",
                    "BulletedList",
                    "-",
                    "Blockquote",
                    "CreateDiv",
                    "-",
                    "JustifyLeft",
                    "JustifyCenter",
                    "JustifyRight",
                    "JustifyBlock",
                  ],
                },
                { name: "links", items: ["Link", "Unlink"] },
                {
                  name: "insert",
                  items: ["SImage", "HorizontalRule", "Youtube", "Iframe"],
                },
              ],
              imageUploadURL: this.props.uploadUrl,
              imageUploadHeader: Api.getAuthHeader().headers,
              dataParser: function (data) {
                return (
                  process.env.REACT_APP_STORAGE_PREFIX + "/" + data.data.url
                );
              },
            }}
          />
        </div>
        <div className="uk-text-danger " style={{ minHeight: "18px" }}>
          <small>{hasError && this.props.meta.error}</small>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorInput);
