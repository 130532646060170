import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { register } from "../../../actions/collector/auth";
import logo from "../../../assets/images/logo/black.png";
import RegisterForm from "../../../components/forms/RegisterForm";
import Meta from "../../../components/layout/Meta";
import { Link } from "../../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../../components/util/Text";
import { Path, PathGenerator } from "../../../constants/path";
import Logger from "../../../utils/logger";

class RegisterScreen extends PureComponent {
  onSubmit = (values) => {
    return this.props.actions.register(values);
  };

  onSuccess = (data) => {
    Logger.event({
      category: "register",
      action: "register",
      label: window.location.href,
    });
    this.props.history.push(
      PathGenerator.getLocalPath(Path.collector.auth.thanks.url),
    );
  };

  render = () => {
    return (
      <div>
        <Meta title={getPureMessage("collector.auth.register.header")} />
        <div className="uk-text-center">
          <img src={logo} className="uk-width-medium" />
        </div>
        <h1 className="uk-text-center">
          <IntlText id="collector.auth.register.header" />
        </h1>
        <div className="uk-margin-large uk-text-center">
          <div className="uk-width-large@s uk-text-left uk-display-inline-block">
            <div className="uk-margin-medium">
              <RegisterForm
                onSubmit={this.onSubmit}
                onSuccess={this.onSuccess}
              />
            </div>
            <hr />
            <div className="uk-margin-medium">
              <p className="uk-margin-small uk-text-center">
                <IntlText id="collector.auth.register.have" />
              </p>
              <div className="uk-margin-small uk-text-center">
                <Link
                  className="uk-button-default uk-button"
                  to={Path.collector.auth.login.url}
                  ga={{
                    category: "button",
                    action: "collector_auth_register",
                    label: "back",
                  }}
                >
                  <IntlText id="collector.auth.register.back" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      register: (data) => dispatch(register(data)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterScreen),
);
