import store from "../stores";

const API_ENDPOINT = process.env.REACT_APP_SERVER_URL + "/api/v1";

export class EndPoints {
  static root() {
    var base =
      API_ENDPOINT +
      "/" +
      (store.getState().language ? store.getState().language.edit : "en");
    return base;
  }

  static categories() {
    var base = EndPoints.root() + "/categories";
    return base;
  }

  static places() {
    var base = EndPoints.root() + "/places";
    return base;
  }

  static contacts() {
    var base = EndPoints.root() + "/contacts";
    return base;
  }

  static photos() {
    var base = EndPoints.root() + "/photos";
    return base;
  }

  static likes() {
    var base = EndPoints.root() + "/likes";
    return base;
  }

  static gallery(mode = null) {
    var base = EndPoints.root() + "/gallery";
    if (mode != null) {
      base += "/" + mode;
    }
    return base;
  }

  static galleryArtists(artistId = null, suffix = null) {
    var base = EndPoints.gallery("artists");
    if (artistId != null) {
      base += "/" + artistId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static galleryArtworks(artworkId = null, suffix = null) {
    var base = EndPoints.gallery("artworks");
    if (artworkId != null) {
      base += "/" + artworkId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static galleryArtworkImages(artworkId, artworkImageId = null) {
    var base = EndPoints.galleryArtworks(artworkId, "images");
    if (artworkImageId != null) {
      base += "/" + artworkImageId;
    }
    return base;
  }

  static galleryShows(showId = null, suffix = null) {
    var base = EndPoints.gallery("shows");
    if (showId != null) {
      base += "/" + showId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static showPhotos(showId, photoId = null, suffix = null) {
    var base = EndPoints.galleryShows(showId, "photos");
    if (photoId != null) {
      base += "/" + photoId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static galleryShowImages(showId, showImageId = null) {
    var base = EndPoints.galleryShows(showId, "images");
    if (showImageId != null) {
      base += "/" + showImageId;
    }
    return base;
  }

  static galleryInformations(informationId = null, suffix = null) {
    var base = EndPoints.gallery("informations");
    if (informationId != null) {
      base += "/" + informationId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static galleryArticles(articleId = null, suffix = null) {
    var base = EndPoints.gallery("articles");
    if (articleId != null) {
      base += "/" + articleId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static galleryLocations(locationId = null) {
    var base = EndPoints.gallery("locations");
    if (locationId != null) {
      base += "/" + locationId;
    }
    return base;
  }

  static galleryImages(suffix = null) {
    var base = EndPoints.gallery("images");
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static galleryAttends(attendId = null, suffix = null) {
    var base = EndPoints.gallery("attends");
    if (attendId != null) {
      base += "/" + attendId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static galleryExhibitions(attendId, exhibitionId = null) {
    var base = EndPoints.galleryAttends(attendId, "exhibitions");
    if (exhibitionId != null) {
      base += "/" + exhibitionId;
    }
    return base;
  }

  static galleryPurchases(purchaseId = null) {
    var base = EndPoints.gallery("purchases");
    if (purchaseId != null) {
      base += "/" + purchaseId;
    }
    return base;
  }

  static fair(suffix = null) {
    var base = EndPoints.root() + "/fair";
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static fairAttends(attendId = null) {
    var base = EndPoints.fair("attends");
    if (attendId != null) {
      base += "/" + attendId;
    }
    return base;
  }

  static fairArticles(fairId = null, suffix = null) {
    var base = EndPoints.fair("articles");
    if (fairId != null) {
      base += "/" + fairId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static fairImages(suffix = null) {
    var base = EndPoints.fair("images");
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static fairPhotos(suffix = null) {
    var base = EndPoints.fair("photos");
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static fairRelated(relatedId = null) {
    var base = EndPoints.fair("related");
    if (relatedId != null) {
      base += "/" + relatedId;
    }
    return base;
  }

  static fairSections(sectionId = null) {
    var base = EndPoints.fair("sections");
    if (sectionId != null) {
      base += "/" + sectionId;
    }
    return base;
  }

  static admin(suffix = null) {
    var base = EndPoints.root() + "/admin";
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static adminArticles(articleId = null, suffix = null) {
    var base = EndPoints.admin("articles");
    if (articleId != null) {
      base += "/" + articleId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static adminImages(suffix = null) {
    var base = EndPoints.admin("images");
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static adminNews(newsId = null, suffix = null) {
    var base = EndPoints.admin("news");
    if (newsId != null) {
      base += "/" + newsId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static adminBanners(bannerId = null, suffix = null) {
    var base = EndPoints.admin("banners");
    if (bannerId != null) {
      base += "/" + bannerId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static adminSortBanners(bannerId = null, suffix = null) {
    var base = EndPoints.admin("banners");
    if (bannerId != null) {
      base += "/" + bannerId + "/sort";
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static adminBannersPCImage(bannerId = null, suffix = null) {
    var base = EndPoints.admin("banners/pc_thumnail");
    if (bannerId != null) {
      base += "/" + bannerId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static adminBannersSPImage(bannerId = null, suffix = null) {
    var base = EndPoints.admin("banners/sp_thumnail");
    if (bannerId != null) {
      base += "/" + bannerId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static medium(suffix = null) {
    var base = EndPoints.root() + "/medium";
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static mediumArticles(articleId = null, suffix = null) {
    var base = EndPoints.medium("articles");
    if (articleId != null) {
      base += "/" + articleId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static mediumImages(suffix = null) {
    var base = EndPoints.medium("images");
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static informations(informationId = null) {
    var base = EndPoints.root() + "/informations";
    if (informationId != null) {
      base += "/" + informationId;
    }
    return base;
  }

  static galleries(galleryId = null) {
    var base = EndPoints.root() + "/galleries";
    if (galleryId != null) {
      base += "/" + galleryId;
    }
    return base;
  }

  static artists(artistId = null, suffix = null) {
    var base = EndPoints.root() + "/artists";
    if (artistId != null) {
      base += "/" + artistId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static artworks(artworkId = null, suffix = null) {
    var base = EndPoints.root() + "/artworks";
    if (artworkId != null) {
      base += "/" + artworkId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static articles(articleId = null) {
    var base = EndPoints.root() + "/articles";
    if (articleId != null) {
      base += "/" + articleId;
    }
    return base;
  }

  static shows(showId = null) {
    var base = EndPoints.root() + "/shows";
    if (showId != null) {
      base += "/" + showId;
    }
    return base;
  }

  static fairs(fairId = null) {
    var base = EndPoints.root() + "/fairs";
    if (fairId != null) {
      base += "/" + fairId;
    }
    return base;
  }

  static news(newsId = null) {
    var base = EndPoints.root() + "/news";
    if (newsId != null) {
      base += "/" + newsId;
    }
    return base;
  }

  static informations(informationId = null) {
    var base = EndPoints.root() + "/informations";
    if (informationId != null) {
      base += "/" + informationId;
    }
    return base;
  }

  static collector(mode = null) {
    var base = EndPoints.root() + "/collector";
    if (mode != null) {
      base += "/" + mode;
    }
    return base;
  }

  static collectorPurchase(artworkId = null, suffix = null) {
    var base = EndPoints.collector("purchases");
    if (artworkId != null) {
      base += "/" + artworkId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static banners(bannerId = null, suffix = null) {
    var base = API_ENDPOINT + "/" + store.getState().language.view + "/banners";
    if (bannerId != null) {
      base += "/" + bannerId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }

  static getbanners(bannerId = null, suffix = null) {
    var base = EndPoints.root() + "/allbanners";
    if (bannerId != null) {
      base += "/" + bannerId;
    }
    if (suffix != null) {
      base += "/" + suffix;
    }
    return base;
  }
}
