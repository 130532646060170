import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Formatter } from "../../utils/formatter";
import { Button } from "../util/Clickable";
import { FirstImage } from "../util/Image";
import { IntlText } from "../util/Text";

class ArtworkPart extends Component {
  render = () => {
    const artwork = this.props.artwork;
    const lang = this.props.lang ? this.props.lang : this.props.language.view;

    const data = Formatter.mergeDesc(artwork, artwork.artwork_descs, lang);

    return (
      <div className="uk-grid-small uk-flex-middle" data-uk-grid>
        <div className="uk-width-small uk-image-wrapper">
          <FirstImage
            srcs={artwork.artwork_images}
            finder={(item) => item.url}
          />
        </div>
        <div className="uk-width-expand">
          <h4 className="uk-margin-remove">{data.title}</h4>
          <p className="uk-margin-remove">
            {data.artists
              .map((artist) => {
                const desc = artist.artist_descs.find((desc) => {
                  return desc.lang == this.props.language.edit;
                });
                return desc ? desc.name : "";
              })
              .join(" / ")}
          </p>
        </div>
        {this.props.editing && (
          <div className="uk-width-1-1 uk-text-right">
            <Button
              className="uk-button uk-button-default uk-button-small"
              onClick={() => {
                this.props.onClick && this.props.onClick(artwork);
              }}
            >
              <IntlText id="forms.add" />
            </Button>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtworkPart),
);
