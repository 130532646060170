import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setViewLang } from "../actions/language";
import { Path, PathGenerator } from "../constants/path";
import { Location } from "../utils/location";

class RedirectScreen extends Component {
  componentWillMount = () => {
    this.props.actions.setViewLang(Location.getCandidateLanguage());
    this.props.history.push(
      PathGenerator.getRealUrl(Path.root.url, {
        lang: Location.getCandidateLanguage(),
      }),
    );
  };

  render = () => {
    return null;
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setViewLang: (lang) => dispatch(setViewLang(lang)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RedirectScreen),
);
