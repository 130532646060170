import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search } from "../../actions/artwork";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { FirstImage } from "../util/Image";
import { Loading } from "../util/Loading";
import { IntlText } from "../util/Text";

class Artist extends Component {
  state = {
    artworks: null,
  };

  componentWillMount = () => {
    this.props.actions
      .search({
        artist_id: this.props.artist.id,
        pagesize: 5,
        sort: "updated_at",
        order: "DESC",
      })
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            artworks: value.data,
          });
        }
      });
  };

  renderArtworks = (artist) => {
    if (this.state.artworks.length == 0) {
      return (
        <h4>
          <IntlText id="lists.artworks.no" />
        </h4>
      );
    }
    return (
      <div
        className="uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-auto uk-grid-small"
        data-uk-grid
      >
        {this.state.artworks.map((artwork, index) => {
          var className = "";
          if (index > 3) {
            className = "uk-visible@m";
          } else if (index > 1) {
            className = "uk-visible@s";
          }
          return (
            <div
              key={index}
              className={"uk-text-center uk-text-left@s " + className}
            >
              <div className="uk-thumnail uk-image-wrapper">
                <FirstImage
                  srcs={artwork.artwork_images}
                  finder={(image) => image.url}
                  alt={artist.name}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render = () => {
    var artist = Formatter.mergeDesc(
      this.props.artist,
      this.props.artist.artist_descs,
      this.props.language.view,
    );
    const url = PathGenerator.getLocalPath(Path.artists.view.url, {
      artistId: artist.id,
    });
    return (
      <Link
        className="uk-display-block uk-link-reset"
        to={url}
        ga={{ category: "link", action: "results_list_artist", label: url }}
      >
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width-1-1">
            <span className="uk-label uk-margin-small-right uk-text-bold uk-text-mincho">
              ARTIST
            </span>
            <h3 className="uk-display-inline">{artist.name}</h3>
          </div>
          <div className="uk-width-1-1">
            {this.state.artworks ? this.renderArtworks(artist) : <Loading />}
          </div>
        </div>
      </Link>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
    },
    dispatch,
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Artist));
