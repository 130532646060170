const messages = {
  login: {
    signin: "Sign In",
  },
  attr: {
    required: "required",
    email: "Email",
    email_again: "Email again",
    contact_email: "Contact email",
    contact_email_again: "Contact email again",
    password: "Password",
    password_again: "Confirm password",
    new_password: "New password",
    new_password_again: "New password again",
    tel: "TEL",
    hp: "Homepage URL",
    user_lang: "User Language",
    publish_status: "Publish Status",
    expired_month: "Expired month",
    expired_year: "Expired year",
    card_number: "Card Number",
    cvc: "CVC",
    post_code: "ZIP code",
    address: "Address",
    place: "Place Keyword",
    gallery_message: "Message to colletor",
    section_id: "Section",
    title: "Title",
    posted_at: "Post Date",
    editor_name: "Editor Name",
    summary: "Summary",
    start_at: "Start from",
    end_at: "End at",
    schedule: "Schedule",
    height: "Height",
    width: "Width",
    depth: "Depth",
    size_unit: "Size Unit",
    creation_year: "Created Year",
    category_id: "Classification",
    classification: "Classification",
    medium: "Medium",
    color: "Color",
    price: "Price",
    price_unit: "Price Unit",
    sales_status: "Sales Status",
    birth_place: "Place of Birth",
    activity_area: "Activity Area",
    birth_year: "Year of Birth",
    death_year: "Year of Death",
    primary_lang: "Primary Language",
    fair_only_status: "Sub Status",

    exhibition_status: {
      all: "All",
      exhibited: "Exhibited",
    },
    gallery: {
      name: "Name",
      description: "About this gallery",
    },
    show: {
      title: "Show Title",
      description: "About this show",
    },
    bank: {
      number: "Bank Number",
      name: "Bank Name",
      branch: "Bank Branch",
      holder: "Bank Holder Name",
    },
    location: {
      name: "Location name",
      open_year: "Open Year",
    },
    information: {
      basic: "Basic Information",
      description: "Description",
      related: "Related",
    },
    artwork: {
      basic: "Basic Info",
      sale: "Sale Info",
      title: "Title",
      description: "About this artwork",
      history: "Award and History",
    },
    artist: {
      name: "Artist Name",
      history: "History of this artist",
      biography: "Biography",
      represents: "This artist represents your gallery.",
    },
    section: {
      label: "Section Name",
    },
    fair: {
      basic: "Basic Info",
      reception: "Reception Info",
      committee_title: "Executive Committee",
      partner: "Partner Info",
      title: "Fair Title",
      description: "About this fair",
      schedule: "Fair Schedule",
      ticket: "Ticket Information",
      ticket_url: "Ticket Site URL",
      reception_schedule: "Reception Schedule",
      reception_address: "Reception Address",
      reception_fee: "Reception Fee",
      organizer: "Organizer",
      chief_committee: "Chief Committee",
      committee: "committee",
      supporter: "Supporter",
      sponsorship: "Sponsorship",
      cooperator: "Cooperator",
    },
  },
  validaton: {
    required: "Required",
    email: "Incorrect email",
    num: "Only number",
    password: "Password is more than 6 words",
    date: "Format to YYYY-MM-DD",
    minNumber: "Too small number",
    maxNumber: "Too large number",
    decimal: "Use half-size",
    tel: "Format to number only",
    zip: "Format to 000-0000",
    url: "Incorrect url",
    zero: "Use only 0",
    isConfirmed: "Not same value",
    isConfirmedEmail: "Not same email",
    isConfirmedPassword: "Not same password",
  },
  submit: "Submit",
  add: "Add",
  edit: "Edit",
  delete: "delete",
  more: "MORE",
  reject: "Reject",
  confirm: "Confirm",
  loading: "Loading ...",
};

export default messages;
