import qs from "qs";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { reset } from "../../../actions/fair/auth";
import { showAlert } from "../../../actions/message";
import logo from "../../../assets/images/logo/black.png";
import ResetForm from "../../../components/forms/ResetForm";
import { Link } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import { Path, PathGenerator } from "../../../constants/path";

class ResetScreen extends PureComponent {
  state = {
    reset_token: "",
    email: "",
  };

  componentWillMount = () => {
    const param = qs.parse(this.props.location.search.replace("?", ""));
    this.setState({
      reset_token: param.reset_token,
      email: param.email,
    });
  };

  submitLogin = (values) => {
    return this.props.actions.reset(values);
  };

  onSuccess = (data) => {
    this.props.actions.showAlert(
      <IntlText id="fair.auth.reset.completed" />,
      <IntlText id="fair.auth.reset.please" />,
      (confirmed) => {
        if (!confirmed) {
          return;
        }

        this.props.history.push(
          PathGenerator.getLocalPath(Path.fair.auth.login.url),
        );
      },
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-text-center">
          <img src={logo} className="uk-width-medium" />
        </div>
        <h1 className="uk-text-center">
          <IntlText id="fair.auth.reset.header" />
        </h1>
        <div className="uk-margin-large uk-text-center">
          <div className="uk-width-large@s uk-text-left uk-display-inline-block">
            <div className="uk-margin-medium">
              <ResetForm
                initialValues={this.state}
                onSubmit={this.submitLogin}
                onSuccess={this.onSuccess}
              />
            </div>
            <div className="uk-margin-medium uk-text-center">
              <Link
                className="uk-button-default uk-button"
                to={Path.fair.auth.login.url}
              >
                <IntlText id="fair.auth.reset.back" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      reset: (data) => dispatch(reset(data)),
      showAlert: (...param) => dispatch(showAlert(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetScreen),
);
