import {
  GET_FAIR_DATA,
  UPDATE_FAIR,
  UPDATE_FAIR_AUTH,
  DELETE_FAIR_IMAGE,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";
import { setData } from "./auth";

export const getData = () => {
  return (dispatch) => {
    return {
      type: GET_FAIR_DATA,
      payload: () => {
        return Api.get(EndPoints.fair()).then((response) => {
          if (response.status < 300) {
            dispatch(setData(response.data));
          }
          return response;
        });
      },
    };
  };
};

export const edit = (data) => {
  return (dispatch) => {
    return {
      type: UPDATE_FAIR,
      payload: () => {
        return Api.put(EndPoints.fair(), data);
      },
    };
  };
};

export const editAuth = (data) => {
  return (dispatch) => {
    return {
      type: UPDATE_FAIR_AUTH,
      payload: () => {
        return Api.put(EndPoints.fair("auth"), data);
      },
    };
  };
};

export const deleteImage = (param) => {
  return (dispatch) => {
    return {
      type: DELETE_FAIR_IMAGE,
      payload: () => {
        return Api.delete(EndPoints.fair("image"), param);
      },
    };
  };
};
