import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setCondition } from "../../actions/cache";
import { search } from "../../actions/fair";
import Meta from "../../components/layout/Meta";
import { Button } from "../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../components/util/Text";
import Fair from "../../components/views/Fair";
import JamboFair from "../../components/views/JamboFair";

class IndexScreen extends Component {
  state = {
    currents: null,
    upcomings: null,
    pasts: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.props.actions
        .search({
          timing: "current",
        })
        .then(({ value, action }) => {
          if (value.status < 300) {
            this.setState({
              currents: value.data,
            });
            this.forceUpdate();
          }
        })
        .catch((error) => console.log(error));

      this.props.actions
        .search({
          timing: "upcoming",
          all: true,
          no_artwork: true,
        })
        .then(({ value, action }) => {
          if (value.status < 300) {
            this.setState({
              upcomings: value.data,
            });
            this.forceUpdate();
          }
        })
        .catch((error) => console.log(error));

      this.readMore(true);
    }
  };

  componentDidUpdate = () => {
    if (
      this.state.currents != null &&
      this.state.pasts != null &&
      this.state.upcomings != null
    ) {
      this.props.actions.setCondition(this.state);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.state.loading != nextState.loading;
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        page: this.state.meta.current_page + 1,
        timing: "past",
        no_artwork: true,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            pasts:
              this.state.pasts && !refresh
                ? this.state.pasts.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div className="uk-section">
        <div className="uk-container">
          <Meta
            title={getPureMessage("fairs.title")}
            description={getPureMessage("fairs.description")}
          />

          {this.state.currents && this.state.currents.length > 0 && (
            <div className="uk-margin-xlarge-bottom">
              <h1 className="uk-heading-mark uk-margin-remove">
                <IntlText id="fairs.current" />
              </h1>
              <div className="uk-margin-medium">
                {(() => {
                  if (this.state.currents && this.state.currents.length > 0) {
                    return this.state.currents.map((fair, index) => {
                      return (
                        <div className="uk-margin" key={index}>
                          <JamboFair fair={fair} large={true} />
                        </div>
                      );
                    });
                  } else if (
                    this.state.currents &&
                    this.state.currents.length == 0
                  ) {
                    return (
                      <span className="uk-h3">
                        <IntlText id="lists.fairs.no" />
                      </span>
                    );
                  }
                })()}
              </div>
            </div>
          )}

          {this.state.upcomings && this.state.upcomings.length > 0 && (
            <div className="uk-margin-xlarge-bottom">
              <h2 className="uk-heading-mark uk-margin">
                <IntlText id="fairs.upcoming" />
              </h2>
              <div className="uk-margin">
                {this.state.upcomings &&
                  this.state.upcomings.map((fair, index) => {
                    return (
                      <div className="uk-margin-ssmall" key={index}>
                        <Fair fair={fair} arrow />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          <div className="uk-margin-xlarge">
            <h2 className="uk-heading-mark uk-margin">
              <IntlText id="fairs.past" />
            </h2>
            <div className="uk-margin">
              {this.state.pasts &&
                this.state.pasts.map((fair, index) => {
                  return (
                    <div className="uk-separator-bottom" key={index}>
                      <Fair fair={fair} arrow />
                    </div>
                  );
                })}
            </div>
            {this.state.meta.last_page &&
              this.state.meta.last_page > this.state.meta.current_page && (
                <div className="uk-margin-medium uk-text-center">
                  <Button
                    className="uk-button uk-button-default"
                    onClick={() => this.readMore(false)}
                    loading={this.state.loading ? 1 : 0}
                    ga={{ category: "button", action: "fairs", label: "more" }}
                  >
                    <IntlText id="forms.more" />
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
