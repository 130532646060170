import { SEARCH_SHOWS, GET_SHOW } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";

export const search = (param = {}) => {
  return (dispatch) => {
    return {
      type: SEARCH_SHOWS,
      payload: () => {
        return Api.get(EndPoints.shows(), param);
      },
    };
  };
};

export const get = (showId) => {
  return (dispatch) => {
    return {
      type: GET_SHOW,
      payload: () => {
        return Api.get(EndPoints.shows(showId));
      },
    };
  };
};
