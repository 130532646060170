import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { login } from "../../../actions/admin/auth";
import logo from "../../../assets/images/logo/black.png";
import LoginForm from "../../../components/forms/LoginForm";
import { Path, PathGenerator } from "../../../constants/path";

class LoginScreen extends PureComponent {
  submitLogin = (values) => {
    return this.props.actions.login(values);
  };

  onSuccess = (data) => {
    this.props.history.push(PathGenerator.getLocalPath(Path.admin.top.url));
  };

  render = () => {
    return (
      <div>
        <div className="uk-text-center">
          <img src={logo} className="uk-width-medium" />
        </div>
        <h1 className="uk-text-center">Admin's Login</h1>
        <div className="uk-margin-large uk-text-center">
          <div className="uk-width-large@s uk-text-left uk-display-inline-block">
            <div className="uk-margin-medium">
              <LoginForm
                onSubmit={this.submitLogin}
                onSuccess={this.onSuccess}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    constant: state.constant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      login: (data) => dispatch(login(data)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreen),
);
