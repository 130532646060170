import { GET_RELATES, ADD_RELATE, DELETE_RELATE } from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const getAll = () => {
  return (dispatch) => {
    return {
      type: GET_RELATES,
      payload: () => {
        return Api.get(EndPoints.fairRelated());
      },
    };
  };
};

export const add = (param) => {
  return (dispatch) => {
    return {
      type: ADD_RELATE,
      payload: () => {
        return Api.post(EndPoints.fairRelated(), param);
      },
    };
  };
};

export const deleteRelate = (fairId) => {
  return (dispatch) => {
    return {
      type: DELETE_RELATE,
      payload: () => {
        return Api.delete(EndPoints.fairRelated(fairId));
      },
    };
  };
};
