import {
  GET_COLLECTOR_DATA,
  UPDATE_COLLECTOR,
  UPDATE_COLLECTOR_AUTH,
  DELETE_COLLECTOR_IMAGE,
  GET_COLLECTOR_LIKES,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";
import { setData } from "./auth";
import { setLikes } from "../like";

export const getData = () => {
  return (dispatch) => {
    return {
      type: GET_COLLECTOR_DATA,
      payload: () => {
        return Api.get(EndPoints.collector());
      },
    };
  };
};

export const getLikes = () => {
  return (dispatch) => {
    return {
      type: GET_COLLECTOR_LIKES,
      payload: () => {
        return Api.get(EndPoints.collector("likes")).then((response) => {
          if (response.status < 300) {
            dispatch(setLikes(response.data));
          }
          return response;
        });
      },
    };
  };
};

export const edit = (data) => {
  return (dispatch) => {
    return {
      type: UPDATE_COLLECTOR,
      payload: () => {
        return Api.put(EndPoints.collector(), data).then((response) => {
          if (response.status < 300) {
            dispatch(setData(response.data));
          }
          return response;
        });
      },
    };
  };
};

export const editAuth = (data) => {
  return (dispatch) => {
    return {
      type: UPDATE_COLLECTOR_AUTH,
      payload: () => {
        return Api.put(EndPoints.collector("auth"), data);
      },
    };
  };
};

export const deleteImage = (param) => {
  return (dispatch) => {
    return {
      type: DELETE_COLLECTOR_IMAGE,
      payload: () => {
        return Api.delete(EndPoints.collector("image"), param);
      },
    };
  };
};
