import {
  POST_FAIR_LOGIN,
  POST_FAIR_LOGOUT,
  SET_FAIR_DATA,
  FORGET_FAIR_PASSWORD,
  RESET_FAIR_PASSWORD,
} from "../../constants/type";
import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const login = (data) => {
  return (dispatch) => {
    return {
      type: POST_FAIR_LOGIN,
      payload: () => {
        return Api.post(EndPoints.fair("auth"), data).then((response) => {
          if (response.status < 300) {
            dispatch(setData(response.data));
          }
          return response;
        });
      },
    };
  };
};

export const logout = () => {
  return (dispatch) => {
    return {
      type: POST_FAIR_LOGOUT,
      payload: () => {
        return Api.delete(EndPoints.fair("auth"));
      },
    };
  };
};

export const forget = (data) => {
  return (dispatch) => {
    return {
      type: FORGET_FAIR_PASSWORD,
      payload: () => {
        return Api.post(EndPoints.fair("reset"), data);
      },
    };
  };
};

export const reset = (data) => {
  return (dispatch) => {
    return {
      type: RESET_FAIR_PASSWORD,
      payload: () => {
        return Api.put(EndPoints.fair("reset"), data);
      },
    };
  };
};

export const setData = (data) => {
  return {
    type: SET_FAIR_DATA,
    data: data,
  };
};
