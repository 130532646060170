import React from "react";
import { ExternalPath } from "../../constants/path";
import UA, { Android, iOS } from "../../utils/ua";
import { Link } from "./Clickable";
import { IntlHTML } from "./Text";

export const Twitter = (props) => {
  var href = "https://twitter.com/intent/tweet?url=";
  if (props.url) {
    href += encodeURI(props.url);
  } else {
    href += encodeURI(window.location.href);
  }
  href += "&text=" + encodeURI(props.title);
  return (
    <Link
      data-uk-icon="icon: twitter; ratio:1.5;"
      {...props}
      target="_blank"
      href={href}
      ga={{ category: "share", action: "twitter", label: window.location.href }}
    />
  );
};

export const Facebook = (props) => {
  var href = "https://www.facebook.com/sharer/sharer.php?u=";
  if (props.url) {
    href += encodeURI(props.url);
  } else {
    href += encodeURI(window.location.href);
  }
  return (
    <Link
      data-uk-icon="icon: facebook; ratio:1.5;"
      {...props}
      target="_blank"
      href={href}
      ga={{
        category: "share",
        action: "facebook",
        label: window.location.href,
      }}
    />
  );
};

export const Pinterest = (props) => {
  var href = "http://pinterest.com/pin/create/button/?url=";
  if (props.url) {
    href += encodeURI(props.url);
  } else {
    href += encodeURI(window.location.href);
  }
  href += "&media=" + encodeURI(props.media);
  href += "&description=" + encodeURI(props.title);

  return (
    <Link
      data-uk-icon="icon: pinterest; ratio:1.5;"
      {...props}
      target="_blank"
      href={href}
      ga={{
        category: "share",
        action: "pinterest",
        label: window.location.href,
      }}
    />
  );
};

export const FollowMe = (props) => {
  var href = "";
  if (UA.os() == iOS) {
    href = "fb://page/?id=1430895803679706";
  } else if (UA.os() == Android) {
    href = "fb://page/1430895803679706";
  } else {
    href = "https://facebook.com/1430895803679706";
  }
  return (
    <div className="uk-margin-xlarge-top">
      <div className="uk-margin-ssmall uk-text-center">
        <small className="uk-text-bold">
          <IntlHTML id="util.sns.lead" />
        </small>
      </div>
      <div className="uk-margin-ssmall uk-text-center">
        <Link
          target="_blank"
          href={href}
          className="uk-link-muted uk-margin-small-left uk-margin-small-right uk-display-inline-block"
          ga={{
            category: "link",
            action: "facebook",
            label: window.location.href,
          }}
        >
          <span
            className="uk-margin-ssmall-right"
            data-uk-icon="icon:facebook;ratio:1.7;"
          ></span>
        </Link>

        <Link
          target="_blank"
          href={ExternalPath.twitter}
          className="uk-link-muted uk-margin-small-left uk-margin-small-right uk-display-inline-block"
          ga={{
            category: "link",
            action: "twitter",
            label: window.location.href,
          }}
        >
          <span
            className="uk-margin-ssmall-right"
            data-uk-icon="icon:twitter;ratio:1.7;"
          ></span>
        </Link>

        <Link
          target="_blank"
          href={ExternalPath.instagram}
          className="uk-link-muted uk-margin-small-left uk-margin-small-right uk-display-inline-block"
          ga={{
            category: "link",
            action: "instagram",
            label: window.location.href,
          }}
        >
          <span
            className="uk-margin-ssmall-right"
            data-uk-icon="icon:instagram;ratio:1.7;"
          ></span>
        </Link>
      </div>
    </div>
  );
};
