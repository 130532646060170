import React, { Component } from "react";
import zero from "../../assets/images/errors/0.png";
import three from "../../assets/images/errors/3.png";
import four from "../../assets/images/errors/4.png";
import bg from "../../assets/images/errors/erroer_bg.png";
import { Link } from "../../components/util/Clickable";
import { IntlText } from "../../components/util/Text";
import { Path } from "../../constants/path";

class NotFound extends Component {
  render = () => {
    return (
      <div className="uk-text-center">
        <div className="uk-display-inline-block uk-width-xxlarge@s uk-margin-medium uk-position-relative">
          <img className="uk-width-1-1" src={bg} alt="error" />

          <div className="uk-position-cover uk-width-1-1 uk-height-1-1">
            <div data-uk-grid className="uk-child-width-1-3">
              <div>
                <img className="uk-width-1-1" src={four} alt="4" />
              </div>
              <div>
                <img
                  className="uk-width-1-1"
                  src={zero}
                  alt="0"
                  style={{ marginTop: 12 }}
                />
              </div>
              <div>
                {this.props.third == "3" ? (
                  <img className="uk-width-1-1" src={three} alt="3" />
                ) : (
                  <img className="uk-width-1-1" src={four} alt="4" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="uk-text-center uk-margin-medium">
          <h1 className="uk-heading-mark uk-text-muted uk-display-inline-block">
            <IntlText id="util.not_found.message" />
          </h1>
        </div>
        <div className="uk-text-center uk-margin-medium">
          <Link
            to={Path.root.url}
            className="uk-button uk-button-default"
            ga={{ category: "link", action: "not_found", label: "top" }}
          >
            <IntlText id="util.not_found.back" />
          </Link>
        </div>
      </div>
    );
  };
}

export default NotFound;
