import { GET_BANNER } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: GET_BANNER,
      payload: () => {
        return Api.get(EndPoints.banners(), param);
      },
    };
  };
};


export const get = (param) => {
  return (dispatch) => {
    return {
      type: GET_BANNER,
      payload: () => {
        return Api.get(EndPoints.getbanners(), param);
      },
    };
  };
};
