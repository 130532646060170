import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";

class Attend extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const attend = this.props.attend;
    const gallery = Formatter.mergeDesc(
      attend.gallery,
      attend.gallery.gallery_descs,
      lang,
    );
    return (
      <div>
        <Link
          className="uk-link-reset"
          to={PathGenerator.getLocalPath(Path.galleries.view.url, {
            galleryId: gallery.id,
          })}
        >
          <div className="uk-margin-small uk-height-medium uk-image-wrapper">
            <Image
              className=""
              src={
                attend.thumnail_url ? attend.thumnail_url : gallery.thumnail_url
              }
            />
          </div>
          <div className="uk-margin-small">
            <h3 className="uk-margin-remove ">{gallery.name}</h3>
            <p className="uk-margin-remove  uk-text-muted">{attend.section}</p>
          </div>
        </Link>
        {this.props.editing && (
          <div className="uk-margin-small uk-text-center">
            <Link
              className="uk-button uk-button-danger"
              onClick={(event) => {
                this.props.onEdit && this.props.onEdit(event);
              }}
            >
              {this.props.buttonText ? this.props.buttonText : "Edit"}
            </Link>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Attend);
