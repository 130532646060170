export const FairPath = {
  url: "/:lang/fair",
  auth: {
    url: "/:lang/fair/auth",
    register: {
      url: "/:lang/fair/auth/register",
    },
    login: {
      url: "/:lang/fair/auth/login",
    },
    forget: {
      url: "/:lang/fair/auth/forget",
    },
    reset: {
      url: "/:lang/fair/auth/reset",
    },
  },
  top: {
    url: "/:lang/fair",
  },
  edit: {
    url: "/:lang/fair/edit",
  },
  profile: {
    url: "/:lang/fair/profile",
  },
  relates: {
    url: "/:lang/fair/relates",
  },
  photos: {
    url: "/:lang/fair/photos",
  },
  sections: {
    url: "/:lang/fair/sections",
  },
  invites: {
    url: "/:lang/fair/invites",
    add: {
      url: "/:lang/fair/invites/add",
    },
  },
  articles: {
    url: "/:lang/fair/articles",
    edit: {
      url: "/:lang/fair/articles/:articleId/edit",
    },
  },
};
