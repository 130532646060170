import {
  SEARCH_ATTENDS,
  ADD_ATTENDS,
  EDIT_ATTEND,
  DELETE_ATTEND,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: SEARCH_ATTENDS,
      payload: () => {
        return Api.get(EndPoints.fairAttends(), param);
      },
    };
  };
};

export const addAll = (param) => {
  return (dispatch) => {
    return {
      type: ADD_ATTENDS,
      payload: () => {
        return Api.post(EndPoints.fairAttends(), param);
      },
    };
  };
};

export const edit = (attendId, param) => {
  return (dispatch) => {
    return {
      type: EDIT_ATTEND,
      payload: () => {
        return Api.put(EndPoints.fairAttends(attendId), param);
      },
    };
  };
};

export const deleteAttend = (attendId) => {
  return (dispatch) => {
    return {
      type: DELETE_ATTEND,
      payload: () => {
        return Api.delete(EndPoints.fairAttends(attendId));
      },
    };
  };
};
