import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search } from "../../actions/artist";
import { setCondition } from "../../actions/cache";
import Meta from "../../components/layout/Meta";
import ArtistCards from "../../components/lists/ArtistCards";
import { Button } from "../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../components/util/Text";
import JamboArtist from "../../components/views/JamboArtist";

class IndexScreen extends Component {
  state = {
    featureds: null,
    artists: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      // this.props.actions.search({
      //   treat: "represented", with_gallery: true,
      //   pagesize: 4, sort: "updated_at", order: "desc", public: true
      // }).then( ({ value, action }) => {
      //   if( value.status < 300 ){
      //     this.setState({
      //       featureds: value.data,
      //     });
      //     this.forceUpdate();
      //   }
      // }).catch( error => console.log(error) );

      this.readMore(true);
    }
  };

  componentDidUpdate = () => {
    if (this.state.featureds != null && this.state.artists != null) {
      this.props.actions.setCondition(this.state);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.state.loading != nextState.loading;
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        page: this.state.meta.current_page + 1,
        pagesize: 24,
        public: true,
        sort_method: "inquiry",
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          if (refresh) {
            this.setState({
              featureds: value.data.slice(0, 4),
              artists: value.data.slice(4, value.data.length - 4),
              meta: value.response.data.meta,
              loading: false,
            });
          } else {
            this.setState({
              artists: this.state.artists.concat(value.data),
              meta: value.response.data.meta,
              loading: false,
            });
          }
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <Meta
          title={getPureMessage("artists.meta")}
          description={getPureMessage("artists.description")}
        />
        <h1 className="uk-heading-mark uk-margin-remove">
          <IntlText id="artists.title" />
        </h1>
        <div className="uk-margin-medium" data-uk-grid>
          {this.state.featureds &&
            this.state.featureds.map((artist, index) => {
              return (
                <div key={index} className="uk-width-1-2@s">
                  <JamboArtist artist={artist} />
                </div>
              );
            })}
        </div>

        <div className="uk-margin-xlarge">
          <div className="uk-margin-medium">
            <ArtistCards artists={this.state.artists} />
          </div>
          {this.state.meta.last_page &&
            this.state.meta.last_page > this.state.meta.current_page && (
              <div className="uk-margin-medium uk-text-center">
                <Button
                  className="uk-button uk-button-default"
                  onClick={() => this.readMore(false)}
                  loading={this.state.loading ? 1 : 0}
                  ga={{ category: "button", action: "artists", label: "more" }}
                >
                  <IntlText id="forms.more" />
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
