import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { IntlText } from "../util/Text";
import ShowView from "../views/Show";

class ShowCardsList extends Component {
  render = () => {
    if (this.props.shows == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.shows.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.shows.no" />
          </h3>
        </div>
      );
    }

    return (
      <div
        className={
          this.props.size
            ? "uk-child-width-1-" + this.props.size + "@s"
            : "uk-child-width-1-2@s"
        }
        data-uk-grid="masonry: true"
      >
        {this.props.shows.map((show, key) => {
          return (
            <div key={key}>
              <ShowView
                show={show}
                lang={
                  this.props.editing
                    ? this.props.language.edit
                    : this.props.language.view
                }
                editing={this.props.editing}
                small={this.props.size == 3}
              />
            </div>
          );
        })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShowCardsList),
);
