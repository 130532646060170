import React, { Component } from "react";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import Logger from "../../utils/logger";
import { getPureMessage, IntlText } from "../util/Text";

export default class CheckBoxs extends Component {
  getValue = (value) => {
    if (this.props.input.value == null) {
      return false;
    }

    if (!Array.isArray(this.props.input.value)) {
      this.props.input.value = [];
    }
    return this.props.input.value.find((v) => v.value == value);
  };

  onChange = (value) => {
    if (this.props.ga != null) {
      Logger.event(this.props.ga);
    } else if (this.props.meta) {
      Logger.event({
        category: this.props.meta.form,
        action: "checkboxs",
        label: this.props.input.name,
      });
    }
    this.props.input.onChange(value);
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;
    const label = this.props.labelId
      ? getPureMessage(this.props.labelId)
      : this.props.label;
    return (
      <div>
        {(() => {
          if (label != null) {
            return (
              <label className="uk-form-label uk-text-nowrap">
                {label}
                <wbr />
                {(() => {
                  if (this.props.required) {
                    return (
                      <small className="uk-text-danger">
                        {" "}
                        ※<IntlText id="forms.attr.required" />
                      </small>
                    );
                  }
                })()}
              </label>
            );
          }
        })()}
        <div className="uk-form-controls">
          <CheckboxGroup
            name={this.props.name}
            value={this.props.input.value}
            onChange={this.onChange}
            checkboxDepth={3}
          >
            {this.props.options.map((option, index) => {
              return (
                <div key={index} className="uk-margin-ssmall">
                  <label>
                    <Checkbox className="uk-checkbox" value={option.value} />
                    <small className="uk-text-bold">
                      {option.labelId != null
                        ? getPureMessage(option.labelId)
                        : option.label}
                    </small>
                  </label>
                </div>
              );
            })}
          </CheckboxGroup>
        </div>
        {!this.props.noError && (
          <div className="uk-text-danger " style={{ minHeight: "18px" }}>
            <small>{hasError && this.props.meta.error}</small>
          </div>
        )}
      </div>
    );
  };
}
