import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { IntlText } from "../util/Text";
import Gallery from "../views/Gallery";

class GalleriesList extends Component {
  render = () => {
    if (this.props.galleries == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.galleries.length <= 0) {
      return (
        <div>
          <IntlText id="lists.galleries.no" />
        </div>
      );
    }

    return (
      <div
        data-uk-grid
        className={
          "uk-child-width-1-2@s " +
          (this.props.size
            ? "uk-child-width-1-" + this.props.size + "@m"
            : "uk-child-width-1-3@m")
        }
      >
        {this.props.galleries.map((gallery, index) => {
          return (
            <div key={index}>
              <Gallery
                gallery={gallery}
                editing={this.props.editing}
                buttonText={this.props.buttonText}
                onClick={() => {
                  this.props.onClick && this.props.onClick(gallery);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GalleriesList),
);
