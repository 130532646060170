import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { summary } from "../../../actions/gallery/purchase";
import SalesList from "../../../components/lists/Sales";
import { Button } from "../../../components/util/Clickable";
import { Loading } from "../../../components/util/Loading";
import { IntlText } from "../../../components/util/Text";

class SalesScreen extends PureComponent {
  state = {
    summary: null,
    past: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    this.props.actions
      .summary({
        from: moment().startOf("month").toDate(),
        summary: true,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            summary:
              value.data && value.data.length > 0
                ? value.data[0]
                : { purchase_count: 0, sales: 0 },
          });
        }
      })
      .catch((error) => console.log(error));

    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }

    this.props.actions
      .summary({
        page: this.state.meta.current_page + 1,
        summary: true,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            past:
              this.state.past && !refresh
                ? this.state.past.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="gallery.sales.title" />
        </h1>
        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark uk-margin">
            <IntlText id="gallery.sales.monthly" /> {moment().format("YYYY-MM")}
          </h2>
          <div className="uk-margin">
            {(() => {
              if (this.state.summary == null) {
                return <Loading />;
              } else {
                return (
                  <div data-uk-grid>
                    <div className="uk-width-1-3@s">
                      <div className="uk-background-muted uk-padding uk-text-center">
                        <IntlText id="gallery.sales.purchase" />
                        <br />
                        <span className="uk-h3 uk-margin-remove">
                          {this.state.summary.purchase_count}
                        </span>
                      </div>
                    </div>
                    <div className="uk-width-1-3@s">
                      <div className="uk-background-muted uk-padding uk-text-center">
                        <IntlText id="gallery.sales.sales" />
                        <br />
                        <span className="uk-h3 uk-margin-remove">
                          {Number(this.state.summary.sales).toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="uk-width-1-3@s">
                      <div className="uk-background-muted uk-padding uk-text-center">
                        <IntlText id="gallery.sales.revenue" />
                        <br />
                        <span className="uk-h3 uk-margin-remove">
                          {Number(
                            this.state.summary.sales *
                              (1 - this.props.gallery.margin_rate),
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            })()}
          </div>
        </div>

        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark uk-margin">
            <IntlText id="gallery.sales.history" />
          </h2>
          <div className="uk-margin">
            <SalesList
              sales={this.state.past}
              margin={this.props.gallery.margin_rate}
            />
          </div>
          {this.state.meta.last_page &&
            this.state.meta.last_page > this.state.meta.current_page && (
              <div className="uk-margin uk-text-center">
                <Button
                  className="uk-button uk-button-default"
                  onClick={() => this.readMore(false)}
                  loading={this.state.loading ? 1 : 0}
                >
                  <IntlText id="forms.more" />
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      summary: (...param) => dispatch(summary(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SalesScreen),
);
