import Immutable from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  deleteImage,
  deleteNews,
  edit,
  get,
} from "../../../actions/admin/news";
import { showConfirm } from "../../../actions/message";
import NewsForm from "../../../components/forms/admin/NewsForm";
import ContentPicker from "../../../components/inputs/ContentPicker";
import Thumnail from "../../../components/inputs/Thumnail";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Link } from "../../../components/util/Clickable";
import { EndPoints } from "../../../constants/endpoints";
import { Path, PathGenerator } from "../../../constants/path";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class EditScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      news: {},
      editing: {},
    };
  }

  componentWillMount = () => {
    this.props.actions
      .get(this.props.match.params.newsId)
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            news: value.data,
            editing: this.getInfo(value.data, this.props.language.edit),
            content: value.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  getInfo = (news, lang) => {
    if (news == null) {
      return {};
    }
    const desc = news.news_descs.find((desc) => {
      return desc.lang == lang;
    });
    return Object.assign({}, desc, news);
  };

  onChangeLang = (lang) => {
    const data = this.getInfo(this.state.news, lang);
    this.setState({
      editing: data,
    });
  };

  onEdited = (data) => {
    Notification.success("Update Succeeded.");
    this.setState({
      news: data,
    });
  };

  onDelete = () => {
    this.props.actions.showConfirm(
      "Do you want to really delete?",
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }
        this.props.actions
          .deleteNews(this.state.news.id)
          .then(({ value, status }) => {
            Notification.success("Delete Succeeded.");
            this.props.history.push(
              PathGenerator.getLocalPath(Path.admin.news.url),
            );
          })
          .catch((error) => console.log(error));
      },
    );
  };

  onUploaded = (data) => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["news", "thumnail_url"], (v) => data.thumnail_url)
        .updateIn(["editing", "thumnail_url"], (v) => data.thumnail_url)
        .toJS(),
    );
    Notification.success("Upload Succeeded.");
  };

  onDeleteImage = () => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["news", "thumnail_url"], (v) => null)
        .updateIn(["editing", "thumnail_url"], (v) => null)
        .toJS(),
    );
    this.props.actions
      .deleteImage(this.state.news.id)
      .then(({ value, action }) => {
        if (value.status < 300) {
          Notification.success("Delete Succeeded.");
        }
      })
      .catch((error) => console.log(error));
  };

  onRelatedSelect = (data) => {
    this.props.actions
      .edit(this.state.news.id, data)
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            news: value.data,
            editing: this.getInfo(value.data, this.props.language.edit),
          });
          Notification.success("Update Succeeded.");
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-small" data-uk-grid>
          <div className="uk-width-expand">
            <h1>Edit News</h1>
          </div>
          <div className="uk-width-medium@s uk-width-1-1 uk-text-right">
            <ChangeEditLang onChange={this.onChangeLang} />
          </div>
        </div>

        <div className="uk-margin-large">
          <h2>New Info</h2>
          <div className="" data-uk-grid>
            <div className="uk-width-expand">
              <NewsForm
                onSubmit={(values) =>
                  this.props.actions.edit(this.state.news.id, values)
                }
                onSuccess={this.onEdited}
                initialValues={this.state.editing}
              />
            </div>
            <div className="uk-width-large@s">
              <h2>Thumnail</h2>
              <div className="uk-margin">
                <Thumnail
                  url={EndPoints.adminNews(this.state.news.id, "image")}
                  src={this.state.editing.thumnail_url}
                  onSuccess={this.onUploaded}
                  onDelete={this.onDeleteImage}
                  keyExtractor={(value) => value.thumnail_url}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="uk-margin-large">
          <ContentPicker
            onSelect={this.onRelatedSelect}
            selected={this.state.content}
          />
        </div>

        <hr />
        <div className="uk-margin-large">
          <div className="uk-margin uk-text-center">
            <Link
              className="uk-button uk-button-danger"
              onClick={this.onDelete}
            >
              Delete
            </Link>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    admin: state.auth.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (...param) => dispatch(get(...param)),
      edit: (...param) => dispatch(edit(...param)),
      deleteNews: (...param) => dispatch(deleteNews(...param)),
      deleteImage: (...param) => dispatch(deleteImage(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditScreen),
);
