import React, { Component } from "react";
import Logger from "../../utils/logger";
import { getPureMessage, IntlText } from "../util/Text";

export default class CheckBox extends Component {
  onClick = () => {
    if (this.props.ga != null) {
      Logger.event(this.props.ga);
    } else if (this.props.meta) {
      Logger.event({
        category: this.props.meta.form,
        action: "checkbox",
        label: this.props.input.name,
      });
    }
  };

  render = () => {
    const label = this.props.labelId
      ? getPureMessage(this.props.labelId)
      : this.props.label;
    return (
      <div>
        <div className="uk-form-input">
          <label onClick={this.onClick}>
            <input
              className="uk-checkbox"
              type="checkbox"
              checked={this.props.input.value == 1 ? true : false}
              onChange={() =>
                this.props.input.onChange(this.props.input.value != 1 ? 1 : 0)
              }
            />
            <span>
              {label}
              {(() => {
                if (this.props.required) {
                  return (
                    <small className="uk-text-danger">
                      {" "}
                      ※<IntlText id="forms.attr.required" />
                    </small>
                  );
                }
              })()}
            </span>
          </label>
        </div>
        {this.props.touched && this.props.error && (
          <small className="uk-text-danger">{this.props.error}</small>
        )}
      </div>
    );
  };
}
