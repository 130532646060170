import {
  GET_EXHIBITIONS,
  ADD_EXHIBITION,
  EDIT_EXHIBITION,
  DELETE_EXHIBITION,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (attendId, param) => {
  return (dispatch) => {
    return {
      type: GET_EXHIBITIONS,
      payload: () => {
        return Api.get(EndPoints.galleryExhibitions(attendId), param);
      },
    };
  };
};

export const add = (attendId, param) => {
  return (dispatch) => {
    return {
      type: ADD_EXHIBITION,
      payload: () => {
        return Api.post(EndPoints.galleryExhibitions(attendId), param);
      },
    };
  };
};

export const edit = (attendId, exId, param) => {
  return (dispatch) => {
    return {
      type: EDIT_EXHIBITION,
      payload: () => {
        return Api.put(EndPoints.galleryExhibitions(attendId, exId), param);
      },
    };
  };
};

export const deleteExhibition = (attendId, exId) => {
  return (dispatch) => {
    return {
      type: DELETE_EXHIBITION,
      payload: () => {
        return Api.delete(EndPoints.galleryExhibitions(attendId, exId));
      },
    };
  };
};
