import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { logout } from "../../actions/medium/auth";
import { getData } from "../../actions/medium/index";
import { showConfirm } from "../../actions/message";
import logo from "../../assets/images/logo/black.png";
import logoMark from "../../assets/images/logo/icon.png";
import { Path, PathGenerator } from "../../constants/path";
import { Link } from "../util/Clickable";

class MediumHeader extends Component {
  componentWillMount = () => {
    this.redirectIfNotAuthed(this.props);
    if (this.props.auth.role == "medium") {
      this.props.actions
        .getData()
        .then(({ value, action }) => {
          if (value.status > 400) {
            this.doLogout();
          }
        })
        .catch((error) => console.log(error));
    }
  };

  componentWillReceiveProps = (nextProps) => {
    this.redirectIfNotAuthed(nextProps);
  };

  redirectIfNotAuthed = (props) => {
    if (props.history.location.pathname.match(/\/medium\/auth\/[\w]*/)) {
      if (props.auth.role == "medium") {
        props.history.push(PathGenerator.getLocalPath(Path.medium.top.url));
      }
    } else {
      if (props.auth.role != "medium") {
        props.history.push(
          PathGenerator.getLocalPath(Path.medium.auth.login.url),
        );
      }
    }
  };

  logout = () => {
    this.props.actions.showConfirm(
      "ログアウトしますか？",
      null,
      (confirmed) => {
        if (confirmed) {
          this.doLogout();
        }
      },
    );
  };

  doLogout = () => {
    this.props.actions
      .logout()
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.props.history.push(
            PathGenerator.getLocalPath(Path.medium.auth.login.url),
          );
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <div
          {...{
            "uk-sticky": "sel-target: > div; cls-active: uk-navbar-sticky",
          }}
        >
          <div className="uk-background-white uk-box-shadow-small">
            <header className="uk-padding@s uk-padding-small uk-padding-remove-vertical">
              <nav
                className="uk-navbar-container uk-background-white"
                {...{ "uk-navbar": "" }}
              >
                <div className="uk-navbar-left uk-position-relative">
                  <div className="uk-navbar-item uk-padding-remove">
                    <Link
                      className="uk-link-reset uk-margin-small-right"
                      to={Path.medium.top.url}
                    >
                      <img src={logo} className="uk-width-logo uk-visible@s" />
                      <img
                        src={logoMark}
                        className="uk-height-ssmall uk-hidden@s"
                      />
                    </Link>
                    <span className="uk-margin-remove uk-h4 uk-text-mincho">
                      For Medium
                    </span>
                  </div>
                </div>
                {(() => {
                  if (this.props.auth.role == "medium") {
                    return (
                      <div className="uk-navbar-right uk-visible@m">
                        <div className="uk-navbar-item">
                          <Link
                            className="uk-button uk-button-text"
                            to={Path.medium.top.url}
                          >
                            {this.props.auth.data.email}
                          </Link>
                        </div>
                        <div className="uk-navbar-item uk-padding-remove">
                          <Link
                            className="uk-button uk-button-text"
                            onClick={this.logout}
                          >
                            ログアウト
                          </Link>
                        </div>
                      </div>
                    );
                  }
                })()}
                {(() => {
                  if (this.props.auth.role == "medium") {
                    return (
                      <div className="uk-navbar-right uk-hidden@m">
                        <a
                          className="uk-navbar-toggle"
                          {...{ "uk-toggle": "target: #medium-menu" }}
                        >
                          <span {...{ "uk-navbar-toggle-icon": "" }}></span>
                        </a>
                      </div>
                    );
                  }
                })()}
              </nav>
            </header>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logout: () => dispatch(logout()),
      getData: () => dispatch(getData()),
      showConfirm: (...data) => dispatch(showConfirm(...data)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MediumHeader),
);
