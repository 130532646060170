import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setCondition } from "../../actions/cache";
import { search as searchShows } from "../../actions/show";
import Meta from "../../components/layout/Meta";
import ShowCards from "../../components/lists/ShowCards";
import { Button } from "../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../components/util/Text";

class ShowsScreen extends Component {
  state = {
    currents: null,
    upcomings: null,
    pasts: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.load();
    }
  };

  componentDidUpdate = () => {
    if (this.state.currents != null && this.state.upcomings != null) {
      this.props.actions.setCondition(this.state);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.state.loading != nextState.loading;
  };

  load = () => {
    this.props.actions
      .searchShows({
        gallery_id: this.props.gallery.id,
        timing: "current",
      })
      .then(({ value }) => {
        this.setState({
          currents: value.data,
        });
        this.forceUpdate();
      });

    this.props.actions
      .searchShows({
        gallery_id: this.props.gallery.id,
        timing: "upcoming",
      })
      .then(({ value }) => {
        this.setState({
          upcomings: value.data,
        });
        this.forceUpdate();
      });

    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .searchShows({
        gallery_id: this.props.gallery.id,
        timing: "past",
        page: this.state.meta.current_page + 1,
        pagesize: 24,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            pasts:
              this.state.pasts && !refresh
                ? this.state.pasts.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <Meta
          title={
            getPureMessage("galleries.shows.meta") + this.props.gallery.name
          }
          description={this.props.gallery.description}
          image={this.props.gallery.thumnail_url}
        />
        <h1 className="uk-heading-mark uk-margin-remove uk-h2">
          <IntlText
            id="galleries.shows.current"
            values={{ name: this.props.gallery.name }}
          />
        </h1>
        <div className="uk-margin-medium">
          <ShowCards size={3} shows={this.state.currents} />
        </div>

        <div className="uk-margin-xlarge">
          <h2 className="uk-heading-mark">
            <IntlText
              id="galleries.shows.upcoming"
              values={{ name: this.props.gallery.name }}
            />
          </h2>
          <div className="uk-margin-medium">
            <ShowCards shows={this.state.upcomings} />
          </div>
        </div>

        <div className="uk-margin-xlarge">
          <h2 className="uk-heading-mark">
            <IntlText
              id="galleries.shows.past"
              values={{ name: this.props.gallery.name }}
            />
          </h2>
          <div className="uk-margin-medium">
            <ShowCards size={3} shows={this.state.pasts} />
          </div>
          {this.state.meta.last_page &&
            this.state.meta.last_page > this.state.meta.current_page && (
              <div className="uk-margin-medium uk-text-center">
                <Button
                  className="uk-button uk-button-default"
                  onClick={() => this.readMore(false)}
                  loading={this.state.loading ? 1 : 0}
                  ga={{
                    category: "button",
                    action: "gallery_shows",
                    label: "more",
                  }}
                >
                  <IntlText id="forms.more" />
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchShows: (...param) => dispatch(searchShows(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShowsScreen),
);
