import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArticles } from "../../actions/article";
import { search as searchArtists } from "../../actions/artist";
import { setCondition } from "../../actions/cache";
import { search as searchInformations } from "../../actions/information";
import { search as searchShows } from "../../actions/show";
import Meta from "../../components/layout/Meta";
import ArticleCards from "../../components/lists/ArticleCards";
import ArtistCards from "../../components/lists/ArtistCards";
import ShowCards from "../../components/lists/ShowCards";
import { Link } from "../../components/util/Clickable";
import { Image } from "../../components/util/Image";
import { Loading } from "../../components/util/Loading";
import Slider from "../../components/util/Slider";
import { IntlText, LinedText, ViewAllHeader } from "../../components/util/Text";
import Fair from "../../components/views/Fair";
import Information from "../../components/views/Information";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";

class OverviewScreen extends Component {
  state = {
    informations: null,
    shows: null,
    artists: null,
    articles: null,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.reload();
    }
  };

  componentDidUpdate = () => {
    if (
      this.state.informations != null &&
      this.state.shows != null &&
      this.state.artists != null &&
      this.state.articles != null
    ) {
      this.props.actions.setCondition(this.state);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return false;
  };

  reload = () => {
    this.props.actions
      .searchInformations({
        gallery_id: this.props.gallery.id,
        pagesize: 3,
        sort: "posted_at",
        order: "desc",
      })
      .then(({ value }) => {
        this.setState({
          informations: value.data,
        });
        this.forceUpdate();
      });
    this.props.actions
      .searchShows({
        gallery_id: this.props.gallery.id,
        pagesize: 4,
        latest: true,
      })
      .then(({ value }) => {
        this.setState({
          shows: value.data,
        });
        this.forceUpdate();
      });
    this.props.actions
      .searchArtists({
        gallery_id: this.props.gallery.id,
        treat: "represented",
      })
      .then(({ value }) => {
        this.setState({
          artists: value.data,
        });
        this.forceUpdate();
      });
    this.props.actions
      .searchArticles({
        gallery_id: this.props.gallery.id,
        pagesize: 3,
      })
      .then(({ value }) => {
        this.setState({
          articles: value.data,
        });
        this.forceUpdate();
      });
  };

  render = () => {
    if (this.state.informations == null || this.props.gallery == null) {
      return <Loading />;
    }
    const defaultGallery = Formatter.defalutDesc(
      this.props.gallery,
      this.props.gallery.gallery_descs,
    );
    return (
      <div>
        <Meta
          title={this.props.gallery.name}
          description={this.props.gallery.description}
          image={this.props.gallery.thumnail_url}
        />
        <Slider
          small
          contents={this.state.informations.map((information, index) => {
            return <Information key={index} information={information} />;
          })}
        />

        <div className="uk-margin-xlarge">
          <h2 className="uk-heading-mark">
            <IntlText
              id="galleries.about"
              values={{ name: this.props.gallery.name }}
            />
          </h2>
          <div className="uk-margin-medium" data-uk-grid>
            <div className="uk-width-auto">
              <div className="uk-width-large@s uk-width-small uk-height-medium@s uk-height-small uk-image-wrapper">
                <Image src={this.props.gallery.thumnail_url} />
              </div>
            </div>
            <div className="uk-width-expand">
              <LinedText
                text={
                  this.props.gallery.description
                    ? this.props.gallery.description
                    : defaultGallery.description
                }
              />
            </div>
          </div>
        </div>

        {this.state.shows && this.state.shows.length > 0 && (
          <div className="uk-margin-xlarge">
            <div className="uk-margin-medium">
              <ViewAllHeader
                title={
                  <IntlText
                    id="galleries.show"
                    values={{ name: this.props.gallery.name }}
                  />
                }
                url={PathGenerator.getLocalPath(Path.galleries.view.shows.url, {
                  galleryId: this.props.gallery.id,
                })}
              />
            </div>
            <div className="uk-margin-medium">
              <ShowCards shows={this.state.shows} />
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default uk-button-plus"
                to={PathGenerator.getLocalPath(Path.galleries.view.shows.url, {
                  galleryId: this.props.gallery.id,
                })}
                ga={{ category: "link", action: "root", label: "all_shows" }}
              >
                <IntlText id="word.view_all_text" />
              </Link>
            </div>
          </div>
        )}

        {this.props.gallery.fairs && this.props.gallery.fairs.length > 0 && (
          <div className="uk-margin-xlarge">
            <h2 className="uk-heading-mark">
              <IntlText
                id="galleries.fair"
                values={{ name: this.props.gallery.name }}
              />
            </h2>
            {this.props.gallery.fairs.map((fair, index) => {
              return (
                <div key={index} className="uk-margin-medium">
                  <Fair fair={fair} />
                </div>
              );
            })}
          </div>
        )}

        {this.state.artists && this.state.artists.length > 0 && (
          <div className="uk-margin-xlarge">
            <div className="uk-margin-medium">
              <ViewAllHeader
                title={
                  <IntlText
                    id="galleries.represented_artists"
                    values={{ name: this.props.gallery.name }}
                  />
                }
                url={PathGenerator.getLocalPath(
                  Path.galleries.view.artists.url,
                  { galleryId: this.props.gallery.id },
                )}
              />
            </div>
            <div className="uk-margin-medium">
              <ArtistCards artists={this.state.artists} />
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default uk-button-plus"
                to={PathGenerator.getLocalPath(
                  Path.galleries.view.artists.url,
                  { galleryId: this.props.gallery.id },
                )}
                ga={{ category: "link", action: "root", label: "all_artists" }}
              >
                <IntlText id="word.view_all_text" />
              </Link>
            </div>
          </div>
        )}

        {this.state.articles && this.state.articles.length > 0 && (
          <div className="uk-margin-xlarge">
            <div className="uk-margin-medium">
              <ViewAllHeader
                title={
                  <IntlText
                    id="galleries.articles_about"
                    values={{ name: this.props.gallery.name }}
                  />
                }
                url={PathGenerator.getLocalPath(
                  Path.galleries.view.articles.url,
                  { galleryId: this.props.gallery.id },
                )}
              />
            </div>
            <div className="uk-margin-medium">
              <ArticleCards articles={this.state.articles} />
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default uk-button-plus"
                to={PathGenerator.getLocalPath(
                  Path.galleries.view.articles.url,
                  { galleryId: this.props.gallery.id },
                )}
                ga={{ category: "link", action: "root", label: "all_articles" }}
              >
                <IntlText id="word.view_all_text" />
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchInformations: (...param) => dispatch(searchInformations(...param)),
      searchShows: (...param) => dispatch(searchShows(...param)),
      searchArtists: (...param) => dispatch(searchArtists(...param)),
      searchArticles: (...param) => dispatch(searchArticles(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverviewScreen),
);
