import { SET_CONDITION } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";
import store from "../stores";

export const setCondition = (state) => {
  const page = window.location.href;
  return {
    type: SET_CONDITION,
    key: page,
    state: state,
  };
};

export const setCache = (key, data) => {
  return {
    type: SET_CONDITION,
    key: "data|" + key,
    state: data,
  };
};

export const getCache = (key) => {
  key = "data|" + key;
  if (!store.getState().condition || store.getState().condition[key] == null) {
    return null;
  }
  return store.getState().condition[key];
};
