import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Path } from "../../constants/path";
import NotFoundScreen from "../other/NotFoundScreen";
import IndexScreen from "./IndexScreen";
import ViewScreen from "./ViewScreen";

class BaseScreen extends PureComponent {
  render = () => {
    return (
      <div className="uk-section">
        <div className="uk-container">
          <Switch>
            <Route exact path={Path.artists.url} component={IndexScreen} />
            <Route path={Path.artists.view.url} component={ViewScreen} />
            <Route component={NotFoundScreen} />
          </Switch>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseScreen),
);
