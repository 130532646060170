import React, { PureComponent } from "react";
import { Button } from "../util/Clickable";
import { Image } from "../util/Image";
import UploadIcon from "./UploadIcon";

export default class Icon extends PureComponent {
  state = {
    src: null,
  };

  componentDidMount = () => {
    this.setState({
      src: this.props.src,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      src: nextProps.src,
    });
  };

  onSuccess = (value) => {
    const url = this.props.keyExtractor
      ? this.props.keyExtractor(value)
      : value.url;
    this.setState({
      src: url,
    });
    this.props.onSuccess && this.props.onSuccess(value);
  };

  onDelete = () => {
    this.setState({
      src: null,
    });
    this.props.onDelete && this.props.onDelete();
  };

  render = () => {
    if (this.state.src) {
      return (
        <div className="uk-position-relative uk-display-inline-block">
          <div
            className="uk-border-circle uk-visible-toggle uk-image-wrapper"
            style={{ width: 188, height: 188 }}
          >
            <Image noCover src={this.state.src} />
          </div>
          <div className="uk-position-top-right uk-position-small uk-hidden-hover">
            <Button
              className="uk-icon-button uk-button-danger"
              data-uk-icon="icon:close;"
              onClick={this.onDelete}
            ></Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="uk-display-inline-block">
          <UploadIcon {...this.props} onSuccess={this.onSuccess} />
        </div>
      );
    }
  };
}
