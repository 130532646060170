import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { IntlText } from "../util/Text";
import ArtworkPart from "../views/ArtworkPart";

class ArtworksList extends Component {
  render = () => {
    if (this.props.artworks == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.artworks.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.artworks.no" />
          </h3>
        </div>
      );
    }

    return (
      <div>
        {this.props.artworks.map((artwork, index) => {
          return (
            <div key={index} className="uk-margin-small">
              <ArtworkPart
                lang={this.props.language.edit}
                artwork={artwork}
                editing={true}
                onClick={(value) => {
                  this.props.onClick && this.props.onClick(value);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtworksList),
);
