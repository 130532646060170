import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

class Slider extends Component {
  componentDidMount = () => {};

  render = () => {
    if (this.props.contents == null || this.props.contents.length == 0) {
      return null;
    }
    return (
      <div className={this.props.small ? "uk-slider-small" : ""}>
        <div
          className="uk-position-relative uk-visible-toggle uk-light"
          data-uk-slider="center: true; autoplay: true;"
        >
          <ul className="uk-slider-items uk-grid uk-grid-collapse">
            {this.props.contents.map((content, index) => {
              return (
                <li className="uk-width-container@s uk-width-1-1" key={index}>
                  {content}
                </li>
              );
            })}
            {
              //=== 2枚以上のループ ===
              this.props.contents.map((content, index) => {
                return (
                  <li className="uk-width-container@s uk-width-1-1" key={index}>
                    {content}
                  </li>
                );
              })
              //====================
            }
          </ul>
          <a
            className="uk-slidenav-left uk-hidden-hover"
            href="#"
            {...{ "uk-slidenav-previous": "" }}
            {...{ "uk-slider-item": "previous" }}
          ></a>
          <a
            className="uk-slidenav-right uk-hidden-hover"
            href="#"
            {...{ "uk-slidenav-next": "" }}
            {...{ "uk-slider-item": "next" }}
          ></a>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Slider));
