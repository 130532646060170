import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setEditLang, setViewLang } from "../../actions/language";
import { languages } from "../../constants/option";
import { languageStr } from "../../utils/location";
import { Button, Link } from "../util/Clickable";

const regEx = new RegExp("^/(" + languageStr + ")");

class NavViewLang extends Component {
  onChangeLang = (lang) => {
    this.props.actions.setEditLang(lang.value);
    this.props.actions.setViewLang(lang.value);
  };

  render = () => {
    const currentLang = languages.find((language) => {
      return language.value == this.props.language.view;
    });
    return (
      <div className="uk-padding-remove">
        <Button
          className="uk-button uk-button-text uk-text-mincho"
          ga={{
            category: "change_lang",
            action: "button_click",
            label: "open",
          }}
        >
          ・{currentLang && currentLang.short}
        </Button>
        <div data-uk-dropdown="mode: click; offset: 32;pos: top-right;">
          <ul className="uk-nav uk-dropdown-nav">
            {languages.map((lang, key) => {
              const url =
                this.props.location.pathname.replace(regEx, "/" + lang.value) +
                this.props.location.search;
              return (
                <li key={key}>
                  <Link
                    refresh
                    to={url}
                    onClick={() => this.onChangeLang(lang)}
                    ga={{
                      category: "change_lang",
                      action: "header",
                      label: lang.label,
                    }}
                  >
                    {lang.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setViewLang: (lang) => dispatch(setViewLang(lang)),
      setEditLang: (lang) => dispatch(setEditLang(lang)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavViewLang),
);
