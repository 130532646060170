import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { LikeButton } from "../util/Like";

class JamboFair extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const fair = !this.props.fair.title
      ? Formatter.mergeDesc(this.props.fair, this.props.fair.fair_descs, lang)
      : this.props.fair;
    var url = PathGenerator.getLocalPath(Path.fairs.view.url, {
      fairId: fair.id,
    });
    if (fair.slug != null) {
      url = PathGenerator.getLocalPath(Path.original.url, {
        fairId: fair.slug,
      });
    }
    return (
      <div className="uk-position-relative">
        <Link
          className="uk-link-reset uk-display-block uk-position-relative uk-margin-small"
          to={url}
          ga={{
            category: "link",
            action: "fair_jambo",
            label: "fair_" + fair.id,
          }}
        >
          <div
            className={
              "uk-width-1-1 uk-image-wrapper uk-height-xlarge@s uk-height-medium"
            }
          >
            <Image full src={fair.thumnail_url} alt={fair.title} />
          </div>
          <div
            className="uk-position-z-index uk-position-bottom uk-padding-small@s uk-padding-ssmall uk-overlay uk-overlay-gradient uk-light uk-padding-right"
            style={{ height: this.props.large ? 120 : 60 }}
          >
            <div data-uk-grid className="uk-grid-small">
              <div className="uk-width-auto">
                <div className={"uk-image-wrapper uk-thumnail"}>
                  <Image src={fair.logo_url} alt={fair.title} />
                </div>
              </div>
              <div className="uk-width-expand">
                <h3 className={"uk-margin-ssmall uk-heading-primary"}>
                  {fair.title}
                </h3>
                <p className="uk-margin-remove uk-text-white">{fair.address}</p>
                <p className="uk-margin-remove uk-text-white">
                  {moment(fair.start_at).format("YYYY.MM.DD ddd")}
                  <span> - </span>
                  {moment(fair.end_at).format("YYYY.MM.DD ddd")}
                </p>
              </div>
            </div>
          </div>
          {!this.props.editing && (
            <div className="uk-position-xsmall uk-position-bottom-right">
              <LikeButton type="fair" data={fair} />
            </div>
          )}
        </Link>
        {this.props.editing && (
          <div className="uk-margin-small uk-text-center">
            <Link
              className="uk-button uk-button-default"
              onClick={(event) => {
                this.props.onEdit && this.props.onEdit(event);
              }}
            >
              {this.props.buttonText ? this.props.buttonText : "Edit"}
            </Link>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JamboFair);
