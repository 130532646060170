import qs from "qs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { refresh } from "../../actions/collector/auth";
import logo from "../../assets/images/logo/black.png";
import white from "../../assets/images/logo/white.svg";
import { ExternalPath, Path, PathGenerator } from "../../constants/path";
import KeywordForm from "../forms/KeywordForm";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import NavViewLang from "../util/NavViewLang";
import { IntlText } from "../util/Text";

declare var UIkit;

class Header extends Component {
  componentWillMount = () => {
    if (this.props.collector.id) {
      this.props.actions
        .refresh()
        .then(({ value, action }) => {})
        .catch((error) => console.log(error));
    }
  };

  onSearch = (values) => {
    this.closeSidebar();
    const param = qs.stringify(values);
    this.props.history.push(
      PathGenerator.getLocalPath(Path.search.url) + "?" + param,
    );
  };

  closeSidebar = () => {
    if (this.sidebar) {
      UIkit.offcanvas(this.sidebar).hide();
    }
  };

  render = () => {
    return (
      <div>
        <div
          {...{
            "uk-sticky":
              "sel-target: > div; cls-active: uk-navbar-sticky; show-on-up: true;",
          }}
        >
          <div className="uk-background-white uk-box-shadow-small">
            <header className="uk-container">
              <nav
                className="uk-navbar-container uk-grid-small uk-flex-between"
                data-uk-navbar
                data-uk-grid
              >
                <div className="uk-width-auto uk-position-relative">
                  <div className="uk-navbar-item uk-padding-remove">
                    <Link
                      className="uk-link-reset"
                      to={Path.root.url}
                      ga={{ category: "link", action: "header", label: "logo" }}
                    >
                      <img src={logo} className="uk-width-logo" />
                    </Link>
                  </div>
                </div>
                <div className="uk-width-auto uk-visible@m">
                  <div className="uk-navbar-item">
                    <Link
                      className="uk-link-reset uk-button-text uk-text-mincho"
                      to={Path.artworks.url}
                      ga={{
                        category: "link",
                        action: "hearder",
                        label: "tartworks",
                      }}
                    >
                      <IntlText id="word.artworks" />
                    </Link>
                  </div>
                </div>
                <div className="uk-width-auto uk-visible@m">
                  <div className="uk-navbar-item">
                    <Link
                      className="uk-link-reset uk-button-text uk-text-mincho"
                      to={Path.galleries.url}
                      ga={{
                        category: "link",
                        action: "header",
                        label: "galleries",
                      }}
                    >
                      <IntlText id="word.galleries" />
                    </Link>
                  </div>
                </div>
                <div className="uk-width-auto uk-visible@m">
                  <div className="uk-navbar-item">
                    <Link
                      className="uk-link-reset uk-button-text uk-text-mincho"
                      to={Path.fairs.url}
                      ga={{
                        category: "link",
                        action: "header",
                        label: "fairs",
                      }}
                    >
                      <IntlText id="word.fairs" />
                    </Link>
                  </div>
                </div>
                <div className="uk-width-auto uk-visible@m">
                  <div className="uk-navbar-item">
                    <Link
                      className="uk-link-reset uk-button-text uk-text-mincho"
                      ga={{
                        category: "show_more",
                        action: "header",
                        label: "more",
                      }}
                    >
                      <IntlText id="layout.more" />
                    </Link>
                    <div data-uk-dropdown="mode: click; offset: 32;pos: top-right;">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li>
                          <Link
                            to={Path.artists.url}
                            ga={{
                              category: "link",
                              action: "header",
                              label: "artists",
                            }}
                          >
                            <IntlText id="word.artists" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={Path.shows.url}
                            ga={{
                              category: "link",
                              action: "header",
                              label: "shows",
                            }}
                          >
                            <IntlText id="word.shows" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={Path.articles.url}
                            ga={{
                              category: "link",
                              action: "header",
                              label: "articles",
                            }}
                          >
                            <IntlText id="word.articles" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="uk-width-auto">
                  <div className="uk-display-inline-block uk-margin-small-right uk-text-middle uk-visible@m">
                    <a
                      className="uk-navbar-item uk-padding-remove"
                      {...{ "uk-search-icon": "" }}
                      href="#"
                    ></a>
                    <div
                      id="search-drop"
                      className="uk-drop uk-background-white"
                      data-uk-drop="mode: click; pos: left-center; offset: -28;delay-hide:0;"
                    >
                      <KeywordForm
                        form="keywork-top-form"
                        onSubmit={this.onSearch}
                      />
                    </div>
                  </div>
                  <div className="uk-display-inline-block uk-margin-ssmall-right uk-margin-small-right@s uk-text-middle">
                    <Link
                      className="uk-navbar-item uk-padding-remove uk-link-reset uk-position-relative"
                      ga={{
                        category: "button",
                        action: "header",
                        label: "likes",
                      }}
                      to={Path.likes.url}
                    >
                      <span data-uk-icon="heart">
                        {this.props.like.data &&
                          this.props.like.data.length > 0 &&
                          this.props.like.unchecked && (
                            <span className="uk-badge uk-button-badge">
                              {this.props.like.data.length}
                            </span>
                          )}
                      </span>
                    </Link>
                  </div>
                  <div className="uk-display-inline-block uk-margin-small-right uk-visible@m uk-text-middle">
                    {this.props.collector.id ? (
                      <Link
                        className="uk-navbar-item uk-padding-remove uk-link-reset"
                        ga={{
                          category: "button",
                          action: "header",
                          label: "user",
                        }}
                        to={Path.collector.top.url}
                      >
                        {this.props.collector.thumnail_url ? (
                          <div className="uk-image-wrapper uk-position-relative uk-icon-image-circle-small">
                            <Image
                              src={this.props.collector.thumnail_url}
                              alt={this.props.collector.nickname}
                            />
                          </div>
                        ) : (
                          <span data-uk-icon="user"></span>
                        )}
                      </Link>
                    ) : (
                      <Link
                        className="uk-navbar-item uk-padding-remove uk-link-reset"
                        ga={{
                          category: "button",
                          action: "header",
                          label: "signin",
                        }}
                        to={Path.collector.auth.login.url}
                      >
                        <span data-uk-icon="sign-in"></span>
                      </Link>
                    )}
                  </div>
                  <div className="uk-display-inline-block uk-text-middle">
                    <div className="uk-navbar-item uk-padding-remove">
                      <NavViewLang />
                    </div>
                  </div>
                  <div className="uk-display-inline-block uk-hidden@m uk-margin-small-left uk-text-middle">
                    <Link
                      className="uk-navbar-toggle uk-padding-remove"
                      {...{ "uk-toggle": "target: #user-menu" }}
                      ga={{
                        category: "button",
                        action: "header",
                        label: "mobile_side_menu",
                      }}
                    >
                      <span {...{ "uk-navbar-toggle-icon": "" }}></span>
                    </Link>
                  </div>
                </div>
              </nav>
            </header>
          </div>
        </div>
        <div
          id="user-menu"
          data-uk-offcanvas="flip: true; overlay: true"
          ref={(sidebar) => (this.sidebar = sidebar)}
        >
          <div className="uk-offcanvas-bar">
            <button
              className="uk-offcanvas-close"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-margin">
              <KeywordForm form="keywork-side-form" onSubmit={this.onSearch} />
            </div>
            <div className="uk-margin">
              <ul className="uk-list">
                <li>
                  <Link
                    className="uk-text-mincho uk-h4"
                    to={Path.artworks.url}
                    ga={{
                      category: "link",
                      action: "sidemenu",
                      label: "artworks",
                    }}
                    onClick={this.closeSidebar}
                  >
                    <IntlText id="word.artworks" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="uk-text-mincho uk-h4"
                    to={Path.galleries.url}
                    ga={{
                      category: "link",
                      action: "sidemenu",
                      label: "galleries",
                    }}
                    onClick={this.closeSidebar}
                  >
                    <IntlText id="word.galleries" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="uk-text-mincho uk-h4"
                    to={Path.fairs.url}
                    ga={{
                      category: "link",
                      action: "sidemenu",
                      label: "fairs",
                    }}
                    onClick={this.closeSidebar}
                  >
                    <IntlText id="word.fairs" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="uk-text-mincho uk-h4"
                    to={Path.shows.url}
                    ga={{
                      category: "link",
                      action: "sidemenu",
                      label: "shows",
                    }}
                    onClick={this.closeSidebar}
                  >
                    <IntlText id="word.shows" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="uk-text-mincho uk-h4"
                    to={Path.artists.url}
                    ga={{
                      category: "link",
                      action: "sidemenu",
                      label: "artists",
                    }}
                    onClick={this.closeSidebar}
                  >
                    <IntlText id="word.artists" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="uk-text-mincho uk-h4"
                    to={Path.articles.url}
                    ga={{
                      category: "link",
                      action: "sidemenu",
                      label: "articles",
                    }}
                    onClick={this.closeSidebar}
                  >
                    <IntlText id="word.articles" />
                  </Link>
                </li>
              </ul>
            </div>
            <hr />
            <div className="uk-margin">
              <ul className="uk-list">
                <li>
                  {this.props.collector.id ? (
                    <Link
                      className="uk-text-mincho uk-h4"
                      to={Path.collector.top.url}
                      ga={{
                        category: "link",
                        action: "sidemenu",
                        label: "collector",
                      }}
                      onClick={this.closeSidebar}
                    >
                      <IntlText id="collector.menu.mypage" />
                    </Link>
                  ) : (
                    <Link
                      className="uk-text-mincho uk-h4"
                      to={Path.collector.auth.login.url}
                      ga={{
                        category: "link",
                        action: "sidemenu",
                        label: "signin",
                      }}
                      onClick={this.closeSidebar}
                    >
                      <IntlText id="collector.menu.signin" />
                    </Link>
                  )}
                </li>
                <li>
                  <Link
                    className="uk-text-mincho uk-h4"
                    to={Path.likes.url}
                    ga={{
                      category: "link",
                      action: "sidemenu",
                      label: "likes",
                    }}
                    onClick={this.closeSidebar}
                  >
                    <IntlText id="collector.menu.likes" />
                  </Link>
                </li>
              </ul>
            </div>
            <hr />
            <div className="uk-margin">
              <img className="uk-width-1-1" src={white} />
            </div>
            <div
              className="uk-margin uk-grid-small uk-flex-center"
              data-uk-grid
            >
              <div className="uk-width-auto">
                <Link
                  href={ExternalPath.facebook}
                  target="_blank"
                  className="uk-button uk-button-text"
                  ga={{
                    category: "link",
                    action: "sidemenu",
                    label: "facebook",
                  }}
                  data-uk-icon="icon:facebook;ratio:1.2;"
                ></Link>
              </div>
              <div className="uk-width-auto">
                <Link
                  href={ExternalPath.twitter}
                  target="_blank"
                  className="uk-button uk-button-text"
                  ga={{
                    category: "link",
                    action: "sidemenu",
                    label: "twitter",
                  }}
                  data-uk-icon="icon:twitter;ratio:1.2;"
                ></Link>
              </div>
              <div className="uk-width-auto">
                <Link
                  href={ExternalPath.instagram}
                  target="_blank"
                  className="uk-button uk-button-text"
                  ga={{
                    category: "link",
                    action: "sidemenu",
                    label: "instagram",
                  }}
                  data-uk-icon="icon:instagram;ratio:1.2;"
                ></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    like: state.like,
    collector: state.auth.role == "collector" ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      refresh: (...param) => dispatch(refresh(...param)),
    },
    dispatch,
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
