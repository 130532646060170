import Immutable from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  deleteArticle,
  deleteImage,
  edit,
  get,
} from "../../../actions/gallery/article";
import { showConfirm } from "../../../actions/message";
import ArticleForm from "../../../components/forms/ArticleForm";
import ContentPicker from "../../../components/inputs/ContentPicker";
import EditorInput from "../../../components/inputs/EditorInput";
import Thumnail from "../../../components/inputs/Thumnail";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Link } from "../../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../../components/util/Text";
import { EndPoints } from "../../../constants/endpoints";
import { Path } from "../../../constants/path";
import { Formatter } from "../../../utils/formatter";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class EditArticleScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      article: null,
      editing: {},
      loading: false,
      description: "",
      thumnail_url: null,
      content: {},
    };
  }

  componentWillMount = () => {
    this.props.actions
      .get(this.props.match.params.articleId)
      .then(({ value, action }) => {
        if (value.status < 300) {
          const data = Formatter.mergeDesc(
            value.data,
            value.data.article_descs,
            this.props.language.edit,
          );
          this.setState({
            article: value.data,
            editing: data,
            description: data.description,
            thumnail_url: data.thumnail_url,
            content:
              value.data.publishes && value.data.publishes.length > 0
                ? value.data.publishes[0]
                : {},
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onChangeLang = (lang) => {
    const editing = Formatter.mergeDesc(
      this.state.article,
      this.state.article.article_descs,
      lang,
    );
    this.setState({
      editing: editing,
      description: editing.description,
    });
  };

  onDescChange = (description) => {
    this.setState({ description: description });
  };

  onEdit = (values) => {
    delete values.publishes;
    const param = Object.assign({}, values, {
      description: this.state.description,
    });
    return this.props.actions.edit(this.state.article.id, param);
  };

  onEdited = (data) => {
    Notification.successIntl("util.success.update");
    this.setState({
      article: data,
    });
  };

  onContentSelect = (data) => {
    this.setState({
      content: data,
    });
    this.props.actions
      .edit(this.state.article.id, { publishes: [data] })
      .then(({ value, status }) => {
        Notification.successIntl("util.success.update");
      })
      .catch((error) => console.log(error));
  };

  onDelete = () => {
    this.props.actions.showConfirm(
      getPureMessage("util.confirm.title.delete"),
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }
        this.props.actions
          .deleteArticle(this.state.article.id)
          .then(({ value, status }) => {
            Notification.successIntl("util.success.delete");
            this.props.history.push(Path.gallery.articles.url);
          })
          .catch((error) => console.log(error));
      },
    );
  };

  onUploaded = (data) => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["thumnail_url"], (v) => data.thumnail_url)
        .toJS(),
    );
    Notification.successIntl("util.success.update");
  };

  onUploadError = () => {
    Notification.errorIntl("util.error.update");
  };

  onDeleteImage = () => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["thumnail_url"], (v) => null)
        .toJS(),
    );
    this.props.actions
      .deleteImage(this.state.article.id)
      .then(({ value, action }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.delete");
        }
      })
      .catch((error) => console.log(error));
  };

  renderEditor = () => {
    return (
      <div className="uk-margin" data-uk-grid>
        <div className="uk-width-large@s">
          <h2>
            <IntlText id="gallery.article.basic" />
          </h2>
          <div className="uk-margin">
            <ArticleForm
              editing={true}
              initialValues={this.state.editing}
              onSubmit={this.onEdit}
              onSuccess={this.onEdited}
            />
          </div>
          <hr />
          <div className="uk-margin uk-text-center">
            <Link
              className="uk-button uk-button-danger"
              onClick={this.onDelete}
            >
              <IntlText id="forms.delete" />
            </Link>
          </div>
          <h2>
            <IntlText id="gallery.article.thumnail" />
          </h2>
          <div className="uk-margin">
            <Thumnail
              url={EndPoints.galleryArticles(this.state.article.id, "image")}
              src={this.state.thumnail_url}
              onSuccess={this.onUploaded}
              onDelete={this.onDeleteImage}
              keyExtractor={(value) => value.thumnail_url}
            />
          </div>
        </div>

        <div className="uk-width-expand">
          <h2>
            <IntlText id="gallery.article.description" />
          </h2>
          <div className="uk-margin">
            <EditorInput
              uploadUrl={EndPoints.galleryImages()}
              onChange={this.onDescChange}
              value={this.state.description}
            />
          </div>

          <div className="uk-margin">
            <ContentPicker
              onSelect={this.onContentSelect}
              removeKeys={["gallery", "fair", "artwork"]}
              selected={this.state.content}
              query={{ gellery_id: this.props.gallery.id }}
            />
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-small" data-uk-grid>
          <div className="uk-width-expand">
            <h1>
              <IntlText id="gallery.article.title" />
            </h1>
          </div>
          <div className="uk-width-medium@s uk-width-1-1 uk-text-right">
            <ChangeEditLang onChange={this.onChangeLang} />
          </div>
        </div>

        <div className="uk-margin-medium">
          {(() => {
            if (!this.state.article) {
              return (
                <div className="uk-text-center">
                  <span data-uk-spinner="ratio: 3"></span>
                </div>
              );
            } else {
              return this.renderEditor();
            }
          })()}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (param) => dispatch(get(param)),
      edit: (...param) => dispatch(edit(...param)),
      deleteArticle: (...param) => dispatch(deleteArticle(...param)),
      deleteImage: (...param) => dispatch(deleteImage(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditArticleScreen),
);
