import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { LikeButton } from "../util/Like";
import { Loading } from "../util/Loading";
import { IntlText } from "../util/Text";
import Artist from "./Artist";

class LikesList extends Component {
  renderDefault = (label, thumanil, title, description, url) => {
    return (
      <Link
        className="uk-display-block uk-link-reset uk-position-relative"
        to={url}
        ga={{ category: "link", action: "results_list", label: url }}
      >
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width-1-1">
            <span className="uk-label uk-margin-small-right uk-text-bold uk-text-mincho">
              {label}
            </span>
            <h3 className="uk-display-inline">{title}</h3>
          </div>
          <div className="uk-width-auto">
            <div className="uk-thumnail uk-image-wrapper">
              <Image src={thumanil} alt={title} />
            </div>
          </div>
          <div className="uk-width-expand">
            <p className="uk-height-lines-3 uk-overflow-hidden">
              {description}
            </p>
          </div>
        </div>
      </Link>
    );
  };

  render = () => {
    if (this.props.results == null) {
      return <Loading />;
    } else if (this.props.results.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.likes.no" />
          </h3>
        </div>
      );
    }

    return (
      <ul className="uk-list uk-list-divider">
        {this.props.results.map((result, index) => {
          var content = null;
          var type = "";
          var data = null;
          if (
            result.artist &&
            (this.props.only == null || this.props.only == "artist")
          ) {
            content = <Artist artist={result.artist} />;
            type = "artist";
            data = result[type];
          } else if (
            result.show &&
            (this.props.only == null || this.props.only == "show")
          ) {
            const show = Formatter.mergeDesc(
              result.show,
              result.show.show_descs,
              this.props.language.view,
            );
            const imageUrl =
              show.show_images && show.show_images.length > 0
                ? show.show_images[0].url
                : null;

            content = this.renderDefault(
              "SHOW",
              imageUrl,
              show.title,
              show.description,
              PathGenerator.getLocalPath(Path.shows.view.url, {
                showId: show.id,
              }),
            );
            type = "show";
            data = result[type];
          } else if (
            result.artwork &&
            (this.props.only == null || this.props.only == "artwork")
          ) {
            const artwork = Formatter.mergeDesc(
              result.artwork,
              result.artwork.artwork_descs,
              this.props.language.view,
            );
            const imageUrl =
              artwork.artwork_images && artwork.artwork_images.length > 0
                ? artwork.artwork_images[0].url
                : null;

            content = this.renderDefault(
              "ARTWORK",
              imageUrl,
              artwork.title,
              artwork.description,
              PathGenerator.getLocalPath(Path.artworks.view.url, {
                artworkId: artwork.id,
              }),
            );
            type = "artwork";
            data = result[type];
          } else if (
            result.article &&
            (this.props.only == null || this.props.only == "article")
          ) {
            const article = Formatter.mergeDesc(
              result.article,
              result.article.article_descs,
              this.props.language.view,
            );

            content = this.renderDefault(
              "ARTICLE",
              article.thumnail_url,
              article.title,
              article.meta_description,
              PathGenerator.getLocalPath(Path.articles.view.url, {
                articleId: article.id,
              }),
            );
            type = "article";
            data = result[type];
          } else if (
            result.gallery &&
            (this.props.only == null || this.props.only == "gallery")
          ) {
            const gallery = Formatter.mergeDesc(
              result.gallery,
              result.gallery.gallery_descs,
              this.props.language.view,
            );

            content = this.renderDefault(
              "GALLERY",
              gallery.thumnail_url,
              gallery.name,
              gallery.description,
              PathGenerator.getLocalPath(Path.galleries.view.url, {
                galleryId: gallery.id,
              }),
            );
            type = "gallery";
            data = result[type];
          } else if (
            result.fair &&
            (this.props.only == null || this.props.only == "fair")
          ) {
            const fair = Formatter.mergeDesc(
              result.fair,
              result.fair.fair_descs,
              this.props.language.view,
            );

            var url = PathGenerator.getLocalPath(Path.fairs.view.url, {
              fairId: fair.id,
            });
            if (fair.slug != null) {
              url = PathGenerator.getLocalPath(Path.original.url, {
                fairId: fair.slug,
              });
            }
            content = this.renderDefault(
              "FAIR",
              fair.thumnail_url,
              fair.title,
              fair.description,
              url,
            );
            type = "fair";
            data = result[type];
          } else {
            return;
          }
          return (
            <li key={index} className="uk-margin-remove">
              <div className="uk-hover-muted uk-hover-allow uk-padding-small uk-position-relative">
                {content}
                <div className="uk-position-xsmall uk-position-bottom-right">
                  <LikeButton type={type} data={data} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LikesList),
);
