import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArticles } from "../../actions/article";
import { setCondition } from "../../actions/cache";
import { search as searchFairs } from "../../actions/fair";
import { search as searchPhoto } from "../../actions/photo";
import Meta from "../../components/layout/Meta";
import ArticleCards from "../../components/lists/ArticleCards";
import FairsList from "../../components/lists/Fairs";
import PhotosList from "../../components/lists/Photos";
import { Link } from "../../components/util/Clickable";
import { Loading } from "../../components/util/Loading";
import { IntlText, LinedText, ViewAllHeader } from "../../components/util/Text";
import { Formatter } from "../../utils/formatter";

class OverviewScreen extends Component {
  state = {
    articles: null,
    fairs: null,
    photos: null,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.load();
    }
  };

  componentDidUpdate = () => {
    if (this.state.articles != null && this.state.fairs != null) {
      this.props.actions.setCondition(this.state);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return false;
  };

  load = () => {
    this.props.actions
      .searchArticles({
        fair_id: this.props.fair.id,
        pagesize: 6,
        sort: "posted_at",
        order: "desc",
      })
      .then(({ value }) => {
        this.setState({
          articles: value.data,
        });
        this.forceUpdate();
      });
    this.props.actions
      .searchFairs({
        related_fair_id: this.props.fair.id,
        pagesize: 24,
        no_artwork: true,
      })
      .then(({ value }) => {
        this.setState({
          fairs: value.data,
        });
        this.forceUpdate();
      });

    this.props.actions
      .searchPhoto({
        fair_id: this.props.fair.id,
        pagesize: 6,
      })
      .then(({ value }) => {
        this.setState({
          photos: value.data,
        });
        this.forceUpdate();
      });
  };

  render = () => {
    if (this.state.articles == null) {
      return <Loading />;
    }

    const defaultFair = Formatter.defalutDesc(
      this.props.fair,
      this.props.fair.fair_descs,
    );

    return (
      <div>
        <Meta
          title={this.props.fair.title}
          description={this.props.fair.description}
          image={this.props.fair.thumnail_url}
        />
        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText
              id="fairs.overview.about"
              values={{ title: this.props.fair.title }}
            />
          </h2>
          <LinedText
            className="uk-margin"
            text={
              this.props.fair.description
                ? this.props.fair.description
                : defaultFair.description
            }
          />
          <div className="uk-margin-medium uk-grid-large" data-uk-grid>
            <div className="uk-width-1-2@s">
              <div className="uk-grid-small uk-margin-medium" data-uk-grid>
                <div className="uk-width-small@s">
                  <h3 className="uk-text-bold uk-h4">
                    <IntlText id="fairs.overview.schedule" />
                  </h3>
                </div>
                <div className="uk-width-expand">
                  <LinedText
                    text={
                      this.props.fair.schedule
                        ? this.props.fair.schedule
                        : defaultFair.schedule
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div className="uk-grid-small uk-margin-medium" data-uk-grid>
                <div className="uk-width-small@s">
                  <h3 className="uk-text-bold uk-h4">
                    <IntlText id="fairs.overview.place" />
                  </h3>
                </div>
                <div className="uk-width-expand">
                  <p>
                    {this.props.fair.address
                      ? this.props.fair.address
                      : defaultFair.address}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.photos && this.state.photos.length > 0 && (
          <div className="uk-margin-xlarge">
            <div className="uk-margin-medium">
              <ViewAllHeader
                title={
                  <IntlText
                    id="fairs.overview.photos"
                    values={{ title: this.props.fair.title }}
                  />
                }
              />
            </div>
            <div className="uk-margin-medium">
              <PhotosList photos={this.state.photos} />
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default uk-button-plus"
                to={this.props.photoPath}
                ga={{ category: "link", action: "fair", label: "all_photos" }}
              >
                <IntlText id="word.view_all_text" />
              </Link>
            </div>
          </div>
        )}

        {this.state.articles && this.state.articles.length > 0 && (
          <div className="uk-margin-xlarge">
            <div className="uk-margin-medium">
              <ViewAllHeader
                title={
                  <IntlText
                    id="fairs.overview.articles"
                    values={{ title: this.props.fair.title }}
                  />
                }
              />
            </div>
            <div className="uk-margin-medium">
              <ArticleCards articles={this.state.articles} />
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default uk-button-plus"
                to={this.props.articlePath}
                ga={{ category: "link", action: "fair", label: "all_artiles" }}
              >
                <IntlText id="word.view_all_text" />
              </Link>
            </div>
          </div>
        )}

        {this.state.fairs && this.state.fairs.length > 0 && (
          <div className="uk-margin-xlarge">
            <h2 className="uk-heading-mark">
              <IntlText
                id="fairs.overview.related"
                values={{ title: this.props.fair.title }}
              />
            </h2>
            <div className="uk-margin-medium">
              <FairsList fairs={this.state.fairs} />
            </div>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchArticles: (...param) => dispatch(searchArticles(...param)),
      searchFairs: (...param) => dispatch(searchFairs(...param)),
      searchPhoto: (...param) => dispatch(searchPhoto(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverviewScreen),
);
