import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { change, Field, reduxForm } from "redux-form";
import {
  DomesticShippingStatus,
  mediums,
  salesStatusForSearch,
} from "../../constants/option";
import CheckBoxs from "../inputs/CheckBoxs";
import ColorPicker from "../inputs/ColorPicker";
import Select from "../inputs/Select";
import SliderInput from "../inputs/SliderInput";
import TextInput from "../inputs/TextInput";
import { Button } from "../util/Clickable";
import { getPureMessage, IntlText } from "../util/Text";

var periods = [];
for (var i = 2010; i >= 1980; i -= 10) {
  periods.push({ label: i, value: i });
}

class ArtworkSearchForm extends Component {
  state = {
    visible: false,
  };

  toggleForm = () => {
    this.setState({ visible: !this.state.visible });
  };

  renderCondition = () => {
    return (
      <div className="uk-margin-small">
        {this.renderValue("price", this.priceRenderer)}
        {this.renderValue("medium", (value) => {
          const option = mediums.find((m) => m.value == value);
          return getPureMessage(option.labelId);
        })}
        {this.renderValue("width", this.lengthRenderer)}
        {this.renderValue("height", this.lengthRenderer)}
        {this.renderValue("period", (value) => value.join(", "))}
        {this.renderValue("place", (value) => value.join(", "))}
        {this.renderValue("color", (value) => {
          return (
            <span
              className="uk-circle"
              title={value}
              style={{ backgroundColor: value }}
            ></span>
          );
        })}
      </div>
    );
  };

  renderValue = (key, renderer) => {
    if (!this.props.initialValues || !this.props.initialValues[key]) {
      return;
    }
    return (
      <div className="uk-text-bold uk-text-small uk-margin-ssmall">
        <span className="uk-text-middle uk-color-picker">
          {renderer(this.props.initialValues[key])}
        </span>
        <a
          className="uk-button uk-button-box-small"
          data-uk-icon="icon:close;ratio:0.8;"
          onClick={() => {
            this.props.actions.changeValue(key, null);
          }}
        />
      </div>
    );
  };

  priceRenderer = (values) => {
    return (
      <span>
        <span>{values.min}円</span>
        <span> - </span>
        <span>{values.max}円</span>
      </span>
    );
  };

  lengthRenderer = (values) => {
    return (
      <span>
        <span>{values.min}cm</span>
        <span> - </span>
        <span>{values.max}cm</span>
      </span>
    );
  };

  renderFields = () => {
    return (
      <div>
        {/*<div className="uk-margin-small">
          { this.renderCondition() }
        </div>*/}
        {this.state.visible && (
          <div className="uk-margin-small" data-uk-grid>
            <div className="uk-width-1-3@s">
              <div className="uk-margin-small">
                <Field
                  name="gallery_id"
                  placeholder={getPureMessage(
                    "forms.attr.artwork_filter.choose",
                  )}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  labelId="forms.attr.artwork_filter.by_gallery"
                  noError
                  options={
                    this.props.galleries &&
                    this.props.galleries.map((gallery) => {
                      return { label: gallery.name, value: gallery.id };
                    })
                  }
                />
              </div>
              <hr className="uk-margin-small" />
              <div className="uk-margin-small">
                <Field
                  name="fair_id"
                  placeholder={getPureMessage(
                    "forms.attr.artwork_filter.choose",
                  )}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  labelId="forms.attr.artwork_filter.by_fair"
                  noError
                  options={
                    this.props.fairs &&
                    this.props.fairs.map((fair) => {
                      return { label: fair.title, value: fair.id };
                    })
                  }
                />
              </div>
              <hr className="uk-margin-small" />
              <div className="uk-margin-small">
                <Field
                  name="medium"
                  component={CheckBoxs}
                  labelId="forms.attr.search.medium"
                  options={mediums}
                  noError
                />
              </div>
              <hr className="uk-margin-small" />
            </div>
            <div className="uk-width-1-3@s">
              <div className="uk-margin-small">
                <Field
                  name="sales_status"
                  placeholder={getPureMessage(
                    "forms.attr.artwork_filter.choose",
                  )}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  labelId="forms.attr.search.sales_status"
                  options={salesStatusForSearch}
                  noError
                />
              </div>
              <hr className="uk-margin-small" />
              <div className="uk-margin-small">
                <Field
                  name="domestic_shipping_status"
                  placeholder={getPureMessage(
                    "forms.attr.artwork_filter.choose",
                  )}
                  component={CheckBoxs}
                  className="uk-form-small uk-form-clear"
                  labelId="forms.attr.domestic_shipping_status.status"
                  options={DomesticShippingStatus}
                  noError
                />
              </div>
              <hr className="uk-margin-small" />
              <div className="uk-margin-small">
                <Field
                  name="period"
                  component={CheckBoxs}
                  labelId="forms.attr.search.period"
                  options={periods}
                  noError
                />
              </div>
              <hr className="uk-margin-small" />
              <div className="uk-margin-small">
                <Field
                  name="keyword"
                  labelId="forms.attr.free_word"
                  type="text"
                  component={TextInput}
                  placeholder=" "
                />
              </div>
              <hr className="uk-margin-small" />
            </div>
            <div className="uk-width-1-3@s">
              <div className="uk-margin-small">
                <Field
                  ref={(priceField) => (this.priceField = priceField)}
                  name="price"
                  component={SliderInput}
                  labelId="forms.attr.search.price"
                  step={1}
                  min={0}
                  max={8}
                  labelRender={this.priceRenderer}
                  valueExtractor={(value) => 10 ** value}
                  rangeExtractor={(values) => {
                    return [
                      values.min ? Math.log10(values.min) : 0,
                      values.max ? Math.log10(values.max) : 8,
                    ];
                  }}
                  noError
                />
              </div>
              <hr className="uk-margin-small" />
              <div className="uk-margin-small">
                <Field
                  name="width"
                  component={SliderInput}
                  labelId="forms.attr.search.width"
                  step={50}
                  min={0}
                  max={500}
                  labelRender={this.lengthRenderer}
                  noError
                />
              </div>
              <hr className="uk-margin-small" />
              <div className="uk-margin-small">
                <Field
                  name="height"
                  component={SliderInput}
                  labelId="forms.attr.search.height"
                  step={50}
                  min={0}
                  max={500}
                  labelRender={this.lengthRenderer}
                  noError
                />
              </div>
              <hr className="uk-margin-small" />
              <div className="uk-margin-small">
                <Field
                  name="color"
                  component={ColorPicker}
                  labelId="forms.attr.search.color"
                  noError
                />
              </div>
            </div>
            <div className="uk-width-1-1 uk-text-center">
              <Button
                className="uk-button uk-button-primary uk-width-large@s"
                type="submit"
              >
                <IntlText id="forms.search" />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  render = () => {
    // console.log('=====')
    // console.log(this.props.handleSubmit);
    // console.log('=====')
    return (
      <form
        onSubmit={this.props.handleSubmit}
        action={this.props.action}
        method="post"
      >
        {this.renderFields()}
      </form>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    constant: state.constant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeValue: (field, value) =>
        dispatch(change("artwork-search-form", field, value)),
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(
  reduxForm({
    form: "artwork-search-form",
    // enableReinitialize : true
  })(ArtworkSearchForm),
);
