export const defaultLang = "ja";

export const languageStr = "en|ja|zh\\-Hans|zh\\-Hant";

export class Location {
  static getLang() {
    const pathname = window.location.pathname;
    const paths = pathname.split("/");
    if (paths.length <= 1 || paths[1].length == 0) {
      return defaultLang;
    } else {
      return paths[1];
    }
  }

  static getHTMLLang(lang) {
    switch (lang) {
      case "zh-Hans":
        return "zh-cmn-Hans";
      case "zh-Hant":
        return "zh-cmn-Hant";
      default:
        return lang;
    }
  }

  static getCandidateLanguage() {
    var language =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      window.navigator.userLanguage ||
      window.navigator.browserLanguage;
    if (language == null) {
      return defaultLang;
    } else if (language.indexOf("ja") !== false) {
      return "ja";
    } else if (language.indexOf("en") !== false) {
      return "en";
    } else if (language.indexOf("zh") !== false) {
      return "zh-Hans";
    } else {
      return defaultLang;
    }
  }

  static isChangeLang() {
    var language = Location.getCandidateLanguage();

    switch (language) {
      case "ja":
        return "日本語に切り替えますか？";
      case "en":
        return "Do you want to use English?";
      case "zh-Hans":
      case "zh-Hant":
        return "你想用中文嗎？";
    }
    return "Do you want to use English?";
  }
}

export const toSearchParams = (obj) => {
  const searchParams = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (value) {
      searchParams.set(key, value);
    }
  });

  return searchParams;
};
