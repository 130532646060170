import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Meta from "../../../components/layout/Meta";
import { Link } from "../../../components/util/Clickable";
import {
  getPureMessage,
  IntlHTML,
  IntlText,
} from "../../../components/util/Text";
import { Path } from "../../../constants/path";

class ThanksScreen extends PureComponent {
  render = () => {
    return (
      <div>
        <Meta title={getPureMessage("collector.auth.thanks.header")} />
        <h1 className="uk-text-center">
          <IntlText id="collector.auth.thanks.header" />
        </h1>
        <p className="uk-text-center@s">
          <IntlHTML id="collector.auth.thanks.lead" />
        </p>
        <div className="uk-margin uk-text-center">
          <Link
            to={Path.root.url}
            className="uk-button uk-button-default"
            ga={{
              category: "button",
              action: "collector_auth_thanks",
              label: "back",
            }}
          >
            <IntlText id="collector.auth.thanks.back" />
          </Link>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ThanksScreen),
);
