import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { logout } from "../../actions/medium/auth";
import { showConfirm } from "../../actions/message";
import { Link } from "../../components/util/Clickable";
import { Path, PathGenerator } from "../../constants/path";
import EditArticleScreen from "./articles/EditScreen";
import ArticlesScreen from "./articles/IndexScreen";
import EditScreen from "./other/EditScreen";
import TopScreen from "./other/TopScreen";

class BaseScreen extends PureComponent {
  logout = () => {
    this.props.actions.showConfirm(
      "ログアウトしますか？",
      null,
      (confirmed) => {
        if (confirmed) {
          this.props.actions
            .logout()
            .then(({ value, action }) => {
              if (value.status < 300) {
                this.props.history.push(
                  PathGenerator.getLocalPath(Path.medium.auth.login.url),
                );
              }
            })
            .catch((error) => console.log(error));
        }
      },
    );
  };

  doLogout = () => {};

  renderSidebar = () => {
    return (
      <div>
        <ul className="uk-nav-default uk-list uk-list-divider" data-uk-nav>
          <li className="">
            <Link className="" to={Path.medium.articles.url}>
              記事一覧
            </Link>
          </li>
          <li>
            <Link className="" to={Path.medium.edit.url}>
              設定
            </Link>
          </li>
          <li className="">
            <Link className="" onClick={this.logout}>
              ログアウト
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-grid-collapse uk-grid-match" data-uk-grid>
          <div
            className="uk-width-medium@m uk-background-secondary uk-light uk-visible@m"
            style={{ minHeight: "calc(100vh - 56px)" }}
          >
            <div className="uk-padding@s uk-padding-small">
              <div>{this.renderSidebar()}</div>
            </div>
          </div>
          <div className="uk-width-expand">
            <div className="uk-padding@s uk-padding-small">
              <Switch>
                <Route exact path={Path.medium.top.url} component={TopScreen} />
                <Route
                  exact
                  path={Path.medium.edit.url}
                  component={EditScreen}
                />
                <Route
                  exact
                  path={Path.medium.articles.url}
                  component={ArticlesScreen}
                />
                <Route
                  exact
                  path={Path.medium.articles.edit.url}
                  component={EditArticleScreen}
                />
              </Switch>
            </div>
          </div>
        </div>
        <div id="medium-menu" data-uk-offcanvas="flip: true; overlay: true">
          <div className="uk-offcanvas-bar">
            <button
              className="uk-offcanvas-close"
              type="button"
              data-uk-close
            ></button>
            {this.renderSidebar()}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    admin: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showConfirm: (...data) => dispatch(showConfirm(...data)),
      logout: () => dispatch(logout()),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseScreen),
);
