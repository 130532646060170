import { ADD_ARTWORK_HISTORY, CLEAR_HISTORY } from "../constants/type";
import { Formatter } from "../utils/formatter";
import store from "../stores";

export const addArtworkHistory = (artwork) => {
  return {
    type: ADD_ARTWORK_HISTORY,
    content: artwork,
  };
};

export const clearHistory = () => {
  return {
    type: CLEAR_HISTORY,
  };
};
