import { SEARCH_GALLERIES, GET_GALLERY } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";
import { setCache, getCache } from "./cache";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: SEARCH_GALLERIES,
      payload: () => {
        return Api.get(EndPoints.galleries(), param);
      },
    };
  };
};

export const get = (galleryId) => {
  return (dispatch) => {
    return {
      type: GET_GALLERY,
      payload: () => {
        const key = "galleries/" + galleryId;
        const promise = new Promise((resolve, reject) => {
          const data = getCache(key);
          if (data) {
            return resolve(data);
          }
          Api.get(EndPoints.galleries(galleryId))
            .then((result) => {
              if (result.status < 300) {
                dispatch(setCache(key, result));
              }
              resolve(result);
            })
            .catch((error) => reject(error));
        });
        return promise;
      },
    };
  };
};
