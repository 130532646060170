import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { IntlText } from "../util/Text";
import { Button } from "../util/Clickable";
import { Formatter } from "../../utils/formatter";

import SearchForm from "../forms/SearchForm";
import { search as searchArtists } from "../../actions/artist.js";
import { search as searchArtworks } from "../../actions/artwork.js";
import { search as searchGalleries } from "../../actions/gallery.js";
import { search as searchFairs } from "../../actions/fair.js";

import ArtistsList from "../lists/Artists";
import ArtworkCardsList from "../lists/ArtworkCards";
import GalleriesList from "../lists/Galleries";
import FairsList from "../lists/Fairs";

declare var UIkit;

const modes = [
  {
    title: "Artists",
    keyword: "Name",
    key: "artist",
    name: "artist_id",
    action: "searchArtists",
    lists: (item, onSelect) => (
      <ArtistsList
        buttonText="Select"
        editing={true}
        artists={item}
        onClick={onSelect}
      />
    ),
  },
  {
    title: "Artworks",
    keyword: "Title",
    key: "artwork",
    name: "artwork_id",
    action: "searchArtworks",
    lists: (item, onSelect) => (
      <ArtworkCardsList
        buttonText={() => "Select"}
        editing={true}
        artworks={item}
        onClick={onSelect}
      />
    ),
  },
  {
    title: "Galleries",
    keyword: "Title",
    key: "gallery",
    name: "gallery_id",
    action: "searchGalleries",
    lists: (item, onSelect) => (
      <GalleriesList
        buttonText="Select"
        editing={true}
        galleries={item}
        onClick={onSelect}
      />
    ),
  },
  {
    title: "Fairs",
    keyword: "Title",
    key: "fair",
    name: "fair_id",
    action: "searchFairs",
    lists: (item, onSelect) => (
      <FairsList
        buttonText="Select"
        editing={true}
        fairs={item}
        onClick={onSelect}
      />
    ),
  },
];

class ContentPicker extends Component {
  state = {
    mode: null,
    modes: [],
    selected: {},
    candidates: [],
    keyword: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    const viewModes = modes.filter((m) => {
      if (this.props.removeKeys == null) {
        return true;
      }
      return (
        this.props.removeKeys.find((key) => {
          return m.key == key;
        }) == null
      );
    });
    this.setState(
      {
        mode: viewModes[0],
        modes: viewModes,
        selected: this.setSelected(viewModes, this.props.selected),
      },
      () => {
        this.readMore(true);
      },
    );
  };

  componentDidMount = () => {
    UIkit.tab(this.tab).show(0);
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      selected: this.setSelected(this.state.modes, nextProps.selected),
    });
  };

  setSelected = (modes, selected) => {
    const ret = {};
    if (selected) {
      modes.forEach((mode) => {
        ret[mode.name] = selected[mode.name];
        ret[mode.key] = selected[mode.key];
      });
    }
    return ret;
  };

  onTabChange = (index) => {
    this.setState(
      {
        mode: this.state.modes[index],
        keyword: null,
        candidates: [],
        meta: {
          current_page: 0,
          last_page: null,
        },
      },
      () => {
        this.readMore(true);
      },
    );
  };

  onSelect = (data) => {
    const value = {};
    this.state.modes.forEach((mode) => {
      value[mode.name] = null;
      value[mode.key] = null;
    });
    value[this.state.mode.name] = data.id;
    value[this.state.mode.key] = data;

    this.setState({
      selected: value,
    });
    this.props.onSelect && this.props.onSelect(value);
  };

  onRemoveSelect = () => {
    const value = {};
    this.state.modes.forEach((mode) => {
      value[mode.name] = null;
      value[mode.key] = null;
    });
    this.setState({
      selected: value,
    });
    this.props.onSelect && this.props.onSelect(value);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions[this.state.mode.action]({
      keyword: this.state.keyword,
      page: this.state.meta.current_page + 1,
    })
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            candidates:
              this.state.candidates && !refresh
                ? this.state.candidates.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onSearch = (values) => {
    this.setState({
      keyword: values.keyword,
      meta: {
        current_page: 0,
      },
    });
    return this.props.actions[this.state.mode.action](values);
  };

  onSearched = (data, response) => {
    this.setState({
      candidates: data,
      meta: response.data.meta,
    });
  };

  renderRelated = () => {
    var name = null;
    if (this.state.selected == null) {
      return null;
    } else if (this.state.selected.artwork) {
      const artwork = Formatter.mergeDesc(
        this.state.selected.artwork,
        this.state.selected.artwork.artwork_descs,
        this.props.language.view,
      );
      name = artwork.title;
    } else if (this.state.selected.artist) {
      const artist = Formatter.mergeDesc(
        this.state.selected.artist,
        this.state.selected.artist.artist_descs,
        this.props.language.view,
      );
      name = artist.name;
    } else if (this.state.selected.gallery) {
      const gallery = Formatter.mergeDesc(
        this.state.selected.gallery,
        this.state.selected.gallery.gallery_descs,
        this.props.language.view,
      );
      name = gallery.name;
    } else if (this.state.selected.fair) {
      const fair = Formatter.mergeDesc(
        this.state.selected.fair,
        this.state.selected.fair.fair_descs,
        this.props.language.view,
      );
      name = fair.title;
    } else {
      return null;
    }

    return (
      <div className="uk-margin-small">
        <h3 className="uk-margin-ssmall">
          <IntlText id="forms.attr.related" />
        </h3>
        <div className="uk-margin-ssmall uk-flex-middle" data-uk-grid>
          <div className="uk-width-expand">
            <span className="uk-h4">{name}</span>
          </div>
          <div className="uk-width-auto">
            <Button
              className="uk-button-danger uk-icon-button"
              data-uk-icon="icon:close;"
              onClick={this.onRemoveSelect}
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        {this.renderRelated()}
        <div className="uk-margin-small">
          <ul
            ref={(tab) => (this.tab = tab)}
            className="uk-child-width-expand"
            {...{ "uk-tab": "" }}
          >
            {this.state.modes.map((mode, index) => {
              return (
                <li key={index}>
                  <a onClick={() => this.onTabChange(index)} href="#">
                    {mode.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="uk-margin-small">
          <SearchForm onSubmit={this.onSearch} onSuccess={this.onSearched} />
        </div>
        <div className="uk-margin uk-height-max-large uk-overflow-auto">
          <div className="uk-margin-small">
            {this.state.mode.lists(this.state.candidates, this.onSelect)}
          </div>
          {this.state.meta.last_page &&
            this.state.meta.last_page > this.state.meta.current_page && (
              <div className="uk-margin-small uk-text-center">
                <Button
                  className="uk-button uk-button-default"
                  onClick={() => this.readMore(false)}
                  loading={this.state.loading ? 1 : 0}
                >
                  <IntlText id="forms.more" />
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchArtists: (...param) => dispatch(searchArtists(...param)),
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      searchGalleries: (...param) => dispatch(searchGalleries(...param)),
      searchFairs: (...param) => dispatch(searchFairs(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentPicker),
);
