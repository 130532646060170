import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import { bindActionCreators } from "redux";
import { updatePayment } from "../../actions/gallery/index";
import { Path, PathGenerator } from "../../constants/path";
import { Notification } from "../../utils/notification";
import CardForm from "../forms/CardForm";
import { Button } from "../util/Clickable";
import { getPureMessage, IntlHTML, IntlText } from "../util/Text";

declare var UIkit;

class Plan extends Component {
  onSubmitCard = (data) => {
    return this.props.actions.updatePayment(data);
  };

  onUpdated = (data) => {
    Notification.successIntl("util.success.update");
    UIkit.modal(this.editCardModal).hide();

    UIkit.util.on(this.editCardModal, "hidden", () => {
      this.props.onUpdated && this.props.onUpdated(data);
    });
  };

  getPlan = (planId) => {
    if (planId == 0) {
      return {
        id: 0,
        name: getPureMessage("views.plan.trial"),
        price: getPureMessage("views.plan.free"),
      };
    } else {
      return {
        id: 1,
        name: getPureMessage("views.plan.basic"),
        price:
          this.props.gallery.plan_price + getPureMessage("views.plan.unit"),
        init_price:
          this.props.gallery.initial_price +
          getPureMessage("views.plan.init_unit"),
      };
    }
  };

  render = () => {
    const plan = this.getPlan(this.props.gallery.status);
    if (plan == null) {
      return null;
    }
    const basicPlan = this.getPlan(1);
    return (
      <div>
        <div className="uk-margin">
          <div className="uk-margin-small">
            <span className="uk-h3">
              <IntlHTML
                id="views.plan.current_id"
                values={{ plan_name: plan.name, price: plan.price }}
              />
            </span>
          </div>
          {this.props.gallery.campaign_end_at != null && (
            <div className="uk-margin-small">
              <span className="uk-h5 uk-text-danger">
                <IntlHTML
                  id="views.plan.campaign.release"
                  values={{
                    campaign_end_at: moment(
                      this.props.gallery.campaign_end_at,
                    ).format("YYYY-MM-DD"),
                  }}
                />
              </span>
            </div>
          )}
        </div>

        {(() => {
          if (this.props.editing && this.props.gallery.status != 0) {
            return (
              <div className="uk-margin">
                <div className="uk-margin-small">
                  <h4 className="uk-margin-remove">
                    <IntlText id="views.plan.current_card" />
                  </h4>
                  <p className="uk-margin-remove ">
                    <span className="uk-margin-small-right">
                      **** **** **** **** {this.props.gallery.card_suffix}
                    </span>
                    <span>
                      <IntlText id="views.plan.expired" />:{" "}
                      {moment(this.props.gallery.expired_at).format("YYYY/MM")}
                    </span>
                  </p>
                </div>
                <div className="uk-margin-small uk-text-center">
                  <Button
                    className="uk-button uk-button-default"
                    onClick={() => {
                      UIkit.modal(this.editCardModal, {
                        container: false,
                      }).show();
                    }}
                  >
                    <IntlText id="views.plan.edit_payment" />
                  </Button>
                </div>
              </div>
            );
          } else if (this.props.gallery.status == 0) {
            return (
              <div className="uk-margin">
                <div className="uk-margin-small">
                  <div data-uk-alert>
                    <h3 className="uk-text-danger uk-margin-small">
                      <IntlHTML
                        id="views.plan.caution_header"
                        values={{
                          date: moment(this.props.gallery.trial_end_at).format(
                            "YYYY/MM/DD",
                          ),
                        }}
                      />
                    </h3>
                    <p className="uk-margin-small">
                      <IntlHTML
                        id="views.plan.caution_text"
                        values={{
                          init_price: basicPlan.init_price,
                          plan_name: basicPlan.name,
                          price: basicPlan.price,
                        }}
                      />
                    </p>
                  </div>
                </div>
                {this.props.editing ? (
                  <div className="uk-margin-small uk-text-center">
                    <Button
                      className="uk-button uk-button-default"
                      onClick={() => {
                        UIkit.modal(this.editCardModal, {
                          container: false,
                        }).show();
                      }}
                    >
                      <IntlText id="views.plan.caution_button" />
                    </Button>
                  </div>
                ) : (
                  <div className="uk-margin-small uk-text-center">
                    <Button
                      className="uk-button uk-button-default"
                      onClick={() => {
                        this.props.history.push(
                          PathGenerator.getLocalPath(Path.gallery.edit.url, {
                            galleryId: this.props.gallery.id,
                          }),
                        );
                      }}
                    >
                      <IntlText id="views.plan.caution_button" />
                    </Button>
                  </div>
                )}
              </div>
            );
          }
        })()}

        {this.props.editing && (
          <div
            ref={(editCardModal) => (this.editCardModal = editCardModal)}
            data-uk-modal
          >
            <div className="uk-modal-dialog uk-width-xxlarge@s">
              <button
                className="uk-modal-close-default"
                type="button"
                data-uk-close
              ></button>
              <div className="uk-modal-header">
                <h2 className="uk-modal-title">
                  <IntlText id="views.plan.edit_payment" />
                </h2>
              </div>
              <div className="uk-modal-body">
                <div className="uk-margin-small uk-text-center">
                  <span className="uk-h3">{basicPlan.name}</span>
                  <br />
                  <IntlText id="views.plan.initial" />
                  {basicPlan.init_price}, {basicPlan.price}
                </div>
                <div className="uk-margin-small">
                  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                    <Elements>
                      <CardForm
                        onSubmit={this.onSubmitCard}
                        onSuccess={this.onUpdated}
                      />
                    </Elements>
                  </StripeProvider>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updatePayment: (data) => dispatch(updatePayment(data)),
    },
    dispatch,
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Plan));
