const messages = {
  layout: {
    menu: {
      dashboard: "ダッシュボード",
      articles: "記事",
      setting: "設定",
      sign_out: "ログアウト",

      fair_information: "フェア情報",
      information: "基本情報",
      sections: "セクション",
      related: "関連するフェア",

      invite: "招待",
      inviting: "招待済ギャラリー",
      add_invitation: "招待の送信",
      preview: "フェアページをプレビュー",
      photos: "写真",
    },
  },
  auth: {
    login: {
      header: "Fair's Login",
      ifyouforget: "パスワードをお忘れの場合は、",
      resethere: "こちらからリセットします。",
      donthave: "Art Scenesのアカウントがない場合は",
      contact: "お問い合わせフォームよりご連絡ください。",
      contactus: "問い合わせる",
      agree: "同意する",
    },
    reset: {
      header: "パスワードリセット",
      back: "ログイン画面へ戻る",
      completed: "リセットが完了しました。",
      please: "新しいパスワードで再度ログインしてください。",
    },
    forget: {
      header: "パスワードリセット",
      message:
        "ご登録いただいたメールアドレスを入力してください。メールが届かなかった場合は、異なるメールアドレスでのご登録がないかご確認ください。",
      back: "ログイン画面へ戻る",
      completed: "リセット用メールを送信しました",
      please:
        "ご入力されたメールアドレスにリセット用リンクを送信しました。記載された内容に従って、パスワードの再設定を行ってください。メールが届かなかった場合は、異なるメールアドレスでのご登録がないかご確認ください。",
    },
  },
  dashboard: {
    title: "ダッシュボード",
    galleries: "招待済みのギャラリー",
  },
  settings: {
    title: "設定",
    authorize: "アカウント情報",
  },
  articles: {
    title: "記事一覧",
    add: "記事の新規作成",
  },
  article: {
    title: "記事の編集",
    basic: "記事情報",
    thumnail: "トップ画像",
    description: "本文",
  },
  invites: {
    title: "招待済みのギャラリー",
    add_confirm: "招待します。よろしいですか?",
    add_title: "ギャラリーの新規招待",
    galleries: "ギャラリー一覧",
    delete_detail: "ギャラリーが登録したこのフェアへの出展情報が削除されます。",
    select: "選択済みのギャラリー",
    invite: "招待する",
    remove: "除外する",
  },
  related: {
    title: "関連するフェア",
    others: "他のフェア",
    related: "関連するフェア",
    remove: "除外する",
  },
  sections: {
    title: "セクション情報",
    artworks: "展示される作品数: ",
    add_section: "セクションの新規追加",
    edit_section: "セクションの編集",
  },
  profile: {
    title: "フェア情報",
    info: "基本情報",
    images: "画像",
    cover: "カバー画像",
    logo: "ロゴ",
  },
  photos: {
    title: "写真一覧",
    edit: "キャプションの編集",
  },
};

export default messages;
