import React, { Component } from "react";
import Logger from "../../utils/logger";
import { getPureMessage, IntlHTML, IntlText } from "../util/Text";

export default class Select extends Component {
  onClick = () => {
    if (this.props.ga != null) {
      Logger.event(this.props.ga);
    } else if (this.props.meta) {
      Logger.event({
        category: this.props.meta.form,
        action: "select",
        label: this.props.input.name,
      });
    }
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;

    const label = this.props.labelId
      ? getPureMessage(this.props.labelId)
      : this.props.label;
    const placeholder = this.props.placeholder ? this.props.placeholder : label;

    return (
      <div>
        {(() => {
          if (label != null) {
            return (
              <label className="uk-form-label">
                {label}
                <wbr />
                {(() => {
                  if (this.props.required) {
                    return (
                      <small className="uk-text-danger">
                        {" "}
                        ※<IntlText id="forms.attr.required" />
                      </small>
                    );
                  }
                })()}
                {this.props.suppyId && (
                  <div className="uk-inline uk-margin-ssmall-left">
                    <a data-uk-icon="icon:question; ratio:0.7;" />
                    <div data-uk-drop="mode: click; pos:right-center;">
                      <div className="uk-card uk-card-secondary uk-card-small uk-padding-small">
                        <IntlHTML id={this.props.suppyId} />
                      </div>
                    </div>
                  </div>
                )}
              </label>
            );
          }
        })()}
        <div className="uk-form-controls">
          <select
            onClick={this.onClick}
            style={this.props.style}
            className={
              "uk-select uk-input-custom " +
              (hasError ? "uk-input-error " : " ") +
              this.props.className
            }
            {...this.props.input}
          >
            {!this.props.notNull && <option value={""}>{placeholder}</option>}
            {this.props.options &&
              this.props.options.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.labelId
                      ? getPureMessage(option.labelId)
                      : option.label}
                  </option>
                );
              })}
          </select>
        </div>
        {!this.props.noError && (
          <div className="uk-text-danger " style={{ minHeight: "18px" }}>
            <small>{hasError && this.props.meta.error}</small>
          </div>
        )}
      </div>
    );
  };
}
