import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { IntlText } from "../util/Text";
import Artist from "../views/Artist";

class ArtistCardsList extends Component {
  render = () => {
    if (this.props.artists == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.artists.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.artists.no" />
          </h3>
        </div>
      );
    }

    return (
      <div
        className="uk-child-width-1-3@m uk-child-width-1-2"
        data-uk-grid="masonry: true"
      >
        {this.props.artists.map((artist, key) => {
          return (
            <div key={key}>
              <Artist
                artist={artist}
                lang={
                  this.props.editing
                    ? this.props.language.edit
                    : this.props.language.view
                }
                editing={this.props.editing}
              />
            </div>
          );
        })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistCardsList),
);
