import React, { Component } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { statuses } from "../../constants/option";
import DatePicker from "../inputs/DatePicker";
import Select from "../inputs/Select";
import Textarea from "../inputs/Textarea";
import TextInput from "../inputs/TextInput";
import { Button } from "../util/Clickable";
import { IntlText } from "../util/Text";
import * as validate from "../util/Validator";

class ArticleForm extends Component {
  state = {
    loading: false,
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-margin">
          <Field
            name="title"
            type="text"
            component={TextInput}
            labelId="forms.attr.title"
            validate={[validate.required]}
            required
          />
        </div>
        {this.props.editing && (
          <div className="uk-margin">
            <div className="uk-margin-small">
              <Field
                name="posted_at"
                component={DatePicker}
                labelId="forms.attr.posted_at"
              />
            </div>
            <div className="uk-margin-small">
              <Field
                name="status"
                component={Select}
                labelId="forms.attr.publish_status"
                options={statuses}
                validate={[validate.required]}
                required
              />
            </div>
            <div className="uk-margin-small">
              <Field
                name="editor_name"
                component={TextInput}
                labelId="forms.attr.editor_name"
                text="text"
              />
            </div>
            <div className="uk-margin-small">
              <Field
                name="meta_description"
                component={Textarea}
                labelId="forms.attr.summary"
              />
            </div>
          </div>
        )}
        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "article-form",
  enableReinitialize: true,
})(ArticleForm);
