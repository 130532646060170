import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Meta from "../../components/layout/Meta";
import { Path } from "../../constants/path";
import ForgetScreen from "./auth/ForgetScreen";
import LoginScreen from "./auth/LoginScreen";
import ResetScreen from "./auth/ResetScreen";
import RegisterCardScreen from "./register/RegisterCardScreen";

class AuthScreen extends PureComponent {
  render = () => {
    return (
      <div>
        <Meta title="For Gallery" />
        <div className="uk-section uk-section-large">
          <div className="uk-container uk-container-small">
            <Switch>
              <Route
                exact
                path={Path.gallery.auth.login.url}
                component={LoginScreen}
              />
              <Route
                exact
                path={Path.gallery.auth.forget.url}
                component={ForgetScreen}
              />
              <Route
                exact
                path={Path.gallery.auth.reset.url}
                component={ResetScreen}
              />
              <Route
                exact
                path={Path.gallery.auth.card.url}
                component={RegisterCardScreen}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthScreen),
);
