import React, { Component } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import DatetimePicker from "../../inputs/DatetimePicker";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import UploadImage from "../../inputs/UploadImage";
import * as validate from "../../util/Validator";
import { EndPoints } from "../../../constants/endpoints";
import { Notification } from "../../../utils/notification";
import { Image } from "../../util/Image";

class BannerForm extends Component {
  state = {
    loading: false,
    pc_thumnail_url: null,
    sp_thumnail_url: null,
  };

  onChange = (mode) => {
    this.setState({ mode: mode });
  };

  onSubmit = (values) => {
    values.pc_thumnail_url = this.state.pc_thumnail_url? this.state.pc_thumnail_url: this.props.initialValues.pc_thumnail_url
    values.sp_thumnail_url = this.state.sp_thumnail_url? this.state.sp_thumnail_url: this.props.initialValues.sp_thumnail_url
    this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
        window.location.href = '/ja/admin/banner';
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  onPCUploaded = (data) => {
    this.setState({
      pc_thumnail_url: data.url,
    }
    );
    Notification.successIntl("util.success.update");
  };

  onSPUploaded = (data) => {
    this.setState({
      sp_thumnail_url: data.url,
    }
    );
    Notification.successIntl("util.success.update");
  };

  onUploadError = () => {
    Notification.errorIntl("util.error.update");
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-margin-small">
          <label className="uk-margin-small uk-text-small">PC Thumnail<span className="uk-text-danger">※必須</span><span className="uk-margin-left">1024×420以上(5:2)推奨</span></label>
          <UploadImage
            url={EndPoints.adminBannersPCImage()}
            onSuccess={this.onPCUploaded}
            onError={this.onUploadError}
          />
          { (this.props.initialValues || this.state.pc_thumnail_url) && <Image className="uk-align-center uk-height-small" style={{'aspect-ratio': '5 / 2', 'object-fit': 'cover'}} src={ this.props.initialValues && !this.state.pc_thumnail_url? this.props.initialValues.pc_thumnail_url: this.state.pc_thumnail_url} /> }
          <label className="uk-margin-small uk-text-small">SP Thumnail<span className="uk-text-danger">※必須</span><span className="uk-margin-left">1024×420以上(4:3)推奨</span></label>
          <UploadImage
            url={EndPoints.adminBannersSPImage()}
            onSuccess={this.onSPUploaded}
            onError={this.onUploadError}
          />
          { (this.props.initialValues || this.state.sp_thumnail_url) && <Image className="uk-align-center uk-height-small" style={{'aspect-ratio': '4 / 3', 'object-fit': 'cover'}} src={ this.props.initialValues && !this.state.sp_thumnail_url? this.props.initialValues.sp_thumnail_url: this.state.sp_thumnail_url} /> }
          <Field
            name="alt"
            type="text"
            component={TextInput}
            label="Alt"
          />
        </div>
        <div className="uk-margin-small uk-child-width-1-2@s" data-uk-grid>
          <div>
            <Field
              name="start_at"
              component={DatetimePicker}
              label="Start Date"
              validate={[validate.required]}
              required
            />
          </div>
          <div>
            <Field
              name="end_at"
              component={DatetimePicker}
              label="End Date"
              validate={[validate.required]}
              required
            />
          </div>
        </div>
        <div className="uk-margin-small">
          <Field
            name="site_url"
            type="text"
            component={TextInput}
            label="Link URL"
          />
          <Field
            name="site_label"
            type="text"
            component={TextInput}
            label="Link label"
          />
        </div>

        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            Submit
          </Button>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "admin-news-form",
  enableReinitialize: true,
})(BannerForm);
