import {
  SEARCH_ATTENDS,
  GET_ATTEND,
  DELETE_ATTEND_IMAGE,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: SEARCH_ATTENDS,
      payload: () => {
        return Api.get(EndPoints.galleryAttends(), param);
      },
    };
  };
};

export const get = (attendId) => {
  return (dispatch) => {
    return {
      type: GET_ATTEND,
      payload: () => {
        return Api.get(EndPoints.galleryAttends(attendId));
      },
    };
  };
};

export const deleteImage = (attendId) => {
  return (dispatch) => {
    return {
      type: DELETE_ATTEND_IMAGE,
      payload: () => {
        return Api.delete(EndPoints.galleryAttends(attendId, "image"));
      },
    };
  };
};
