import { SET_VIEW_LANG, SET_EDIT_LANG } from "../constants/type";

export const setViewLang = (lang) => {
  return {
    type: SET_VIEW_LANG,
    lang: lang,
  };
};

export const setEditLang = (lang) => {
  return {
    type: SET_EDIT_LANG,
    lang: lang,
  };
};
