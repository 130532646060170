import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { get, search as searchArticles } from "../../actions/article";
import Article from "../../components/details/Article";
import Meta from "../../components/layout/Meta";
import ArticleCards from "../../components/lists/ArticleCards";
import { Image, imageUrl } from "../../components/util/Image";
import { LikeDetail } from "../../components/util/Like";
import { Loading } from "../../components/util/Loading";
import NotFound from "../../components/util/NotFound";
import { Facebook, Pinterest, Twitter } from "../../components/util/SNS";
import { IntlText } from "../../components/util/Text";
import { Formatter } from "../../utils/formatter";

declare var UIkit;

class ViewScreen extends PureComponent {
  state = {
    article: null,
    articles: null,
  };

  componentWillMount = () => {
    this.reload(this.props.match.params.articleId);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.match.params.articleId != nextProps.match.params.articleId) {
      this.reload(nextProps.match.params.articleId);
    }
  };

  reload = (articleId) => {
    this.setState({
      article: null,
      articles: null,
    });

    this.props.actions.get(articleId).then(({ value }) => {
      if (value.status > 300) {
        return this.setState({
          article: false,
        });
      }
      this.setState({
        article: value.data,
      });
    });

    this.props.actions
      .searchArticles({
        pagesize: 6,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            articles: value.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    if (this.state.article == null) {
      return (
        <div className="uk-section">
          <div className="uk-container uk-container-small">
            <Loading />
          </div>
        </div>
      );
    } else if (!this.state.article) {
      return (
        <div className="uk-section">
          <div className="uk-container uk-container-small">
            <NotFound />
          </div>
        </div>
      );
    }
    const article = Formatter.mergeDesc(
      this.state.article,
      this.state.article.article_descs,
      this.props.language.view,
    );

    return (
      <div>
        <Meta
          title={article.title}
          description={article.meta_description}
          image={article.thumnail_url}
        />

        <div className="uk-position-relative">
          <div className="uk-position-cover uk-image-wrapper uk-height-1-1 uk-width-1-1">
            <Image full src={article.thumnail_url} alt={article.title} />
          </div>
          <div className="uk-position-cover uk-overlay uk-overlay-primary"></div>
          <div className="uk-section uk-position-relative uk-light uk-height-1-1">
            <div className="uk-padding-medium-top@s uk-padding-medium-bottom@s">
              <div className="uk-container">
                <div data-uk-grid>
                  <div className="uk-width-xxlarge@s">
                    <h1 className="uk-text-gothic">{article.title}</h1>
                    <div className="uk-margin">
                      {article.posted_at && (
                        <p className="uk-margin-remove">
                          {moment(article.posted_at).format("YYYY.MM.DD")}
                        </p>
                      )}
                      <p className="uk-margin-remove">{article.editor_name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-small">
            <div data-uk-grid className="uk-flex-middle">
              <div className="uk-width-expand">
                <LikeDetail type="article" data={article} />
              </div>
              <div className="uk-width-auto uk-text-muted">
                <Twitter title={article.title} />
                <Facebook
                  className="uk-margin-small-left"
                  title={article.title}
                />
                {/*<Pinterest className="uk-margin-small-left" title={article.title} media={imageUrl(article.thumnail_url)} />*/}
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-small">
            <Article article={article} />
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-small">
            <div data-uk-grid className="uk-flex-middle">
              <div className="uk-width-expand">
                <LikeDetail type="article" data={article} />
              </div>
              <div className="uk-width-auto uk-text-muted">
                <Twitter title={article.title} />
                <Facebook
                  className="uk-margin-small-left"
                  title={article.title}
                />
                <Pinterest
                  className="uk-margin-small-left"
                  title={article.title}
                  media={imageUrl(article.thumnail_url)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-large">
          <div className="uk-container">
            <h2 className="uk-heading-mark">
              <IntlText id="articles.related" />
            </h2>
            <div className="uk-margin-medium">
              <ArticleCards articles={this.state.articles} />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (...param) => dispatch(get(...param)),
      searchArticles: (...param) => dispatch(searchArticles(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewScreen),
);
