import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  deleteImage,
  detach,
  edit,
  get,
} from "../../../actions/gallery/artist";
import { showConfirm } from "../../../actions/message";
import ArtistForm from "../../../components/forms/gallery/ArtistForm";
import Thumnail from "../../../components/inputs/Thumnail";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Link } from "../../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../../components/util/Text";
import { EndPoints } from "../../../constants/endpoints";
import { Path } from "../../../constants/path";
import { Formatter } from "../../../utils/formatter";
import { Notification } from "../../../utils/notification";

class EditScreen extends Component {
  state = {
    artist: {},
    editing: {},
  };

  componentWillMount = () => {
    this.props.actions
      .get(this.props.match.params.artistId)
      .then(({ value, action }) => {
        if (value.status < 300) {
          const artist = { ...value.data.belong, ...value.data };
          const editing = Formatter.mergeDesc(
            artist,
            artist.artist_descs,
            this.props.language.edit,
          );

          this.setState({
            artist: value.data,
            editing: editing,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onChangeLang = (lang) => {
    const artist = { ...this.state.artist.belong, ...this.state.artist };
    const editing = Formatter.mergeDesc(artist, artist.artist_descs, lang);

    this.setState({
      editing: editing,
    });
  };

  onEdit = (values) => {
    return this.props.actions.edit(this.state.artist.id, values);
  };

  onEdited = (data) => {
    this.setState({
      artist: data,
    });
    Notification.successIntl("util.success.update");
  };

  onDelete = () => {
    this.props.actions.showConfirm(
      getPureMessage("util.confirm.title.delete"),
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }
        this.props.actions
          .detach(this.state.artist.id)
          .then(({ value, status }) => {
            Notification.successIntl("util.success.delete");
            this.props.history.push(Path.gallery.artists.url);
          })
          .catch((error) => console.log(error));
      },
    );
  };

  onUploaded = (data) => {
    // this.setState( Immutable.fromJS(this.state)
    //   .updateIn( ["artist", "thumnail_url"], v => data.thumnail_url )
    //   .updateIn( ["editing", "thumnail_url"], v => data.thumnail_url )
    //   .toJS()
    // );
    Notification.successIntl("util.success.update");
  };

  onUploadError = () => {
    Notification.errorIntl("util.error.update");
  };

  onDeleteImage = () => {
    // this.setState( Immutable.fromJS(this.state)
    //   .updateIn( ["artist", "thumnail_url"], v => null )
    //   .updateIn( ["editing", "thumnail_url"], v => null )
    //   .toJS()
    // );
    this.props.actions
      .deleteImage(this.state.artist.id)
      .then(({ value, action }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.delete");
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-large" data-uk-grid>
          <div className="uk-width-expand">
            <h1>
              <IntlText id="gallery.artists.edit_title" />
            </h1>
          </div>
          <div className="uk-width-medium@s uk-text-right">
            <ChangeEditLang onChange={this.onChangeLang} />
          </div>
        </div>

        <div className="uk-margin-large" data-uk-grid>
          <div className="uk-width-expand">
            <h3>
              <IntlText id="gallery.artists.profile" />
            </h3>
            <ArtistForm
              ref={(editForm) => (this.editForm = editForm)}
              onSubmit={this.onEdit}
              onSuccess={this.onEdited}
              initialValues={this.state.editing}
            />
          </div>
          <div className="uk-width-large@s">
            <h3>
              <IntlText id="gallery.artists.thumnail" />
            </h3>
            <Thumnail
              url={EndPoints.galleryArtists(this.state.artist.id, "image")}
              src={this.state.editing.thumnail_url}
              onSuccess={this.onUploaded}
              onDelete={this.onDeleteImage}
              keyExtractor={(value) => value.thumnail_url}
            />
          </div>
        </div>
        <hr />
        <div className="uk-margin-large uk-text-center">
          <Link className="uk-button uk-button-danger" onClick={this.onDelete}>
            <IntlText id="forms.delete" />
          </Link>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (artistId) => dispatch(get(artistId)),
      edit: (...param) => dispatch(edit(...param)),
      detach: (...param) => dispatch(detach(...param)),
      deleteImage: (...param) => dispatch(deleteImage(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditScreen),
);
