import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { login } from "../../../actions/fair/auth";
import logo from "../../../assets/images/logo/black.png";
import LoginForm from "../../../components/forms/LoginForm";
import { Link } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import { Path, PathGenerator } from "../../../constants/path";

class LoginScreen extends PureComponent {
  submitLogin = (values) => {
    return this.props.actions.login(values);
  };

  onSuccess = (data) => {
    this.props.history.push(PathGenerator.getLocalPath(Path.fair.top.url));
  };

  render = () => {
    return (
      <div>
        <div className="uk-text-center">
          <img src={logo} className="uk-width-medium" />
        </div>
        <h1 className="uk-text-center">
          <IntlText id="fair.auth.login.header" />
        </h1>
        <div className="uk-margin-large uk-text-center">
          <div className="uk-width-large@s uk-text-left uk-display-inline-block">
            <div className="uk-margin-medium">
              <LoginForm
                onSubmit={this.submitLogin}
                onSuccess={this.onSuccess}
              />
            </div>
            <div className="uk-margin-small uk-text-center">
              <IntlText id="fair.auth.login.ifyouforget" />
              <Link
                className="uk-button-link uk-button"
                to={Path.fair.auth.forget.url}
              >
                <IntlText id="fair.auth.login.resethere" />
              </Link>
            </div>
            <hr />
            <div className="uk-margin-medium">
              <p className="uk-margin-small uk-text-center">
                <IntlText id="fair.auth.login.donthave" />
                <br />
                <IntlText id="fair.auth.login.contact" />
              </p>
              <div className="uk-margin-small uk-text-center">
                <Link
                  className="uk-button-default uk-button"
                  to={Path.other.contact.url}
                >
                  <IntlText id="fair.auth.login.contactus" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      login: (data) => dispatch(login(data)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreen),
);
