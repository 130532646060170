import { SHOW_ALERT, CLEAR_ALERT, SHOW_CONFIRM } from "../constants/type";
import Immutable from "immutable";

const initStatus = {
  alert: {},
  confirm: {},
};

export default function messageReducer(state = initStatus, action = {}) {
  switch (action.type) {
    case SHOW_ALERT:
      return Immutable.fromJS(state)
        .updateIn(["alert"], (v) => {
          return action;
        })
        .toJS();

    case SHOW_CONFIRM:
      return Immutable.fromJS(state)
        .updateIn(["confirm"], (v) => {
          return action;
        })
        .toJS();

    case CLEAR_ALERT:
      return initStatus;

    default:
      return state;
  }
}
