import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setCondition } from "../../actions/cache";
import { search as searchFairs } from "../../actions/fair";
import { search as searchShows } from "../../actions/show";
import Meta from "../../components/layout/Meta";
import { Link } from "../../components/util/Clickable";
import { Loading } from "../../components/util/Loading";
import {
  getPureMessage,
  IntlText,
  LinedText,
} from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";

class HistoryScreen extends Component {
  state = {
    shows: null,
    fairs: null,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.load();
    }
  };

  componentDidUpdate = () => {
    if (this.state.shows != null && this.state.fairs != null) {
      this.props.actions.setCondition(this.state);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return false;
  };

  load = () => {
    this.props.actions
      .searchFairs({
        artist_id: this.props.artist.id,
        no_artwork: true,
        all: true,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            fairs: value.data,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchShows({
        artist_id: this.props.artist.id,
        quote_count: true,
        all: true,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            shows: value.data,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  };

  renderChronology = (data, extractor) => {
    if (data == null) {
      return <Loading />;
    }
    return (
      <div className="uk-margin-small">
        {data.length > 0 ? (
          data.map((raw, index) => {
            const data = extractor(raw);
            return (
              <div key={index} data-uk-grid className="uk-grid-small">
                <div className="uk-width-ssmall">
                  <span className="uk-text-muted">{data.year}</span>
                </div>
                <div className="uk-width-expand">
                  <Link
                    className="uk-link-reset uk-underline"
                    to={data.url}
                    ga={{
                      category: "link",
                      action: "artist_history",
                      label: data.url,
                    }}
                  >
                    <span>{data.description}</span>
                  </Link>
                </div>
              </div>
            );
          })
        ) : (
          <IntlText id="lists.histories.no" />
        )}
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <Meta
          title={
            this.props.artist.name + getPureMessage("artists.history.meta")
          }
          description={this.props.artist.history}
          image={this.props.artist.thumnail_url}
        />

        <h1 className="uk-heading-mark uk-h2">
          <IntlText
            id="artists.history.title"
            values={{ name: this.props.artist.name }}
          />
        </h1>
        <div className="uk-margin-medium">
          <LinedText text={this.props.artist.history} />
        </div>
        {this.state.shows && this.state.shows.length > 0 && (
          <div className="uk-margin-medium">
            <h3 className="uk-margin-small uk-h4">
              <IntlText id="artists.history.shows" />
            </h3>
            {this.renderChronology(this.state.shows, (show) => {
              var show = Formatter.mergeDesc(
                show,
                show.show_descs,
                this.props.language.view,
              );
              return {
                year: show.start_at
                  ? moment(show.start_at).format("YYYY")
                  : "-",
                description: show.title,
                url: PathGenerator.getLocalPath(Path.shows.view.url, {
                  showId: show.id,
                }),
              };
            })}
          </div>
        )}

        {this.state.fairs && this.state.fairs.length > 0 && (
          <div className="uk-margin-medium">
            <h3 className="uk-margin-small uk-h4">
              <IntlText id="artists.history.fairs" />
            </h3>
            {this.renderChronology(this.state.fairs, (fair) => {
              var fair = Formatter.mergeDesc(
                fair,
                fair.fair_descs,
                this.props.language.view,
              );
              var url = PathGenerator.getLocalPath(Path.fairs.view.url, {
                fairId: fair.id,
              });
              if (fair.slug != null) {
                url = PathGenerator.getLocalPath(Path.original.url, {
                  fairId: fair.slug,
                });
              }
              return {
                year: fair.start_at
                  ? moment(fair.start_at).format("YYYY")
                  : "-",
                description: fair.title,
                url: url,
              };
            })}
          </div>
        )}
        {this.state.shows &&
          this.state.shows.length == 0 &&
          this.state.fairs &&
          this.state.fairs.length == 0 &&
          !this.props.artist.history && <IntlText id="lists.histories.no" />}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchShows: (...param) => dispatch(searchShows(...param)),
      searchFairs: (...param) => dispatch(searchFairs(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HistoryScreen),
);
