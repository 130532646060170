import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Logger from "../../utils/logger";

class Analytics extends Component {
  constructor(props) {
    super(props);
    Logger.init(props.auth.data);
  }

  componentDidMount = () => {
    Logger.pageview();
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.location.pathname + nextProps.location.search !=
      this.props.location.pathname + this.props.location.search
    ) {
      Logger.pageview();
    }
  };

  render() {
    return <div />;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Analytics),
);
