import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { IntlText } from "../util/Text";
import Attend from "../views/Attend";

class AttendsList extends Component {
  render = () => {
    if (this.props.attends == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.attends.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.attends.no" />
          </h3>
        </div>
      );
    }

    return (
      <div className="uk-child-width-1-3@m uk-child-width-1-2" data-uk-grid>
        {this.props.attends &&
          this.props.attends.map((attend, index) => {
            return (
              <div key={index}>
                <Attend
                  attend={attend}
                  editing={this.props.editing}
                  buttonText={this.props.buttonText}
                  onEdit={() => this.props.onEdit && this.props.onEdit(attend)}
                />
              </div>
            );
          })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AttendsList),
);
