import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Formatter } from "../../utils/formatter";
import { fullUrl, Image, imageUrl } from "../util/Image";
import { IntlText } from "../util/Text";

class Photos extends Component {
  render = () => {
    if (this.props.photos == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.photos.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.photos.no" />
          </h3>
        </div>
      );
    }
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    return (
      <div
        className="uk-grid-collapse"
        data-uk-grid
        data-uk-lightbox="animation: slide"
      >
        {this.props.photos.map((photo, index) => {
          const data = Formatter.mergeDesc(photo, photo.photo_descs, lang);
          return (
            <div className="uk-width-1-4@s uk-width-1-3" key={index}>
              <div className="uk-position-relative">
                <a
                  className="uk-display-inline-block uk-width-1-1 uk-height-medium@s uk-height-small uk-image-wrapper uk-link-reset"
                  href={fullUrl(imageUrl(data.url))}
                  data-caption={data.description}
                >
                  <Image src={data.url} alt={data.description} />
                </a>
                <div className="uk-position-small uk-position-bottom-right">
                  {this.props.onEdit && (
                    <button
                      className="uk-margin-ssmall-left uk-icon-button uk-button-muted"
                      onClick={(event) => {
                        this.props.onEdit(photo);
                      }}
                    >
                      <span data-uk-icon="icon:file-edit;"></span>
                    </button>
                  )}
                  {this.props.onDelete && (
                    <button
                      className="uk-margin-ssmall-left uk-icon-button uk-button-danger"
                      onClick={(event) => {
                        this.props.onDelete(photo);
                      }}
                    >
                      <span data-uk-icon="icon:trash;"></span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Photos));
