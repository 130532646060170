import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { logout } from "../../actions/admin/auth";
import { showConfirm } from "../../actions/message";
import { Link } from "../../components/util/Clickable";
import { Path, PathGenerator } from "../../constants/path";
import EditArticleScreen from "./articles/EditScreen";
import ArticlesScreen from "./articles/IndexScreen";
import EditNewsScreen from "./news/EditScreen";
import NewsScreen from "./news/IndexScreen";
import EditScreen from "./other/EditScreen";
import TopScreen from "./other/TopScreen";
import BannersScreen from "./banners/IndexScreen";

class BaseScreen extends PureComponent {
  logout = () => {
    this.props.actions.showConfirm("Sign Out", null, (confirmed) => {
      if (confirmed) {
        this.props.actions
          .logout()
          .then(({ value, action }) => {
            if (value.status < 300) {
              this.props.history.push(
                PathGenerator.getLocalPath(Path.admin.auth.login.url),
              );
            }
          })
          .catch((error) => console.log(error));
      }
    });
  };

  doLogout = () => {};

  renderSidebar = () => {
    return (
      <div>
        <ul className="uk-nav-default uk-list uk-list-divider" data-uk-nav>
          <li className="">
            <Link className="" to={Path.admin.top.url}>
              Dashboard
            </Link>
          </li>
          {false && (
            <li className="uk-parent">
              <a className="" href="#">
                Accounts
              </a>
              <ul className="uk-nav-sub">
                <li>
                  <Link className="" to={Path.admin.galleries.url}>
                    Galleries
                  </Link>
                </li>
                <li>
                  <Link className="" to={Path.admin.fairs.url}>
                    Fairs
                  </Link>
                </li>
              </ul>
            </li>
          )}
          <li className="uk-parent">
            <a className="" href="#">
              Media
            </a>
            <ul className="uk-nav-sub">
              <li>
                <Link className="" to={Path.admin.articles.url}>
                  Articles
                </Link>
              </li>
              {this.props.admin.role == 1 && (
                <li>
                  <Link className="" to={Path.admin.news.url}>
                    News
                  </Link>
                </li>
              )}
              <li>
                <Link className="" to={Path.admin.banners.url}>
                  Banners
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link className="" to={Path.admin.edit.url}>
              Settings
            </Link>
          </li>
          <li className="">
            <Link className="" onClick={this.logout}>
              Sign Out
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-grid-collapse uk-grid-match" data-uk-grid>
          <div
            className="uk-width-medium@m uk-background-secondary uk-light uk-visible@m"
            style={{ minHeight: "calc(100vh - 56px)" }}
          >
            <div className="uk-padding@s uk-padding-small">
              <div>{this.renderSidebar()}</div>
            </div>
          </div>
          <div className="uk-width-expand">
            <div className="uk-padding@s uk-padding-small">
              <Switch>
                <Route exact path={Path.admin.top.url} component={TopScreen} />
                <Route
                  exact
                  path={Path.admin.edit.url}
                  component={EditScreen}
                />

                <Route
                  exact
                  path={Path.admin.articles.url}
                  component={ArticlesScreen}
                />
                <Route
                  exact
                  path={Path.admin.articles.edit.url}
                  component={EditArticleScreen}
                />

                <Route
                  exact
                  path={Path.admin.news.url}
                  component={NewsScreen}
                />
                <Route
                  exact
                  path={Path.admin.news.edit.url}
                  component={EditNewsScreen}
                />
                <Route
                  exact
                  path={Path.admin.banners.url}
                  component={BannersScreen}
                />
              </Switch>
            </div>
          </div>
        </div>
        <div id="admin-menu" data-uk-offcanvas="flip: true; overlay: true">
          <div className="uk-offcanvas-bar">
            <button
              className="uk-offcanvas-close"
              type="button"
              data-uk-close
            ></button>
            {this.renderSidebar()}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    admin: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showConfirm: (...data) => dispatch(showConfirm(...data)),
      logout: () => dispatch(logout()),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseScreen),
);
