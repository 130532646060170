import React, { Component } from "react";
import Logger from "../../utils/logger";
import { getPureMessage, IntlText } from "../util/Text";

export default class TextInput extends Component {
  onFocus = (event) => {
    if (this.props.ga != null) {
      Logger.event(this.props.ga);
    } else if (this.props.meta) {
      Logger.event({
        category: this.props.meta.form,
        action: "text_input",
        label: this.props.input.name,
      });
    }
    this.props.input.onFocus && this.props.input.onFocus(event);
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;
    const label = this.props.labelId
      ? getPureMessage(this.props.labelId)
      : this.props.label;
    const placeholder = this.props.placeholder ? this.props.placeholder : label;
    return (
      <div>
        {(() => {
          if (label != null) {
            return (
              <label className="uk-form-label uk-text-nowrap">
                {label}
                <wbr />
                {(() => {
                  if (this.props.required) {
                    return (
                      <small className="uk-text-danger">
                        {" "}
                        ※<IntlText id="forms.attr.required" />
                      </small>
                    );
                  }
                })()}
              </label>
            );
          }
        })()}
        <div className="uk-form-controls">
          <input
            style={this.props.style}
            className={
              "uk-input uk-input-custom " +
              (hasError ? "uk-input-error" : null) +
              " " +
              this.props.className
            }
            {...this.props.input}
            placeholder={placeholder}
            type={this.props.type}
            autoCorrect="off"
            autoCapitalize="off"
            onFocus={this.onFocus}
          />
        </div>
        {!this.props.noError && (
          <div className="uk-text-danger " style={{ minHeight: "18px" }}>
            <small>{hasError && this.props.meta.error}</small>
          </div>
        )}
      </div>
    );
  };
}
