import Immutable from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArtworks } from "../../actions/artwork";
import { setCondition } from "../../actions/cache";
import { search as searchFairs } from "../../actions/fair";
import { search as searchGalleries } from "../../actions/gallery";
import ArtworkSearchForm from "../../components/forms/ArtworkSearchForm";
import ArtworkSortForm from "../../components/forms/ArtworkSortForm";
import Meta from "../../components/layout/Meta";
import ArtworkCards from "../../components/lists/ArtworkCards";
import { Button } from "../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../components/util/Text";
import { Formatter } from "../../utils/formatter";

class IndexScreen extends PureComponent {
  state = {
    queryParam: {
      sort_method: "recommend",
    },
    artworks: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
    artists: null,
    galleries: null,
    fairs: null,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.readMore(true);
    }
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({
        loading: true,
      });
    }

    const param = Object.assign({}, this.state.queryParam, {
      pagesize: 12,
      page: this.state.meta.current_page + 1,
      public: true,
      excludes: this.state.artworks
        ? this.state.artworks.map((a) => a.id).join(",")
        : "0",
    });

    this.props.actions.searchGalleries({ all: true }).then(({ value }) => {
      const galleries = value.data.map((gallery) => {
        const formated_gallary = Formatter.mergeDesc(
          gallery,
          gallery.gallery_descs,
          this.props.language.view,
        );
        return formated_gallary;
      });
      this.setState({
        galleries: Formatter.sortByName(galleries, "name"),
      });
      this.forceUpdate();
    });

    this.props.actions
      .searchArtworks(param)
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState(
            {
              artworks:
                this.state.artworks && !refresh
                  ? this.state.artworks.concat(value.data)
                  : value.data,
              meta: value.response.data.meta,
              loading: false,
            },
            () => {
              this.props.actions.setCondition(this.state);
            },
          );
        }
      })
      .catch((error) => console.log(error));

    this.props.actions.searchFairs().then(({ value }) => {
      const fairs = value.data.map((fair) => {
        const formated_fairs = Formatter.mergeDesc(
          fair,
          fair.fair_descs,
          this.props.language.view,
        );
        return formated_fairs;
      });
      this.setState({
        fairs: Formatter.sortByName(fairs, "title"),
      });
      this.forceUpdate();
    });
  };

  onChangeSort = (values) => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["queryParam", "sort_method"], (v) => values.sort_method)
        .updateIn(["artworks"], (v) => null)
        .updateIn(["meta"], (v) => {
          return { current_page: 0 };
        })
        .toJS(),
      () => {
        this.readMore(true);
      },
    );
  };

  onSearch = (values) => {
    values.sort_method = this.state.queryParam.sort_method;
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["queryParam"], (v) => values)
        .updateIn(["artworks"], (v) => null)
        .updateIn(["meta"], (v) => {
          return { current_page: 0 };
        })
        .toJS(),
      () => {
        this.readMore(true);
      },
    );
  };

  onSearchToggle = () => {
    this.searchForm.wrappedInstance.ref.wrappedInstance.wrapped.toggleForm();
  };

  renderSearch = () => {
    return (
      <div>
        <div className="uk-margin-small uk-flex-middle" data-uk-grid>
          <div className="uk-width-expand">
            <span className="uk-text-muted uk-h4">
              <IntlText
                id="artworks.count"
                values={{ num: this.state.meta.total }}
              />
            </span>
          </div>
          <div className="uk-width-auto">
            <button
              type="button"
              className="uk-button uk-button-muted uk-width-auto"
              onClick={this.onSearchToggle}
            >
              <IntlText id="artworks.search" />
            </button>
          </div>
        </div>
        <div className="uk-margin-small">
          <ArtworkSearchForm
            ref={(searchForm) => (this.searchForm = searchForm)}
            initialValues={this.state.queryParam}
            galleries={this.state.galleries}
            fairs={this.state.fairs}
            onSubmit={this.onSearch}
          />
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <Meta
          title={getPureMessage("artworks.title")}
          description={getPureMessage("artworks.description")}
        />
        <h1 className="uk-heading-mark uk-margin-remove">
          <IntlText id="artworks.title" />
        </h1>
        <div className="uk-margin-small">{this.renderSearch()}</div>
        <div className="uk-margin-medium">
          <div className="uk-margin uk-text-right">
            <div className="uk-display-inline-block">
              <ArtworkSortForm
                initialValues={this.state.queryParam}
                onChange={this.onChangeSort}
              />
            </div>
          </div>
          <div className="uk-margin">
            <ArtworkCards column={3} artworks={this.state.artworks} />
          </div>
          {this.state.meta.last_page &&
            this.state.meta.last_page > this.state.meta.current_page && (
              <div className="uk-margin uk-text-center">
                <Button
                  className="uk-button uk-button-default"
                  onClick={() => this.readMore(false)}
                  loading={this.state.loading ? 1 : 0}
                >
                  MORE
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      searchGalleries: (...param) => dispatch(searchGalleries(...param)),
      searchFairs: (...params) => dispatch(searchFairs(...params)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
