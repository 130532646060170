import { GET_PLACES } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";

export const getAll = () => {
  return (dispatch) => {
    return {
      type: GET_PLACES,
      payload: () => {
        return Api.get(EndPoints.places());
      },
    };
  };
};
