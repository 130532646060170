import React, { Component } from "react";
import Logger from "../../utils/logger";
import imageCompression from "browser-image-compression";
import loadImage from "blueimp-load-image";
import Api from "../../utils/api";
import { IntlText } from "../util/Text";

declare var UIkit;

export default class UploadIcon extends Component {
  state = {
    compressing: false,
  };

  componentDidMount = () => {
    this.setUpload(this.props);
  };

  componentWillReceiveProps = (nextProps) => {
    this.setUpload(nextProps);
  };

  setUpload = (props) => {
    UIkit.upload(this.uploader, {
      url: props.url,
      name: "image",
      type: "json",
      beforeAll: async (upload, files) => {
        if (process.env.REACT_APP_MODE == "production") {
          Logger.event({
            category: "uploader",
            action: "upload",
            label: props.url,
          });
        }
        this.setState({ compressing: true });
        const promise = new Promise((resolve, reject) => {
          loadImage(
            files[0],
            (canvas) => {
              imageCompression
                .getFilefromDataUrl(
                  canvas.toDataURL(),
                  files[0].name,
                  files[0].lastModified,
                )
                .then((file) => {
                  imageCompression(file, 3, 1296).then((compressedFile) => {
                    resolve(compressedFile);
                  });
                });
            },
            { orientation: true },
          );
        });
        await promise.then((file) => {
          files[0] = file;
        });
        this.setState({ compressing: false });
      },
      beforeSend: (env) => {
        const header = Api.getAuthHeader();
        env.headers = header.headers;
      },
      error: (arg) => {
        props.onError && props.onError(arg);
      },
      complete: ({ response }) => {
        const data = response.data;
        props.onSuccess && props.onSuccess(data);
      },
      loadStart: (e) => {
        if (this.progress) {
          this.progress.removeAttribute("hidden");
          this.progress.max = e.total;
          this.progress.value = e.loaded;
        }
      },

      progress: (e) => {
        if (this.progress) {
          this.progress.max = e.total;
          this.progress.value = e.loaded;
        }
      },
      loadEnd: (e) => {
        if (this.progress) {
          this.progress.max = e.total;
          this.progress.value = e.loaded;
          setTimeout(() => {
            this.progress.setAttribute("hidden", "hidden");
          }, 1000);
        }
      },
    });
  };

  render = () => {
    return (
      <div>
        <div
          ref={(uploader) => (this.uploader = uploader)}
          className="js-upload uk-placeholder uk-text-center uk-border-circle uk-position-relative"
          style={{ width: 120, height: 120 }}
        >
          <div className="uk-position-center" {...{ "uk-form-custom": "" }}>
            <input type="file" />
            <span
              data-uk-icon="icon: camera;ratio:3;"
              className="uk-text-muted-light"
            ></span>
          </div>
        </div>
        {this.state.compressing && (
          <div className="uk-text-center">
            <span className="uk-text-muted ">
              <IntlText id="util.upload_image.compressing" />
            </span>
          </div>
        )}
        <progress
          ref={(progress) => (this.progress = progress)}
          className="uk-progress"
          value="0"
          max="100"
          hidden
        ></progress>
      </div>
    );
  };
}
