import qs from "qs";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search } from "../../actions/search";
import ResultsList from "../../components/common/ResultsList";
import KeywordFilterForm from "../../components/forms/KeywordFilterForm";
import KeywordLargeForm from "../../components/forms/KeywordLargeForm";
import Meta from "../../components/layout/Meta";
import Paginate from "../../components/util/Paginate";
import { getPureMessage, IntlText } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";
import Logger from "../../utils/logger";

class SearchScreen extends PureComponent {
  state = {
    keyword: null,
    only: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    results: null,
    short: false,
  };

  componentWillMount = () => {
    this.reload(this.props);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.location.search != nextProps.location.search) {
      this.reload(nextProps);
    }
  };

  reload = (props) => {
    const param = qs.parse(props.location.search.replace("?", ""));
    var keyword = param.keyword ? param.keyword : "";
    keyword = keyword.trim();
    if (keyword == null || keyword.length <= 0) {
      return this.setState({
        keyword: keyword,
        results: null,
        short: true,
      });
    } else {
      this.setState({
        keyword: keyword,
        results: null,
        short: false,
      });
    }

    props.actions
      .search({
        keyword: keyword,
        page: param.page,
        pagesize: 24,
        only: param.only,
      })
      .then(({ value }) => {
        if (value.status > 300) {
          return;
        }
        this.setState({
          results: value.data,
          meta: value.response.data.meta,
        });
      });

    if (process.env.REACT_APP_MODE == "production") {
      Logger.event({
        category: "search-keyword",
        action: keyword,
        label: param.page ? param.page + "" : "1",
      });
    }
  };

  onChangeKeyword = (values) => {
    this.props.history.push(
      PathGenerator.getLocalPath(Path.search.url, {}, values),
    );
  };

  onChangeFilter = (values) => {
    this.setState({
      only: values.only,
    });
    values.keyword = this.state.keyword;
    this.props.history.push(
      PathGenerator.getLocalPath(Path.search.url, {}, values),
    );
  };

  render = () => {
    const param = qs.parse(this.props.location.search.replace("?", ""));
    return (
      <div className="uk-section uk-section-large">
        <div className="uk-container">
          <Meta
            title={getPureMessage("others.search.meta") + this.state.keyword}
          />
          {this.state.short ? (
            <div className="uk-text-center">
              <div className="uk-display-inline-block uk-width-xlarge@s uk-text-left">
                <h1 className="uk-text-center">
                  <IntlText
                    id="others.search.keyword"
                    values={{ keyword: this.state.keyword }}
                  />
                </h1>
                <p className="uk-text-center">
                  <IntlText id="others.search.more" />
                </p>
                <div className="uk-margin-small">
                  <KeywordLargeForm onSubmit={this.onChangeKeyword} />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="uk-margin">
                <h1 className="uk-text-muted uk-text-gothic uk-margin-remove">
                  <span className="uk-h5 uk-text-muted">
                    <IntlText id="others.search.result" />
                  </span>
                  <span>“{this.state.keyword}”</span>
                </h1>
                <span className="uk-h5 uk-text-muted uk-margin-remove">
                  <IntlText
                    id="others.search.count"
                    values={{ count: this.state.meta.total }}
                  />
                </span>
                <div className="uk-margin-small">
                  {this.state.results && (
                    <KeywordFilterForm
                      initialValues={{ only: param.only }}
                      onChange={this.onChangeFilter}
                    />
                  )}
                </div>
              </div>
              <div className="uk-margin">
                <Paginate meta={this.state.meta} />
              </div>
              <div className="uk-margin">
                <ResultsList results={this.state.results} />
              </div>
              <div className="uk-margin">
                <Paginate meta={this.state.meta} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchScreen),
);
