import { defaultLang } from "./location";

export class Formatter {
  static mergeDesc(base, descs, lang) {
    var desc = descs
      ? descs.find((desc) => {
          return desc.lang == lang;
        })
      : null;
    if (desc == null) {
      desc = descs
        ? descs.find((desc) => {
            return desc.lang == defaultLang;
          })
        : null;
    }
    if (desc == null) {
      desc = {};
    }
    return Object.assign({}, desc, base);
  }

  static defalutDesc(base, descs) {
    const desc = descs
      ? descs.find((desc) => {
          return desc.lang == defaultLang;
        })
      : {};
    return Object.assign({}, desc, base);
  }

  static pricing(price) {
    return String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }

  static comma(num) {
    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }

  static sortByName(base, value) {
    base.sort((a, b) => {
      if (a[value] < b[value]) return -1;
      if (a[value] > b[value]) return 1;
      return 0;
    });
    return base;
  }
}

export const isSameLike = (prev, next) => {
  const keys = [
    "artwork_id",
    "artist_id",
    "fair_id",
    "gallery_id",
    "article_id",
    "show_id",
  ];
  return (
    keys.find((key) => {
      return prev[key] != next[key];
    }) == null
  );
};
