import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { LikeButton } from "../util/Like";

class JamboArtist extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const artist = !this.props.artist.name
      ? Formatter.mergeDesc(
          this.props.artist,
          this.props.artist.artist_descs,
          lang,
        )
      : this.props.artist;

    var gallery =
      artist.represent_galleries && artist.represent_galleries.length > 0
        ? artist.represent_galleries[0]
        : null;
    if (gallery) {
      gallery = Formatter.mergeDesc(gallery, gallery.gallery_descs, lang);
    }

    return (
      <Link
        to={PathGenerator.getLocalPath(Path.artists.view.url, {
          artistId: artist.id,
        })}
        className="uk-link-reset uk-display-block uk-position-relative"
        ga={{
          category: "link",
          action: "artist_jambo",
          label: "artist_" + artist.id,
        }}
      >
        <div className="uk-width-1-1 uk-height-large@m uk-height-medium uk-image-wrapper">
          <Image full src={artist.thumnail_url} alt={artist.name} />
        </div>
        <div className="uk-position-bottom uk-overlay uk-overlay-gradient uk-light uk-padding-right uk-position-z-index">
          <div data-uk-grid className="uk-grid-small">
            <div className="uk-width-expand">
              <h3 className="uk-margin-remove uk-h3 uk-display-inline">
                {artist.name}
              </h3>
              {gallery && <span> | {gallery.name}</span>}
            </div>
          </div>
        </div>
        <div className="uk-position-xsmall uk-position-bottom-right">
          <LikeButton type="artist" data={artist} />
        </div>
      </Link>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JamboArtist);
