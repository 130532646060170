import moment from "moment";
import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";

class JamboArticle extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const article = !this.props.article.title
      ? Formatter.mergeDesc(
          this.props.article,
          this.props.article.article_descs,
          lang,
        )
      : this.props.article;
    return (
      <div>
        <Link
          className="uk-link-reset uk-display-block uk-background-muted"
          to={PathGenerator.getLocalPath(Path.articles.view.url, {
            articleId: article.id,
          })}
          ga={{
            category: "link",
            action: "article_jambo",
            label: "article_" + article.id,
          }}
        >
          <div data-uk-grid className="uk-grid-collapse">
            <div className="uk-width-expand">
              <div className="uk-height-large@s uk-height-medium uk-width-1-1 uk-image-wrapper">
                <Image full src={article.thumnail_url} alt={article.title} />
              </div>
            </div>

            <div className="uk-width-large@s">
              <div className="uk-padding@s uk-padding-small uk-flex-column@s">
                <h3 className="uk-margin-small">{article.title}</h3>
                {article.meta_description && (
                  <LinesEllipsis
                    text={article.meta_description}
                    maxLine="4"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                    component="p"
                    className="uk-margin-small"
                  />
                )}
                <div className="uk-margin-small">
                  <p className="uk-margin-remove uk-text-muted">
                    <small>
                      {article.posted_at
                        ? moment(article.posted_at).format("YYYY.MM.DD")
                        : "-"}
                    </small>
                  </p>
                  <p className="uk-margin-remove uk-text-muted">
                    <small>{article.editor_name}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JamboArticle);
