import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Formatter } from "../../utils/formatter";
import { Button } from "../util/Clickable";
import { IntlText } from "../util/Text";

class ArtistPart extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const artist = !this.props.artist.name
      ? Formatter.mergeDesc(
          this.props.artist,
          this.props.artist.artist_descs,
          lang,
        )
      : this.props.artist;
    return (
      <div className="uk-grid-small uk-flex-middle" data-uk-grid>
        <div className="uk-width-expand">
          <h4 className="uk-margin-remove">{artist.name}</h4>
          <p className="uk-margin-remove">{artist.birth_place}</p>
        </div>
        {this.props.editing && (
          <div className="uk-width-auto">
            <Button
              className="uk-button uk-button-default uk-button-small"
              onClick={() => {
                this.props.onClick && this.props.onClick(artist);
              }}
            >
              {this.props.buttonText ? (
                this.props.buttonText
              ) : (
                <IntlText id="forms.add" />
              )}
            </Button>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistPart),
);
