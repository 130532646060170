import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { LikeButton } from "../util/Like";
import { IntlText } from "../util/Text";

class Artist extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const artist = !this.props.artist.name
      ? Formatter.mergeDesc(
          this.props.artist,
          this.props.artist.artist_descs,
          lang,
        )
      : this.props.artist;
    return (
      <div>
        <Link
          to={PathGenerator.getLocalPath(Path.artists.view.url, {
            artistId: artist.id,
          })}
          className="uk-link-reset uk-display-block uk-position-relative uk-margin"
          ga={{
            category: "link",
            action: "artist_card",
            label: "artist_" + artist.id,
          }}
        >
          <div className="uk-margin-small uk-position-relative">
            <div className="uk-height-medium@s uk-height-small uk-image-wrapper">
              <Image src={artist.thumnail_url} alt={artist.name} />
            </div>
            {!this.props.editing && (
              <div className="uk-position-xsmall uk-position-bottom-right">
                <LikeButton type="artist" data={artist} />
              </div>
            )}
          </div>
          <h3 className="uk-margin-small">{artist.name}</h3>
        </Link>
        {this.props.editing && (
          <div className="uk-margin uk-text-center">
            <Link
              className="uk-button uk-button-default"
              to={PathGenerator.getLocalPath(Path.gallery.artists.edit.url, {
                artistId: artist.id,
              })}
            >
              <IntlText id="forms.edit" />
            </Link>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Artist);
