import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { LikeButton } from "../util/Like";
import { IntlText } from "../util/Text";
import Artwork from "../views/Artwork";

class Fair extends Component {
  renderContent = (fair, lang) => {
    var url = PathGenerator.getLocalPath(Path.fairs.view.url, {
      fairId: fair.id,
    });
    if (fair.slug != null) {
      url = PathGenerator.getLocalPath(Path.original.url, {
        fairId: fair.slug,
      });
    }
    return (
      <div className="uk-padding-right">
        <div data-uk-grid>
          <div className="uk-width-auto">
            <div className="uk-thumnail uk-image-wrapper">
              <Image src={fair.logo_url} alt={fair.title} />
            </div>
          </div>
          <div className="uk-width-expand">
            {!this.props.arrow ? (
              <Link to={url}>
                <h3 className="uk-margin-remove uk-underline">{fair.title}</h3>
              </Link>
            ) : (
              <h3 className="uk-margin-remove">{fair.title}</h3>
            )}
            <p className="uk-margin-ssmall">{fair.address}</p>
            <p className="uk-margin-ssmall">
              {moment(fair.start_at).format("YYYY.MM.DD ddd")}
              <span> - </span>
              {moment(fair.end_at).format("YYYY.MM.DD ddd")}
            </p>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const fair = !this.props.fair.title
      ? Formatter.mergeDesc(this.props.fair, this.props.fair.fair_descs, lang)
      : this.props.fair;
    return (
      <div>
        <div className="uk-margin uk-position-relative uk-padding-large-right">
          {(() => {
            if (this.props.arrow) {
              return (
                <Link
                  className="uk-link-reset uk-display-block uk-position-relative uk-hover-muted uk-hover-arrow uk-padding-small"
                  to={PathGenerator.getLocalPath(Path.fairs.view.url, {
                    fairId: fair.id,
                  })}
                  ga={{
                    category: "link",
                    action: "fair_part",
                    label: "fair_" + fair.id,
                  }}
                >
                  {this.renderContent(fair, lang)}
                </Link>
              );
            } else {
              return this.renderContent(fair, lang);
            }
          })()}
          <div className="uk-position-xsmall uk-position-bottom-right">
            <LikeButton type="fair" data={fair} />
          </div>
        </div>
        {fair.exhibitions && (
          <div className="uk-margin">
            <h4 className="uk-margin-small">
              <IntlText id="views.fair.artworks" />
            </h4>
            <div
              className="uk-margin-small uk-child-width-1-3@s uk-child-width-1-2"
              data-uk-grid
            >
              {fair.exhibitions.map((exhibition, index) => {
                return (
                  <div key={index} className={index == 2 ? "uk-visible@s" : ""}>
                    <Artwork artwork={exhibition.artwork} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fair);
