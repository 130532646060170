import ja from "../langs/ja/messages";
import en from "../langs/en/messages";
import tw from "../langs/zh-Hant/messages";
import cn from "../langs/zh-Hans/messages";
import store from "../stores";

export const getMessage = (lang) => {
  switch (lang) {
    case "ja":
      return minifiy(ja);
    // case "zh-Hant":
    // return minifiy(tw);
    // case "zh-Hans":
    // return minifiy(cn);
    default:
      return minifiy(en);
  }
};

export const getPureMessage = (key) => {
  const lang = store.getState().language.view;
  const keys = key.split(".").reverse();
  switch (lang) {
    case "ja":
      return extract(keys, ja, key);
    // case "zh-Hant":
    //   return extract(keys, tw, key);
    // case "zh-Hans":
    //   return extract(keys, cn, key);
    default:
      return extract(keys, en, key);
  }
};

const minifiy = (messages, ret = {}, prefix = "") => {
  Object.keys(messages).map((key) => {
    var value = messages[key];
    if (typeof value === "string") {
      ret["" + prefix + key] = value;
    } else {
      minifiy(value, ret, prefix + key + ".");
    }
  });
  return ret;
};

const extract = (keys, messages, allKey) => {
  if (keys.length == 0) {
    return null;
  }
  const first = keys.pop();
  const value = messages[first];
  if (typeof value === "string" || keys.length == 0) {
    return value;
  } else if (value == null) {
    return allKey;
  } else {
    return extract(keys, value, allKey);
  }
};
