import {
  GET_NEWS,
  GET_NEW,
  ADD_NEWS,
  EDIT_NEWS,
  DELETE_NEWS,
  DELETE_NEWS_IMAGE,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: GET_NEWS,
      payload: () => {
        return Api.get(EndPoints.adminNews(), param);
      },
    };
  };
};

export const get = (newsId) => {
  return (dispatch) => {
    return {
      type: GET_NEW,
      payload: () => {
        return Api.get(EndPoints.adminNews(newsId));
      },
    };
  };
};

export const add = (data) => {
  return (dispatch) => {
    return {
      type: ADD_NEWS,
      payload: () => {
        return Api.post(EndPoints.adminNews(), data);
      },
    };
  };
};

export const edit = (newsId, data) => {
  return (dispatch) => {
    return {
      type: EDIT_NEWS,
      payload: () => {
        return Api.put(EndPoints.adminNews(newsId), data);
      },
    };
  };
};

export const deleteNews = (newsId) => {
  return (dispatch) => {
    return {
      type: DELETE_NEWS,
      payload: () => {
        return Api.delete(EndPoints.adminNews(newsId));
      },
    };
  };
};

export const deleteImage = (newsId) => {
  return (dispatch) => {
    return {
      type: DELETE_NEWS_IMAGE,
      payload: () => {
        return Api.delete(EndPoints.adminNews(newsId, "image"));
      },
    };
  };
};
