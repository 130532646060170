import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArtists } from "../../actions/artist";
import { setCondition } from "../../actions/cache";
import Artist from "../../components/details/Artist";
import Meta from "../../components/layout/Meta";
import { Button, Link } from "../../components/util/Clickable";
import { Loading } from "../../components/util/Loading";
import { getPureMessage, IntlText } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";

class ArtistsScreen extends PureComponent {
  state = {
    represented: null,
    availables: null,
    artists: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.load();
    }
  };

  componentDidUpdate = () => {
    if (
      this.state.represented != null &&
      this.state.availables != null &&
      this.state.artists != null
    ) {
      this.props.actions.setCondition(this.state);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.state.loading != nextState.loading;
  };

  load = () => {
    this.props.actions
      .searchArtists({
        gallery_id: this.props.gallery.id,
        treat: "represented",
      })
      .then(({ value }) => {
        this.setState({
          represented: value.data,
        });
        this.forceUpdate();
      });

    this.props.actions
      .searchArtists({
        gallery_id: this.props.gallery.id,
        all: true,
      })
      .then(({ value }) => {
        this.setState({
          availables: value.data,
        });
        this.forceUpdate();
      });

    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .searchArtists({
        gallery_id: this.props.gallery.id,
        page: this.state.meta.current_page + 1,
        pagesize: 8,
        artworks: 3,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            artists:
              this.state.artists && !refresh
                ? this.state.artists.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <Meta
          title={
            getPureMessage("galleries.artists.meta") + this.props.gallery.name
          }
          description={this.props.gallery.description}
          image={this.props.gallery.thumnail_url}
        />
        <div className="uk-margin-large" data-uk-grid>
          <div className="uk-width-1-2@s uk-separator@s">
            <h1 className="uk-heading-mark uk-h2">
              <IntlText
                id="galleries.artists.represents"
                values={{ name: this.props.gallery.name }}
              />
            </h1>
            <div className="uk-margin uk-height-medium@s uk-height-small uk-overflow-auto">
              {(() => {
                if (!this.state.represented) {
                  return <Loading />;
                } else {
                  return (
                    <div
                      className="uk-grid-small uk-child-width-1-2@s"
                      data-uk-grid
                    >
                      {this.state.represented.map((artist, index) => {
                        var artist = Formatter.mergeDesc(
                          artist,
                          artist.artist_descs,
                          this.props.language.view,
                        );
                        return (
                          <div key={index}>
                            <Link
                              className="uk-link-muted"
                              to={PathGenerator.getLocalPath(
                                Path.artists.view.url,
                                { artistId: artist.id },
                              )}
                              ga={{
                                category: "link",
                                action: "gallery_artists",
                                label: "artist_" + artist.id,
                              }}
                            >
                              {artist.name}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })()}
            </div>
          </div>
          <div className="uk-width-1-2@s">
            <h2 className="uk-heading-mark">
              <IntlText
                id="galleries.artists.available"
                values={{ name: this.props.gallery.name }}
              />
            </h2>
            <div className="uk-margin uk-height-medium@s uk-height-small uk-overflow-auto">
              {(() => {
                if (!this.state.availables) {
                  return <Loading />;
                } else {
                  return (
                    <div
                      className="uk-grid-small uk-child-width-1-2@s"
                      data-uk-grid
                    >
                      {this.state.availables.map((artist, index) => {
                        var artist = Formatter.mergeDesc(
                          artist,
                          artist.artist_descs,
                          this.props.language.view,
                        );
                        return (
                          <div key={index}>
                            <Link
                              className="uk-link-muted"
                              to={PathGenerator.getLocalPath(
                                Path.artists.view.url,
                                { artistId: artist.id },
                              )}
                              ga={{
                                category: "link",
                                action: "gallery.artists",
                                label: "artist_" + artist.id,
                              }}
                            >
                              {artist.name}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
        {this.state.artists &&
          this.state.artists.map((artist, index) => {
            return (
              <div key={index} className="uk-margin-large">
                <div className="uk-chip" />
                <div className="uk-margin-medium">
                  <Artist artist={artist} />
                </div>
              </div>
            );
          })}
        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-medium uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
                ga={{
                  category: "button",
                  action: "gallery_artists",
                  label: "more",
                }}
              >
                <IntlText id="forms.more" />
              </Button>
            </div>
          )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchArtists: (...param) => dispatch(searchArtists(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistsScreen),
);
