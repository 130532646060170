const messages = {
  alert: {
    ok: "OK",
  },
  confirm: {
    ok: "OK",
    cancel: "Cancle",
    delete: "Delete",
    title: {
      delete: "Do you realy delete?",
    },
  },
  success: {
    add: "Add Succeeded",
    update: "Update Succeeded",
    delete: "Delete Succeeded",
    get: "Get Succeeded",
  },
  error: {
    add: "Add Failed",
    update: "Update Failed",
    delete: "Delete Failed",
    get: "Get Failed",
  },
  upload_image: {
    drop: "Drop new image",
    or: "or",
    select: "Select.",
    compressing: "Compressing ...",
  },
};

export default messages;
