import Immutable from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { deletePhoto, edit, search } from "../../../actions/fair/photo";
import { showConfirm } from "../../../actions/message";
import PhotoForm from "../../../components/forms/PhotoForm";
import UploadImage from "../../../components/inputs/UploadImage";
import PhotoList from "../../../components/lists/Photos";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Button } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import { EndPoints } from "../../../constants/endpoints";
import { Formatter } from "../../../utils/formatter";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class IndexScreen extends PureComponent {
  state = {
    photos: null,
    editing: {
      id: null,
      description: null,
    },
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        fair_id: this.props.fair.id,
        page: this.state.meta.current_page + 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            photos:
              this.state.photos && !refresh
                ? this.state.photos.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onUploaded = (data) => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["photos"], (v) => {
          return [data].concat(this.state.photos);
        })
        .toJS(),
    );
    Notification.successIntl("util.success.update");
  };

  onUploadError = () => {
    Notification.successIntl("util.error.update");
  };

  onEdit = (photo) => {
    var data = Formatter.mergeDesc(
      photo,
      photo.photo_descs,
      this.props.language.edit,
    );
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["editing", "id"], (v) => data.id)
        .updateIn(["editing", "description"], (v) => data.description)
        .toJS(),
      () => {
        UIkit.modal(this.editModal, { container: false }).show();
      },
    );
  };

  onEdited = (data) => {
    UIkit.modal(this.editModal).hide();
    Notification.successIntl("util.success.update");
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["photos"], (v) => {
          return this.state.photos.map((photo) => {
            if (photo.id != data.id) {
              return photo;
            } else {
              return data;
            }
          });
        })
        .toJS(),
    );
  };

  onDelete = (photo) => {
    this.props.actions.showConfirm(
      <IntlText id="util.confirm.title.delete" />,
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }

        this.props.actions
          .deletePhoto(photo.id)
          .then(({ value }) => {
            if (value.status < 300) {
              this.setState({
                photos: this.state.photos.filter((at) => {
                  return at.id != photo.id;
                }),
              });
              Notification.successIntl("util.success.delete");
            }
          })
          .catch((error) => console.log(error));
      },
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-small" data-uk-grid>
          <div className="uk-width-expand">
            <h1>
              <IntlText id="fair.photos.title" />
            </h1>
          </div>
          <div className="uk-width-medium@s uk-width-1-1 uk-text-right">
            <ChangeEditLang />
          </div>
        </div>
        <div className="uk-margin-medium">
          <UploadImage
            url={EndPoints.fairPhotos()}
            onSuccess={this.onUploaded}
            onError={this.onUploadError}
          />
        </div>
        <div className="uk-margin-medium">
          <PhotoList
            photos={this.state.photos}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
          />
        </div>
        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-medium uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
              >
                <IntlText id="forms.more" />
              </Button>
            </div>
          )}

        <div ref={(editModal) => (this.editModal = editModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-margin-auto-vertical uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h3>
                <IntlText id="fair.photos.edit" />
              </h3>
            </div>
            <div className="uk-modal-body">
              <PhotoForm
                onSubmit={(values) =>
                  this.props.actions.edit(this.state.editing.id, values)
                }
                onSuccess={this.onEdited}
                initialValues={this.state.editing}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    fair: state.auth.data ? state.auth.data : {},
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
      edit: (...param) => dispatch(edit(...param)),
      deletePhoto: (...param) => dispatch(deletePhoto(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
