import {
  ADD_LIKE,
  CLEAR_LIKE,
  SET_LIKES,
  CHECK_LIKES,
} from "../constants/type";
import Immutable from "immutable";
import { isSameLike } from "../utils/formatter";

const initStatus = {
  data: [],
  unchecked: false,
};

export default function likeReducer(state = initStatus, action = {}) {
  switch (action.type) {
    case ADD_LIKE:
      return Immutable.fromJS(state)
        .updateIn(["data"], (v) => {
          const result = state.data.find((favo) => {
            return isSameLike(favo, action.like);
          });
          if (!result) {
            state.data.push(action.like);
          }
          return state.data;
        })
        .updateIn(["unchecked"], (v) => true)
        .toJS();

    case CLEAR_LIKE:
      return Immutable.fromJS(state)
        .updateIn(["data"], (v) => {
          return state.data.filter((favo) => {
            return !isSameLike(favo, action.like);
          });
        })
        .toJS();

    case SET_LIKES:
      return Immutable.fromJS(state)
        .updateIn(["data"], (v) => {
          return action.likes;
        })
        .toJS();

    case CHECK_LIKES:
      return Immutable.fromJS(state)
        .updateIn(["unchecked"], (v) => false)
        .toJS();

    default:
      return state;
  }
}
