import React, { PureComponent } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { statuses } from "../../../constants/option";
import DatePicker from "../../inputs/DatePicker";
import Select from "../../inputs/Select";
import Textarea from "../../inputs/Textarea";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import { IntlText } from "../../util/Text";
import * as validate from "../../util/Validator";

declare var UIkit;
const relates = ["show_id", "artwork_id", "fair_id"];

class InformationForm extends PureComponent {
  state = {
    loading: false,
    mode: null,
  };

  componentDidMount = () => {
    UIkit.tab(this.tab).show(0);
    this.setState({ mode: relates[0] });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.initialValues != null) {
      relates.forEach((relate, index) => {
        if (nextProps.initialValues[relate] != null) {
          UIkit.tab(this.tab).show(index);
          this.setState({ mode: relate });
        }
      });
    }
  };

  onChange = (mode) => {
    this.setState({ mode: mode });
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    relates.forEach((relate) => {
      if (this.state.mode != relate) {
        values[relate] = null;
      }
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <h3>
          <IntlText id="forms.attr.information.basic" />
        </h3>
        <div className="uk-margin">
          <div className="uk-margin-ssmall">
            <Field
              name="title"
              type="text"
              component={TextInput}
              labelId="forms.attr.title"
              validate={[validate.required]}
              required
            />
          </div>

          <div className="uk-margin-ssmall uk-child-width-1-2@s" data-uk-grid>
            <div>
              <Field
                name="posted_at"
                component={DatePicker}
                labelId="forms.attr.posted_at"
                validate={[validate.required]}
                required
              />
            </div>
            <div>
              <Field
                name="status"
                component={Select}
                labelId="forms.attr.publish_status"
                validate={[validate.required]}
                required
                options={statuses}
              />
            </div>
          </div>

          <div className="uk-margin-ssmall">
            <Field
              name="description"
              component={Textarea}
              labelId="forms.attr.information.description"
            />
          </div>
        </div>

        <h3>
          <IntlText id="forms.attr.information.related" />
        </h3>
        <div className="uk-margin">
          <ul
            ref={(tab) => (this.tab = tab)}
            className="uk-child-width-expand"
            {...{ "uk-tab": "" }}
          >
            <li>
              <a onClick={() => this.onChange("show_id")} href="#">
                <IntlText id="word.show" />
              </a>
            </li>
            <li>
              <a onClick={() => this.onChange("artwork_id")} className="#">
                <IntlText id="word.artwork" />
              </a>
            </li>
            <li>
              <a onClick={() => this.onChange("fair_id")} className="#">
                <IntlText id="word.fair" />
              </a>
            </li>
          </ul>
          <ul className="uk-switcher uk-margin-small">
            <li>
              <Field
                ref={(showField) => (this.showField = showField)}
                name="show_id"
                component={Select}
                labelId="word.show"
                options={this.props.shows}
              />
            </li>
            <li>
              <Field
                name="artwork_id"
                component={Select}
                labelId="word.artwork"
                options={this.props.artworks}
              />
            </li>
            <li>
              <Field
                name="fair_id"
                component={Select}
                labelId="word.fair"
                options={this.props.fairs}
              />
            </li>
          </ul>
        </div>

        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "gallery-information-form",
  enableReinitialize: true,
})(InformationForm);
