const messages = {
  menu: {
    signin: "ログイン",
    mypage: "マイページ",
    likes: "Likes",
    signout: "ログアウト",
  },
  auth: {
    login: {
      header: "Collector's Login",
      ifyouforget: "パスワードをお忘れの場合は、",
      resethere: "こちらからリセットしてください。",
      to_register: "新規登録はこちら",
      agree: "同意する",
    },
    register: {
      header: "新規登録",
      back: "ログイン",
      have: "アカウントをお持ちの方はこちら",
    },
    reset: {
      header: "パスワードリセット",
      back: "ログイン画面へ戻る",
      completed: "リセットが完了しました。",
      please: "新しいパスワードで再度ログインしてください。",
    },
    forget: {
      header: "パスワードリセット",
      message:
        "ご登録いただいたメールアドレスを入力してください。メールが届かなかった場合は、異なるメールアドレスでのご登録がないかご確認ください。",
      back: "ログイン画面へ戻る",
      completed: "リセット用メールを送信しました",
      please:
        "ご入力されたメールアドレスにリセット用リンクを送信しました。記載された内容に従って、パスワードの再設定を行ってください。メールが届かなかった場合は、異なるメールアドレスでのご登録がないかご確認ください。",
    },
    thanks: {
      header: "ご登録ありがとうございます",
      back: "トップへ戻る",
      lead: "ご登録のメールアドレスに本登録用メールを送信しました。メールの内容を確認し、本登録へお進みください。",
    },
    validate: {
      header: "アカウント設定",
      completed: "アカウント登録が完了しました",
    },
  },
  title: "マイページ",
  mypage: {
    profile: "プロフィール",
    authorization: "アカウント設定",
    likes: "Likes",
    ifwantunsubscribe: "解約をご希望の方は、",
    contact: "お問い合わせフォームよりご連絡ください。",
  },
  edit_auth: {
    title: "アカウント設定",
  },
  edit: {
    title: "プロフィール",
  },
};

export default messages;
