import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import ClearInput from "../inputs/ClearInput";
import { Button } from "../util/Clickable";

class KeywordLargeForm extends PureComponent {
  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        action={this.props.action}
        method="post"
      >
        <div className="uk-grid-collapse uk-flex-middle" data-uk-grid>
          <div className="uk-width-expand">
            <Field
              name="keyword"
              type="text"
              component={ClearInput}
              className="uk-input-keyword uk-form-large"
              placeholder="Please input keyword"
            />
          </div>
          <div className="uk-width-ssmall">
            <Button
              className="uk-button uk-button-large uk-button-primary uk-width-1-1 uk-padding-remove"
              type="submit"
              ga={{ category: this.props.form, action: "submit" }}
            >
              <span data-uk-icon="icon:search;" />
            </Button>
          </div>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "keyword-large-form",
  enableReinitialize: true,
})(KeywordLargeForm);
