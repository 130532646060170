import qs from "qs";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { validate } from "../../../actions/collector/auth";
import { showAlert } from "../../../actions/message";
import logo from "../../../assets/images/logo/black.png";
import ValidateForm from "../../../components/forms/ValidateForm";
import Meta from "../../../components/layout/Meta";
import { getPureMessage, IntlText } from "../../../components/util/Text";
import { Path, PathGenerator } from "../../../constants/path";
import Logger from "../../../utils/logger";

class ValidateScreen extends PureComponent {
  state = {
    init_token: "",
    email: "",
  };

  componentWillMount = () => {
    const param = qs.parse(this.props.location.search.replace("?", ""));
    this.setState({
      init_token: param.init_token,
      email: param.email,
    });
  };

  onSubmit = (values) => {
    return this.props.actions.validate(values);
  };

  onSuccess = (data) => {
    Logger.event({
      category: "register",
      action: "validate",
      label: window.location.href,
    });
    this.props.actions.showAlert(
      <IntlText id="collector.auth.validate.completed" />,
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }
        this.props.history.push(
          PathGenerator.getLocalPath(Path.collector.top.url),
        );
      },
    );
  };

  render = () => {
    return (
      <div>
        <Meta title={getPureMessage("collector.auth.validate.header")} />
        <div className="uk-text-center">
          <img src={logo} className="uk-width-medium" />
        </div>
        <h1 className="uk-text-center">
          <IntlText id="collector.auth.validate.header" />
        </h1>
        <div className="uk-margin-large uk-text-center">
          <div className="uk-width-large@s uk-text-left uk-display-inline-block">
            <ValidateForm
              initialValues={this.state}
              onSubmit={this.onSubmit}
              onSuccess={this.onSuccess}
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      validate: (data) => dispatch(validate(data)),
      showAlert: (...param) => dispatch(showAlert(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ValidateScreen),
);
