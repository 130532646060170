import { SHOW_ALERT, SHOW_CONFIRM, CLEAR_ALERT } from "../constants/type";

export const showAlert = (title, message, callback = null, ok = null) => {
  return {
    type: SHOW_ALERT,
    title: title,
    message: message,
    ok: ok,
    callback: callback,
  };
};

export const clearAlert = () => {
  return {
    type: CLEAR_ALERT,
  };
};

export const showConfirm = (
  title,
  message,
  callback = null,
  ok = null,
  cancel = null,
) => {
  return {
    type: SHOW_CONFIRM,
    title: title,
    message: message,
    ok: ok,
    cancel: cancel,
    callback: callback,
  };
};
