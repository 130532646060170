import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { checkLikes } from "../../actions/like";
import LikesList from "../../components/common/LikesList";
import KeywordFilterForm from "../../components/forms/KeywordFilterForm";
import Meta from "../../components/layout/Meta";
import { getPureMessage, IntlText } from "../../components/util/Text";

class LikesScreen extends PureComponent {
  state = {
    only: null,
  };

  componentWillMount = () => {
    this.props.actions.checkLikes();
  };

  onChangeFilter = (values) => {
    this.setState({
      only: values.only,
    });
  };

  render = () => {
    return (
      <div className="uk-section uk-section-large">
        <div className="uk-container">
          <Meta title={getPureMessage("others.likes.title")} />
          <h1 className="uk-heading-mark uk-margin-remove">
            <IntlText id="others.likes.title" />
          </h1>
          <div className="uk-margin-small">
            <KeywordFilterForm onChange={this.onChangeFilter} />
          </div>
          <div className="uk-margin-medium">
            <LikesList only={this.state.only} results={this.props.like.data} />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    like: state.like,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      checkLikes,
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LikesScreen),
);
