const messages = {
  fairs: {
    no: "該当のフェアはありません。",
  },
  informations: {
    no: "新着情報はありません。",
    post_date: "投稿日",
    thumnail: "トップ画像",
    description: "詳細",
    action: "アクション",
  },
  sales: {
    no: "売上情報はありません。",
    date: "日付",
    count: "件数",
    sales: "売上($)",
    revenue: "利益($)",
  },
  purchases: {
    no: "購入情報はありません",
    date: "日付",
    artwork: "作品",
    collector: "コレクター",
    messages: "メッセージ",
    from_collector: "コレクターから",
    from_gallery: "ギャラリーから",
    confirm: "確定・拒否",
  },
  articles: {
    no: "記事はありません。",
    post_date: "投稿日",
    thumnail: "トップ画像",
    description: "詳細",
    action: "アクション",
    public: "公開",
    private: "非公開",
  },
  shows: {
    no: "展示はありません",
  },
  artworks: {
    no: "作品はありません",
  },
  artists: {
    no: "アーティスト情報はありません",
  },
  attends: {
    no: "出展情報はありません",
  },
  galleries: {
    no: "ギャラリーはありません",
  },
  histories: {
    no: "データがありません",
  },
  results: {
    no: "見つかりませんでした",
  },
  news: {
    no: "ニュースはありません",
  },
  likes: {
    no: "お気に入りした項目はまだありません。",
  },
  photos: {
    no: "写真はまだありません。",
  },
  banners: {
    no: "バナーはありません。",
  },
};

export default messages;
