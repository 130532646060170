import {
  GET_SECTIONS,
  ADD_SECTION,
  EDIT_SECTION,
  DELETE_SECTION,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const getAll = () => {
  return (dispatch) => {
    return {
      type: GET_SECTIONS,
      payload: () => {
        return Api.get(EndPoints.fairSections());
      },
    };
  };
};

export const add = (param) => {
  return (dispatch) => {
    return {
      type: ADD_SECTION,
      payload: () => {
        return Api.post(EndPoints.fairSections(), param);
      },
    };
  };
};

export const edit = (sectionId, param) => {
  return (dispatch) => {
    return {
      type: EDIT_SECTION,
      payload: () => {
        return Api.put(EndPoints.fairSections(sectionId), param);
      },
    };
  };
};

export const deleteSection = (sectionId) => {
  return (dispatch) => {
    return {
      type: DELETE_SECTION,
      payload: () => {
        return Api.delete(EndPoints.fairSections(sectionId));
      },
    };
  };
};
