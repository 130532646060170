import moment from "moment";
import React, { Component } from "react";
import NativeDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Logger from "../../utils/logger";
import { getPureMessage, IntlText } from "../util/Text";

export default class DatetimePicker extends Component {
  onClear = () => {
    if (this.props.ga != null) {
      Logger.event(this.props.ga);
    } else if (this.props.meta) {
      Logger.event({
        category: this.props.meta.form,
        action: "date_picker_clear",
        label: this.props.input.name,
      });
    }

    this.props.input.onChange(null);
  };

  onChange = (moment) => {
    if (process.env.REACT_APP_MODE == "production") {
      if (this.props.ga != null) {
        Logger.event(this.props.ga);
      } else if (this.props.meta) {
        Logger.event({
          category: this.props.meta.form,
          action: "date_picker",
          label: this.props.input.name,
        });
      }
    }
    if (moment) {
      this.props.input.onChange(moment.format("YYYY-MM-DD HH:mm:00"));
    }
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;

    const label = this.props.labelId
      ? getPureMessage(this.props.labelId)
      : this.props.label;
    const placeholder = this.props.placeholder ? this.props.placeholder : label;

    return (
      <div>
        {(() => {
          if (label != null) {
            return (
              <label className="uk-form-label uk-text-nowrap">
                {label}
                <wbr />
                {(() => {
                  if (this.props.required) {
                    return (
                      <small className="uk-text-danger">
                        {" "}
                        ※<IntlText id="forms.attr.required" />
                      </small>
                    );
                  }
                })()}
              </label>
            );
          }
        })()}
        <div className="uk-form-controls uk-position-relative">
          <NativeDatePicker
            style={this.props.style}
            className={
              "uk-input uk-input-custom " + (hasError ? "uk-input-error" : null)
            }
            {...this.props.input}
            showYearDropdown
            showMonthDropdown
            onChange={this.onChange}
            showTimeSelect
            dateFormat="Ss"
            timeFormat="HH:mm"
            selected={
              this.props.input.value ? moment(this.props.input.value) : null
            }
            placeholderText={placeholder}
          />
          {this.props.input.value && (
            <span
              onClick={this.onClear}
              className="uk-position-center-right uk-position-small"
              data-uk-icon="icon:close; ratio: 0.6;"
            ></span>
          )}
        </div>
        {hasError && (
          <small className="uk-text-danger ">{this.props.meta.error}</small>
        )}
      </div>
    );
  };
}
