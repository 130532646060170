import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import { bindActionCreators } from "redux";
import { get } from "../../../actions/gallery";
import { registerCard } from "../../../actions/gallery/index";
import { showAlert } from "../../../actions/message";
import logo from "../../../assets/images/logo/black.png";
import CardForm from "../../../components/forms/CardForm";
import Meta from "../../../components/layout/Meta";
import { Loading } from "../../../components/util/Loading";
import { Formatter } from "../../../utils/formatter";

class RegisterCardScreen extends PureComponent {
  state = {
    gallery: null,
  };

  componentWillMount = () => {
    this.props.actions
      .get(this.props.match.params.galleryId)
      .then(({ value }) => {
        if (value.status > 300) {
          return this.setState({
            gallery: false,
          });
        }
        this.setState({
          gallery: value.data,
        });
      });
  };

  onSubmit = (values) => {
    return this.props.actions.registerCard(
      this.props.match.params.galleryId,
      values,
    );
  };

  onSuccess = (data) => {
    this.props.actions.showAlert(
      "ありがとうございます",
      <span>
        申し込みを受け付けました。
        <br />
        今後ともご利用どうぞ宜しくお願いします。
      </span>,
    );
  };

  render = () => {
    return (
      <div className="uk-text-center">
        <Meta title={"ギャラリー様向けキャンペーン応募フォーム"} />
        <div className="uk-width-xxlarge@s uk-text-left uk-display-inline-block">
          <div className="uk-text-center">
            <img src={logo} className="uk-width-medium" />
          </div>
          <h1 className="uk-text-center">
            リリースキャンペーン
            <br />
            応募フォーム
          </h1>
          {(() => {
            if (this.state.gallery == null) {
              return <Loading />;
            } else if (this.state.gallery == false) {
              return (
                <div className="uk-margin-large">
                  <p className="uk-h3 uk-text-center uk-text-danger">
                    URLを確認してください
                  </p>
                </div>
              );
            } else {
              const gallery = Formatter.mergeDesc(
                this.state.gallery,
                this.state.gallery.gallery_descs,
                "ja",
              );
              return (
                <div className="uk-margin-large">
                  <p className="uk-h3 uk-text-center">{gallery.name} 様</p>
                  <div className="uk-margin-medium">
                    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                      <Elements>
                        <CardForm
                          onSubmit={this.onSubmit}
                          onSuccess={this.onSuccess}
                        />
                      </Elements>
                    </StripeProvider>
                  </div>
                </div>
              );
            }
          })()}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showAlert: (title, message) => dispatch(showAlert(title, message)),
      registerCard: (...param) => dispatch(registerCard(...param)),
      get: (param) => dispatch(get(param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterCardScreen),
);
