import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArticles } from "../actions/article";
import { search as searchArtists } from "../actions/artist";
import { search as searchArtworks } from "../actions/artwork";
import { setCondition } from "../actions/cache";
import { search as searchFair } from "../actions/fair";
import { search as searchNews } from "../actions/news";
import { search as searchShows } from "../actions/show";
import { search as searchBanner } from "../actions/banner";
import new_top from "../assets/images/top/about/new_top.jpg";
import new_top_sp from "../assets/images/top/about/new_top_sp.jpg";
import Meta from "../components/layout/Meta";
import ArticleCards from "../components/lists/ArticleCards";
import ArtworkCards from "../components/lists/ArtworkCards";
import NewsCards from "../components/lists/NewsCards";
import ShowsList from "../components/lists/ShowCards";
import { Link } from "../components/util/Clickable";
import Slider from "../components/util/Slider";
import { IntlText, ViewAllHeader } from "../components/util/Text";
import { Image } from "../components/util/Image";
import Fair from "../components/views/Fair";
import { Path, PathGenerator } from "../constants/path";
import { Formatter } from "../utils/formatter";

/**
 * Topページに関するコンポーネント(url: /)
 */
class RootScreen extends Component {
  state = {
    fairs: null,
    news: null,
    artworks: null,
    artists: null,
    banners: [],
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.reload();
    }
  };

  componentDidUpdate = () => {
    if (
      this.state.fairs != null &&
      this.state.news != null &&
      this.state.artworks != null &&
      this.state.artists != null
    ) {
      this.props.actions.setCondition(this.state);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return false;
  };

  reload = () => {
    this.props.actions
      .searchBanner()
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            banners: value.data,
          });
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchNews({
        pagesize: 3,
        sort: "posted_at",
        order: "desc",
      })
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            news: value.data,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchFair({
        latest: true,
        pagesize: 3,
        sort: "start_at",
        order: "desc",
        no_artwork: true,
      })
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            fairs: value.data,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchArtworks({
        pagesize: 12,
        sort_method: "random_open",
        price: { min: "925" },
      })
      .then(({ value }) => {
        if (value.status < 300) {
          if (value.data.length >= 12) {
            this.setState({
              artworks: value.data,
            });
            this.forceUpdate();
          } else {
            this.props.actions
              .searchArtworks({
                pagesize: 12 - value.data.length,
                sort_method: "random_notopen",
              })
              .then((nextResult) => {
                if (nextResult.value.status < 300) {
                  this.setState({
                    artworks: value.data.concat(nextResult.value.data),
                  });
                  this.forceUpdate();
                }
              })
              .catch((error) => console.log(error));
          }
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchArtists({
        pagesize: 9,
        belongs: true,
        sort_method: "priority",
      })
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            artists: value.data,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchShows({
        pagesize: 4,
        latest: true,
      })
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            shows: value.data,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchArticles({
        pagesize: 3,
      })
      .then(({ value }) => {
        if (value.status < 300) {
          this.setState({
            articles: value.data,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  };

  // link はオプショナル。指定しない場合はリンクが付与されない画像のみのバナーが返却される
  TopBanner = ({ image, link = { url: null, gaLabel: null } }) => {
    const { pc, sp, alt } = image;
    const { url, gaLabel } = link;

    const ga = gaLabel
      ? {
          category: "link",
          action: "root",
          label: gaLabel,
        }
      : null;

    const content = (
      <div className="uk-height-xlarge@s uk-height-large">
        {pc[0] === '/'? <img className="uk-visible@s" style={{'width': '100%', 'height': '100%', 'object-fit': 'cover'}} src={pc} alt={alt} />: <Image className="uk-visible@s" style={{'width': '100%', 'height': '100%', 'object-fit': 'cover'}} src={pc} alt={alt} />}
        {sp[0] === '/'? <img className="uk-hidden@s" style={{'width': '100%', 'height': '100%', 'object-fit': 'cover'}} src={sp} alt={alt} />: <Image className="uk-hidden@s" style={{'width': '100%', 'height': '100%', 'object-fit': 'cover'}} src={sp} alt={alt} noSmall />}
      </div>
    );

    return (
      <div>
        {url ?
          url.includes('http')? (
          <a
            className="uk-display-block uk-link-reset uk-position-relative uk-visible-toggle"
            href={url}
            target="_blank"
            rel="noopener"
          >
            {content}
          </a>) :
          (
          <Link
            className="uk-display-block uk-link-reset uk-position-relative uk-visible-toggle"
            to={url}
            ga={ga}
          >
            {content}
          </Link>)
         : (
          <React.Fragment>{content}</React.Fragment>
        )}
      </div>
    );
  };

  render = () => {
    // 単一バナーのみ表示する場合、無限スライドしたいので、同じオブジェクトをもう一個いれておく
    // Sliderコンポーネント内でも2回ループを回しているので、ここで2要素定義すると最終的に4要素描画される
    // FIXME: Sliderコンポーネントのリファクタと、optionプロパティで無限ループするように指定できるようなインタフェースを用意して、こっちで個数を調整しなくて済むようにしたい
    let sliders = [];
    for (const banner of this.state.banners) {
      var banner_image = {
        image: {
          pc: banner.pc_thumnail_url,
          sp: banner.sp_thumnail_url,
          alt: banner.alt,
        },
        link: {
          url: banner.site_url,
          gaLabel: banner.label,
        },
      };
      sliders.push(banner_image);
    };
    sliders.push({
      image: {
        pc: new_top,
        sp: new_top_sp,
        alt: "ART SCENES アートシーンズ",
      },
    });
    if (sliders.length == 1) {
      sliders.push(sliders[0]);
    }

    return (
      <div>
        <Meta />
        <Slider contents={sliders.map(this.TopBanner)} />
        <div className="uk-section">
          <div className="uk-container">
            <h2 className="uk-heading-mark">
              <IntlText id="root.news" />
            </h2>
            <div className="uk-margin">
              <NewsCards news={this.state.news} />
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default uk-button-plus"
                to={Path.news.url}
                ga={{ category: "link", action: "root", label: "all_news" }}
              >
                <IntlText id="word.view_all_text" />
              </Link>
            </div>
          </div>
        </div>
        <div className="uk-section">
          <div className="uk-container">
            <div className="uk-margin">
              <ViewAllHeader titleId="root.artworks" label="artworks" />
            </div>
            <div className="uk-margin">
              <ArtworkCards artworks={this.state.artworks} />
            </div>
            <div className="uk-chip" />
            <div
              className="uk-margin uk-child-width-1-3@s uk-child-width-1-2"
              data-uk-grid
            >
              {this.state.artists &&
                this.state.artists.map((artist, index) => {
                  var artist = Formatter.mergeDesc(
                    artist,
                    artist.artist_descs,
                    this.props.language.view,
                  );
                  return (
                    <div key={index}>
                      <Link
                        className="uk-button uk-button-text uk-text-left"
                        to={PathGenerator.getLocalPath(Path.artists.view.url, {
                          artistId: artist.id,
                        })}
                      >
                        {artist.name}
                      </Link>
                    </div>
                  );
                })}
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default uk-button-plus"
                to={Path.artworks.url}
                ga={{ category: "link", action: "root", label: "all_artworks" }}
              >
                <IntlText id="word.view_all_text" />
              </Link>
            </div>
          </div>
        </div>
        {this.state.fairs && this.state.fairs.length > 0 && (
          <div className="uk-section">
            <div className="uk-container">
              <h2 className="uk-heading-mark">
                <IntlText id="root.fairs" />
              </h2>
              {this.state.fairs.map((fair, index) => {
                return (
                  <div key={index} className="uk-margin-medium">
                    <Fair fair={fair} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="uk-section">
          <div className="uk-container">
            <div className="uk-margin">
              <ViewAllHeader titleId="root.shows" label="shows" />
            </div>
            <div className="uk-margin-medium">
              <ShowsList shows={this.state.shows} />
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default uk-button-plus"
                to={Path.shows.url}
                ga={{ category: "link", action: "root", label: "all_shows" }}
              >
                <IntlText id="word.view_all_text" />
              </Link>
            </div>
          </div>
        </div>
        <div className="uk-section">
          <div className="uk-container">
            <div className="uk-margin">
              <ViewAllHeader titleId="root.articles" label="articles" />
            </div>
            <div className="uk-margin-medium">
              <ArticleCards articles={this.state.articles} />
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default uk-button-plus"
                to={Path.articles.url}
                ga={{ category: "link", action: "root", label: "all_articles" }}
              >
                <IntlText id="word.view_all_text" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchFair: (...param) => dispatch(searchFair(...param)),
      searchNews: (...param) => dispatch(searchNews(...param)),
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      searchArtists: (...param) => dispatch(searchArtists(...param)),
      searchShows: (...param) => dispatch(searchShows(...param)),
      searchArticles: (...param) => dispatch(searchArticles(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
      searchBanner: (...param) => dispatch(searchBanner(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RootScreen),
);
