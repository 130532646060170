import qs from "qs";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { get } from "../../actions/artwork";
import Artwork from "../../components/details/Artwork";
import { Link } from "../../components/util/Clickable";
import { Loading } from "../../components/util/Loading";
import { IntlHTML, IntlText } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";

class InquiriedScreen extends PureComponent {
  state = {
    artwork: null,
    gallery_id: null,
  };

  componentWillMount = () => {
    const artworkId = this.props.match.params.artworkId;
    const param = qs.parse(this.props.location.search.replace("?", ""));

    this.props.actions
      .get(artworkId, { gallery_id: param.gallery_id })
      .then(({ value }) => {
        if (value.status > 300) {
          return;
        }
        this.setState({
          artwork: value.data,
          galleryId: param.gallery_id,
        });
      });
  };

  render = () => {
    if (this.state.artwork == null) {
      return <Loading />;
    }

    const artwork = Formatter.mergeDesc(
      this.state.artwork,
      this.state.artwork.artwork_descs,
      this.props.language.view,
    );

    const artists = this.state.artwork.artists.map((artist, index) => {
      return Formatter.mergeDesc(
        artist,
        artist.artist_descs,
        this.props.language.view,
      );
    });

    var gallery =
      artwork.sale && artwork.sale.gallery
        ? Formatter.mergeDesc(
            artwork.sale.gallery,
            artwork.sale.gallery.gallery_descs,
            this.props.language.view,
          )
        : null;

    return (
      <div>
        {gallery ? (
          <div className="uk-margin-xlarge">
            <h1 className="uk-text-center">
              <IntlText id="artworks.inquiry.title" />
            </h1>
            <p className="uk-text-center@s">
              <IntlHTML id="artworks.inquiry.lead" />
              <Link to={Path.other.contact.url}>
                <IntlText id="artworks.inquiry.contact_us" />
              </Link>
            </p>
            <h3 className="uk-text-center">
              <IntlText id="artworks.inquiry.next_step" />
            </h3>
            <div className="uk-text-center">
              <span className="uk-h2">
                <IntlText id="artworks.inquiry.next_lead" />
              </span>
            </div>
            <p className="uk-text-center@s">
              <Link
                ga={{ category: "link", action: "inquiry", label: "gallery" }}
                to={PathGenerator.getLocalPath(Path.galleries.view.url, {
                  galleryId: gallery.id,
                })}
              >
                {gallery.name}
              </Link>
              <IntlText id="artworks.inquiry.will_send" />
              <br />
              <IntlText id="artworks.inquiry.not_get" />
              <Link
                ga={{ category: "link", action: "inquiry", label: "contact" }}
                to={Path.other.contact.url}
              >
                <IntlText id="artworks.inquiry.contact_us" />
              </Link>
            </p>
          </div>
        ) : (
          <div className="uk-margin-xlarge">
            <h1 className="uk-text-center">
              <IntlText id="artworks.inquiry.sorry" />
            </h1>
            <p className="uk-text-center@s">
              <IntlText id="artworks.inquiry.soldout" />
            </p>
          </div>
        )}

        <div className="uk-margin-xlarge uk-text-center">
          <Link
            className="uk-button uk-button-default"
            to={PathGenerator.getLocalPath(
              Path.artworks.view.url,
              { artworkId: this.state.artwork.id },
              { gallery_id: this.state.galleryId },
            )}
            ga={{ category: "link", action: "inquiry", label: "back" }}
          >
            <IntlText id="artworks.inquiry.back" />
          </Link>
        </div>
        <div className="uk-margin-xlarge">
          <h2 className="uk-heading-mark">
            <IntlText id="artworks.inquiry.artwork" />
          </h2>
          <div className="uk-margin-medium">
            <Artwork artwork={artwork} artists={artists} />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (...param) => dispatch(get(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InquiriedScreen),
);
