import React, { Component } from "react";
import { CirclePicker } from "react-color";
import Logger from "../../utils/logger";
import { getPureMessage, IntlText } from "../util/Text";

export default class ColorPicker extends Component {
  onChangeComplete = (color) => {
    console.log(color);
    if (this.props.ga != null) {
      Logger.event(this.props.ga);
    } else if (this.props.meta) {
      Logger.event({
        category: this.props.meta.form,
        action: "color_picker",
        label: this.props.input.name,
      });
    }

    this.props.input.onChange && this.props.input.onChange(color.hex);
  };

  onClear = () => {
    if (process.env.REACT_APP_MODE == "production") {
      if (this.props.ga != null) {
        Logger.event(this.props.ga);
      } else if (this.props.meta) {
        Logger.event({
          category: this.props.meta.form,
          action: "color_picker_clear",
          label: this.props.input.name,
        });
      }
    }
    this.props.input.onChange && this.props.input.onChange(null);
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;

    const label = this.props.labelId
      ? getPureMessage(this.props.labelId)
      : this.props.label;
    return (
      <div>
        {(() => {
          if (label != null) {
            return (
              <label className="uk-form-label uk-text-nowrap">
                {label}
                <wbr />
                {(() => {
                  if (this.props.required) {
                    return (
                      <small className="uk-text-danger">
                        {" "}
                        ※<IntlText id="forms.attr.required" />
                      </small>
                    );
                  }
                })()}
              </label>
            );
          }
        })()}
        <div
          className="uk-form-controls uk-margin-ssmall uk-grid-small uk-flex-middle"
          data-uk-grid
        >
          {!this.props.noNull && (
            <div className="uk-width-auto">
              <a data-uk-icon="icon:close;" onClick={this.onClear}></a>
            </div>
          )}
          <div className="uk-width-expand uk-color-picker">
            <CirclePicker
              width="100%"
              color={this.props.input.value}
              colors={[
                "#ADD8E6",
                "#00008B",
                "#A65BA6",
                "#FFC0CB",
                "#FF0000",
                "#ffa500",
                "#FFFF00",
                "#008000",
                "#E0E0E0",
              ]}
              onChangeComplete={this.onChangeComplete}
            />
          </div>
        </div>
        {!this.props.noError && (
          <div className="uk-text-danger " style={{ minHeight: "18px" }}>
            <small>{hasError && this.props.meta.error}</small>
          </div>
        )}
      </div>
    );
  };
}
