import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { statuses } from "../../../constants/option";
import AutoComplete from "../../inputs/AutoComplete";
import DatePicker from "../../inputs/DatePicker";
import Select from "../../inputs/Select";
import Textarea from "../../inputs/Textarea";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import { IntlText } from "../../util/Text";
import * as validate from "../../util/Validator";

class ShowForm extends Component {
  state = {
    loading: false,
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-margin">
          <div className="uk-margin-small">
            <Field
              name="title"
              type="text"
              component={TextInput}
              labelId="forms.attr.show.title"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-margin-small uk-grid-small" data-uk-grid>
            <div className="uk-width-medium@s">
              <Field
                name="place"
                component={AutoComplete}
                labelId="forms.attr.place"
                options={this.props.constant.places.map((place) => {
                  return {
                    label: place.name,
                    value: place.id,
                  };
                })}
              />
            </div>
            <div className="uk-width-expand">
              <Field
                name="address"
                type="text"
                component={TextInput}
                labelId="forms.attr.address"
              />
            </div>
          </div>
          <div
            className="uk-margin-small uk-grid-small uk-flex-middle"
            data-uk-grid
          >
            <div className="uk-width-small">
              <Field
                name="status"
                component={Select}
                labelId="forms.attr.status"
                options={statuses}
              />
            </div>
          </div>
          <div
            className="uk-margin-small uk-grid-small uk-flex-middle"
            data-uk-grid
          >
            <div className="uk-width-expand">
              <Field
                name="start_at"
                component={DatePicker}
                labelId="forms.attr.start_at"
              />
            </div>
            <div className="uk-width-auto">
              <span>~</span>
            </div>
            <div className="uk-width-expand">
              <Field
                name="end_at"
                component={DatePicker}
                labelId="forms.attr.end_at"
              />
            </div>
          </div>
        </div>
        <div className="uk-margin" data-uk-grid>
          <div className="uk-width-1-2@s">
            <Field
              name="description"
              component={Textarea}
              labelId="forms.attr.show.description"
            />
          </div>
          <div className="uk-width-1-2@s">
            <Field
              name="schedule"
              component={Textarea}
              labelId="forms.attr.schedule"
            />
          </div>
        </div>

        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    constant: state.constant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default reduxForm({
  form: "gallery-show-form",
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(ShowForm));
