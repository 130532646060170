import React, { PureComponent } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import { IntlText } from "../../util/Text";
import * as validate from "../../util/Validator";

class AuthForm extends PureComponent {
  state = {
    loading: false,
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-2@s">
            <Field
              name="email"
              type="email"
              component={TextInput}
              labelId="forms.attr.email"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-width-1-2@s">
            <Field
              name="email_confirmation"
              type="email"
              component={TextInput}
              labelId="forms.attr.email_again"
              validate={[validate.required, validate.isConfirmedEmail]}
              required
            />
          </div>
        </div>

        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-2@s">
            <Field
              name="password"
              component={TextInput}
              labelId="forms.attr.new_password"
              type="password"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-width-1-2@s">
            <Field
              name="password_confirmation"
              type="password"
              component={TextInput}
              labelId="forms.attr.new_password_again"
              validate={[validate.required, validate.isConfirmedPassword]}
              required
            />
          </div>
        </div>

        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "gallery-auth-Form",
  enableReinitialize: true,
})(AuthForm);
