import Immutable from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { logout, mergeData } from "../../actions/collector/auth";
import { deleteImage, getData, getLikes } from "../../actions/collector/index";
import { showConfirm } from "../../actions/message";
import LikesImages from "../../components/common/LikesImages";
import Icon from "../../components/inputs/Icon";
import { Button, Link } from "../../components/util/Clickable";
import { Loading } from "../../components/util/Loading";
import { IntlText, LinedText } from "../../components/util/Text";
import { EndPoints } from "../../constants/endpoints";
import { languages } from "../../constants/option";
import { Path, PathGenerator } from "../../constants/path";
import { Notification } from "../../utils/notification";

class TopScreen extends PureComponent {
  state = {
    collector: null,
  };

  componentWillMount = () => {
    this.reload();
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.location.pathname != nextProps.location.pathname) {
      this.reload();
    }
  };

  reload = () => {
    this.props.actions.getData().then(({ value }) => {
      if (value.status > 300) {
        return;
      }
      this.setState({
        collector: value.data,
      });
    });
    this.props.actions.getLikes();
  };

  onLogout = () => {
    this.props.actions.showConfirm(
      <IntlText id="collector.menu.signout" />,
      null,
      (confirmed) => {
        if (confirmed) {
          this.props.actions
            .logout()
            .then(({ value, action }) => {
              if (value.status < 300) {
                this.props.history.push(
                  PathGenerator.getLocalPath(Path.root.url),
                );
              }
            })
            .catch((error) => console.log(error));
        }
      },
    );
  };

  onUploaded = (data) => {
    Notification.successIntl("util.success.update");
    this.props.actions.mergeData({ thumnail_url: data.thumnail_url });
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["collector", "thumnail_url"], (v) => data.thumnail_url)
        .toJS(),
    );
  };

  onDeleted = () => {
    this.props.actions.mergeData({ thumnail_url: null });
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["collector", "thumnail_url"], (v) => null)
        .toJS(),
    );
    this.props.actions
      .deleteImage()
      .then(({ value }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.delete");
        }
      })
      .catch((error) => console.log(error));
  };

  renderContent = (label, text, content = null) => {
    if (text == null) {
      return null;
    }
    return (
      <div
        data-uk-grid="margin:uk-margin-ssmall;"
        className="uk-grid-small uk-margin-small"
      >
        <div className="uk-width-medium@s">
          <span className="uk-text-muted">{label}</span>
        </div>
        <div className="uk-width-expand">
          {content ? content(text) : <LinedText text={text} />}
        </div>
      </div>
    );
  };

  render = () => {
    if (!this.state.collector) {
      return <Loading />;
    }

    const selectedLang = languages.find(
      (lang) => lang.value == this.state.collector.primary_lang,
    );

    return (
      <div data-uk-grid>
        <div className="uk-width-auto@s uk-text-center">
          <Icon
            url={EndPoints.collector("image")}
            src={this.state.collector.thumnail_url}
            onSuccess={this.onUploaded}
            onDelete={this.onDeleted}
            keyExtractor={(value) => value.thumnail_url}
          />
        </div>
        <div className="uk-width-expand">
          <div className="uk-margin-medium">
            <h2>
              <IntlText id="collector.mypage.profile" />
            </h2>
            <div className="uk-margin">
              <div className="uk-margin-small">
                {this.renderContent(
                  <IntlText id="forms.attr.nickname" />,
                  this.state.collector.nickname,
                )}
              </div>
              <div className="uk-margin-small">
                {this.renderContent(
                  <IntlText id="forms.attr.name" />,
                  this.state.collector.name,
                )}
              </div>
              <div className="uk-margin-small">
                {this.renderContent(
                  <IntlText id="forms.attr.tel" />,
                  this.state.collector.tel,
                )}
              </div>
              <div className="uk-margin-small">
                {this.renderContent(
                  <IntlText id="forms.attr.country" />,
                  this.state.collector.country,
                )}
              </div>
              <div className="uk-margin-small">
                {this.renderContent(
                  <IntlText id="forms.attr.address" />,
                  this.state.collector.address,
                )}
              </div>
              {/*<div className="uk-margin-small">
                              { this.renderContent( (<IntlText id="forms.attr.primary_lang" />), selectedLang ? selectedLang.label : null ) }
                            </div>*/}
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default"
                to={Path.collector.edit.url}
              >
                <IntlText id="forms.edit" />
              </Link>
            </div>
          </div>

          <hr />

          <div className="uk-margin-medium">
            <h2>
              <IntlText id="collector.mypage.authorization" />
            </h2>
            <div className="uk-margin">
              <div className="uk-margin-small">
                {this.renderContent(
                  <IntlText id="forms.attr.email" />,
                  this.state.collector.email,
                )}
              </div>
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default"
                to={Path.collector.edit_auth.url}
              >
                <IntlText id="forms.edit" />
              </Link>
            </div>
          </div>

          <hr />

          <div className="uk-margin-medium">
            <h2>
              <IntlText id="collector.mypage.likes" />
            </h2>
            <div className="uk-margin">
              <LikesImages likes={this.props.like.data} />
            </div>
            <div className="uk-margin uk-text-center">
              <Link className="uk-button uk-button-default" to={Path.likes.url}>
                More
              </Link>
            </div>
          </div>

          <hr />

          <div className="uk-margin-medium">
            <div className="uk-margin uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={this.onLogout}
              >
                <IntlText id="forms.auth.signout" />
              </Button>
            </div>
            <div className="uk-margin uk-text-right">
              <IntlText id="collector.mypage.ifwantunsubscribe" />
              <Link
                className="uk-button uk-button-link"
                to={Path.other.contact.url}
              >
                <IntlText id="collector.mypage.contact" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    collector: state.auth.data ? state.auth.data : {},
    like: state.like,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getData: () => dispatch(getData()),
      getLikes: () => dispatch(getLikes()),
      deleteImage: () => dispatch(deleteImage()),
      logout: () => dispatch(logout()),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
      mergeData,
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopScreen),
);
