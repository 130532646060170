import { GET_CATEGORIES, GET_PLACES, SET_VIP } from "../constants/type";
import Immutable from "immutable";

const initStatus = {
  categories: [],
  places: [],
};

export default function constantReducer(state = initStatus, action = {}) {
  switch (action.type) {
    case GET_CATEGORIES + "_FULFILLED":
      if (action.payload.status > 300) {
        return state;
      }
      return Immutable.fromJS(state)
        .updateIn(["categories"], (v) => action.payload.data)
        .toJS();

    case GET_PLACES + "_FULFILLED":
      if (action.payload.status > 300) {
        return state;
      }
      return Immutable.fromJS(state)
        .updateIn(["places"], (v) => action.payload.data)
        .toJS();

    default:
      return state;
  }
}
