import Immutable from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  deleteImage,
  getData,
  updateAuth,
  updateData,
  updateDesc,
  updateContactEmail,
} from "../../../actions/gallery/index";
import AuthForm from "../../../components/forms/gallery/AuthForm";
import BankForm from "../../../components/forms/gallery/BankForm";
import DescForm from "../../../components/forms/gallery/DescForm";
import SettingForm from "../../../components/forms/gallery/SettingForm";
import ContactEmailForm from "../../../components/forms/gallery/ContactEmailForm";
import Thumnail from "../../../components/inputs/Thumnail";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Link } from "../../../components/util/Clickable";
import { Loading } from "../../../components/util/Loading";
import { IntlText } from "../../../components/util/Text";
import Plan from "../../../components/views/Plan";
import { EndPoints } from "../../../constants/endpoints";
import { Path } from "../../../constants/path";
import { Formatter } from "../../../utils/formatter";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class EditScreen extends PureComponent {
  state = {
    gallery: null,
    desc: {},
  };

  componentWillMount = () => {
    this.props.actions
      .getData()
      .then(({ value, action }) => {
        if (value.status < 300) {
          const desc = Formatter.mergeDesc(
            value.data,
            value.data.gallery_descs,
            this.props.language.edit,
          );
          this.setState({
            gallery: value.data,
            desc: desc ? desc : {},
          });
        }
      })
      .catch((error) => console.log(error));
  };

  submitSetting = (values) => {
    return this.props.actions.updateData(values);
  };

  onSuccess = (data) => {
    this.setState(
      Immutable.fromJS(this.state).mergeIn(["gallery"], data).toJS(),
    );
    Notification.successIntl("util.success.update");
  };

  onChageEditLang = (lang) => {
    const desc = Formatter.mergeDesc(
      this.state.gallery,
      this.state.gallery.gallery_descs,
      lang,
    );

    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["desc"], (v) => {
          return desc ? desc : {};
        })
        .toJS(),
    );
  };

  submitDesc = (values) => {
    return this.props.actions.updateDesc(values);
  };

  onSuccessDesc = (data) => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["gallery"], (v) => data)
        .toJS(),
    );
    Notification.successIntl("util.success.update");
  };

  submitAuth = (values) => {
    return this.props.actions.updateAuth(values);
  };

  submitContactEmail = (values) => {
    return this.props.actions.updateContactEmail(values);
  };

  onSuccessCard = (data) => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["gallery", "status"], (v) => data.status)
        .updateIn(["gallery", "card_suffix"], (v) => data.card_suffix)
        .updateIn(["gallery", "expired_at"], (v) => data.expired_at)
        .toJS(),
    );
  };

  onUploaded = (data) => {
    Notification.successIntl("util.success.update");
  };

  onDeleted = (isLogo = null) => {
    this.props.actions
      .deleteImage({
        logo: isLogo,
      })
      .then(({ value }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.delete");
        }
      })
      .catch((error) => console.log(error));
  };

  renderPlan = () => {
    return null;
    return (
      <div className="uk-margin-medium">
        <h2 className="uk-heading-mark">
          <IntlText id="gallery.edit.plan" />
        </h2>
        <div className="uk-margin">
          <Plan
            gallery={this.state.gallery}
            onUpdated={this.onSuccessCard}
            editing={true}
          />
        </div>
      </div>
    );
  };

  render = () => {
    if (!this.state.gallery) {
      return (
        <div>
          <h1>
            <IntlText id="gallery.edit.title" />
          </h1>
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <h1>
          <IntlText id="gallery.edit.title" />
        </h1>

        {this.state.gallery.status == 0 && this.renderPlan()}

        <div className="uk-margin-medium">
          <div className="uk-margin uk-flex-middle uk-flex-right" data-uk-grid>
            <div className="uk-width-expand@s uk-width-1-1">
              <h2 className="uk-heading-mark">
                <IntlText id="gallery.edit.gallery_info" />
              </h2>
            </div>
            <div className="uk-width-medium@s uk-text-right">
              <ChangeEditLang onChange={this.onChageEditLang} />
            </div>
          </div>
          <div className="uk-margin">
            {this.state.desc && (
              <DescForm
                initialValues={this.state.desc}
                onSuccess={this.onSuccessDesc}
                onSubmit={this.submitDesc}
              />
            )}
          </div>
        </div>

        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.edit.settings" />
          </h2>
          <div className="uk-margin">
            {this.state.gallery.id && (
              <SettingForm
                initialValues={this.state.gallery}
                onSuccess={this.onSuccess}
                onSubmit={this.submitSetting}
              />
            )}
          </div>
        </div>

        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.edit.payment" />
          </h2>
          <div className="uk-margin">
            {this.state.gallery.id && (
              <BankForm
                initialValues={this.state.gallery}
                onSuccess={this.onSuccess}
                onSubmit={this.submitSetting}
              />
            )}
          </div>
        </div>

        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.edit.images" />
          </h2>
          <div className="uk-margin" data-uk-grid>
            <div className="uk-width-expand">
              <h3>
                <IntlText id="gallery.edit.cover" />
              </h3>
              <div className="uk-margin-small">
                <Thumnail
                  url={EndPoints.gallery("image")}
                  src={this.state.gallery.thumnail_url}
                  onSuccess={this.onUploaded}
                  onDelete={() => this.onDeleted()}
                  keyExtractor={(value) => value.thumnail_url}
                />
              </div>
            </div>
            <div className="uk-width-large@s">
              <h3>
                <IntlText id="gallery.edit.logo" />
              </h3>
              <div className="uk-margin-small">
                <Thumnail
                  url={EndPoints.gallery("image") + "?logo=true"}
                  src={this.state.gallery.logo_url}
                  onSuccess={this.onUploaded}
                  onDelete={() => this.onDeleted(true)}
                  keyExtractor={(value) => value.logo_url}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.gallery.status != 0 && this.renderPlan()}

        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.edit.authorize" />
          </h2>
          <div className="uk-margin">
            <AuthForm
              initialValues={{ email: this.state.gallery.email }}
              onSuccess={this.onSuccess}
              onSubmit={this.submitAuth}
            />
          </div>
        </div>
        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.edit.addcontact" />
          </h2>
          <div className="uk-margin">
            <ContactEmailForm
              initialValues={{ contact_email: this.state.gallery.contact_email }}
              onSuccess={this.onSuccess}
              onSubmit={this.submitContactEmail}
            />
          </div>
        </div>

        <div className="uk-margin-medium">
          <div data-uk-alert>
            <p>
              <IntlText id="gallery.edit.unsubscribe" />
              <Link
                ga={{
                  category: "link",
                  action: "gallery.edit",
                  label: "unsubscribe",
                }}
                to={Path.other.contact.url}
              >
                <IntlText id="gallery.edit.contact" />
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    gallery: state.auth.data ? state.auth.data : {},
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getData: () => dispatch(getData()),
      updateData: (data) => dispatch(updateData(data)),
      updateDesc: (data) => dispatch(updateDesc(data)),
      updateAuth: (data) => dispatch(updateAuth(data)),
      updateContactEmail: (data) => dispatch(updateContactEmail(data)),
      deleteImage: (...param) => dispatch(deleteImage(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditScreen),
);
