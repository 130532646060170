import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { login } from "../../../actions/gallery/auth";
import { approve } from "../../../actions/gallery/index";
import { setEditLang, setViewLang } from "../../../actions/language";
import logo from "../../../assets/images/logo/black.png";
import LoginForm from "../../../components/forms/LoginForm";
import ChangeViewLang from "../../../components/util/ChangeViewLang";
import { Link } from "../../../components/util/Clickable";
import { IntlText, LinedText } from "../../../components/util/Text";
import { Path, PathGenerator } from "../../../constants/path";
import { terms } from "../../../constants/term/gallery";
import { defaultLang } from "../../../utils/location";

declare var UIkit;

class LoginScreen extends PureComponent {
  submitLogin = (values) => {
    return this.props.actions.login(values);
  };

  onSuccess = (data) => {
    if (data.is_approved != 1) {
      UIkit.modal(this.modal, { container: false }).show();
    } else {
      this.onLogin(data);
    }
  };

  onAgree = () => {
    UIkit.util.on(this.modal, "hidden", () => {
      this.onLogin(this.props.gallery);
    });
    this.props.actions.approve().then(({ value }) => {});

    UIkit.modal(this.modal).hide();
  };

  onLogin = (data) => {
    const lang = data.primary_lang ? data.primary_lang : defaultLang;
    this.props.actions.setViewLang(lang);
    this.props.actions.setEditLang(lang);
    this.props.history.push(
      PathGenerator.getRealUrl(Path.gallery.top.url, { lang: lang }),
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-text-center">
          <img src={logo} className="uk-width-medium" />
        </div>
        <h1 className="uk-text-center">
          <IntlText id="gallery.auth.login.header" />
        </h1>
        <div className="uk-margin-large uk-text-center">
          <div className="uk-width-large@s uk-text-left uk-display-inline-block">
            <div className="uk-margin-medium">
              <LoginForm
                onSubmit={this.submitLogin}
                onSuccess={this.onSuccess}
              />
            </div>
            <div className="uk-margin-small uk-text-center">
              <span className="uk-margin-ssmall-right">
                <IntlText id="gallery.auth.login.ifyouforget" />
              </span>
              <Link
                className="uk-button-link uk-button"
                to={Path.gallery.auth.forget.url}
              >
                <IntlText id="gallery.auth.login.resethere" />
              </Link>
            </div>
            <hr />
            <div className="uk-margin-medium">
              <p className="uk-margin-small uk-text-center">
                <IntlText id="gallery.auth.login.donthave" />
                <br />
                <IntlText id="gallery.auth.login.contact" />
              </p>
              <div className="uk-margin-small uk-text-center">
                <Link
                  className="uk-button-default uk-button"
                  to={Path.other.contact.url}
                >
                  <IntlText id="gallery.auth.login.contactus" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={(modal) => (this.modal = modal)}
          {...{ "uk-modal": "container: false;" }}
        >
          <div className="uk-modal-dialog uk-width-xxlarge@s uk-modal-body uk-margin-auto-vertical">
            <div className="uk-modal-header uk-grid-small" data-uk-grid>
              <div className="uk-width-expand">
                <h2 className="uk-modal-title">
                  <IntlText id="gallery.auth.login.term" />
                </h2>
              </div>
              <div className="uk-width-auto">
                <ChangeViewLang />
              </div>
            </div>
            <div className="uk-modal-body uk-height-large uk-overflow-auto">
              <LinedText text={terms[this.props.language.view]} />
            </div>
            <div className="uk-modal-footer uk-text-center">
              <button
                className="uk-button uk-button-default"
                onClick={this.onAgree}
              >
                <IntlText id="gallery.auth.login.agree" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      login: (data) => dispatch(login(data)),
      approve: () => dispatch(approve()),
      setViewLang: (...param) => dispatch(setViewLang(...param)),
      setEditLang: (...param) => dispatch(setEditLang(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreen),
);
