import { GET_ADMIN, EDIT_ADMIN, EDIT_ADMIN_AUTH } from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";
import { setData } from "./auth";

export const getData = () => {
  return (dispatch) => {
    return {
      type: GET_ADMIN,
      payload: () => {
        return Api.get(EndPoints.admin()).then((response) => {
          if (response.status < 300) {
            dispatch(setData(response.data));
          }
          return response;
        });
      },
    };
  };
};

export const edit = (data) => {
  return (dispatch) => {
    return {
      type: EDIT_ADMIN,
      payload: () => {
        return Api.put(EndPoints.admin(), data);
      },
    };
  };
};

export const editAuth = (data) => {
  return (dispatch) => {
    return {
      type: EDIT_ADMIN_AUTH,
      payload: () => {
        return Api.put(EndPoints.admin("auth"), data);
      },
    };
  };
};
