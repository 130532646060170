import { SEARCH_FAIRS, GET_FAIR } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";
import { setCache, getCache } from "./cache";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: SEARCH_FAIRS,
      payload: () => {
        return Api.get(EndPoints.fairs(), param);
      },
    };
  };
};

export const get = (fairId) => {
  return (dispatch) => {
    return {
      type: SEARCH_FAIRS,
      payload: () => {
        const key = "fairs/" + fairId;
        const promise = new Promise((resolve, reject) => {
          const data = getCache(key);
          if (data) {
            return resolve(data);
          }
          Api.get(EndPoints.fairs(fairId))
            .then((result) => {
              if (result.status < 300) {
                dispatch(setCache(key, result));
              }
              resolve(result);
            })
            .catch((error) => reject(error));
        });
        return promise;
      },
    };
  };
};
