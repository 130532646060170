import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { add, search } from "../../../actions/admin/news";
import { showConfirm } from "../../../actions/message";
import NewsForm from "../../../components/forms/admin/NewsForm";
import NewsList from "../../../components/lists/News";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Button, Link } from "../../../components/util/Clickable";
import { Path, PathGenerator } from "../../../constants/path";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class IndexScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      news: null,
      meta: {
        current_page: 0,
        last_page: null,
      },
      loading: false,
    };
  }

  componentWillMount = () => {
    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        page: this.state.meta.current_page + 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            news:
              this.state.news && !refresh
                ? this.state.news.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onAdded = (values) => {
    UIkit.modal(this.addModal).hide();
    this.addForm.wrappedInstance.props.reset();
    this.setState(
      {
        news: [values].concat(this.state.news),
      },
      () => {
        Notification.success("Add succeeded.");
      },
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-small" data-uk-grid>
          <div className="uk-width-auto">
            <h1>News</h1>
          </div>
          <div className="uk-width-expand">
            <Link
              className="uk-button uk-button-default"
              onClick={() => {
                UIkit.modal(this.addModal, { container: false }).show();
              }}
            >
              <span>Add</span>
              <span
                className="uk-margin-small-left"
                data-uk-icon="icon:plus; ratio:0.8;"
              />
            </Link>
          </div>
          <div className="uk-width-medium@s uk-width-1-1 uk-text-right">
            <ChangeEditLang />
          </div>
        </div>

        <div className="uk-margin-large">
          <NewsList
            lang={this.props.language.edit}
            editing={true}
            news={this.state.news}
            onEdit={(news) => {
              this.props.history.push(
                PathGenerator.getLocalPath(Path.admin.news.edit.url, {
                  newsId: news.id,
                }),
              );
            }}
          />
        </div>

        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-large uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
              >
                More
              </Button>
            </div>
          )}

        <div ref={(addModal) => (this.addModal = addModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">Add News</h2>
            </div>
            <div className="uk-modal-body">
              <NewsForm
                ref={(addForm) => (this.addForm = addForm)}
                form="news-add-form"
                onSubmit={(values) => this.props.actions.add(values)}
                onSuccess={this.onAdded}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    admin: state.auth.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
      add: (...param) => dispatch(add(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
