import {
  GET_LOCATIONS,
  ADD_LOCATION,
  EDIT_LOCATION,
  DELETE_LOCATION,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";
import { setData } from "./auth";

export const getAll = () => {
  return (dispatch) => {
    return {
      type: GET_LOCATIONS,
      payload: () => {
        return Api.get(EndPoints.galleryLocations());
      },
    };
  };
};

export const add = (data) => {
  return (dispatch) => {
    return {
      type: ADD_LOCATION,
      payload: () => {
        return Api.post(EndPoints.galleryLocations(), data);
      },
    };
  };
};

export const edit = (locationId, data) => {
  return (dispatch) => {
    return {
      type: EDIT_LOCATION,
      payload: () => {
        return Api.put(EndPoints.galleryLocations(locationId), data);
      },
    };
  };
};

export const del = (locationId) => {
  return (dispatch) => {
    return {
      type: EDIT_LOCATION,
      payload: () => {
        return Api.delete(EndPoints.galleryLocations(locationId));
      },
    };
  };
};
