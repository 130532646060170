import { SET_CONDITION } from "../constants/type";
import Immutable from "immutable";

const initStatus = {};

export default function conditionReducer(state = initStatus, action = {}) {
  switch (action.type) {
    case SET_CONDITION:
      return Immutable.fromJS(state)
        .updateIn([action.key], (v) => action.state)
        .toJS();

    default:
      return state;
  }
}
