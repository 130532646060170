import { ADD_BANNER, EDIT_BANNER, SORT_BANNER, DELETE_BANNER } from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const add = (data) => {
  return (dispatch) => {
    return {
      type: ADD_BANNER,
      payload: () => {
        return Api.post(EndPoints.adminBanners(), data);
      },
    };
  };
};

export const editBanner = (bannerId, value) => {
  return (dispatch) => {
    return {
      type: EDIT_BANNER,
      payload: () => {
        return Api.put(EndPoints.adminBanners(bannerId), {'value': value});
      },
    };
  };
};

export const sortBanner = (bannerId, above_or_below) => {
  return (dispatch) => {
    return {
      type: SORT_BANNER,
      payload: () => {
        return Api.put(EndPoints.adminSortBanners(bannerId), {'above_or_below': above_or_below});
      },
    };
  };
};

export const deleteBanner = (bannerId) => {
  return (dispatch) => {
    return {
      type: DELETE_BANNER,
      payload: () => {
        return Api.delete(EndPoints.adminBanners(bannerId));
      },
    };
  };
};
