import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { IntlText } from "../../components/util/Text";
import Fair from "../views/FairCard";

class FairsList extends Component {
  render = () => {
    if (this.props.fairs == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.fairs.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.fairs.no" />
          </h3>
        </div>
      );
    }

    return (
      <div
        data-uk-grid
        className={
          "uk-child-width-1-1 " +
          (this.props.size == 2
            ? " uk-child-width-1-2@s"
            : " uk-child-width-1-3@s")
        }
      >
        {this.props.fairs.map((fair, index) => {
          return (
            <div key={index}>
              <Fair
                fair={fair}
                onEdit={(value) =>
                  this.props.onClick && this.props.onClick(fair)
                }
                editing={this.props.editing}
                lang={
                  this.props.lang ? this.props.lang : this.props.language.view
                }
                buttonText={this.props.buttonText}
                large={this.props.large}
              />
            </div>
          );
        })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FairsList),
);
