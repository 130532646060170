import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from "react-stripe-elements";
import { bindActionCreators } from "redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { mergeData } from "../../../actions/collector/auth";
import cards from "../../../assets/images/vendor/cards.svg";
import Textarea from "../../inputs/Textarea";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import { getPureMessage, IntlText } from "../../util/Text";
import * as validate from "../../util/Validator";

class PurchaseForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      mode: "card",
      newCard: !props.initialValues || !props.initialValues.expired_at,
    };
  }

  changeMode = (mode) => {
    this.setState({ mode: mode });
  };

  toggleNewCard = (mode) => {
    this.setState({ newCard: mode });
  };

  onSubmit = (values) => {
    switch (this.state.mode) {
      case "alipay":
        return this.onSubmitAlipay(values);

      default:
        return this.onSubmitCard(values);
    }
  };

  onSubmitAlipay = (values) => {
    this.setState({
      loading: true,
      error: null,
    });
    this.props.stripe
      .createSource({
        type: "alipay",
        amount: this.props.price,
        currency: this.props.priceUnit == "jpy" ? "jpy" : "usd",
        redirect: {
          return_url: this.props.redirectUrl("alipay"),
        },
      })
      .then(({ source }) => {
        values.source = source.id;
        this.props.actions.mergeData(values);

        window.location.href = source.redirect.url;
      });
  };

  onSubmitCard = (values) => {
    this.setState({
      loading: true,
      error: null,
    });
    if (!this.state.newCard) {
      this.onSubmitData(values);
    } else {
      this.props.stripe
        .createToken()
        .then(({ token }) => {
          if (token == null) {
            throw new SubmissionError({
              _error: getPureMessage("forms.attr.purchase.invalid_card"),
            });
          }
          const param = Object.assign(
            {},
            {
              card_token: token.id,
              card_suffix: token.card.last4,
              expired_at: moment(
                token.card.exp_year + "-" + token.card.exp_month + "-01",
                "YYYY-MM-DD",
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            },
            values,
          );

          this.onSubmitData(param);
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: getPureMessage("forms.attr.purchase.invalid_request"),
          });
        });
    }
  };

  onSubmitData = (param) => {
    this.props
      .onSubmit(param)
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.onSuccess &&
                this.props.onSuccess(value.data, value.response);
            },
          );
        } else {
          this.setState({
            loading: false,
          });
          const errors = Object.keys(value.errors).reduce((dic, key) => {
            dic[key] = Array.isArray(value.errors[key])
              ? value.errors[key].join("\n")
              : null;
            return dic;
          }, {});
          throw errors;
        }
      })
      .catch((errors) => {
        this.setState({
          loading: false,
          error: getPureMessage("forms.attr.purchase.invalid_request"),
        });
      });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <h4 className="uk-margin-small">
          <IntlText id="forms.attr.purchase.user_info" />
        </h4>
        <div className="uk-margin-small">
          <div className="uk-margin-ssmall">
            <label>
              <IntlText id="forms.attr.name" />
            </label>
            <div>
              <span>
                {this.props.initialValues && this.props.initialValues.name}
              </span>
            </div>
          </div>
          <div className="uk-margin-ssmall">
            <Field
              className="uk-form-clear"
              name="tel"
              type="tel"
              component={TextInput}
              labelId="forms.attr.tel"
              validate={[validate.required, validate.tel]}
              required
              placeholder={getPureMessage("forms.attr.tel")}
            />
          </div>
          <div className="uk-margin-ssmall">
            <Field
              name="country"
              type="text"
              component={TextInput}
              validate={[validate.required]}
              required
              labelId="forms.attr.purchase.country"
              placeholder={getPureMessage("forms.placeholder.country")}
            />
          </div>
          <div className="uk-margin-ssmall">
            <Field
              name="address"
              component={Textarea}
              className="uk-form-textarea-xsmall"
              validate={[validate.required]}
              required
              labelId="forms.attr.purchase.address"
              placeholder={getPureMessage("forms.placeholder.address")}
            />
          </div>
          <div className="uk-margin-ssmall">
            <Field
              className="uk-form-textarea-small"
              name="collector_message"
              component={Textarea}
              labelId="forms.attr.question"
              placeholder=" "
            />
          </div>
        </div>

        <h4 className="uk-margin-small">
          <IntlText id="forms.attr.purchase.credit_info" />
        </h4>
        <ul
          className="uk-subnav uk-subnav-pill uk-child-width-expand uk-margin-small"
          {...{ "uk-switcher": "" }}
        >
          <li>
            <a
              className="uk-text-center"
              href="#"
              onClick={() => this.changeMode("card")}
            >
              <IntlText id="forms.attr.purchase.credit_card" />
            </a>
          </li>
          {/* <li>
            <a className="uk-text-center" href="#" onClick={() => this.changeMode("alipay") }>
              <IntlText id="forms.attr.purchase.alipay" />
            </a>
          </li> */}
        </ul>

        <ul className="uk-switcher uk-margin-small">
          <li>
            {this.props.initialValues && this.props.initialValues.expired_at && (
              <div
                className="uk-margin-ssmall uk-switcher-card"
                active={"" + !this.state.newCard}
                onClick={() => this.toggleNewCard(false)}
              >
                <div>
                  <IntlText id="forms.attr.card.inuse" />
                </div>
                <div className="uk-text-muted">
                  <span className="uk-margin-ssmall-right">
                    **** **** **** {this.props.initialValues.card_suffix}
                  </span>
                  <span>
                    <span className="uk-margin-ssmall-right">
                      <IntlText id="forms.attr.expired" />
                    </span>
                    <span>
                      {moment(this.props.initialValues.expired_at).format(
                        "MM/YY",
                      )}
                    </span>
                  </span>
                </div>
              </div>
            )}
            <div
              className="uk-margin-ssmall uk-switcher-card"
              active={"" + this.state.newCard}
              onClick={() => this.toggleNewCard(true)}
            >
              <div>
                <IntlText id="forms.attr.card.new" />
              </div>
            </div>
            <hr />
            {(!this.props.initialValues ||
              !this.props.initialValues.expired_at ||
              this.state.newCard) && (
              <div>
                <div className="uk-margin-ssmall">
                  <img className="uk-width-1-1" src={cards} alt="cards" />
                </div>
                <div className="uk-margin-ssmall">
                  <label className="uk-form-label uk-text-nowrap">
                    <IntlText id="forms.attr.card_number" />
                    <small className="uk-text-danger">
                      {" "}
                      ※<IntlText id="forms.attr.required" />
                    </small>
                  </label>
                  <div className="uk-form-controls">
                    <CardNumberElement className="uk-input uk-input-element" />
                  </div>
                </div>
                <div
                  className="uk-margin-ssmall uk-child-width-1-2@s uk-grid-small"
                  data-uk-grid
                >
                  <div>
                    <label className="uk-form-label uk-text-nowrap">
                      <IntlText id="forms.attr.expired" />
                      <small className="uk-text-danger">
                        {" "}
                        ※<IntlText id="forms.attr.required" />
                      </small>
                    </label>
                    <div className="uk-form-controls">
                      <CardExpiryElement className="uk-input uk-input-element" />
                    </div>
                  </div>
                  <div>
                    <label className="uk-form-label uk-text-nowrap">
                      <IntlText id="forms.attr.cvc" />{" "}
                      <small className="uk-text-danger">
                        {" "}
                        ※<IntlText id="forms.attr.required" />
                      </small>
                    </label>
                    <div className="uk-form-controls">
                      <CardCVCElement className="uk-input uk-input-element" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(() => {
              if (this.props.error || this.state.error) {
                return (
                  <div className="uk-margin-ssmall uk-text-danger uk-text-center@s">
                    <span>
                      {this.props.error}
                      {this.state.error}
                    </span>
                  </div>
                );
              }
            })()}
            <div className="uk-margin-small uk-text-center">
              <Button
                className="uk-button uk-button-default uk-button-large"
                type="submit"
                disabled={this.props.invalid}
                ga={{ category: this.props.form, action: "submit" }}
                loading={this.state.loading ? 1 : 0}
              >
                <IntlText id="forms.attr.purchase.submit" />
              </Button>
            </div>
          </li>
          <li>
            {(() => {
              if (this.props.error || this.state.error) {
                return (
                  <div className="uk-margin-ssmall uk-text-danger uk-text-center@s">
                    <span>
                      {this.props.error}
                      {this.state.error}
                    </span>
                  </div>
                );
              }
            })()}
            <div className="uk-margin-small uk-text-center">
              <Button
                className="uk-button uk-button-default uk-button-large"
                type="submit"
                disabled={this.props.invalid}
                ga={{ category: this.props.form, action: "alipay" }}
                loading={this.state.loading ? 1 : 0}
                onClick={this.onAlipaySubmit}
              >
                <IntlText id="forms.attr.purchase.use_alipay" />
              </Button>
            </div>
          </li>
        </ul>
        <Field name="gallery_id" type="hidden" component={TextInput} noError />
      </form>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      mergeData: (param) => dispatch(mergeData(param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    injectStripe(
      reduxForm({
        form: "collector-purchase-form",
        enableReinitialize: true,
      })(PurchaseForm),
    ),
  ),
);
