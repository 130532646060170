import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getData } from "../../actions/fair/index";
import { search } from "../../actions/fair/invite";
import AttendsList from "../../components/lists/Attends";
import { IntlText } from "../../components/util/Text";

class TopScreen extends PureComponent {
  state = {
    attends: null,
  };

  componentWillMount = () => {
    this.props.actions
      .search()
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            attends: value.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="fair.dashboard.title" />
        </h1>
        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="fair.dashboard.galleries" />
          </h2>
          <div className="uk-margin">
            <AttendsList attends={this.state.attends} />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    fair: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (param) => dispatch(search(param)),
      getData: () => dispatch(getData()),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopScreen),
);
