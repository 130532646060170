import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addLike, clearLike } from "../../actions/like";
import { isSameLike } from "../../utils/formatter";
import { Button } from "./Clickable";

class LikeButtonComponent extends Component {
  state = {
    isLiked: false,
    like: null,
  };

  componentWillMount = () => {
    this.setLike(this.props);
  };

  componentWillReceiveProps = (nextProps) => {
    this.setLike(nextProps);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      nextProps.data.id != nextProps.data.id || nextProps.type != nextProps.type
    );
  };

  setLike = (props) => {
    const like = {};
    like[props.type] = props.data;
    like[props.type + "_id"] = props.data.id;

    const isLiked = props.like.data.find((l) => {
      return isSameLike(l, like);
    });

    this.setState({
      isLiked: isLiked != null,
      like: like,
    });
    this.forceUpdate();
  };

  toggleLike = (event) => {
    event.preventDefault();
    if (this.state.isLiked) {
      this.props.actions.clearLike(this.state.like);
    } else {
      this.props.actions.addLike(this.state.like);
    }
    this.setState({
      isLiked: !this.state.isLiked,
    });
    this.forceUpdate();
  };

  render = () => {
    return (
      <Button
        className="uk-icon-button uk-like-button"
        active={this.state.isLiked + ""}
        onClick={this.toggleLike}
        ga={{
          category: "like",
          action: this.props.type,
          label: "" + this.props.data.id,
          value: this.state.isLiked ? 1 : -1,
        }}
      >
        <span data-uk-icon="icon: heart; ratio: 0.8;" />
      </Button>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    like: state.like,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addLike: (...param) => dispatch(addLike(...param)),
      clearLike: (...param) => dispatch(clearLike(...param)),
    },
    dispatch,
  ),
});

export const LikeButton = connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(LikeButtonComponent);

class LikeDetailComponent extends Component {
  state = {
    isLiked: false,
    like: null,
    count: 0,
  };

  componentWillMount = () => {
    this.setLike(this.props);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return false;
  };

  setLike = (props) => {
    const like = {};
    like[props.type] = props.data;
    like[props.type + "_id"] = props.data.id;

    const isLiked = props.like.data.find((l) => {
      return isSameLike(l, like);
    });

    this.setState({
      isLiked: isLiked != null,
      like: like,
      count: props.data && props.data.likes_count ? props.data.likes_count : 0,
    });
    this.forceUpdate();
  };

  toggleLike = (event) => {
    event.preventDefault();
    var count = this.state.count;
    if (this.state.isLiked) {
      this.props.actions.clearLike(this.state.like);
      if (count > 0) {
        count--;
      }
    } else {
      this.props.actions.addLike(this.state.like);
      count++;
    }
    this.setState({
      isLiked: !this.state.isLiked,
      count: count,
    });
    this.forceUpdate();
  };

  render = () => {
    return (
      <div className="uk-display-inline-block">
        <div className="uk-grid-collapse uk-flex-middle" data-uk-grid>
          <div className="uk-width-auto uk-margin-ssmall-right">
            <Button
              className="uk-icon-button uk-like-button uk-like-button-large"
              active={this.state.isLiked + ""}
              onClick={this.toggleLike}
              ga={{
                category: "like",
                action: this.props.type,
                label: "" + this.props.data.id,
                value: this.state.isLiked ? 1 : -1,
              }}
            >
              <span data-uk-icon="icon: heart" />
            </Button>
          </div>
          {/*<div className="uk-width-auto">
            <span className="uk-text-muted uk-text-mincho">
              Like
            </span><br/>
            <span className="uk-text-mincho uk-h3 uk-margin-remove">
              { Formatter.comma(this.state.count) }
            </span>
          </div>*/}
        </div>
      </div>
    );
  };
}

export const LikeDetail = connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(LikeDetailComponent);
