import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Image } from "../util/Image";
import { IntlText } from "../util/Text";
import { Link } from "../util/Clickable";
import { editBanner, sortBanner, deleteBanner } from "../../actions/admin/banner"
import { Notification } from "../../utils/notification";
import { showConfirm } from "../../actions/message";
import BannerForm from "../forms/admin/BannerForm";

declare var UIkit;
class BannersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_banner: null
    }
  }

  onEdit = (id, value) => {
    this.props.actions
      .editBanner(id, value)
      .then(({ value, status }) => {
        Notification.success("Edit Succeeded.");
        if (value.status < 300) {
          window.location.href = '/ja/admin/banner';
        }
      })
      .catch((error) => console.log(error));
  };

  onSort = (id, above_or_below) => {
    this.props.actions
      .sortBanner(id, above_or_below)
      .then(({ value, status }) => {
        Notification.success("Edit Succeeded.");
        if (value.status < 300) {
          window.location.href = '/ja/admin/banner';
        }
      })
      .catch((error) => console.log(error));
  };

  onDelete = (id) => {
    this.props.actions.showConfirm(
      "Do you want to really delete?",
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }
        this.props.actions
          .deleteBanner(id)
          .then(({ value, status }) => {
            Notification.success("Delete Succeeded.");
            if (value.status < 300) {
              window.location.href = '/ja/admin/banner';
            }
          })
          .catch((error) => console.log(error));
      },
    );
  };

  render = () => {
    if (this.props.banners == null) {
      return (
        <div className="uk-text-center">
          <span banner-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.banners.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.banners.no" />
          </h3>
        </div>
      );
    }

    return (
      <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-striped">
          <thead>
            <tr>
              <th className="uk-table-shrink">PC Thumnail</th>
              <th className="uk-table-shrink">SP Thumnail</th>
              <th className="uk-table-shrink">Site Url</th>
              <th className="uk-table-shrink">Start Date</th>
              <th className="uk-table-shrink">End Date</th>
              <th className="uk-table-shrink">Edit</th>
              <th className="uk-table-shrink">Order</th>
              <th className="uk-table-shrink">Delete</th>
            </tr>
          </thead>
          <tbody>
            {this.props.banners.map((banner, key) => {
              if (banner.pc_thumnail_url.includes(this.props.language.edit)) {
                return (
                  <tr key={key}>
                    <td>
                      <Image
                        className="uk-width-medium"
                        src={banner.pc_thumnail_url}
                        style={{'aspect-ratio': '5 / 2', 'object-fit': 'cover'}}
                      />
                    </td>
                    <td>
                      <Image
                        className="uk-width-medium"
                        src={banner.sp_thumnail_url}
                        style={{'aspect-ratio': '4 / 3', 'object-fit': 'cover'}}
                      />
                    </td>
                    <td>
                      <a className="uk-margin-small">{banner.site_url}</a>
                    </td>
                    <td>
                      <div className="uk-width-small">
                        {banner.start_at &&
                          moment(banner.start_at).format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                    </td>
                    <td>
                      <div className="uk-width-small">
                        {banner.end_at &&
                          moment(banner.end_at).format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                    </td>
                    <td>
                      <Link
                          className="uk-button"
                          uk-icon="icon: pencil"
                          onClick={() => {
                            this.setState({edit_banner: banner})
                            UIkit.modal(this.editModal, { container: false }).show();
                          }}
                        >
                      </Link>
                    </td>
                    <td>
                      <div className="uk-flex uk-flex-column">
                        <Link
                          className="uk-button"
                          uk-icon="icon: chevron-up"
                          onClick={() => {this.onSort(banner.id, -1)}}
                        >
                        </Link>
                        <Link
                          className="uk-button"
                          uk-icon="icon: chevron-down"
                          onClick={() => {this.onSort(banner.id, 1)}}
                        >
                        </Link>
                      </div>
                    </td>
                    <td>
                      <Link
                        className="uk-button"
                        uk-icon="icon: trash"
                        onClick={() => {this.onDelete(banner.id)}}
                      >
                      </Link>
                    </td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
        <div ref={(editModal) => (this.editModal = editModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">Edit Banner</h2>
            </div>
            <div className="uk-modal-body">
              <BannerForm
                ref={(editForm) => (this.editForm = editForm)}
                form="banner-add-form"
                onSubmit={(values) => this.onEdit(this.state.edit_banner.id, values)}
                initialValues={this.state.edit_banner}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    editBanner: (...param) => dispatch(editBanner(...param)),
    sortBanner: (...param) => dispatch(sortBanner(...param)),
    deleteBanner: (...param) => dispatch(deleteBanner(...param)),
    showConfirm: (...param) => dispatch(showConfirm(...param)),
  }, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BannersList),
);
