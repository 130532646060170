import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchPhotos } from "../../actions/photo";
import { get, search as searchShows } from "../../actions/show";
import Meta from "../../components/layout/Meta";
import ArtworkCards from "../../components/lists/ArtworkCards";
import PhotoList from "../../components/lists/Photos";
import ShowCards from "../../components/lists/ShowCards";
import { Button, Link } from "../../components/util/Clickable";
import { LikeDetail } from "../../components/util/Like";
import { Loading } from "../../components/util/Loading";
import MapBase from "../../components/util/Map";
import NotFound from "../../components/util/NotFound";
import SlideShow from "../../components/util/SlideShow";
import { IntlText, LinedText } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";

declare var UIkit;

class ViewScreen extends PureComponent {
  state = {
    show: null,
    shows: null,
    photos: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    this.reload(this.props.match.params.showId);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.match.params.showId != nextProps.match.params.showId) {
      this.reload(nextProps.match.params.showId);
    }
  };

  reload = (showId) => {
    this.setState({
      show: null,
      artworks: null,
      artists: null,
      related_shows: null,
    });

    this.props.actions.get(showId).then(({ value }) => {
      if (value.status > 300) {
        return this.setState({
          show: false,
        });
      }
      this.setState({
        show: value.data,
        related_shows: [],
      });
      if (value.data.created_gallery_id) {
        this.props.actions
          .searchShows({
            gallery_id: value.data.created_gallery_id,
          })
          .then(({ value }) => {
            if (value.status > 300) {
              return;
            }
            this.setState({
              related_shows: value.data,
            });
          });
      }
    });
    this.readMore(true, showId);
  };

  readMore = (refresh = false, showId) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .searchPhotos({
        show_id: showId,
        page: refresh ? 1 : this.state.meta.current_page + 1,
        pagesize: 24,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            photos:
              this.state.photos && !refresh
                ? this.state.photos.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    if (this.state.show == null) {
      return <Loading />;
    } else if (!this.state.show) {
      return <NotFound />;
    }
    const show = Formatter.mergeDesc(
      this.state.show,
      this.state.show.show_descs,
      this.props.language.view,
    );

    const galleries = show.galleries.map((gallery) => {
      return Formatter.mergeDesc(
        gallery,
        gallery.gallery_descs,
        this.props.language.view,
      );
    });

    const artists = show.artists.map((artist) => {
      return Formatter.mergeDesc(
        artist,
        artist.artist_descs,
        this.props.language.view,
      );
    });

    const thumnail_url =
      show.show_images && show.show_images.length > 0
        ? show.show_images[0].url
        : null;

    return (
      <div>
        <Meta
          title={show.title}
          description={show.description}
          image={thumnail_url}
        />
        <div className="uk-margin-remove">
          <div className="uk-margin-medium" data-uk-grid>
            <div className="uk-width-expand">
              <SlideShow
                title={show.title}
                images={show.show_images}
                selector
              />
            </div>
            <div className="uk-width-large@m">
              <div className="uk-margin-medium">
                <h1 className="uk-h2 uk-margin-remove">{show.title}</h1>
                <div className="uk-margin-small">
                  <LikeDetail type="show" data={show} />
                </div>
                <div className="uk-margin-small">
                  <p className="uk-margin-remove">
                    {galleries.map((gallery, key) => {
                      return (
                        <Link
                          key={key}
                          className="uk-link-reset uk-margin-ssmall-right uk-underline"
                          to={PathGenerator.getLocalPath(
                            Path.galleries.view.url,
                            { galleryId: gallery.id },
                          )}
                          ga={{
                            category: "link",
                            action: "show",
                            label: "gallery_" + gallery.id,
                          }}
                        >
                          {gallery.name}
                        </Link>
                      );
                    })}
                  </p>
                  <p className="uk-margin-remove">
                    {show.address}
                    <br />
                    {/* <Link className="uk-link-muted" onClick={ () => UIkit.modal(this.mapModal, {container: false}).show() } ga={{category: 'modal', action: 'show', label: 'map'}}>
                      <span className="uk-margin-ssmall-right" data-uk-icon="icon:location;ratio:0.8;" />
                      <IntlText id="forms.attr.map" />
                    </Link> */}
                  </p>
                </div>
                <div className="uk-margin-small">
                  <p className="uk-margin-ssmall uk-text-muted">
                    <span>
                      {show.start_at &&
                        moment(show.start_at).format("YYYY.MM.DD ddd")}
                    </span>
                    <span> - </span>
                    <span>
                      {show.end_at &&
                        moment(show.end_at).format("YYYY.MM.DD ddd")}
                    </span>
                  </p>
                  <LinedText
                    className="uk-margin-ssmall uk-text-muted"
                    text={show.schedule}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          {show.description && (
            <div className="uk-margin-medium">
              <h4 className="uk-margin-small">
                <IntlText
                  id="shows.view.about"
                  values={{ title: show.title }}
                />
              </h4>
              <LinedText
                className="uk-margin-small uk-text-muted"
                text={show.description}
              />
            </div>
          )}
        </div>

        <div className="uk-margin-large uk-chip" />

        {show.artworks && show.artworks.length > 0 && (
          <div className="uk-margin-large">
            <h2 className="uk-heading-mark">
              <IntlText
                id="shows.view.artworks"
                values={{ title: show.title }}
              />
            </h2>
            <div className="uk-margin-medium">
              <ArtworkCards artworks={show.artworks} />
            </div>
          </div>
        )}

        {this.state.photos && this.state.photos.length > 0 && (
          <div className="uk-margin-xlarge">
            <h2 className="uk-heading-mark">
              <IntlText id="shows.view.photos" values={{ title: show.title }} />
            </h2>
            <div className="uk-margin-medium">
              <PhotoList photos={this.state.photos} />
            </div>
            {this.state.meta.last_page &&
              this.state.meta.last_page > this.state.meta.current_page && (
                <div className="uk-margin-medium uk-text-center">
                  <Button
                    className="uk-button uk-button-default"
                    onClick={() =>
                      this.readMore(false, this.props.match.params.showId)
                    }
                    loading={this.state.loading ? 1 : 0}
                    ga={{
                      category: "button",
                      action: "fair_photos",
                      label: "more",
                    }}
                  >
                    <IntlText id="forms.more" />
                  </Button>
                </div>
              )}
          </div>
        )}

        {this.state.related_shows && this.state.related_shows.length > 0 && (
          <div className="uk-margin-xlarge">
            <h2 className="uk-heading-mark">
              <IntlText
                id="shows.view.others"
                values={{
                  gallery: (
                    <span>
                      {galleries.map((gallery, index) => {
                        return (
                          <Link
                            key={index}
                            className="uk-underline uk-link-reset uk-margin-ssmall-right"
                          >
                            {gallery.name}
                          </Link>
                        );
                      })}
                    </span>
                  ),
                }}
              />
            </h2>
            <div className="uk-margin-medium">
              <ShowCards shows={this.state.related_shows} />
            </div>
          </div>
        )}

        <div ref={(mapModal) => (this.mapModal = mapModal)} data-uk-modal>
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">Location of {show.title}</h2>
            </div>
            <div className="uk-modal-body">
              <MapBase isMarkerShown={true} address={show.address} />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (...param) => dispatch(get(...param)),
      searchShows: (...param) => dispatch(searchShows(...param)),
      searchPhotos: (...param) => dispatch(searchPhotos(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewScreen),
);
