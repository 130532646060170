const messages = {
  layout: {
    menu: {
      dashboard: "Dashboard",
      artists: "Artists",
      all_artists: "All Artists",
      add_artist: "Add Artist",
      artworks: "Artworks",
      all_artworks: "All Artworks",
      add_artwork: "Add Artwork",
      shows: "Shows",
      all_shows: "All Show",
      add_show: "Add Show",
      info_and_article: "Info & Article",
      informations: "Infomations",
      articles: "Articles",
      fairs: "Fairs",
      all_fairs: "All Fairs",
      transactions: "Transactions",
      purchases: "Purchases",
      sales: "Sales",
      setting: "Settings",
      edit_profile: "Edit Profile",
      location: "Locations",
      sign_out: "Sign Out",
    },
  },
  auth: {
    login: {
      header: "Gallery's Login",
      ifyouforget: "If you have forgotten your password, ",
      resethere: "reset it here.",
      donthave: "You don't have an account?",
      contact: "Please contact us by filling in this form.",
      contactus: "Contact Us",
      term: "Terms for gallery",
      agree: "Agree",
    },
    reset: {
      header: "Reset Password",
      back: "Back to login",
      completed: "Reset Completed",
      please: "Please login with new password.",
    },
    forget: {
      header: "Reset Password",
      back: "Back to login",
      completed: "Send reset mail",
      please:
        "We send mail with password reset link to {email}. Please check it.",
    },
  },
  dashboard: {
    title: "Dashboard",
    plan: "Plan",
    invitedFair: "Invited Fairs",
    transaction: "Transaction",
    information: "You gallery's news",
    newRequest: "You got {num} request!",
    nothing: "New purchase request is nothing.",
    check: "Check",
  },
  edit: {
    title: "Edit Profile",
    gallery_info: "Gallery Information",
    settings: "Settings",
    payment: "Payment",
    images: "Images",
    cover: "Cover",
    logo: "Logo",
    authorize: "Authorization Information",
    addcontact: "Add Contact Information",
    unsubscribe: "If you want to unsubscribe your membership, please ",
    contact: "contact us.",
    plan: "Plan",
  },
  location: {
    title: "Locations",
    add_location: "Add New Location",
    edit_location: "Edit Location",
    no: "No Locations.",
  },
  sales: {
    title: "Sales",
    monthly: "Monthly Summary",
    purchase: "Purchases(count)",
    sales: "Sales($)",
    revenue: "Revenue($)",
    history: "History",
  },
  purchases: {
    title: "Purchases",
    new: "New Purchase Requests",
    past: "Past Requests",
    confirm: "Do you confirm this request ?",
  },
  fairs: {
    title: "All fairs",
  },
  fair: {
    view_detail: "View Fair Detail",
    thumnail: "Gallery Thumnail",
    artworks: "Exhibition Artworks",
    image: "Image",
    description: "Description",
    actions: "Action",
    section: "Section",
    add: "Add Exhibition",
    edit: "Edit Exhibition",
  },
  articles: {
    title: "All Articles",
    add: "Add New Article",
  },
  article: {
    title: "Edit Article",
    basic: "Article information",
    thumnail: "Thumnail",
    description: "Description",
  },
  informations: {
    title: "Informations",
    add: "Add New Information",
    edit: "Edit Information",
    thumnail: "Thumnail",
  },
  shows: {
    title: "All Shows",
  },
  show: {
    title: "Add Show",
    edit_title: "Edit Show",
    artist: "Select Articles",
    artwork: "Select Artworks",
    info: "Show Info",
    images: "Show Images",
  },
  artworks: {
    title: "All Artworks",
    add_title: "Add New Artwork",
    edit_title: "Edit Artwork",
    artist: "Select Artist",
    recommend: "Do you want these artworks?",
    sale_info: "Sale Info",
    images: "Artwork Images",
  },
  artists: {
    title: "All Artists",
    add_title: "Add Artist",
    edit_title: "Edit Artist",
    profile: "Profile",
    thumnail: "Thumnail",
    recommend: "Do you want these artists?",
    new_artist: "New Artist",
  },
};

export default messages;
