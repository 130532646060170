import qs from "qs";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { get, search as searchArtworks } from "../../actions/artwork";
import { search as searchGalleries } from "../../actions/gallery";
import { inquiry } from "../../actions/purchase";
import Artwork from "../../components/details/Artwork";
import Meta from "../../components/layout/Meta";
import ArtworkCardsList from "../../components/lists/ArtworkCards";
import { Link } from "../../components/util/Clickable";
import { Image } from "../../components/util/Image";
import { LikeButton } from "../../components/util/Like";
import { Loading } from "../../components/util/Loading";
import NotFound from "../../components/util/NotFound";
import { IntlText, LinedText } from "../../components/util/Text";
import Purchase from "../../components/views/Purchase";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";

declare var UIkit;

class ViewScreen extends PureComponent {
  state = {
    artwork: null,
    galleries: null,
    gallery_artworks: null,
    related_artworks: null,
  };

  componentWillMount = () => {
    this.reload(this.props.match.params.artworkId);
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.match.params.artworkId != nextProps.match.params.artworkId ||
      this.props.location.search != nextProps.location.search
    ) {
      this.reload(nextProps.match.params.artworkId);
    }
  };

  reload = (artworkId) => {
    const param = qs.parse(this.props.location.search.replace("?", ""));

    this.setState({
      artwork: null,
      galleries: null,
      gallery_artworks: null,
      related_artworks: null,
    });
    this.forceUpdate();

    this.props.actions
      .get(artworkId, { gallery_id: param.gallery_id })
      .then(({ value }) => {
        if (value.status > 300) {
          this.setState({
            artwork: false,
          });
          return;
        }
        this.setState({
          artwork: value.data,
        });
        this.forceUpdate();
        if (value.data.sale) {
          this.props.actions
            .searchArtworks({
              gallery_id: value.data.sale.gallery_id,
              pagesize: 3,
              except_id: value.data.id,
            })
            .then(({ value }) => {
              if (value.status > 300) {
                return;
              }
              this.setState({
                gallery_artworks: value.data,
              });
              this.forceUpdate();
            });
        }

        if (value.data.artists) {
          this.props.actions
            .searchArtworks({
              artist_ids: value.data.artists.map((artist) => artist.id),
              pagesize: 3,
              except_id: value.data.id,
            })
            .then(({ value }) => {
              if (value.status > 300) {
                return;
              }
              this.setState({
                related_artworks: value.data,
              });
              this.forceUpdate();
            });
        }
      });

    this.props.actions
      .searchGalleries({
        selling_artwork_id: artworkId,
      })
      .then(({ value }) => {
        if (value.status > 300) {
          return;
        }
        this.setState({
          galleries: value.data,
        });
        this.forceUpdate();
      });
  };

  onInquiry = (values) => {
    const param = Object.assign({}, values, {
      gallery_id: this.state.artwork.sale.gallery_id,
    });
    return this.props.actions.inquiry(this.state.artwork.id, param);
  };

  onSuccessContact = (data) => {
    this.props.history.push(
      PathGenerator.getLocalPath(
        Path.artworks.view.inquiried.url,
        { artworkId: this.state.artwork.id },
        { gallery_id: this.state.artwork.sale.gallery_id },
      ),
    );
  };

  renderAboutArtists = (artists) => {
    if (artists == null) {
      return;
    }
    return artists.map((artist, index) => {
      return (
        <div key={index} className="uk-margin-medium">
          <div
            className="uk-margin-small uk-flex-middle uk-grid-small"
            data-uk-grid="margin:uk-margin-ssmall;"
          >
            <div className="uk-width-auto@s">
              <h3 className="uk-margin-remove uk-underline">
                <Link
                  className="uk-link-reset"
                  to={PathGenerator.getLocalPath(Path.artists.view.url, {
                    artistId: artist.id,
                  })}
                  ga={{
                    category: "link",
                    action: "artwork",
                    label: "artist_" + artist.id,
                  }}
                >
                  {artist.name}
                </Link>
              </h3>
            </div>
            <div className="uk-width-large uk-width-expand@s">
              <span className="uk-text-muted">
                {(artist.birth_year || artist.death_year) && (
                  <span className="uk-margin-ssmall-right">
                    {artist.birth_year} - {artist.death_year}
                  </span>
                )}
                {artist.birth_place && (
                  <span className="uk-margin-ssmall-right">
                    {"born in " + artist.birth_place}
                  </span>
                )}
                {artist.activity_area && (
                  <span className="uk-margin-ssmall-right">
                    {"based in " + artist.activity_area}
                  </span>
                )}
              </span>
            </div>
          </div>
          <div className="uk-margin-small uk-grid-small" data-uk-grid>
            <div className="uk-width-auto">
              <div className="uk-thumnail uk-image-wrapper">
                <Image src={artist.thumnail_url} alt={artist.name} />
              </div>
            </div>
            <div className="uk-width-expand">
              <h4 className="uk-margin-remove uk-h5">
                <IntlText id="artworks.overview" />
              </h4>
              <LinedText
                className="uk-text-muted uk-margin-remove"
                text={artist.biography}
              />
              <div className="uk-margin-small uk-text-right">
                <LikeButton type="artist" data={artist} />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  renderGalleries = () => {
    if (this.state.galleries == null) {
      return <Loading />;
    } else if (this.state.galleries.length == 0) {
      return <IntlText id="lists.galleries.no" />;
    }
    return this.state.galleries.map((gallery, index) => {
      var gallery = Formatter.mergeDesc(
        gallery,
        gallery.gallery_descs,
        this.props.language.view,
      );
      return (
        <div key={index} className="uk-margin-medium">
          <div
            className="uk-margin-small uk-flex-middle uk-grid-small"
            data-uk-grid="margin:uk-margin-ssmall;"
          >
            <div className="uk-width-auto@s">
              <h3 className="uk-margin-remove uk-underline">
                <Link
                  className="uk-link-reset"
                  to={PathGenerator.getLocalPath(Path.galleries.view.url, {
                    galleryId: gallery.id,
                  })}
                  ga={{
                    category: "link",
                    action: "artwork",
                    label: "gallery_" + gallery.id,
                  }}
                >
                  {gallery.name}
                </Link>
              </h3>
            </div>
            <div className="uk-width-large@s">
              <span className="uk-text-muted">
                {gallery.locations
                  .map((location, index) => {
                    var location = Formatter.mergeDesc(
                      location,
                      location.location_descs,
                      this.props.language,
                    );
                    return location.place;
                  })
                  .join(", ")}
              </span>
            </div>
          </div>
          <div className="uk-margin-small uk-grid-small" data-uk-grid>
            <div className="uk-width-auto">
              <div className="uk-thumnail uk-image-wrapper">
                <Image src={gallery.logo_url} alt={gallery.name} />
              </div>
            </div>
            <div className="uk-width-expand">
              <h4 className="uk-margin-remove uk-h5">
                <IntlText id="artworks.overview" />
              </h4>
              <LinedText
                className="uk-text-muted uk-margin-remove"
                text={gallery.description}
              />
              <div className="uk-margin-small uk-text-right">
                <LikeButton type="gallery" data={gallery} />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  renderByArtists = (artists) => {
    if (this.state.related_artworks == null || this.state.artwork == null) {
      return <Loading />;
    }

    if (this.state.related_artworks.length == 0) {
      return null;
    }

    return (
      <div className="uk-margin-xlarge">
        <h2 className="uk-heading-mark">
          <IntlText
            id="artworks.other_by_artists"
            values={{
              artists: (
                <span>
                  {artists.map((artist, index) => {
                    return (
                      <Link
                        key={index}
                        className="uk-link-reset uk-underline uk-margin-ssmall-right"
                        to={PathGenerator.getLocalPath(Path.artists.view.url, {
                          artistId: artist.id,
                        })}
                        ga={{
                          category: "link",
                          action: "artwork",
                          label: "artist_" + artist.id,
                        }}
                      >
                        {artist.name}
                      </Link>
                    );
                  })}
                </span>
              ),
            }}
          />
        </h2>
        <div className="uk-margin-medium">
          <ArtworkCardsList artworks={this.state.related_artworks} />
        </div>
      </div>
    );
  };

  renderByGallery = () => {
    if (this.state.gallery_artworks == null || this.state.artwork == null) {
      return null;
    }
    if (
      this.state.artwork.sale == null ||
      this.state.gallery_artworks.length == 0
    ) {
      return null;
    }
    const gallery = Formatter.mergeDesc(
      this.state.artwork.sale.gallery,
      this.state.artwork.sale.gallery.gallery_descs,
      this.props.language.view,
    );
    return (
      <div className="uk-margin-xlarge">
        <h2 className="uk-heading-mark">
          <IntlText
            id="artworks.other_by_gallery"
            values={{
              gallery: (
                <Link
                  className="uk-link-reset uk-underline uk-margin-ssmall-right"
                  to={PathGenerator.getLocalPath(Path.galleries.view.url, {
                    galleryId: gallery.id,
                  })}
                  ga={{
                    category: "link",
                    action: "artwork",
                    label: "gallery_" + gallery.id,
                  }}
                >
                  {gallery.name}
                </Link>
              ),
            }}
          />
        </h2>
        <div className="uk-margin-medium">
          <ArtworkCardsList artworks={this.state.gallery_artworks} />
        </div>
      </div>
    );
  };

  render = () => {
    if (this.state.artwork == null) {
      return <Loading />;
    } else if (!this.state.artwork) {
      return <NotFound />;
    }

    const artwork = Formatter.mergeDesc(
      this.state.artwork,
      this.state.artwork.artwork_descs,
      this.props.language.view,
    );

    const artists = this.state.artwork.artists.map((artist, index) => {
      return Formatter.mergeDesc(
        artist,
        artist.artist_descs,
        this.props.language.view,
      );
    });

    const thumnail_url =
      artwork.artwork_images && artwork.artwork_images.length > 0
        ? artwork.artwork_images[0].url
        : null;
    const title =
      artwork.title + " by " + artists.map((artist) => artist.name).join(", ");
    return (
      <div>
        <Meta
          title={title}
          description={artwork.description}
          image={thumnail_url}
        />
        <div className="">
          <Artwork
            artwork={artwork}
            artists={artists}
            renderBuy={() => {
              return artwork.sale && <Purchase artwork={artwork} />;
            }}
          />
        </div>

        <div className="uk-chip uk-margin-large" />

        <div className="uk-margin-xlarge">
          <h2 className="uk-heading-mark">
            <IntlText id="artworks.artists" values={{ title: artwork.title }} />
          </h2>
          {this.renderAboutArtists(artists)}
        </div>

        <div className="uk-margin-xlarge">
          <h2 className="uk-heading-mark">
            <IntlText id="artworks.gallery" values={{ title: artwork.title }} />
          </h2>
          {this.renderGalleries()}
        </div>

        {this.renderByArtists(artists)}

        {this.renderByGallery()}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (...param) => dispatch(get(...param)),
      searchGalleries: (...param) => dispatch(searchGalleries(...param)),
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      inquiry: (...param) => dispatch(inquiry(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewScreen),
);
