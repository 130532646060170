import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search } from "../../../actions/gallery/artist";
import SearchForm from "../../../components/forms/SearchForm";
import ArtistCards from "../../../components/lists/ArtistCards";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Button, Link } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import { Path } from "../../../constants/path";

class IndexScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      artists: null,
      meta: {
        current_page: 0,
        last_page: null,
      },
      keyword: null,
      loading: false,
    };
  }

  componentWillMount = () => {
    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        gallery_id: this.props.gallery.id,
        keyword: this.state.keyword,
        page: this.state.meta.current_page + 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            artists:
              this.state.artists && !refresh
                ? this.state.artists.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  search = (values) => {
    this.setState({
      keyword: null,
      meta: {
        current_page: 0,
      },
    });
    const param = Object.assign({}, values, {
      gallery_id: this.props.gallery.id,
    });
    return this.props.actions.search(param);
  };

  onSearched = (data, response) => {
    this.setState({
      artists: data,
      meta: response.data.meta,
    });
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-small" data-uk-grid>
          <div className="uk-width-auto">
            <h1>
              <IntlText id="gallery.artists.title" />
            </h1>
          </div>
          <div className="uk-width-expand">
            <Link
              className="uk-button uk-button-default"
              to={Path.gallery.artists.add.url}
            >
              <IntlText id="forms.add" />
              <span
                className="uk-margin-small-left"
                data-uk-icon="icon:plus; ratio:0.8;"
              />
            </Link>
          </div>
          <div className="uk-width-medium@s uk-width-1-1 uk-text-right">
            <ChangeEditLang />
          </div>
        </div>
        <div className="uk-margin-small">
          <SearchForm onSubmit={this.search} onSuccess={this.onSearched} />
        </div>
        <div className="uk-margin-large">
          <ArtistCards
            artists={this.state.artists}
            lang={this.props.language.edit}
            editing={true}
          />
        </div>

        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-large uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
              >
                <IntlText id="forms.more" />
              </Button>
            </div>
          )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
