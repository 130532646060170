import {
  SEARCH_SHOWS,
  GET_SHOW,
  ADD_SHOW,
  EDIT_SHOW,
  DELETE_SHOW,
  DELETE_SHOW_IMAGE,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param = {}) => {
  return (dispatch) => {
    return {
      type: SEARCH_SHOWS,
      payload: () => {
        return Api.get(EndPoints.galleryShows(), param);
      },
    };
  };
};

export const get = (showId) => {
  return (dispatch) => {
    return {
      type: GET_SHOW,
      payload: () => {
        return Api.get(EndPoints.galleryShows(showId));
      },
    };
  };
};

export const add = (data) => {
  return (dispatch) => {
    return {
      type: ADD_SHOW,
      payload: () => {
        return Api.post(EndPoints.galleryShows(), data);
      },
    };
  };
};

export const edit = (showId, data) => {
  return (dispatch) => {
    return {
      type: EDIT_SHOW,
      payload: () => {
        return Api.put(EndPoints.galleryShows(showId), data);
      },
    };
  };
};

export const deleteShow = (showId) => {
  return (dispatch) => {
    return {
      type: DELETE_SHOW,
      payload: () => {
        return Api.delete(EndPoints.galleryShows(showId));
      },
    };
  };
};

export const deleteImage = (showId, showImageId) => {
  return (dispatch) => {
    return {
      type: DELETE_SHOW_IMAGE,
      payload: () => {
        return Api.delete(EndPoints.galleryShowImages(showId, showImageId));
      },
    };
  };
};
