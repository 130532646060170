import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { edit, editAuth, getData } from "../../../actions/admin/index";
import AdminForm from "../../../components/forms/admin/AdminForm";
import AuthForm from "../../../components/forms/admin/AuthForm";
import { Notification } from "../../../utils/notification";

class EditScreen extends PureComponent {
  state = {
    admin: null,
    editing: {},
  };

  componentWillMount = () => {
    this.props.actions
      .getData()
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            admin: value.data,
            editing: value.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };
  onSubmitDesc = (values) => {
    return this.props.actions.edit(values);
  };

  onSuccessDesc = (data) => {
    Notification.success("Update succeeded.");
    this.setState({
      admin: data,
    });
  };

  onSubmitAuth = (values) => {
    return this.props.actions.editAuth(values);
  };

  onUploaded = (data) => {
    Notification.success("Upload succeeded.");
  };

  render = () => {
    return (
      <div>
        <h1>Edit Profile</h1>
        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">Admin Info</h2>
          <div className="uk-margin">
            <AdminForm
              initialValues={this.state.editing}
              onSuccess={this.onSuccessDesc}
              onSuccess={this.onUploaded}
              onSubmit={this.onSubmitDesc}
            />
          </div>
        </div>

        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">Auth Info</h2>
          <div className="uk-margin">
            <AuthForm
              initialValues={{ email: this.state.editing.email }}
              onSuccess={this.onUploaded}
              onSubmit={this.onSubmitAuth}
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    admin: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getData: () => dispatch(getData()),
      edit: (...param) => dispatch(edit(...param)),
      editAuth: (...param) => dispatch(editAuth(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditScreen),
);
