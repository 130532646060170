import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { purchaseStatus, PURCHASE_CANCELED } from "../../constants/option";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Button, Link } from "../util/Clickable";
import { Loading } from "../util/Loading";
import { IntlText, LinedText } from "../util/Text";

class PurchasesList extends PureComponent {
  renderStatus = (status) => {
    const st = purchaseStatus.find((s) => s.value == status);
    if (st == null) {
      return;
    }
    if (st.value == PURCHASE_CANCELED) {
      return (
        <span className="uk-background-secondary uk-label uk-text-white">
          <IntlText id={st.labelId} />
        </span>
      );
    } else {
      return (
        <span className="uk-label">
          <IntlText id={st.labelId} />
        </span>
      );
    }
  };

  render = () => {
    if (this.props.purchases == null) {
      return <Loading />;
    } else if (this.props.purchases.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.purchases.no" />
          </h3>
        </div>
      );
    }

    return (
      <table className="uk-table uk-table-striped">
        <thead>
          <tr>
            <th className="uk-table-shrink">
              <IntlText id="lists.purchases.date" />
            </th>
            <th className="uk-table-expand">
              <IntlText id="lists.purchases.artwork" />
            </th>
            <th className="uk-table-expand">
              <IntlText id="lists.purchases.collector" />
            </th>
            <th className="uk-table-expand">
              <IntlText id="lists.purchases.messages" />
            </th>
            <th className="uk-table-shrink"></th>
          </tr>
        </thead>
        <tbody>
          {this.props.purchases.map((purchase, index) => {
            purchase.artwork = Formatter.mergeDesc(
              purchase.artwork,
              purchase.artwork.artwork_descs,
              this.props.language.view,
            );
            return (
              <tr key={index}>
                <td>
                  <div className="uk-width-small">
                    {moment(purchase.updated_at).format("YYYY/MM/DD")}
                  </div>
                </td>
                <td>
                  <h4 className="uk-margin-small">
                    <Link
                      to={PathGenerator.getLocalPath(
                        Path.gallery.artworks.edit.url,
                        { artworkId: purchase.artwork.id },
                      )}
                    >
                      {purchase.artwork.title}
                    </Link>
                  </h4>
                  <p className="uk-margin-small">
                    <span className="uk-margin-ssmall-right uk-h4">
                      {Formatter.pricing(purchase.price)}
                    </span>
                    <span>{purchase.price_unit}</span>
                  </p>
                </td>
                <td>
                  <h4 className="uk-margin-ssmall">
                    {purchase.collector.name}
                  </h4>
                  <p className="uk-margin-ssmall">
                    {purchase.country}
                    <br />
                    {purchase.address}
                  </p>
                </td>
                <td>
                  <div className="uk-margin-ssmall">
                    <h5 className="uk-margin-remove uk-text-muted">
                      <IntlText id="lists.purchases.from_collector" />
                    </h5>
                    <LinedText
                      className="uk-margin-remove"
                      text={purchase.collector_message}
                    />
                  </div>
                  <div className="uk-margin-ssmall">
                    <h5 className="uk-margin-remove uk-text-muted">
                      <IntlText id="lists.purchases.from_gallery" />
                    </h5>
                    <LinedText
                      className="uk-margin-remove"
                      text={purchase.gallery_message}
                    />
                  </div>
                </td>
                <td>
                  <div className="uk-width-small uk-margin-ssmall">
                    {this.props.onEdit ? (
                      <Button
                        className="uk-button uk-button-default uk-width-1-1 uk-padding-remove"
                        onClick={() => this.props.onEdit(purchase)}
                      >
                        <IntlText id="lists.purchases.confirm" />
                      </Button>
                    ) : (
                      this.renderStatus(purchase.status)
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PurchasesList),
);
