import qs from "qs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Link } from "../util/Clickable";

class Paginate extends Component {
  createUrl = (page) => {
    const param = qs.parse(this.props.location.search.replace("?", ""));
    param.page = page;
    return this.props.location.pathname + "?" + qs.stringify(param);
  };

  render = () => {
    if (
      !this.props.meta ||
      !this.props.meta.current_page ||
      !this.props.meta.last_page
    ) {
      return null;
    }
    const pages = [];

    const start =
      this.props.meta.current_page - 3 > 1
        ? this.props.meta.current_page - 3
        : 1;
    const last =
      this.props.meta.current_page + 3 < this.props.meta.last_page
        ? this.props.meta.current_page + 3
        : this.props.meta.last_page;

    for (var i = start; i <= last; i++) {
      if (i == this.props.meta.current_page) {
        pages.push(
          <span key={i} className="uk-button uk-button-box" disabled>
            <span>{i}</span>
          </span>,
        );
      } else {
        pages.push(
          <Link
            key={i}
            className="uk-button uk-button-box"
            to={this.createUrl(i)}
          >
            <span>{i}</span>
          </Link>,
        );
      }
    }

    return (
      <div className="uk-text-center">
        {this.props.meta.current_page > 1 ? (
          <Link
            className="uk-button uk-button-box uk-margin-small-right"
            to={this.createUrl(this.props.meta.current_page - 1)}
          >
            <span data-uk-icon="icon: chevron-left" />
          </Link>
        ) : (
          <span
            className="uk-button uk-button-box uk-margin-small-right"
            disabled
          >
            <span data-uk-icon="icon: chevron-left" />
          </span>
        )}
        {pages}
        {this.props.meta.current_page < this.props.meta.last_page ? (
          <Link
            className="uk-button uk-button-box uk-margin-small-left"
            to={this.createUrl(this.props.meta.current_page + 1)}
          >
            <span data-uk-icon="icon: chevron-right" />
          </Link>
        ) : (
          <span
            className="uk-button uk-button-box uk-margin-small-left"
            disabled
          >
            <span data-uk-icon="icon: chevron-right" />
          </span>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Paginate),
);
