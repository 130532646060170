import { SEARCH_ARTWORKS, GET_ARTWORK } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";

import { addArtworkHistory } from "./history";

export const search = (param = {}) => {
  return (dispatch) => {
    return {
      type: SEARCH_ARTWORKS,
      payload: () => {
        return Api.get(EndPoints.artworks(), param);
      },
    };
  };
};

export const get = (artworkId, param) => {
  return (dispatch) => {
    return {
      type: GET_ARTWORK,
      payload: () => {
        return Api.get(EndPoints.artworks(artworkId), param).then(
          (response) => {
            if (response.status < 300) {
              dispatch(addArtworkHistory(response.data));
            }
            return response;
          },
        );
      },
    };
  };
};
