import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { addContact } from "../../actions/contact";
import { showAlert } from "../../actions/message";
import ContactForm from "../../components/forms/ContactForm";
import Meta from "../../components/layout/Meta";
import { getPureMessage, IntlText } from "../../components/util/Text";
import Location from "../../components/views/Location";

class ContactScreen extends PureComponent {
  onContact = (values) => {
    return this.props.actions.addContact(values);
  };

  onSuccess = (data) => {
    this.props.actions.showAlert(
      getPureMessage("contact.thanks"),
      getPureMessage("contact.lead"),
    );
    this.form.wrappedInstance.props.reset();
  };

  render = () => {
    return (
      <div>
        <Meta
          title={
            getPureMessage("galleries.contact.meta") + this.props.gallery.name
          }
          description={this.props.gallery.description}
          image={this.props.gallery.thumnail_url}
        />
        <div className="uk-margin-large uk-flex-center" data-uk-grid>
          <div className="uk-width-expand@m uk-width-1-1">
            <h1 className="uk-heading-mark uk-h2">
              <IntlText
                id="galleries.contact.location"
                values={{ name: this.props.gallery.name }}
              />
            </h1>
            {this.props.gallery &&
              this.props.gallery.locations.map((location, index) => {
                return (
                  <div key={index} className="uk-margin-large">
                    <Location location={location} />
                  </div>
                );
              })}
          </div>
          <div className="uk-width-large@m">
            <h2 className="uk-margin-remove uk-h2">
              <IntlText id="galleries.contact.title" />
            </h2>
            <div className="uk-margin-medium">
              <ContactForm
                ref={(form) => (this.form = form)}
                initialValues={{
                  gallery_id: this.props.gallery.id,
                  name: this.props.collector.name,
                  email: this.props.collector.email,
                  tel: this.props.collector.tel,
                }}
                onSubmit={this.onContact}
                onSuccess={this.onSuccess}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    collector: state.auth.role == "collector" ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showAlert: (title, message) => dispatch(showAlert(title, message)),
      addContact: (data) => dispatch(addContact(data)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactScreen),
);
