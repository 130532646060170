import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArtworks } from "../../actions/artwork";
import { setCondition } from "../../actions/cache";
import ArtworkFilterForm from "../../components/forms/ArtworkFilterForm";
import Meta from "../../components/layout/Meta";
import ArtworkCards from "../../components/lists/ArtworkCards";
import { Button, Link } from "../../components/util/Clickable";
import { IntlText, LinedText, ViewAllHeader } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";

class OverviewScreen extends PureComponent {
  state = {
    queryParam: {
      sort_method: "recommend",
    },
    artworks: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.readMore(true);
    }
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    const param = Object.assign({}, this.state.queryParam, {
      artist_id: this.props.artist.id,
      pagesize: 24,
      page: this.state.meta.current_page + 1,
    });

    this.props.actions
      .searchArtworks(param)
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState(
            {
              artworks:
                this.state.artworks && !refresh
                  ? this.state.artworks.concat(value.data)
                  : value.data,
              meta: value.response.data.meta,
              loading: false,
            },
            () => {
              this.props.actions.setCondition(this.state);
            },
          );
        }
      })
      .catch((error) => console.log(error));
  };

  onSearch = (values) => {
    this.setState(
      {
        queryParam: values,
        artworks: null,
        meta: {
          current_page: 0,
        },
      },
      () => {
        this.readMore(true);
      },
    );
  };

  render = () => {
    return (
      <div>
        <Meta
          title={this.props.artist.name}
          description={this.props.artist.biography}
          image={this.props.artist.thumnail_url}
        />

        {this.props.artist.biography && (
          <div className="uk-margin-medium-bottom">
            <h2 className="uk-heading-mark uk-h2">
              <IntlText
                id="artists.overview.biography"
                values={{ name: this.props.artist.name }}
              />
            </h2>
            <LinedText text={this.props.artist.biography} />
          </div>
        )}

        {this.props.artist.history && (
          <div className="uk-margin-xlarge-bottom">
            <div className="uk-margin-medium">
              <h2 className="uk-heading-mark">
                <IntlText
                  id={"artists.overview.history"}
                  values={{ name: this.props.artist.name }}
                />
              </h2>
            </div>
            <div className="uk-margin-medium">
              <LinedText text={this.props.artist.history} />
            </div>
            <div className="uk-margin uk-text-center">
              <Link
                className="uk-button uk-button-default uk-button-plus"
                to={PathGenerator.getLocalPath(Path.artists.view.history.url, {
                  artistId: this.props.artist.id,
                })}
                ga={{ category: "link", action: "root", label: "all_history" }}
              >
                <IntlText id="word.view_all_text" />
              </Link>
            </div>
          </div>
        )}

        <div className="uk-margin-xlarge-bottom">
          <div className="uk-margin-medium">
            <ViewAllHeader
              title={
                <IntlText
                  id="artists.overview.artworks"
                  values={{ name: this.props.artist.name }}
                />
              }
              url={PathGenerator.getLocalPath(Path.artists.view.artworks.url, {
                artistId: this.props.artist.id,
              })}
            />
          </div>
          <div className="uk-margin-medium">
            <ArtworkFilterForm
              initialValues={this.state.queryParam}
              onChange={this.onSearch}
            />
          </div>

          <div className="uk-margin-medium">
            <ArtworkCards artworks={this.state.artworks} />
          </div>

          {this.state.meta.last_page &&
            this.state.meta.last_page > this.state.meta.current_page && (
              <div className="uk-margin-medium uk-text-center">
                <Button
                  className="uk-button uk-button-default"
                  onClick={() => this.readMore(false)}
                  loading={this.state.loading ? 1 : 0}
                  ga={{ category: "button", action: "artists", label: "more" }}
                >
                  <IntlText id="forms.more" />
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverviewScreen),
);
