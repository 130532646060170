const messages = {
  purchase: {
    contacted: "新着受付",
    agreed: "承認済み",
    paid: "支払い済み",
    canceled: "キャンセル",
    error: "エラー",
  },
  status: {
    private: "非公開",
    public: "公開中",
  },
  sales_status: {
    editing: "非公開",
    not_sale: "非売品",
    contact: "要問い合わせ",
    for_sale: "要問い合わせ（価格表示）",
    available: "販売中",
    sold: "SOLD",
  },
  fair_only_status: {
    open: "公開",
    fair_only: "フェアページのみ",
    fair_vip_only: "VIPページのみ",
  },
  sorts: {
    recommend: "おすすめ順",
    recent: "更新順",
    higher: "価格が高い順",
    lower: "価格が安い順",
    older: "作成年が古い作品順",
    newer: "作成年が新しい作品順",
  },
  mediums: {
    painting: "油画・水彩画・アクリル画",
    japanese: "日本画",
    print: "版画",
    paper: "ドローイング",
    sculpture: "彫刻・立体",
    photo: "写真",
    media: "メディアアート",
    mixed: "ミクストメディア",
    design: "デザイン",
    jewelry: "ジュエリー",
    other: "その他",
  },
};

export default messages;
