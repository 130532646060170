import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search } from "../../../actions/fair.js";
import { add, deleteRelate, getAll } from "../../../actions/fair/relate";
import SearchForm from "../../../components/forms/SearchForm";
import FairsList from "../../../components/lists/Fairs";
import { Button } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import Fair from "../../../components/views/FairCard";
import { Notification } from "../../../utils/notification";

class RelatedFairsScreen extends PureComponent {
  state = {
    candidates: [],
    relateds: [],
    meta: {
      current_page: 0,
      last_page: null,
    },
    keyword: null,
    loading: false,
  };

  componentWillMount = () => {
    this.readMore(true);
    this.props.actions
      .getAll()
      .then(({ value }) => {
        if (value.status > 300) {
          return;
        }
        this.setState({ relateds: value.data });
      })
      .catch((error) => console.log(error));
  };

  onSearch = (values) => {
    this.setState({ keyword: values.keyword });
    const param = Object.assign({}, values, {
      not_related_fair_id: this.props.fair.id,
    });
    return this.props.actions.search(param);
  };

  onSearched = (data, response) => {
    this.setState({
      candidates: data.filter((fair) => {
        return !this.state.relateds.find((select) => {
          return select.id == fair.id;
        });
      }),
      meta: response.data.meta,
    });
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        not_related_fair_id: this.props.fair.id,
        keyword: this.state.keyword,
        page: this.state.meta.current_page + 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          const newGalleries = value.data.filter((fair) => {
            return !this.state.relateds.find((select) => {
              return select.id == fair.id;
            });
          });
          this.setState({
            candidates:
              this.state.candidates && !refresh
                ? this.state.candidates.concat(newGalleries)
                : newGalleries,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onSelect = (fair) => {
    this.props.actions
      .add({ related_id: fair.id })
      .then(({ value }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.add");
          this.setState({
            candidates: this.state.candidates.filter(
              (gal) => gal.id != fair.id,
            ),
            relateds: this.state.relateds.concat([value.data]),
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onUnselect = (fair) => {
    this.props.actions
      .deleteRelate(fair.id)
      .then(({ value }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.delete");
          this.setState({
            candidates: this.state.candidates.concat([fair]),
            relateds: this.state.relateds.filter(
              (r) => r.related.id != fair.id,
            ),
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="fair.related.title" />
        </h1>
        <div className="uk-margin-small">
          <SearchForm onSubmit={this.onSearch} onSuccess={this.onSearched} />
        </div>
        <div className="uk-margin-medium" data-uk-grid>
          <div className="uk-width-2-3@s">
            <h2>
              <IntlText id="fair.related.others" />
            </h2>
            <div className="uk-margin">
              <FairsList
                fairs={this.state.candidates}
                onClick={this.onSelect}
                editing={true}
                buttonText={<IntlText id="forms.add" />}
              />
            </div>
            {this.state.meta.last_page &&
              this.state.meta.last_page > this.state.meta.current_page && (
                <div className="uk-margin uk-text-center">
                  <Button
                    className="uk-button uk-button-default"
                    onClick={() => this.readMore(false)}
                    loading={this.state.loading ? 1 : 0}
                  >
                    <IntlText id="forms.more" />
                  </Button>
                </div>
              )}
          </div>
          <div className="uk-width-1-3@s">
            <h2>
              <IntlText id="fair.related.related" />
            </h2>
            <div className="uk-margin">
              {this.state.relateds &&
                this.state.relateds.map((related, index) => {
                  return (
                    <div key={index} className="uk-margin-small">
                      <Fair
                        fair={related.related}
                        onEdit={(value) => this.onUnselect(related.related)}
                        editing={true}
                        lang={this.props.language.view}
                        buttonText={
                          this.props.buttonText ? (
                            this.props.buttonText
                          ) : (
                            <IntlText id="fair.related.remove" />
                          )
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    fair: state.auth ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (param) => dispatch(search(param)),
      getAll: () => dispatch(getAll()),
      add: (param) => dispatch(add(param)),
      deleteRelate: (param) => dispatch(deleteRelate(param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RelatedFairsScreen),
);
