import React, { PureComponent } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import TextInput from "../inputs/TextInput";
import { Button } from "../util/Clickable";

class SearchForm extends PureComponent {
  state = {
    loading: false,
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-grid-collapse" data-uk-grid>
          <div className="uk-width-medium">
            <Field
              name={this.props.name ? this.props.name : "keyword"}
              type="text"
              component={TextInput}
            />
          </div>
          <div className="uk-width-auto">
            <Button
              className="uk-button uk-button-primary"
              type="submit"
              disabled={this.props.invalid}
              ga={{ category: this.props.form, action: "submit" }}
              loading={this.state.loading ? 1 : 0}
            >
              <span data-uk-icon="icon:search;" />
            </Button>
          </div>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "search-form",
  enableReinitialize: true,
})(SearchForm);
