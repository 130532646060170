import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import Select from "../inputs/Select";
import { getPureMessage } from "../util/Text";

class SectionFilterForm extends PureComponent {
  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        action={this.props.action}
        method="post"
      >
        <div className="uk-width-medium">
          <Field
            name="section_id"
            component={Select}
            className="uk-form-small uk-form-clear"
            noError
            placeholder={getPureMessage("forms.attr.section_filter.section")}
            options={this.props.sections.map((section) => {
              return { label: section.label, value: section.id };
            })}
          />
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    constant: state.constant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default reduxForm({
  form: "section-filter-form",
  // enableReinitialize : true
})(connect(mapStateToProps, mapDispatchToProps)(SectionFilterForm));
