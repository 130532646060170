import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { confirm, deny, search } from "../../../actions/gallery/purchase";
import PurchaseRequestForm from "../../../components/forms/gallery/PurchaseRequestForm";
import PurchasesList from "../../../components/lists/Purchases";
import { Button } from "../../../components/util/Clickable";
import { FirstImage } from "../../../components/util/Image";
import { IntlText } from "../../../components/util/Text";
import { PURCHASE_CONTACTED } from "../../../constants/option";
import { Formatter } from "../../../utils/formatter";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class PurchasesScreen extends PureComponent {
  state = {
    unchecked: null,
    past: null,
    editing: null,
    message: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
    password: null,
    unlocked: false,
  };

  componentWillMount = () => {
    this.props.actions
      .search({
        status: PURCHASE_CONTACTED,
        all: true,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            unchecked: value.data,
          });
        }
      })
      .catch((error) => console.log(error));

    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }

    this.props.actions
      .search({
        not_status: PURCHASE_CONTACTED,
        page: this.state.meta.current_page + 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            past:
              this.state.past && !refresh
                ? this.state.past.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  editMessage = (text) => {
    this.setState({ message: text });
  };

  onEdit = (purchase) => {
    this.setState(
      {
        editing: purchase,
        message: null,
      },
      () => {
        UIkit.modal(this.editModal, { container: false }).show();
      },
    );
  };

  onConfirm = (values) => {
    return this.props.actions.confirm(this.state.editing.id, values);
  };

  onDeny = (values) => {
    return this.props.actions.deny(this.state.editing.id, values);
  };

  onSuccess = (data) => {
    UIkit.modal(this.editModal).hide();
    this.setState({
      unchecked: this.state.unchecked.filter(
        (purchase) => purchase.id != data.id,
      ),
      past: [data].concat(this.state.past),
    });
    Notification.successIntl("util.success.update");
  };

  onPassChange = (value) => {
    this.setState({ password: value });
  };

  onUnlock = () => {
    if (this.state.password == "K6zuC8qF") {
      this.setState({ unlocked: true });
    }
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="gallery.purchases.title" />
        </h1>
        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.purchases.new" />
          </h2>
          <div className="uk-margin">
            <PurchasesList
              purchases={this.state.unchecked}
              onEdit={this.onEdit}
            />
          </div>
        </div>

        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.purchases.past" />
          </h2>
          <div className="uk-margin">
            <PurchasesList purchases={this.state.past} />
          </div>
          {this.state.meta.last_page &&
            this.state.meta.last_page > this.state.meta.current_page && (
              <div className="uk-margin uk-text-center">
                <Button
                  className="uk-button uk-button-default"
                  onClick={() => this.readMore(false)}
                  loading={this.state.loading ? 1 : 0}
                >
                  <IntlText id="forms.more" />
                </Button>
              </div>
            )}
        </div>

        <div ref={(editModal) => (this.editModal = editModal)} data-uk-modal>
          {this.state.editing && (
            <div className="uk-modal-dialog uk-width-xxlarge@s">
              <button
                className="uk-modal-close-default"
                type="button"
                data-uk-close
              ></button>
              <div className="uk-modal-header">
                <h2 className="uk-modal-title uk-text-center">
                  <IntlText id="gallery.purchases.confirm" />
                </h2>
              </div>

              {(() => {
                if (this.state.unlocked) {
                  return (
                    <div className="uk-modal-body">
                      <div
                        data-uk-grid
                        className="uk-margin-small uk-grid-small uk-flex-middle"
                      >
                        <div className="uk-width-expand">
                          <h3 className="uk-margin-ssmall uk-text-center">
                            {this.state.editing.artwork.title}
                          </h3>
                          <p className="uk-margin-ssmall uk-text-center">
                            <span className="uk-margin-ssmall-right uk-h4">
                              {Formatter.pricing(this.state.editing.price)}
                            </span>
                            <span>{this.state.editing.price_unit}</span>
                          </p>
                          <div className="uk-margin-ssmall uk-text-center">
                            <div className="uk-thumnail-small uk-image-wrapper">
                              <FirstImage
                                srcs={this.state.editing.artwork.artwork_images}
                                finder={(image) => image.url}
                                alt={this.state.editing.artwork.title}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="uk-width-auto">
                          <span data-uk-icon="icon: arrow-right" />
                        </div>
                        <div className="uk-width-expand">
                          <h3 className="uk-margin-ssmall uk-text-center">
                            {this.state.editing.collector.name}
                          </h3>
                          <p className="uk-margin-ssmall uk-text-center">
                            {this.state.editing.country}
                            <br />
                            {this.state.editing.address}
                          </p>
                        </div>
                      </div>

                      <div className="uk-margin">
                        <PurchaseRequestForm
                          onDeny={this.onDeny}
                          onConfirm={this.onConfirm}
                          onSuccess={this.onSuccess}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="uk-modal-body">
                      <h3 className="uk-margin-ssmall uk-text-center">
                        <IntlText id="gallery.purchases.by_admins" />
                      </h3>
                      <div
                        className="uk-margin uk-flex-center uk-grid-collapse"
                        data-uk-grid
                      >
                        <div className="uk-width-medium">
                          <input
                            type="password"
                            className="uk-input"
                            onChange={(event) =>
                              this.onPassChange(event.target.value)
                            }
                            placeholder="Password"
                          />
                        </div>
                        <div className="uk-width-auto">
                          <Button
                            className="uk-button uk-button-primary"
                            onClick={() => this.onUnlock()}
                          >
                            <IntlText id="forms.submit" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                }
              })()}
            </div>
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    gallery: state.auth.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
      confirm: (...param) => dispatch(confirm(...param)),
      deny: (...param) => dispatch(deny(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PurchasesScreen),
);
