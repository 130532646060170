import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { get } from "../../actions/fair";
import { Link } from "../../components/util/Clickable";
import { FirstImage, Image } from "../../components/util/Image";
import { LikeDetail } from "../../components/util/Like";
import { Loading } from "../../components/util/Loading";
import NotFound from "../../components/util/NotFound";
import { FollowMe } from "../../components/util/SNS";
import { IntlText } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import ArticlesScreen from "./ArticlesScreen";
import ArtworksScreen from "./ArtworksScreen";
import GalleriesScreen from "./GalleriesScreen";
import InfoScreen from "./InfoScreen";
import OverviewScreen from "./OverviewScreen";
import PhotosScreen from "./PhotosScreen";

const urls = [
  {
    id: "menu.overview",
    title: "OVERVIEW",
    url: (fairId) =>
      PathGenerator.getLocalPath(Path.original.url, { fairId: fairId }),
  },
  {
    id: "menu.galleries",
    title: "GALLERIES",
    url: (fairId) =>
      PathGenerator.getLocalPath(Path.original.galleries.url, {
        fairId: fairId,
      }),
  },
  {
    id: "menu.artworks",
    title: "ARTWORKS",
    url: (fairId) =>
      PathGenerator.getLocalPath(Path.original.artworks.url, {
        fairId: fairId,
      }),
  },
  {
    id: "menu.photos",
    title: "PHOTOS",
    url: (fairId) =>
      PathGenerator.getLocalPath(Path.original.photos.url, { fairId: fairId }),
  },
  {
    id: "menu.articles",
    title: "ARTICLES",
    url: (fairId) =>
      PathGenerator.getLocalPath(Path.original.articles.url, {
        fairId: fairId,
      }),
  },
  {
    id: "menu.info",
    title: "INFO",
    url: (fairId) =>
      PathGenerator.getLocalPath(Path.original.info.url, { fairId: fairId }),
  },
];

class CustomViewScreen extends PureComponent {
  state = {
    fair: null,
  };

  componentWillMount = () => {
    this.reload(this.props.match.params.fairId);
    if (
      this.props.location.search != null &&
      this.props.location.search.indexOf("vip=jtvcop3ph5") > -1
    ) {
      window.localStorage.setItem("vip_status", true);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.match.params.fairId != nextProps.match.params.fairId) {
      this.reload(nextProps.match.params.fairId);
    }
  };

  componentDidUpdate = () => {
    setTimeout(() => {
      if (this.state.fair != null) {
        this.menuScroll();
      }
    }, 0);
  };

  menuScroll = () => {
    if (!this.scroll) {
      return;
    }

    Array.from(this.scroll.children[0].children).forEach((element) => {
      if (element.getAttribute("active")) {
        this.active = element;
      }
    });
    if (!this.active) {
      return;
    }
    const left =
      this.active.offsetLeft +
      this.active.offsetWidth / 2 -
      window.innerWidth / 2;
    this.scroll.scrollLeft = left;
  };

  reload = (fairId) => {
    this.setState({
      fair: null,
    });
    this.props.actions.get(fairId).then(({ value }) => {
      if (value.status > 300) {
        return this.setState({
          fair: false,
        });
      }
      this.setState({
        fair: value.data,
      });
    });
  };

  render = () => {
    if (this.state.fair == null) {
      return (
        <div className="uk-section">
          <div className="uk-container">
            <Loading />
          </div>
        </div>
      );
    } else if (!this.state.fair) {
      return (
        <div className="uk-section">
          <div className="uk-container">
            <NotFound />
          </div>
        </div>
      );
    }

    const fair = Formatter.mergeDesc(
      this.state.fair,
      this.state.fair.fair_descs,
      this.props.language.view,
    );

    const defaultFair = Formatter.defalutDesc(
      this.state.fair,
      this.state.fair.fair_descs,
    );

    const artwork =
      fair.artworks && fair.artworks.length > 0 ? fair.artworks[0] : null;

    var isTop = urls[0].url(fair.slug) == this.props.location.pathname;

    return (
      <div>
        <div className="uk-position-relative">
          <div className="uk-position-cover uk-image-wrapper uk-height-xlarge@m uk-height-1-1 uk-width-1-1">
            <Image full src={fair.thumnail_url} alt={fair.title} />
          </div>
          <div className="uk-position-cover uk-overlay uk-overlay-default"></div>
          <div className="uk-section uk-position-relative">
            <div className="uk-container">
              <div data-uk-grid className="uk-margin-large">
                <div className="uk-width-auto">
                  <div className="uk-image-wrapper uk-thumnail">
                    <Image src={fair.logo_url} alt={fair.title} />
                  </div>
                </div>
                <div className="uk-width-expand">
                  <div className="uk-margin-small">
                    <span className="uk-heading-primary uk-margin-remove">
                      {defaultFair.title}
                    </span>
                    <br />
                    {isTop ? (
                      <h1 className="uk-h4 uk-margin-ssmall">{fair.title}</h1>
                    ) : (
                      <span className="uk-h4 uk-margin-ssmall">
                        {fair.title}
                      </span>
                    )}
                  </div>
                  <div className="uk-margin" data-uk-grid>
                    <div className="uk-width-expand">
                      <p className="uk-margin-small">
                        {fair.address}
                        <br />
                        {fair.start_at &&
                          moment(fair.start_at).format("MMM DD")}
                        <span> - </span>
                        {fair.end_at && moment(fair.end_at).format("MMM DD")}
                      </p>
                      <div className="uk-margin-small">
                        <LikeDetail type="fair" data={fair} />
                      </div>
                    </div>
                    <div
                      className="uk-width-auto@m uk-visible@m"
                      style={{ height: 300 }}
                    >
                      {artwork && (
                        <FirstImage
                          style={{ height: 300 }}
                          srcs={artwork.artwork_images}
                          finder={(image) => image.url}
                          alt={artwork.title}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-section uk-section-small">
          <div className="uk-container">
            <div
              className="uk-margin-medium uk-grid-min"
              data-uk-grid="margin: uk-margin-min;"
            >
              {urls.map((url, index) => {
                let href = url.url(fair.slug);
                return (
                  <div key={index} className="uk-width@m uk-width-1-3">
                    <Link
                      className="uk-button uk-button-clear uk-width-1-1 uk-padding-remove"
                      to={href}
                      active={
                        this.props.location.pathname == href ? "true" : null
                      }
                      ga={{
                        category: "link",
                        action: "fair",
                        label: url.title,
                      }}
                    >
                      <IntlText id={url.id} />
                    </Link>
                  </div>
                );
              })}
            </div>

            <div
              className="uk-position-fixed uk-position-bottom  uk-width-1-1 uk-hidden@s uk-background-white uk-position-z-index uk-overflow-auto uk-scrollbar-hidden"
              data-uk-scrollspy="cls: uk-animation-slide-bottom;"
              ref={(scroll) => (this.scroll = scroll)}
              onLoad={this.menuScroll}
            >
              <div
                className="uk-grid-collapse uk-view-menu-base"
                data-uk-grid
                style={{ flexWrap: "nowrap" }}
              >
                {urls.map((url, index) => {
                  let href = url.url(fair.slug);
                  const isActive = this.props.location.pathname == href;
                  return (
                    <div
                      key={index}
                      className="uk-width-auto"
                      active={isActive ? "true" : null}
                    >
                      <Link
                        className="uk-button uk-button-clear uk-width-1-1 uk-padding-small-horizontal"
                        to={href}
                        active={isActive ? "true" : null}
                        ga={{
                          category: "link",
                          action: "fair",
                          label: url.title,
                        }}
                      >
                        <IntlText id={url.id} />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="uk-margin-medium">
              <Switch>
                <Route
                  exact
                  path={Path.original.url}
                  component={() => (
                    <OverviewScreen
                      fair={fair}
                      photoPath={PathGenerator.getLocalPath(
                        Path.original.photos.url,
                        { fairId: fair.slug },
                      )}
                      articlePath={PathGenerator.getLocalPath(
                        Path.original.articles.url,
                        { fairId: fair.slug },
                      )}
                    />
                  )}
                />
                <Route
                  exact
                  path={Path.original.galleries.url}
                  component={() => <GalleriesScreen fair={fair} />}
                />
                <Route
                  exact
                  path={Path.original.artworks.url}
                  component={() => <ArtworksScreen fair={fair} />}
                />
                <Route
                  exact
                  path={Path.original.photos.url}
                  component={() => <PhotosScreen fair={fair} />}
                />
                <Route
                  exact
                  path={Path.original.articles.url}
                  component={() => <ArticlesScreen fair={fair} />}
                />
                <Route
                  exact
                  path={Path.original.info.url}
                  component={() => (
                    <InfoScreen fair={fair} defaultFair={defaultFair} />
                  )}
                />
              </Switch>
            </div>
            <div className="uk-margin-medium">
              <FollowMe />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (param) => dispatch(get(param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomViewScreen),
);
