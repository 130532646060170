import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchArtists } from "../../actions/artist";
import { search as searchArtworks } from "../../actions/artwork";
import { setCondition } from "../../actions/cache";
import { search as searchGalleries } from "../../actions/gallery";
import ArtworkFilterForm from "../../components/forms/fair/ArtworkFilterForm";
import Meta from "../../components/layout/Meta";
import ArtworkCards from "../../components/lists/ArtworkCards";
import { Button } from "../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../components/util/Text";
import { Formatter } from "../../utils/formatter";
import queryString from "query-string";
import { toSearchParams } from "../../utils/location";
import ArtworkScreenByGallery from "./ArtworkScreenByGallery";
import ArtworkSortForm from "../../components/forms/ArtworkSortForm";
import Immutable from "immutable";

class ArtworksScreen extends Component {
  state = {
    queryParam: {},
    artworks: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
    artists: null,
    galleries: null,
    is_locked: true,
    is_GalleryDisplay: false,
    v_pass: "",
  };

  componentWillMount = () => {
    const query = queryString.parse(this.props.location.search);
    this.setState({ queryParam: query });
    if (query.displaymode == "gallery") {
      this.setState({ is_GalleryDisplay: true });
    }

    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      if (this.props.fair.only_started == 1) {
        if (moment(this.props.fair.start_at) > moment()) {
          return;
        }
      }
      this.state.is_locked = false;
      this.load(query);
    }
  };

  componentDidMount = () => {
    var vipstatus = window.localStorage.getItem("vip_status");
    if (vipstatus) {
      // vip解放モード
      if (
        this.props.fair.vip_start_at != null &&
        moment(this.props.fair.vip_start_at) < moment()
      ) {
        // 時間内からOK
        this.state.is_locked = false;
        this.load();
        return;
      }
    }
  };

  componentDidUpdate = () => {
    if (
      this.state.artworks != null &&
      this.state.artists != null &&
      this.state.galleries != null
    ) {
      this.props.actions.setCondition(this.state);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.state.loading != nextState.loading;
  };

  load = (queryParam = {}) => {
    this.props.actions
      .searchGalleries({
        fair_id: this.props.fair.id,
        all: true,
      })
      .then(({ value }) => {
        const galleries = value.data.map((gallery) => {
          const formated_gallaries = Formatter.mergeDesc(
            gallery,
            gallery.gallery_descs,
            this.props.language.view,
          );
          return formated_gallaries;
        });
        this.setState({
          galleries: Formatter.sortByName(galleries, "name"),
        });
        this.forceUpdate();
      });

    this.props.actions
      .searchArtists({
        fair_id: this.props.fair.id,
        all: true,
      })
      .then(({ value }) => {
        const artists = value.data.map((artist) => {
          const formated_artists = Formatter.mergeDesc(
            artist,
            artist.artist_descs,
            this.props.language.view,
          );
          return formated_artists;
        });
        this.setState({
          artists: Formatter.sortByName(artists, "name"),
        });
        this.forceUpdate();
      });

    this.readMore(true, queryParam);
  };

  onChangeSort = (values) => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["queryParam", "sort_method"], (v) => values.sort_method)
        .updateIn(["artworks"], (v) => null)
        .updateIn(["meta"], (v) => {
          return { current_page: 0 };
        })
        .toJS(),
      () => {
        const queryParams = toSearchParams(values);
        const currentUrl = this.props.location.pathname;
        const newUrl =
          queryParams.toString().length > 0
            ? currentUrl + "?" + queryParams.toString()
            : currentUrl;
        window.history.pushState({ path: newUrl }, "", newUrl);
        this.readMore(true);
      },
    );
  };

  readMore = (refresh = false, queryParam = {}) => {
    if (!refresh) {
      this.setState({ loading: true });
    }

    var excludes = this.state.artworks
      ? this.state.artworks.map((a) => a.id).join(",")
      : "0";

    if (this.props.fair.slug == "wat-art-browsing") {
      excludes = null;
    }

    const query =
      Object.keys(queryParam).length > 0 ? queryParam : this.state.queryParam;

    const param = Object.assign({}, query, {
      fair_id: this.props.fair.id,
      pagesize: 24,
      sort_method: window.location.href.split("sort_method=")[1]?.split("&")[0],
      page: this.state.meta.current_page + 1,
      excludes: excludes,
    });

    this.props.actions
      .searchArtworks(param)
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            artworks:
              this.state.artworks && !refresh
                ? this.state.artworks.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  };

  updateQueryParams = (params) => {
    const currentUrl = this.props.location.pathname;
    const newUrl =
      params.toString().length > 0
        ? currentUrl + "?" + params.toString()
        : currentUrl;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  onSearch = (values) => {
    const searchParams = toSearchParams(values);
    this.updateQueryParams(searchParams);

    this.setState(
      {
        queryParam: values,
        artworks: null,
        meta: {
          current_page: 0,
        },
      },
      () => {
        this.readMore(true);
      },
    );
  };

  onUnlock = () => {
    if (this.state.v_pass == "artscenes_admin") {
      this.setState({ is_locked: false });
      this.load();
    }
    if (
      this.props.fair.vip_start_at != null &&
      moment(this.props.fair.vip_start_at) < moment()
    ) {
      if (
        this.props.fair.v_pass != null &&
        this.props.fair.v_pass
          .split("\n")
          .find((key) => key == this.state.v_pass) !== undefined
      ) {
        this.setState({ is_locked: false });
        this.load();
      }
    }
  };

  onPassChange = (pass) => {
    this.setState({
      v_pass: pass,
    });
  };
  onChangeDisplayDefault = () => {
    this.setState({ is_GalleryDisplay: false });
    this.updateQueryParams("")
    this.forceUpdate();
  };
  onChangeDisplayGallery = () => {
    this.setState({ is_GalleryDisplay: true });
    this.updateQueryParams("displaymode=gallery");
    this.forceUpdate();
  };

  render = () => {
    return (
      <div>
        <Meta
          title={getPureMessage("fairs.artworks.meta") + this.props.fair.title}
          description={this.props.fair.description}
          image={this.props.fair.thumnail_url}
        />
        <div className="">
          <h1 className="uk-heading-mark uk-margin-remove uk-h2">
            <IntlText
              id="fairs.artworks.title"
              values={{ title: this.props.fair.title }}
            />
          </h1>
          {!this.state.is_locked && (
            <>
              <div className="uk-flex uk-flex-right uk-margin-small">
                <div className="uk-margin-auto-vertical">
                  <IntlText id="fairs.artworks.display" />
                </div>
                <Button
                  style={
                    this.state.is_GalleryDisplay
                      ? {
                          padding: " 0.5em 1.5em",
                          fontSize: 12,
                          border: " 1px solid #d9d9d9",
                          color: "#999999",
                          backgroundColor: "#ffffff",
                        }
                      : {
                          padding: " 0.5em 1.5em",
                          fontSize: 12,
                          border: "1px solid #000000",
                          color: "#ffffff",
                          backgroundColor: "#000000",
                        }
                  }
                  onClick={() => this.onChangeDisplayDefault()}
                >
                  <IntlText id="fairs.artworks.default" />
                </Button>
                <Button
                  style={
                    !this.state.is_GalleryDisplay
                      ? {
                          padding: " 0.5em 1.5em",
                          fontSize: 12,
                          border: " 1px solid #d9d9d9",
                          color: "#999999",
                          backgroundColor: "#ffffff",
                        }
                      : {
                          padding: " 0.5em 1.5em",
                          fontSize: 12,
                          border: "1px solid #000000",
                          color: "#ffffff",
                          backgroundColor: "#000000",
                        }
                  }
                  onClick={() => this.onChangeDisplayGallery()}
                >
                  <IntlText id="fairs.artworks.gallery" />
                </Button>
              </div>
              <div className="uk-flex uk-flex-right uk-margin-small">
                {!this.state.is_GalleryDisplay && (
                  <ArtworkSortForm
                    initialValues={this.state.queryParam}
                    onChange={this.onChangeSort}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {(() => {
          if (this.state.is_locked) {
            return (
              <div className="uk-margin-medium">
                <h3 className="uk-text-center uk-h1">Coming soon.</h3>
                <div className="uk-margin">
                  <p className="uk-text-center uk-margin-small">
                    {this.props.fair.vip_start_at &&
                      "VIP Only: " +
                        moment(this.props.fair.vip_start_at).format(
                          "YYYY/MM/DD HH:mm",
                        )}
                  </p>
                  <p className="uk-text-center uk-margin-small">
                    {this.props.fair.start_at &&
                      "OPEN: " +
                        moment(this.props.fair.start_at).format(
                          "YYYY/MM/DD HH:mm",
                        )}
                  </p>
                </div>
                <div
                  className="uk-margin uk-flex-center uk-grid-collapse"
                  data-uk-grid
                >
                  <div className="uk-width-medium">
                    <input
                      type="password"
                      className="uk-input"
                      onChange={(event) =>
                        this.onPassChange(event.target.value)
                      }
                      placeholder="Password"
                    />
                  </div>
                  <div className="uk-width-auto">
                    <Button
                      className="uk-button uk-button-primary"
                      onClick={() => this.onUnlock()}
                    >
                      <IntlText id="forms.submit" />
                    </Button>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <>
                {this.state.is_GalleryDisplay ? (
                  <ArtworkScreenByGallery fair_id={this.props.fair.id} />
                ) : (
                  <div className="uk-margin-medium">
                    <div className="uk-margin-medium">
                      <ArtworkFilterForm
                        initialValues={this.state.queryParam}
                        artists={this.state.artists}
                        galleries={this.state.galleries}
                        sections={this.props.fair && this.props.fair.sections}
                        onChange={this.onSearch}
                        lang={this.props.language.view}
                      />
                    </div>
                    <div className="uk-margin-medium">
                      <ArtworkCards artworks={this.state.artworks} />
                    </div>
                    {this.state.meta.last_page &&
                      this.state.meta.last_page >
                        this.state.meta.current_page && (
                        <div className="uk-margin-medium uk-text-center">
                          <Button
                            className="uk-button uk-button-default"
                            onClick={() => this.readMore(false)}
                            loading={this.state.loading ? 1 : 0}
                            ga={{
                              category: "button",
                              action: "fair_artworks",
                              label: "more",
                            }}
                          >
                            <IntlText id="forms.more" />
                          </Button>
                        </div>
                      )}
                  </div>
                )}
              </>
            );
          }
        })()}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      searchArtists: (...param) => dispatch(searchArtists(...param)),
      searchGalleries: (...param) => dispatch(searchGalleries(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtworksScreen),
);
