import { SEARCH_PHOTOS, EDIT_PHOTO, DELETE_PHOTO } from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: SEARCH_PHOTOS,
      payload: () => {
        return Api.get(EndPoints.photos(), param);
      },
    };
  };
};

export const edit = (showId, photoId, param) => {
  return (dispatch) => {
    return {
      type: EDIT_PHOTO,
      payload: () => {
        return Api.put(EndPoints.showPhotos(showId, photoId), param);
      },
    };
  };
};

export const deletePhoto = (showId, photoId) => {
  return (dispatch) => {
    return {
      type: DELETE_PHOTO,
      payload: () => {
        return Api.delete(EndPoints.showPhotos(showId, photoId));
      },
    };
  };
};
