import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { register } from "../../actions/collector/auth";
import Logger from "../../utils/logger";
import RegisterForm from "../forms/RegisterForm";
import { IntlHTML, IntlText } from "./Text";

declare var UIkit;

class LikePops extends PureComponent {
  state = {
    count: 0,
    success: false,
    email: null,
  };

  componentWillMount = () => {
    this.setState({
      count: this.props.like.data.length,
    });
  };

  componentDidMount = () => {
    UIkit.util.on(this.modal, "hidden", () => {
      Logger.event({
        category: "register",
        action: "close_modal",
        label: window.location.href,
      });
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.state.count == 0 &&
      nextProps.like.data.length == 1 &&
      nextProps.collector.id == null
    ) {
      this.setState({ email: null, success: false });
      UIkit.modal(this.modal).show();
      Logger.event({
        category: "register",
        action: "open_modal",
        label: window.location.href,
      });
    }

    this.setState({
      count: nextProps.like.data.length,
    });
  };

  onSubmit = (values) => {
    return this.props.actions.register(values);
  };

  onSuccess = (data) => {
    Logger.event({
      category: "register",
      action: "register",
      label: "on_modal",
    });
    this.setState({ email: data.email, success: true });
  };

  render = () => {
    return (
      <div
        ref={(modal) => (this.modal = modal)}
        className="uk-flex-top"
        {...{ "uk-modal": "container: false;" }}
      >
        <div className="uk-modal-dialog uk-width-xlarge@s uk-modal-body uk-margin-auto-vertical">
          <button
            className="uk-modal-close-default"
            type="button"
            {...{ "uk-close": "" }}
          ></button>
          <div className="">
            <h3 className="uk-text-center ">
              <IntlText id="util.like_pops.title" />
            </h3>
            <p className="uk-text-center">
              <IntlHTML id="util.like_pops.lead" />
            </p>

            <div className="uk-margin">
              {!this.state.success ? (
                <RegisterForm
                  onSubmit={this.onSubmit}
                  onSuccess={this.onSuccess}
                />
              ) : (
                <div>
                  <h4 className="uk-text-center">
                    <IntlText id="util.like_pops.thanks" />
                  </h4>
                  <p>
                    <IntlText
                      id="util.like_pops.next"
                      values={{ email: this.state.email }}
                    />
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    collector: state.auth.role == "collector" ? state.auth.data : {},
    like: state.like,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      register: (data) => dispatch(register(data)),
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LikePops);
