import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import NotFound from "../../components/util/NotFound";

class NotFoundScreen extends Component {
  componentDidMount = () => {};

  render = () => {
    return (
      <div className="uk-section uk-section-large">
        <div className="uk-container uk-container-small">
          <NotFound />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotFoundScreen),
);
