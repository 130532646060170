import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Loading } from "../util/Loading";
import { IntlText } from "../util/Text";
import Content from "./Content";

class ResultsList extends Component {
  render = () => {
    if (this.props.results == null) {
      return <Loading />;
    } else if (this.props.results.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.results.no" />
          </h3>
        </div>
      );
    }

    return (
      <ul className="uk-list uk-list-divider">
        {this.props.results.map((result, index) => {
          return (
            <li key={index} className="uk-margin-remove">
              <div className="uk-hover-muted uk-hover-allow uk-padding-small">
                <Content content={result} />
              </div>
            </li>
          );
        })}
      </ul>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResultsList),
);
