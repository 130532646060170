import Immutable from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search } from "../../../actions/gallery/artist";
import {
  deleteImage,
  edit,
  get,
  unsale,
} from "../../../actions/gallery/artwork";
import { showConfirm } from "../../../actions/message";
import ArtworkForm from "../../../components/forms/gallery/ArtworkForm";
import MultiSelect from "../../../components/inputs/MultiSelect";
import UploadImage from "../../../components/inputs/UploadImage";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Button, Link } from "../../../components/util/Clickable";
import { Image } from "../../../components/util/Image";
import { getPureMessage, IntlText } from "../../../components/util/Text";
import { EndPoints } from "../../../constants/endpoints";
import { Path } from "../../../constants/path";
import { Formatter } from "../../../utils/formatter";
import { Notification } from "../../../utils/notification";

class EditScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      artwork: null,
      editing: {},
      artists: null,
      selectedIds: [],
      loading: false,
    };
  }

  componentWillMount = () => {
    this.props.actions
      .get(this.props.match.params.artworkId)
      .then(({ value, action }) => {
        if (value.status < 300) {
          const artwork = { ...value.data.sale, ...value.data };
          const editing = Formatter.mergeDesc(
            artwork,
            artwork.artwork_descs,
            this.props.language.edit,
          );
          this.setState({
            artwork: value.data,
            editing: editing,
            selectedIds: value.data.artists
              ? value.data.artists.map((artist) => artist.id)
              : [],
          });
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .search({
        gallery_id: this.props.gallery.id,
        all: true,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            artists: value.data.map((artist) => {
              const desc = artist.artist_descs.find((desc) => {
                return desc.lang == this.props.language.edit;
              });
              return { value: artist.id, label: desc ? desc.name : "No name" };
            }),
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onChangeLang = (lang) => {
    const artwork = { ...this.state.artwork.sale, ...this.state.artwork };
    const editing = Formatter.mergeDesc(artwork, artwork.artwork_descs, lang);
    this.setState({
      editing: editing,
    });
  };

  selectArtist = (selectedIds) => {
    if (selectedIds == null) {
      return;
    }
    this.setState({ selectedIds: selectedIds });
  };

  onEdit = (values) => {
    const param = Object.assign({}, values, {
      artist_id: this.state.selectedIds,
      gallery_id: this.props.gallery.id,
    });
    return this.props.actions.edit(this.state.artwork.id, param);
  };

  onEdited = (data) => {
    Notification.successIntl("util.success.update");
    this.setState({
      artwork: data,
    });
  };

  onDelete = () => {
    this.props.actions.showConfirm(
      getPureMessage("util.confirm.title.delete"),
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }
        this.props.actions
          .unsale(this.state.artwork.id)
          .then(({ value, status }) => {
            Notification.successIntl("util.success.delete");
            this.props.history.push(Path.gallery.artworks.url);
          })
          .catch((error) => console.log(error));
      },
    );
  };

  onUploaded = (data) => {
    this.state.artwork.artwork_images.push(data);
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["artwork"], (v) => this.state.artwork)
        .toJS(),
    );
    Notification.successIntl("util.success.update");
  };

  onUploadError = () => {
    Notification.successIntl("util.error.update");
  };

  onDeleteImage = (image) => {
    this.props.actions.showConfirm(
      getPureMessage("util.confirm.title.delete"),
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }
        this.props.actions
          .deleteImage(this.state.artwork.id, image.id)
          .then(({ value, action }) => {
            if (value.status < 300) {
              this.setState(
                Immutable.fromJS(this.state)
                  .updateIn(["artwork", "artwork_images"], (v) =>
                    this.state.artwork.artwork_images.filter(
                      (img) => img.id != image.id,
                    ),
                  )
                  .toJS(),
              );
              Notification.successIntl("util.success.delete");
            }
          })
          .catch((error) => console.log(error));
      },
    );
  };

  renderEditor = () => {
    return (
      <div className="" data-uk-grid>
        <div className="uk-width-expand">
          <div className="uk-margin-medium">
            <h2>
              <IntlText id="gallery.artworks.artist" />
            </h2>
            <MultiSelect
              placeholder="Artists"
              options={this.state.artists}
              selected={this.state.selectedIds}
              onChange={this.selectArtist}
            />
          </div>
          {this.state.selectedIds && this.state.selectedIds.length > 0 && (
            <div className="uk-margin-medium">
              <ArtworkForm
                ref={(editForm) => (this.editForm = editForm)}
                onSubmit={this.onEdit}
                onSuccess={this.onEdited}
                initialValues={this.state.editing}
              />
            </div>
          )}
          <hr />
          <div className="uk-margin-medium uk-text-center">
            <Link
              className="uk-button uk-button-danger"
              onClick={this.onDelete}
            >
              <IntlText id="forms.delete" />
            </Link>
          </div>
        </div>

        <div className="uk-width-large@s">
          <h2>
            <IntlText id="gallery.artworks.images" />
          </h2>
          <div className="uk-margin-small">
            <UploadImage
              url={EndPoints.galleryArtworkImages(this.state.artwork.id)}
              onSuccess={this.onUploaded}
              onError={this.onUploadError}
            />
          </div>
          {this.state.artwork.artwork_images &&
            this.state.artwork.artwork_images.map((image, key) => {
              return (
                <div key={key} className="uk-margin-small uk-position-relative">
                  <Image noSmall className="uk-width-1-1" src={image.url} />
                  <div className="uk-position-small uk-position-top-right">
                    <Button
                      className="uk-icon-button uk-button-danger"
                      data-uk-icon="icon:close;"
                      onClick={() => this.onDeleteImage(image)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-large" data-uk-grid>
          <div className="uk-width-expand">
            <h1>
              <IntlText id="gallery.artworks.edit_title" />
            </h1>
          </div>
          <div className="uk-width-medium@s uk-text-right">
            <ChangeEditLang onChange={this.onChangeLang} />
          </div>
        </div>

        <div className="uk-margin-large">
          {(() => {
            if (!this.state.artists || !this.state.artwork) {
              return (
                <div className="uk-text-center">
                  <span data-uk-spinner="ratio: 3"></span>
                </div>
              );
            } else {
              return this.renderEditor();
            }
          })()}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (param) => dispatch(search(param)),
      get: (param) => dispatch(get(param)),
      edit: (...param) => dispatch(edit(...param)),
      unsale: (...param) => dispatch(unsale(...param)),
      deleteImage: (...param) => dispatch(deleteImage(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditScreen),
);
