import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setEditLang } from "../../actions/language";
import { languages } from "../../constants/option";
import { IntlText } from "./Text";

class ChangeEditLang extends Component {
  onChange = (event) => {
    this.props.actions.setEditLang(event.target.value);
    this.props.onChange && this.props.onChange(event.target.value);
  };

  render = () => {
    return (
      <div>
        <label className="uk-form-label">
          <IntlText id="util.change_edit_lang.title" />
        </label>
        <div className="uk-form-controls">
          <select
            className={"uk-select uk-form-small uk-input-lang"}
            onChange={this.onChange}
            value={this.props.language.edit}
          >
            {languages.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setEditLang: (lang) => dispatch(setEditLang(lang)),
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEditLang);
