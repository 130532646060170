import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import React, { Component } from "react";
import Logger from "../../utils/logger";
import { getPureMessage, IntlText } from "../util/Text";

export default class SliderInput extends Component {
  onChange = (values) => {
    if (this.props.ga != null) {
      Logger.event(this.props.ga);
    } else if (this.props.meta) {
      Logger.event({
        category: this.props.meta.form,
        action: "slider_input",
        label: this.props.input.name,
      });
    }

    this.props.input &&
      this.props.input.onChange &&
      this.props.input.onChange({
        min: this.valueExtractor(values[0]),
        max: this.valueExtractor(values[1]),
      });
  };

  valueExtractor = (range) => {
    return this.props.valueExtractor ? this.props.valueExtractor(range) : range;
  };

  rangeExtractor = (values) => {
    return this.props.rangeExtractor
      ? this.props.rangeExtractor(values)
      : [
          values.min ? values.min : this.props.min,
          values.max ? values.max : this.props.max,
        ];
  };

  handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={this.valueExtractor(value)}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Slider.Handle value={this.valueExtractor(value)} {...restProps} />
      </Tooltip>
    );
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;
    const label = this.props.labelId
      ? getPureMessage(this.props.labelId)
      : this.props.label;
    return (
      <div>
        <div className="uk-grid-small" data-uk-grid>
          <div className="uk-width-expand">
            {(() => {
              if (label != null) {
                return (
                  <label className="uk-form-label uk-text-nowrap">
                    {label}
                    <wbr />
                    {(() => {
                      if (this.props.required) {
                        return (
                          <small className="uk-text-danger">
                            {" "}
                            ※<IntlText id="forms.attr.required" />
                          </small>
                        );
                      }
                    })()}
                  </label>
                );
              }
            })()}
          </div>
          <div className="uk-width-auto">
            <small className="uk-text-muted">
              {(() => {
                if (this.props.input && this.props.input.value) {
                  return (
                    this.props.labelRender &&
                    this.props.labelRender(this.props.input.value)
                  );
                }
              })()}
            </small>
          </div>
        </div>

        <div className="uk-form-controls">
          <Range
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            defaultValue={this.rangeExtractor(this.props.input.value)}
            tipFormatter={(value) => `${value}%`}
            handle={this.handle}
            pushable
            onAfterChange={this.onChange}
          />
        </div>
        {!this.props.noError && (
          <div className="uk-text-danger " style={{ minHeight: "18px" }}>
            <small>{hasError && this.props.meta.error}</small>
          </div>
        )}
      </div>
    );
  };
}
