import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { login } from "../../../actions/collector/auth";
import { setEditLang, setViewLang } from "../../../actions/language";
import logo from "../../../assets/images/logo/black.png";
import LoginForm from "../../../components/forms/LoginForm";
import Meta from "../../../components/layout/Meta";
import { Link } from "../../../components/util/Clickable";
import {
  getPureMessage,
  IntlHTML,
  IntlText,
} from "../../../components/util/Text";
import { Path, PathGenerator } from "../../../constants/path";
import { defaultLang } from "../../../utils/location";

class LoginScreen extends PureComponent {
  submitLogin = (values) => {
    return this.props.actions.login(values);
  };

  onSuccess = (data) => {
    const lang = data.primary_lang ? data.primary_lang : defaultLang;
    this.props.actions.setViewLang(lang);
    this.props.actions.setEditLang(lang);

    this.props.history.push(
      PathGenerator.getRealUrl(Path.collector.top.url, { lang: lang }),
    );
  };

  render = () => {
    return (
      <div>
        <Meta title={getPureMessage("collector.auth.login.header")} />
        <div className="uk-text-center">
          <img src={logo} className="uk-width-medium" />
        </div>
        <h1 className="uk-text-center">
          <IntlText id="collector.auth.login.header" />
        </h1>
        <div className="uk-margin-large uk-text-center">
          <div className="uk-width-large@s uk-text-left uk-display-inline-block">
            <div className="uk-margin-medium">
              <LoginForm
                onSubmit={this.submitLogin}
                onSuccess={this.onSuccess}
              />
            </div>
            <div className="uk-margin-small uk-text-center">
              <IntlText id="collector.auth.login.ifyouforget" />
              <Link
                className="uk-button-link uk-button"
                to={Path.collector.auth.forget.url}
                ga={{
                  category: "button",
                  action: "collector_auth_login",
                  label: "reset",
                }}
              >
                <IntlText id="collector.auth.login.resethere" />
              </Link>
            </div>
            <hr />
            <div className="uk-margin-small ">
              <div className="uk-margin-ssmall uk-text-center">
                <IntlHTML id="util.like_pops.lead" />
              </div>
              <div className="uk-margin-ssmall uk-text-center">
                <Link
                  className="uk-button-default uk-button"
                  to={Path.collector.auth.register.url}
                  ga={{
                    category: "button",
                    action: "collector_auth_login",
                    label: "back",
                  }}
                >
                  <IntlText id="collector.auth.login.to_register" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      login: (data) => dispatch(login(data)),
      setViewLang: (...param) => dispatch(setViewLang(...param)),
      setEditLang: (...param) => dispatch(setEditLang(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreen),
);
