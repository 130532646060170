import React, { Component } from 'react';
import Api from '../../utils/api';
import { IntlText } from '../util/Text';

const { UIkit } = window;

export default class UploadImage extends Component {
  state = {
    compressing: false,
  }

  componentDidMount = () => {
    this.setUpload(this.props);
  }

  componentWillReceiveProps = (nextProps) => {
    this.setUpload(nextProps);
  }

  setUpload = (props) => {
    UIkit.upload(this.uploader, {
      url: props.url,
      name: 'image',
      type: 'json',
      beforeSend: (env) => {
        const header = Api.getAuthHeader();
        env.headers = header.headers;
      },
      error: (arg) => {
        props.onError && props.onError(arg);
      },
      complete: ({ response }) => {
        const { data } = response;
        props.onSuccess && props.onSuccess(data);
      },
      loadStart: (e) => {
        if (this.progress) {
          this.progress.removeAttribute('hidden');
          this.progress.max = e.total;
          this.progress.value = e.loaded;
        }
      },

      progress: (e) => {
        if (this.progress) {
          this.progress.max = e.total;
          this.progress.value = e.loaded;
        }
      },
      loadEnd: (e) => {
        if (this.progress) {
          this.progress.max = e.total;
          this.progress.value = e.loaded;
          setTimeout(() => {
            this.progress.setAttribute('hidden', 'hidden');
          }, 1000);
        }
      },
    });
  }

  render = () => (
    <div>
      <div ref={(uploader) => this.uploader = uploader} className="js-upload uk-placeholder uk-text-center ">
        <span className="uk-margin-small-right" data-uk-icon="icon: cloud-upload" />
        <span className="uk-text-middle">
          <IntlText id="util.upload_image.drop" />
        </span>
        <br />
        <span className="uk-margin-small-right">
          <IntlText id="util.upload_image.or" />
        </span>
        <div {...{ 'uk-form-custom': '' }}>
          <input type="file" accept="image/*"/>
          <span className="uk-link">
            <IntlText id="util.upload_image.select" />
          </span>
        </div>
      </div>
      {
          this.state.compressing && (
            <div className="uk-text-center">
              <span className="uk-text-muted ">
                <IntlText id="util.upload_image.compressing" />
              </span>
            </div>
          )
        }
      <progress ref={(progress) => this.progress = progress} className="uk-progress" value="0" max="100" hidden />
    </div>
  )
}
