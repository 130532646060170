import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { timings } from "../../constants/option";
import Select from "../inputs/Select";
import TextInput from "../inputs/TextInput";
import { getPureMessage } from "../util/Text";

class ShowFilterForm extends PureComponent {
  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        action={this.props.action}
        method="post"
      >
        <div className="uk-grid-small uk-flex-top" data-uk-grid>
          {/* <div className="uk-width-medium">
            <Field 
              name="place"
              placeholder={getPureMessage("forms.attr.show_filter.place")}
              component={Select}
              className="uk-form-small uk-form-clear"
              noError
              options={ this.props.constant.places.map( (place) => {
                return { label: place.name, value:place.name };
              }) }
            />
          </div> */}
          <div className="uk-width-small">
            <Field
              name="timing"
              labelId="forms.attr.status"
              placeholder={getPureMessage("forms.attr.show_filter.all")}
              component={Select}
              className="uk-form-clear"
              noError
              options={timings}
            />
          </div>
          <div className="uk-width-medium@s">
            <Field
              name="keyword"
              labelId="forms.attr.title"
              type="text"
              component={TextInput}
            />
          </div>
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    constant: state.constant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default reduxForm({
  form: "show-filter-form",
})(connect(mapStateToProps, mapDispatchToProps)(ShowFilterForm));
