export const CollectorPath = {
  url: "/:lang/collector",
  auth: {
    url: "/:lang/collector/auth",
    login: {
      url: "/:lang/collector/auth/login",
    },
    register: {
      url: "/:lang/collector/auth/register",
    },
    forget: {
      url: "/:lang/collector/auth/forget",
    },
    reset: {
      url: "/:lang/collector/auth/reset",
    },
    thanks: {
      url: "/:lang/collector/auth/thanks",
    },
    validate: {
      url: "/:lang/collector/auth/validate",
    },
  },
  top: {
    url: "/:lang/collector",
  },
  edit: {
    url: "/:lang/collector/edit",
  },
  edit_auth: {
    url: "/:lang/collector/edit_auth",
  },
};
