import { GET_INFORMATIONS } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: GET_INFORMATIONS,
      payload: () => {
        return Api.get(EndPoints.informations(), param);
      },
    };
  };
};
