export const GALLERY_TRIAL = 0;

export const languages = [
  { label: "English", value: "en", short: "EN", iso: "ja" },
  { label: "日本語", value: "ja", short: "JA", iso: "en" },
  // { label: "中文(簡体)", value: "zh-Hans", short:"CN", iso: "zh" },
  // { label: "中文(繁体)", value: "zh-Hant", short:"TW", iso: "zh" },
];

export const sizeUnits = [
  { label: "inch", value: "inch" },
  { label: "cm", value: "cm" },
];

export const listedStatus = [
  { label: "Open", value: "0" },
  { label: "Not listed", value: "1" },
];

export const openStatus = [
  { label: "Open", value: "0" },
  { label: "Only started", value: "1" },
];

export const priceUnits = [
  { label: "jpy", value: "jpy" },
  { label: "$", value: "$" },
];

export const colors = [
  { label: "White", value: "White" },
  { label: "Black", value: "Black" },
  { label: "Brown", value: "Brown" },
  { label: "Red", value: "Red" },
  { label: "Green", value: "Green" },
  { label: "Blue", value: "Blue" },
  { label: "Yellow", value: "Yellow" },
  { label: "Purple", value: "Purple" },
  { label: "Pink", value: "Pink" },
];

export const SALE_AVAILABLE = 5;
export const SALE_SOLD = 4;
export const SALE_FOR_SALE = 3;
export const SALE_CONTACT_US = 2;
export const SALE_NOT_FOR_SALE = 1;

export const salesStatus = [
  { label: "Editing", value: 0, labelId: "option.sales_status.editing" },
  {
    label: "Not for sale",
    value: SALE_NOT_FOR_SALE,
    labelId: "option.sales_status.not_sale",
  },
  {
    label: "Contact us",
    value: SALE_CONTACT_US,
    labelId: "option.sales_status.contact",
  },
  {
    label: "Contact us(Open Price)",
    value: SALE_FOR_SALE,
    labelId: "option.sales_status.for_sale",
  },
  {
    label: "Available",
    value: SALE_AVAILABLE,
    labelId: "option.sales_status.available",
  },
  { label: "Sold", value: 4, labelId: "option.sales_status.sold" },
];

export const salesStatusForSearch = [
  {
    label: "Not for sale",
    value: SALE_NOT_FOR_SALE,
    labelId: "option.sales_status.not_sale",
  },
  {
    label: "Contact us",
    value: SALE_CONTACT_US,
    labelId: "option.sales_status.contact",
  },
  {
    label: "Contact us(Open Price)",
    value: SALE_FOR_SALE,
    labelId: "option.sales_status.for_sale",
  },
  {
    label: "Available",
    value: SALE_AVAILABLE,
    labelId: "option.sales_status.available",
  },
  { label: "Sold", value: 4, labelId: "option.sales_status.sold" },
];

export const salesStatusForEdit = [
  { label: "Editing", value: 0, labelId: "option.sales_status.editing" },
  {
    label: "Not for sale",
    value: SALE_NOT_FOR_SALE,
    labelId: "option.sales_status.not_sale",
  },
  {
    label: "Contact us",
    value: SALE_CONTACT_US,
    labelId: "option.sales_status.contact",
  },
  {
    label: "Contact us(Open Price)",
    value: SALE_FOR_SALE,
    labelId: "option.sales_status.for_sale",
  },
  {
    label: "Available",
    value: SALE_AVAILABLE,
    labelId: "option.sales_status.available",
  },
  { label: "Sold", value: 4, labelId: "option.sales_status.sold" },
];

export const fairOnlyStatus = [
  { label: "Open", value: 0, labelId: "option.fair_only_status.open" },
  {
    label: "Fair Only",
    value: 1,
    labelId: "option.fair_only_status.fair_only",
  },
  {
    label: "Fair VIP Only",
    value: 2,
    labelId: "option.fair_only_status.fair_vip_only",
  },
];

export const mediums = [
  { label: "Painting", value: "Painting", labelId: "option.mediums.painting" },
  {
    label: "Japanese painting",
    value: "Japanese painting",
    labelId: "option.mediums.japanese",
  },
  {
    label: "Prints & Multiples",
    value: "Prints & Multiples",
    labelId: "option.mediums.print",
  },
  {
    label: "Works on Paper",
    value: "Works on Paper",
    labelId: "option.mediums.paper",
  },
  {
    label: "Sculpture",
    value: "Sculpture",
    labelId: "option.mediums.sculpture",
  },
  {
    label: "Photography",
    value: "Photography",
    labelId: "option.mediums.photo",
  },
  { label: "Media art", value: "Media art", labelId: "option.mediums.media" },
  {
    label: "Mixed media",
    value: "Mixed media",
    labelId: "option.mediums.mixed",
  },
  { label: "Design", value: "Design", labelId: "option.mediums.design" },
  { label: "Jewelry", value: "Jewelry", labelId: "option.mediums.jewelry" },
  { label: "Other", value: "Other", labelId: "option.mediums.other" },
];

export const sizes = [
  { label: "Small(~50cm)", value: "small" },
  { label: "Middle(50cm~200cm)", value: "middle" },
  { label: "Large(200cm~)", value: "large" },
];

export const prices = [
  { label: "1円 ~ 10,000円", value: '{"min":1,"max":10000}' },
  { label: "10,001円 ~ 100,000円", value: '{"min":10001,"max":100000}' },
  { label: "100,001円 ~ 1,000,000円", value: '{"min":100001,"max":1000000}' },
  {
    label: "1,000,001円 ~ 10,000,000円",
    value: '{"min":1000001,"max":10000000}',
  },
  {
    label: "10,000,001円 ~ 100,000,000円",
    value: '{"min":10000001,"max":100000000}',
  },
];

export const sorts = [
  { label: "Recommend", value: "recommend", labelId: "option.sorts.recommend" },
  {
    label: "Recently Update",
    value: "recently",
    labelId: "option.sorts.recent",
  },
  {
    label: "Most Expensive",
    value: "higher_price",
    labelId: "option.sorts.higher",
  },
  {
    label: "Least Expensive",
    value: "lower_price",
    labelId: "option.sorts.lower",
  },
  {
    label: "Artwork Year (desc)",
    value: "older",
    labelId: "option.sorts.older",
  },
  {
    label: "Artwork Year (asc)",
    value: "newer",
    labelId: "option.sorts.newer",
  },
];

export const NORMAL_ARTIST = 0;
export const FEATURED_ARTIST = 1;

export const artistStatus = [
  { label: "Artist", value: NORMAL_ARTIST },
  { label: "Feartured Artist", value: FEATURED_ARTIST },
];

export const PURCHASE_CONTACTED = 0;
export const PURCHASE_AGREED = 1;
export const PURCHASE_PAID = 2;
export const PURCHASE_CANCELED = 3;
export const PURCHASE_ERROR = 4;

export const purchaseStatus = [
  {
    label: "Contacted",
    value: PURCHASE_CONTACTED,
    labelId: "option.purchase.contacted",
  },
  {
    label: "Agreed",
    value: PURCHASE_AGREED,
    labelId: "option.purchase.agreed",
  },
  { label: "Paid", value: PURCHASE_PAID, labelId: "option.purchase.paid" },
  {
    label: "Canceled",
    value: PURCHASE_CANCELED,
    labelId: "option.purchase.canceled",
  },
  { label: "Error", value: PURCHASE_ERROR, labelId: "option.purchase.error" },
];

export const STATUS_EDITING = 0;
export const STATUS_OPEN = 1;

export const statuses = [
  { label: "Private", value: STATUS_EDITING, labelId: "option.status.private" },
  { label: "Published", value: STATUS_OPEN, labelId: "option.status.public" },
];

export const timings = [
  {
    label: "Current",
    value: "current",
    labelId: "forms.attr.timing_filter.current",
  },
  {
    label: "Upcoming",
    value: "upcoming",
    labelId: "forms.attr.timing_filter.upcoming",
  },
  { label: "Past", value: "past", labelId: "forms.attr.timing_filter.past" },
];

export const keywordOnly = [
  { value: "", labelId: "forms.attr.only.all" },
  { value: "gallery", labelId: "forms.attr.only.gallery" },
  { value: "artwork", labelId: "forms.attr.only.artwork" },
  { value: "article", labelId: "forms.attr.only.article" },
  { value: "artist", labelId: "forms.attr.only.artist" },
  { value: "show", labelId: "forms.attr.only.show" },
  { value: "fair", labelId: "forms.attr.only.fair" },
];

export const DOMESTIC_SHIPPING_NOT_FREE = 0;
export const DOMESTIC_SHIPPING_FREE = 1;

export const DomesticShippingStatus = [
  {
    label: "Not Free",
    value: DOMESTIC_SHIPPING_NOT_FREE,
    labelId: "forms.attr.domestic_shipping_status.not_free",
  },
  {
    label: "Free",
    value: DOMESTIC_SHIPPING_FREE,
    labelId: "forms.attr.domestic_shipping_status.free",
  },
];
