import moment from "moment";
import React, { PureComponent } from "react";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from "react-stripe-elements";
import { reduxForm, SubmissionError } from "redux-form";
import cards from "../../assets/images/vendor/cards.svg";
import { Button } from "../util/Clickable";
import { IntlText } from "../util/Text";

class CardForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return new Promise((resolve, reject) => {
      this.props.stripe
        .createToken()
        .then(({ token }) => {
          if (token == null) {
            reject({
              _error: "Invalid credit card",
            });
          }
          const param = {
            card_token: token.id,
            card_suffix: token.card.last4,
            expired_at: moment(
              token.card.exp_year + "-" + token.card.exp_month + "-01",
              "YYYY-MM-DD",
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          };
          this.props.onSubmit(param).then(({ value, action }) => {
            if (value.status < 300) {
              this.setState(
                {
                  loading: false,
                },
                () => {
                  this.props.onSuccess &&
                    this.props.onSuccess(value.data, value.response);
                },
              );
              resolve(value);
            } else {
              this.setState({
                loading: false,
              });
              const errors = Object.keys(value.errors).reduce((dic, key) => {
                dic[key] = Array.isArray(value.errors[key])
                  ? value.errors[key].join("\n")
                  : null;
                return dic;
              }, {});
              reject(errors);
            }
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          reject({
            _error: "Invalid credit card",
          });
        });
    }).catch((errors) => {
      throw new SubmissionError(errors);
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-margin-ssmall">
          <img className="uk-width-1-1" src={cards} alt="cards" />
        </div>
        <div className="uk-margin-ssmall">
          <label className="uk-form-label uk-text-nowrap">
            <IntlText id="forms.attr.card_number" />{" "}
            <small className="uk-text-danger">
              {" "}
              ※<IntlText id="forms.attr.required" />
            </small>
          </label>
          <div className="uk-form-controls">
            <CardNumberElement className="uk-input uk-input-element" />
          </div>
        </div>
        <div
          className="uk-margin-ssmall uk-child-width-1-2@s uk-grid-small"
          data-uk-grid
        >
          <div>
            <label className="uk-form-label uk-text-nowrap">
              <IntlText id="forms.attr.expired" />
              <small className="uk-text-danger">
                {" "}
                ※<IntlText id="forms.attr.required" />
              </small>
            </label>
            <div className="uk-form-controls">
              <CardExpiryElement className="uk-input uk-input-element" />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-nowrap">
              <IntlText id="forms.attr.cvc" />{" "}
              <small className="uk-text-danger">
                {" "}
                ※<IntlText id="forms.attr.required" />
              </small>
            </label>
            <div className="uk-form-controls">
              <CardCVCElement className="uk-input uk-input-element" />
            </div>
          </div>
        </div>

        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>
      </form>
    );
  };
}

export default injectStripe(
  reduxForm({
    form: "card-form",
    enableReinitialize: true,
  })(CardForm),
);
