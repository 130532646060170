export const AdminPath = {
  url: "/:lang/admin",
  auth: {
    url: "/:lang/admin/auth",
    login: {
      url: "/:lang/admin/auth/login",
    },
  },
  top: {
    url: "/:lang/admin",
  },
  galleries: {
    url: "/:lang/admin/galleries",
  },
  fairs: {
    url: "/:lang/admin/fairs",
  },
  news: {
    url: "/:lang/admin/news",
    edit: {
      url: "/:lang/admin/news/:newsId/edit",
    },
  },
  articles: {
    url: "/:lang/admin/articles",
    edit: {
      url: "/:lang/admin/articles/:articleId/edit",
    },
  },
  edit: {
    url: "/:lang/admin/edit",
  },
  banners: {
    url: "/:lang/admin/banner",
  },
};
