import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { logout } from "../../actions/fair/auth";
import { showConfirm } from "../../actions/message";
import Meta from "../../components/layout/Meta";
import { Link } from "../../components/util/Clickable";
import { IntlText } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";
import EditArticleScreen from "./articles/EditScreen";
import ArticlesScreen from "./articles/IndexScreen";
import AddInviteScreen from "./invites/AddScreen";
import InvitesScreen from "./invites/IndexScreen";
import AuthScreen from "./other/AuthScreen";
import ProfileScreen from "./other/ProfileScreen";
import RelatedFairsScreen from "./other/RelatedFairsScreen";
import SectionsScreen from "./other/SectionsScreen";
import PhotosScreen from "./photos/IndexScreen";
import TopScreen from "./TopScreen";

declare var UIkit;

class BaseScreen extends PureComponent {
  componentWillReceiveProps = (nextProps) => {
    if (this.sidebar) {
      UIkit.offcanvas(this.sidebar).hide();
    }
  };

  logout = () => {
    this.props.actions.showConfirm(
      <IntlText id="fair.layout.menu.sign_out" />,
      null,
      (confirmed) => {
        if (confirmed) {
          this.props.actions
            .logout()
            .then(({ value, action }) => {
              if (value.status < 300) {
                this.props.history.push(
                  PathGenerator.getLocalPath(Path.fair.auth.login.url),
                );
              }
            })
            .catch((error) => console.log(error));
        }
      },
    );
  };

  doLogout = () => {};

  renderSidebar = () => {
    return (
      <div>
        <ul className="uk-nav-default uk-list uk-list-divider" data-uk-nav>
          <li className="">
            <Link className="" to={Path.fair.top.url}>
              <IntlText id="fair.layout.menu.dashboard" />
            </Link>
          </li>
          <li className="uk-parent">
            <a className="" href="#">
              <IntlText id="fair.layout.menu.fair_information" />
            </a>
            <ul className="uk-nav-sub uk-margin-remove">
              <li>
                <Link className="" to={Path.fair.profile.url}>
                  <IntlText id="fair.layout.menu.information" />
                </Link>
              </li>
              <li>
                <Link className="" to={Path.fair.sections.url}>
                  <IntlText id="fair.layout.menu.sections" />
                </Link>
              </li>
              <li>
                <Link className="" to={Path.fair.relates.url}>
                  <IntlText id="fair.layout.menu.related" />
                </Link>
              </li>
            </ul>
          </li>
          <li className="uk-parent">
            <a className="" href="#">
              <IntlText id="fair.layout.menu.invite" />
            </a>
            <ul className="uk-nav-sub uk-margin-remove">
              <li>
                <Link className="" to={Path.fair.invites.url}>
                  <span data-uk-icon="icon:list;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="fair.layout.menu.inviting" />
                  </span>
                </Link>
              </li>
              <li>
                <Link className="" to={Path.fair.invites.add.url}>
                  <span data-uk-icon="icon:plus;" />
                  <span className="uk-margin-ssmall-left">
                    <IntlText id="fair.layout.menu.add_invitation" />
                  </span>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link className="" to={Path.fair.articles.url}>
              <IntlText id="fair.layout.menu.articles" />
            </Link>
          </li>
          <li>
            <Link className="" to={Path.fair.photos.url}>
              <IntlText id="fair.layout.menu.photos" />
            </Link>
          </li>
          <li>
            <Link className="" to={Path.fair.edit.url}>
              <IntlText id="fair.layout.menu.setting" />
            </Link>
          </li>
          <li className="">
            <Link className="" onClick={this.logout}>
              <IntlText id="fair.layout.menu.sign_out" />
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  render = () => {
    return (
      <div className="fair_admin">
        <Meta title="For Fair" />
        <div className="uk-grid-collapse uk-grid-match" data-uk-grid>
          <div
            className="uk-width-medium@m uk-background-secondary uk-light uk-visible@m"
            style={{ minHeight: "calc(100vh - 56px)" }}
          >
            <div className="uk-padding@s uk-padding-small">
              <div>{this.renderSidebar()}</div>
            </div>
          </div>
          <div className="uk-width-expand">
            <div className="uk-padding@s uk-padding-small">
              <Switch>
                <Route exact path={Path.fair.top.url} component={TopScreen} />

                <Route
                  exact
                  path={Path.fair.invites.url}
                  component={InvitesScreen}
                />
                <Route
                  exact
                  path={Path.fair.invites.add.url}
                  component={AddInviteScreen}
                />

                <Route exact path={Path.fair.edit.url} component={AuthScreen} />
                <Route
                  exact
                  path={Path.fair.profile.url}
                  component={ProfileScreen}
                />
                <Route
                  exact
                  path={Path.fair.photos.url}
                  component={PhotosScreen}
                />
                <Route
                  exact
                  path={Path.fair.articles.url}
                  component={ArticlesScreen}
                />
                <Route
                  exact
                  path={Path.fair.articles.edit.url}
                  component={EditArticleScreen}
                />
                <Route
                  exact
                  path={Path.fair.relates.url}
                  component={RelatedFairsScreen}
                />
                <Route
                  exact
                  path={Path.fair.sections.url}
                  component={SectionsScreen}
                />
              </Switch>
            </div>
          </div>
        </div>
        <div
          id="fair-menu"
          data-uk-offcanvas="flip: true; overlay: true"
          ref={(sidebar) => (this.sidebar = sidebar)}
        >
          <div className="uk-offcanvas-bar">
            <button
              className="uk-offcanvas-close"
              type="button"
              data-uk-close
            ></button>
            {this.renderSidebar()}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    fair: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showConfirm: (...data) => dispatch(showConfirm(...data)),
      logout: () => dispatch(logout()),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseScreen),
);
