import { getPureMessage } from "../../langs/messages";

export const validate = () => {
  let errors = {};
  return errors;
};

const ErrorMessages = {
  required: getPureMessage("forms.validaton.required"),
  email: getPureMessage("forms.validaton.email"),
  num: getPureMessage("forms.validaton.num"),
  password: getPureMessage("forms.validaton.password"),
  date: getPureMessage("forms.validaton.date"),
  minNumber: getPureMessage("forms.validaton.minNumber"),
  maxNumber: getPureMessage("forms.validaton.maxNumber"),
  decimal: getPureMessage("forms.validaton.decimal"),
  tel: getPureMessage("forms.validaton.tel"),
  zip: getPureMessage("forms.validaton.zip"),
  url: getPureMessage("forms.validaton.url"),
  zero: getPureMessage("forms.validaton.zero"),
};

const Regex = {
  email:
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  num: /^[0-9]+$/,
  password: /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/,
  date: /(\d{4}).?(\d{2}).?(\d{2}).*/,
  decimal: /^[0-9]+(\.[0-9]*)?$/,
  tel: /^\d{1,20}$/,
  zip: /^\d{3}[-]\d{4}$/,
  url: /^(https?)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/,
};

export const required = (value) => {
  if (value === null || value === undefined) {
    return ErrorMessages.required;
  }
  if (typeof value === "string" && value.length == 0) {
    return ErrorMessages.required;
  }
  return undefined;
};

export const zero = (text) => (value) =>
  value !== "" ? undefined : `Input 0 if not ${text}`;

export const email = (value) =>
  value && !Regex.email.test(value) ? ErrorMessages.email : undefined;

export const password = (value) =>
  value && !Regex.password.test(value) ? ErrorMessages.password : undefined;

export const url = (value) =>
  value && !Regex.url.test(value) ? ErrorMessages.url : undefined;

export const zip = (value) =>
  value && !Regex.zip.test(value) ? ErrorMessages.zip : undefined;

export const tel = (value) =>
  value && !Regex.tel.test(value) ? ErrorMessages.tel : undefined;

export const decimal = (value) =>
  value && !Regex.decimal.test(value) ? ErrorMessages.decimal : undefined;

export const date = (value) =>
  value && !Regex.date.test(value) ? ErrorMessages.date : undefined;

export const num = (value) =>
  value && !Regex.num.test(value) ? ErrorMessages.num : undefined;

export const minNumber = (min) => (value) =>
  value && value < min ? `More than ${min}` : undefined;

export const maxNumber = (max) => (value) =>
  value && value > max ? `Less than ${max}` : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? `More than ${min}` : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Within ${max}` : undefined;

export const isConfirmed = (name) => (value, allValues) => {
  return value != allValues[name]
    ? getPureMessage("forms.validaton.isConfirmed")
    : undefined;
};

export const isConfirmedEmail = (value, allValues) => {
  return value != allValues["email"]
    ? getPureMessage("forms.validaton.isConfirmedEmail")
    : undefined;
};

export const isConfirmedContactEmail = (value, allValues) => {
  return value != allValues["contact_email"]
    ? getPureMessage("forms.validaton.isConfirmedEmail")
    : undefined;
};

export const isConfirmedPassword = (value, allValues) => {
  return value != allValues["password"]
    ? getPureMessage("forms.validaton.isConfirmedPassword")
    : undefined;
};

export const requiredIf = (target, condition) => (value, allValues) => {
  if (!condition(allValues[target])) {
    return undefined;
  }
  if (value === null || value === undefined) {
    return ErrorMessages.required;
  }
  if (typeof value === "string" && value.length == 0) {
    return ErrorMessages.required;
  }
  return undefined;
};
