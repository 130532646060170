import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { edit, getData } from "../../../actions/collector/index";
import ProfileForm from "../../../components/forms/collector/ProfileForm";
import { Link } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import { Path } from "../../../constants/path";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class EditProfileScreen extends PureComponent {
  state = {
    editing: {},
  };

  componentWillMount = () => {
    this.setState({
      editing: this.props.collector,
    });
  };

  onSubmit = (values) => {
    return this.props.actions.edit(values);
  };

  onSuccess = (data) => {
    Notification.successIntl("util.success.update");
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="collector.edit.title" />
        </h1>

        <div className="uk-margin-medium">
          <ProfileForm
            initialValues={this.state.editing}
            onSuccess={this.onSuccess}
            onSubmit={this.onSubmit}
          />
        </div>
        <hr />
        <div className="uk-margin-medium uk-text-center">
          <Link
            className="uk-button uk-button-default"
            to={Path.collector.top.url}
          >
            <IntlText id="forms.back" />
          </Link>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    collector: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getData: () => dispatch(getData()),
      edit: (...param) => dispatch(edit(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProfileScreen),
);
