import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { IntlText } from "../util/Text";

class FairCard extends Component {
  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const fair = !this.props.fair.title
      ? Formatter.mergeDesc(this.props.fair, this.props.fair.fair_descs, lang)
      : this.props.fair;

    var url = PathGenerator.getLocalPath(Path.fairs.view.url, {
      fairId: fair.id,
    });
    if (fair.slug != null) {
      url = PathGenerator.getLocalPath(Path.original.url, {
        fairId: fair.slug,
      });
    }
    return (
      <div>
        <Link
          className="uk-link-reset uk-display-block uk-margin-small"
          to={url}
          ga={{
            category: "link",
            action: "fair_card",
            label: "fair_" + fair.id,
          }}
        >
          <div
            className={
              "uk-width-1-1 uk-image-wrapper " +
              (this.props.large
                ? "uk-height-large@s uk-height-medium"
                : "uk-height-medium")
            }
          >
            <Image src={fair.thumnail_url} alt={fair.title} />
          </div>
          <div className="uk-margin-small">
            <h3 className="uk-margin-remove">{fair.title}</h3>
            <p className="uk-margin-remove">{fair.address}</p>
            <p className="uk-margin-remove uk-text-muted">
              <span>
                {fair.start_at &&
                  moment(fair.start_at).format("YYYY.MM.DD ddd")}
              </span>
              <span> - </span>
              <span>
                {fair.end_at && moment(fair.end_at).format("YYYY.MM.DD ddd")}
              </span>
            </p>
          </div>
        </Link>
        {this.props.editing && (
          <div className="uk-margin-small uk-text-center">
            <Link
              className="uk-button uk-button-default"
              onClick={(event) => {
                this.props.onEdit && this.props.onEdit(event);
              }}
            >
              {this.props.buttonText ? (
                this.props.buttonText
              ) : (
                <IntlText id="forms.edit" />
              )}
            </Link>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FairCard);
