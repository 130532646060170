import store from "../stores";
import qs from "qs";

import { GalleryPath } from "./path/gallery";
import { FairPath } from "./path/fair";
import { CollectorPath } from "./path/collector";
import { AdminPath } from "./path/admin";
import { MediumPath } from "./path/medium";
import { OtherPath, AboutPath } from "./path/other";
import { defaultLang } from "../utils/location";

export const Path = {
  langing: {
    url: "/",
  },
  gallery: GalleryPath,
  fair: FairPath,
  collector: CollectorPath,
  admin: AdminPath,
  medium: MediumPath,
  other: OtherPath,
  about: AboutPath,
  root: {
    url: "/:lang/",
  },
  search: {
    url: "/:lang/search",
  },
  artists: {
    url: "/:lang/artists",
    view: {
      url: "/:lang/artists/:artistId",
      artworks: {
        url: "/:lang/artists/:artistId/artworks",
      },
      history: {
        url: "/:lang/artists/:artistId/history",
      },
      articles: {
        url: "/:lang/artists/:artistId/articles",
      },
      shows: {
        url: "/:lang/artists/:artistId/shows",
      },
    },
  },
  artworks: {
    url: "/:lang/artworks",
    view: {
      url: "/:lang/artworks/:artworkId",
      thanks: {
        url: "/:lang/artworks/:artworkId/thanks",
      },
      inquiried: {
        url: "/:lang/artworks/:artworkId/inquiried",
      },
      alipay: {
        url: "/:lang/artworks/:artworkId/alipay",
      },
    },
  },
  galleries: {
    url: "/:lang/galleries",
    view: {
      url: "/:lang/galleries/:galleryId",
      shows: {
        url: "/:lang/galleries/:galleryId/shows",
      },
      artworks: {
        url: "/:lang/galleries/:galleryId/artworks",
      },
      artists: {
        url: "/:lang/galleries/:galleryId/artists",
      },
      articles: {
        url: "/:lang/galleries/:galleryId/articles",
      },
      contact: {
        url: "/:lang/galleries/:galleryId/contact",
      },
    },
  },
  shows: {
    url: "/:lang/shows",
    view: {
      url: "/:lang/shows/:showId",
    },
  },
  fairs: {
    url: "/:lang/fairs",
    view: {
      url: "/:lang/fairs/:fairId",
      galleries: {
        url: "/:lang/fairs/:fairId/galleries",
      },
      artworks: {
        url: "/:lang/fairs/:fairId/artworks",
      },
      photos: {
        url: "/:lang/fairs/:fairId/photos",
      },
      articles: {
        url: "/:lang/fairs/:fairId/articles",
      },
      info: {
        url: "/:lang/fairs/:fairId/info",
      },
    },
  },
  articles: {
    url: "/:lang/articles",
    view: {
      url: "/:lang/articles/:articleId",
    },
  },
  news: {
    url: "/:lang/news",
  },
  likes: {
    url: "/:lang/likes",
  },
  original: {
    url: "/:lang/:fairId",
    galleries: {
      url: "/:lang/:fairId/galleries",
    },
    artworks: {
      url: "/:lang/:fairId/artworks",
    },
    photos: {
      url: "/:lang/:fairId/photos",
    },
    articles: {
      url: "/:lang/:fairId/articles",
    },
    info: {
      url: "/:lang/:fairId/info",
    },
  },
};

export class PathGenerator {
  static getRealUrl(url, keys = {}, query = null) {
    var realUrl = url;
    Object.keys(keys).forEach((key) => {
      if (realUrl.indexOf(":" + key) !== -1) {
        realUrl = realUrl.replace(":" + key, keys[key]);
      }
    });
    if (query) {
      realUrl += "?" + qs.stringify(query);
    }
    return realUrl;
  }

  static getLocalPath(url, key = {}, query = null) {
    const lang = store.getState().language
      ? store.getState().language.view
      : defaultLang;
    key.lang = lang;
    var path = PathGenerator.getRealUrl(url, key, query);
    return path;
  }
}

export const ExternalPath = {
  company: "https://tdrk-inc.co.jp",
  twitter: "https://twitter.com/Art_Scenes",
  facebook: "https://www.facebook.com/Art-Scenes-1430895803679706",
  instagram: "https://www.instagram.com/art_scenes_net/",
  manual:
    "https://docs.google.com/presentation/d/116o0v2mt7QlkrhBrYpsRcEZZ4ob44V2U1CmuhROVWg0/edit#slide=id.p",
};
