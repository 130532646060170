import React, { Component } from "react";
import Logger from "../../utils/logger";

export default class ClearInput extends Component {
  onFocus = (event) => {
    if (this.props.ga != null) {
      Logger.event(this.props.ga);
    } else if (this.props.meta) {
      Logger.event({
        category: this.props.meta.form,
        action: "clear_input",
        label: this.props.input.name,
      });
    }
    this.props.input.onFocus && this.props.input.onFocus(event);
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;
    return (
      <div>
        <input
          style={this.props.style}
          className={
            "uk-input uk-input-custom " +
            (hasError ? "uk-input-error" : null) +
            " " +
            this.props.className
          }
          {...this.props.input}
          placeholder={
            this.props.placeholder != null
              ? this.props.placeholder
              : this.props.label
          }
          type={this.props.type}
          autoCorrect="off"
          autoCapitalize="off"
          onFocus={this.onFocus}
        />
      </div>
    );
  };
}
