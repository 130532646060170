import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  DOMESTIC_SHIPPING_FREE,
  SALE_NOT_FOR_SALE,
  SALE_SOLD,
} from "../../constants/option";
import { Path } from "../../constants/path";
import { Link } from "../util/Clickable";
import { IntlHTML, IntlText } from "../util/Text";

declare var UIkit;

class ShippingStatus extends PureComponent {
  render = () => {
    const language = this.props.language.view;
    const saleStatus = this.props.saleStatus;
    const domesticShippingStatus = this.props.domesticShippingStatus;

    return (
      <React.Fragment>
        {domesticShippingStatus == DOMESTIC_SHIPPING_FREE && language == "ja" && (
          <div className="uk-margin-bottom">
            <small className="uk-text-muted">
              <IntlText id="details.artwork.delivery_price_free"></IntlText>
            </small>
          </div>
        )}
        {domesticShippingStatus == DOMESTIC_SHIPPING_FREE && language == "en" && (
          <div className="uk-margin-bottom">
            <small className="uk-text-muted">
              <a
                href="#"
                onClick={() => {
                  UIkit.modal(this.deliveryModal).show();
                }}
              >
                <IntlText id="details.artwork.delivery_price"></IntlText>
              </a>
            </small>
          </div>
        )}
        {domesticShippingStatus != DOMESTIC_SHIPPING_FREE &&
          saleStatus != SALE_SOLD &&
          saleStatus != SALE_NOT_FOR_SALE && (
            <div className="uk-margin-bottom">
              <small className="uk-text-muted">
                <a
                  href="#"
                  onClick={() => {
                    UIkit.modal(this.deliveryModal).show();
                  }}
                >
                  <IntlText id="details.artwork.delivery_price"></IntlText>
                </a>
              </small>
            </div>
          )}
        <div
          ref={(deliveryModal) => (this.deliveryModal = deliveryModal)}
          data-uk-modal
        >
          <div className="uk-modal-dialog uk-margin-auto-vertical">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="details.artwork.delivery_price"></IntlText>
              </h2>
            </div>
            <div className="uk-modal-body">
              <IntlHTML id="details.artwork.delivery_price_detail"></IntlHTML>
              <div className="uk-margin-small">
                <Link
                  to={Path.other.estimated_delivery_charge.url}
                  className="uk-button uk-button-text"
                  target="_blank"
                >
                  <IntlText id="layout.estimated_delivery_charge" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingStatus),
);
