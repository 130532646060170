import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { add, attach, search } from "../../../actions/gallery/artist";
import { showAlert } from "../../../actions/message";
import ArtistForm from "../../../components/forms/gallery/ArtistForm";
import ArtistsList from "../../../components/lists/Artists";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { IntlText } from "../../../components/util/Text";
import { GALLERY_TRIAL } from "../../../constants/option";
import { Path, PathGenerator } from "../../../constants/path";
import { Notification } from "../../../utils/notification";

class AddScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      candidates: null,
      previous: "",
    };
  }

  componentWillMount = () => {
    this.props.actions
      .search({
        not_gallery_id: this.props.gallery.id,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            candidates: value.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  componentDidMount = () => {
    if (
      this.props.gallery.status == GALLERY_TRIAL &&
      moment(this.props.gallery.trial_end_at) < moment()
    ) {
      this.props.actions.showAlert(
        <IntlText id="util.upgrade.title" />,
        <IntlText id="util.upgrade.message" />,
        () => {
          this.props.history.push(
            PathGenerator.getLocalPath(Path.gallery.edit.url),
          );
        },
        <IntlText id="util.upgrade.ok" />,
      );
    }
  };

  onSelectArtist = (artist) => {
    this.props.actions
      .attach(artist.id)
      .then(({ value, action }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.add");
          this.setState({
            candidates: this.state.candidates.filter((candidate) => {
              return candidate.id != artist.id;
            }),
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onChangeName = (name) => {
    this.setState({
      previous: name,
    });
    setTimeout(() => {
      if (this.state.previous != name) {
        return;
      }
      this.setState({
        candidates: null,
      });
      this.props.actions
        .search({
          not_gallery_id: this.props.gallery.id,
          keyword: name,
        })
        .then(({ value, action }) => {
          if (value.status < 300) {
            this.setState({
              candidates: value.data,
            });
          }
        })
        .catch((error) => console.log(error));
    }, 1000);
  };

  onAdd = (values) => {
    return this.props.actions.add(values);
  };

  onAdded = (data) => {
    Notification.successIntl("util.success.add");
    this.props.history.push(
      PathGenerator.getLocalPath(Path.gallery.artists.edit.url, {
        artistId: data.id,
      }),
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-large" data-uk-grid>
          <div className="uk-width-expand">
            <h1>
              <IntlText id="gallery.artists.add_title" />
            </h1>
          </div>
          <div className="uk-width-medium@s uk-text-right">
            <ChangeEditLang />
          </div>
        </div>
        <div className="uk-margin-large" data-uk-grid>
          <div className="uk-width-large@s uk-flex-last@s">
            <h3>
              <IntlText id="gallery.artists.recommend" />
            </h3>
            <div className="uk-margin">
              <ArtistsList
                artists={this.state.candidates}
                onClick={this.onSelectArtist}
                editing={1}
              />
            </div>
          </div>
          <div className="uk-width-expand">
            <h2>
              <IntlText id="gallery.artists.new_artist" />
            </h2>
            <div className="uk-margin">
              <ArtistForm
                ref={(addForm) => (this.addForm = addForm)}
                onSubmit={this.onAdd}
                onSuccess={this.onAdded}
                onChangeName={this.onChangeName}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showAlert: (...param) => dispatch(showAlert(...param)),
      search: (param) => dispatch(search(param)),
      add: (...param) => dispatch(add(...param)),
      attach: (...param) => dispatch(attach(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddScreen),
);
