import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setEditLang, setViewLang } from "../../actions/language";
import { languages } from "../../constants/option";

class ChangeViewLang extends Component {
  onChange = (event) => {
    this.props.actions.setViewLang(event.target.value);
    this.props.actions.setEditLang(event.target.value);
    this.props.onChange && this.props.onChange(event.target.value);
  };

  render = () => {
    return (
      <div>
        <select
          className={"uk-select uk-input-lang"}
          onChange={this.onChange}
          value={this.props.language.view}
        >
          {languages.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setViewLang: (lang) => dispatch(setViewLang(lang)),
      setEditLang: (lang) => dispatch(setEditLang(lang)),
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeViewLang);
