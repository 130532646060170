import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { mergeData } from "../../../actions/collector/auth";
import Textarea from "../../inputs/Textarea";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import { getPureMessage, IntlText } from "../../util/Text";
import * as validate from "../../util/Validator";

class InquiryForm extends PureComponent {
  state = {
    loading: false,
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    this.props.actions.mergeData(values);
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-margin-ssmall">
          <Field
            className="uk-form-clear"
            name="tel"
            type="tel"
            component={TextInput}
            labelId="forms.attr.tel"
            validate={[validate.required, validate.tel]}
            required
            placeholder={getPureMessage("forms.placeholder.tel")}
          />
          <div className="uk-margin-ssmall">
            <Field
              name="country"
              type="text"
              component={TextInput}
              validate={[validate.required]}
              required
              labelId="forms.attr.purchase.country"
              placeholder={getPureMessage("forms.placeholder.country")}
            />
          </div>
          <div className="uk-margin-ssmall">
            <Field
              name="address"
              component={Textarea}
              className="uk-form-textarea-xsmall"
              validate={[validate.required]}
              required
              labelId="forms.attr.purchase.address"
              placeholder={getPureMessage("forms.placeholder.address")}
            />
          </div>
          <div className="uk-margin-ssmall">
            <Field
              className="uk-form-textarea-small"
              name="collector_message"
              component={Textarea}
              labelId="forms.attr.question"
              placeholder=" "
              validate={[validate.required]}
              required
            />
          </div>
        </div>
        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-ssmall uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin-ssmall uk-text-center">
          <Button
            className="uk-button uk-button-accent uk-button-large"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.attr.inquiry.submit" />
          </Button>
        </div>
        <Field name="gallery_id" type="hidden" component={TextInput} noError />
      </form>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    collector:
      state.auth.data && state.auth.role == "collector" ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      mergeData: (param) => dispatch(mergeData(param)),
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: "collector-inquiry-form",
    // enableReinitialize : true
  })(InquiryForm),
);
