import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { add, del, edit, getAll } from "../../../actions/gallery/location";
import { showConfirm } from "../../../actions/message";
import LocationForm from "../../../components/forms/gallery/LocationForm";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { getPureMessage, IntlText } from "../../../components/util/Text";
import Location from "../../../components/views/Location";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class LocationsScreen extends Component {
  state = {
    locations: [],
    editing: {},
  };

  componentWillMount = () => {
    this.props.actions
      .getAll()
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            locations: value.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onAdded = (data) => {
    this.state.locations.push(data);
    this.setState({
      locations: this.state.locations,
    });
    UIkit.modal(this.addModal).hide();
    Notification.successIntl("util.success.add");
  };

  onEdited = (data) => {
    this.setState({
      locations: this.state.locations.map((loc) => {
        if (loc.id != this.state.editing.id) {
          return loc;
        }
        return data;
      }),
    });
    UIkit.modal(this.editModal).hide();
    Notification.successIntl("util.success.update");
  };

  onEdit = (location) => {
    const descs = location.location_descs
      ? location.location_descs.find((desc) => {
          return desc.lang == this.props.language.edit;
        })
      : {};
    const editingLocation = Object.assign({}, descs, location);
    this.setState(
      {
        editing: editingLocation,
      },
      () => {
        UIkit.modal(this.editModal).show();
      },
    );
  };

  onDelete = (location) => {
    this.props.actions.showConfirm(
      getPureMessage("util.confirm.title.delete"),
      "",
      (result) => {
        if (!result) {
          return;
        }
        this.props.actions
          .del(location.id)
          .then(({ value, action }) => {
            if (value.status < 300) {
              this.setState({
                locations: this.state.locations.filter((loc) => {
                  return loc.id != location.id;
                }),
              });
            }
          })
          .catch((error) => console.log(error));
      },
    );
  };

  renderNewLocation = () => {
    return (
      <div>
        <h2 className="">
          <span
            data-uk-icon="icon:plus; ratio: 1.2;"
            className="uk-margin-small-right uk-text-middle"
          />
          <span className="uk-text-middle">
            <IntlText id="gallery.location.add_location" />
          </span>
        </h2>
        <div className="uk-margin-medium">
          <div>
            <LocationForm
              onSubmit={(values) => this.props.actions.add(values)}
              onSuccess={this.onAdded}
            />
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin uk-flex-middle uk-flex-right" data-uk-grid>
          <div className="uk-width-auto@s">
            <h1>
              <IntlText id="gallery.location.title" />
            </h1>
          </div>
          <div className="uk-width-expand">
            <button
              className="uk-button uk-button-default"
              onClick={() =>
                UIkit.modal(this.addModal, {
                  container: false,
                }).show()
              }
            >
              <IntlText id="forms.add" />
              <span
                className="uk-margin-small-left"
                data-uk-icon="icon:plus;ratio:0.6;"
              ></span>
            </button>
          </div>
          <div className="uk-width-medium@s uk-width-1-1 uk-text-right">
            <ChangeEditLang onChange={this.onChageEditLang} />
          </div>
        </div>

        {this.state.locations.map((location, index) => {
          return (
            <div className="uk-margin-medium" key={index}>
              <Location
                editing
                lang={this.props.language.edit}
                location={location}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
              />
            </div>
          );
        })}
        {this.state.locations.length == 0 && (
          <p>
            <IntlText id="gallery.location.no" />
          </p>
        )}

        <div ref={(addModal) => (this.addModal = addModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="gallery.location.add_location" />
              </h2>
            </div>
            <div className="uk-modal-body">
              <LocationForm
                form="location-add-form"
                onSubmit={(values) => this.props.actions.add(values)}
                onSuccess={this.onAdded}
              />
            </div>
          </div>
        </div>

        <div ref={(editModal) => (this.editModal = editModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="gallery.location.edit_location" />
              </h2>
            </div>
            <div className="uk-modal-body">
              <LocationForm
                form="location-edit-form"
                onSubmit={(values) =>
                  this.props.actions.edit(this.state.editing.id, values)
                }
                onSuccess={this.onEdited}
                initialValues={this.state.editing}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAll: () => dispatch(getAll()),
      add: (data) => dispatch(add(data)),
      edit: (locationId, data) => dispatch(edit(locationId, data)),
      del: (locationId) => dispatch(del(locationId)),
      showConfirm: (...attr) => dispatch(showConfirm(...attr)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationsScreen),
);
