import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Content from "../common/Content";
import { IntlText } from "../util/Text";

class Article extends Component {
  render = () => {
    var description = this.props.article.description;
    if (!description) {
      const article = this.props.article.article_descs.find((article) => {
        return article.description != null && article.description.length > 0;
      });
      description = article ? article.description : null;
    }
    return (
      <div>
        <div className="uk-article-base uk-margin-large">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        {this.props.article.publishes != null &&
          this.props.article.publishes.length > 0 && (
            <div className="uk-margin-large">
              <h2 className="uk-heading-mark">
                <IntlText id="details.article.about" />
              </h2>
              {this.props.article.publishes.map((publish, index) => {
                return (
                  <div key={index}>
                    <div className="uk-hover-muted uk-hover-allow uk-padding-small">
                      <Content content={publish} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        {(this.props.article.gallery != null ||
          this.props.article.fair != null ||
          this.props.article.artist != null) && (
          <div className="uk-margin-large">
            <h2 className="uk-heading-mark">
              <IntlText id="details.article.written_by" />
            </h2>
            <div>
              <div className="uk-hover-muted uk-hover-allow uk-padding-small">
                <Content content={this.props.article} />
              </div>
            </div>
          </div>
        )}
        {this.props.article.medium != null && (
          <div className="uk-margin-medium uk-text-right">
            <span className="uk-margin-ssmall-right">
              <IntlText id="details.article.written_by" />
            </span>
            <span>{this.props.article.medium.name}</span>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
