import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import MapBase from "../util/Map";
import { IntlText } from "../util/Text";

class Location extends Component {
  renderProperty = (title, data) => {
    return (
      <div
        className="uk-margin-small uk-flex-middle"
        data-uk-grid="margin:uk-margin-ssmall"
      >
        <div className="uk-width-small@s">
          <span className="uk-text-muted  uk-margin-remove uk-h5">{title}</span>
        </div>
        <div className="uk-width-expand">{data}</div>
      </div>
    );
  };

  render = () => {
    const lang = this.props.lang ? this.props.lang : this.props.language.view;
    const location = !this.props.location.name
      ? Formatter.mergeDesc(
          this.props.location,
          this.props.location.location_descs,
          lang,
        )
      : this.props.location;
    const defaultLocation = Formatter.defalutDesc(
      this.props.location,
      this.props.location.location_descs,
    );
    return (
      <div>
        <span className="uk-h2 uk-margin-small">{location.name}</span>
        <h3 className="uk-h4 uk-margin-small">{defaultLocation.name}</h3>
        <div className="uk-margin-medium">
          {this.renderProperty(
            <IntlText id="views.location.address" />,
            <p className="uk-h5 uk-margin-remove ">
              {location.post_code && (
                <span className="uk-margin-ssmall-right">
                  〒{location.post_code}
                </span>
              )}
              <span>{location.address}</span>
            </p>,
          )}

          {this.renderProperty(
            <IntlText id="views.location.tel" />,
            <p className="uk-h5 uk-margin-remove ">
              {location.tel && (
                <Link
                  ga={{ category: "link", action: "location", label: "tel" }}
                  href={"tel:" + location.tel}
                >
                  {" "}
                  {location.tel}{" "}
                </Link>
              )}
            </p>,
          )}

          {this.renderProperty(
            <IntlText id="views.location.hp" />,
            <p className="uk-h5 uk-margin-remove ">
              {location.hp && (
                <Link
                  ga={{ category: "link", action: "location", label: "hp" }}
                  href={location.hp}
                  target="_blank"
                >
                  {" "}
                  {location.hp}{" "}
                </Link>
              )}
            </p>,
          )}
        </div>
        {this.props.editing ? (
          <div className="uk-margin-medium uk-text-center">
            <a
              className="uk-button uk-button-default uk-margin-small-right"
              onClick={() => this.props.onEdit && this.props.onEdit(location)}
            >
              <IntlText id="forms.edit" />
            </a>
            <a
              className="uk-button uk-button-danger uk-margin-small-left"
              onClick={() =>
                this.props.onDelete && this.props.onDelete(location)
              }
            >
              <IntlText id="forms.delete" />
            </a>
          </div>
        ) : (
          <div className="uk-margin-medium">
            <MapBase isMarkerShown={true} address={location.address} />
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Location);
