import React, { PureComponent } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import Textarea from "../inputs/Textarea";
import TextInput from "../inputs/TextInput";
import { Button } from "../util/Clickable";
import * as normalize from "../util/Normalizer";
import { getPureMessage, IntlText } from "../util/Text";
import * as validate from "../util/Validator";

class ContactForm extends PureComponent {
  state = {
    loading: false,
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div className="uk-margin-small">
          <div className="uk-margin-ssmall">
            <Field
              name="name"
              type="text"
              component={TextInput}
              labelId="forms.attr.contact.name"
              validate={[validate.required]}
              required
            />
          </div>
          <div className="uk-margin-ssmall">
            <Field
              name="email"
              type="email"
              component={TextInput}
              labelId="forms.attr.email"
              validate={[validate.required, validate.email]}
              required
            />
          </div>
          <div className="uk-margin-ssmall">
            <Field
              name="tel"
              type="tel"
              component={TextInput}
              labelId="forms.attr.tel"
              validate={[validate.required, validate.tel]}
              required
              normalize={normalize.numberOnly}
            />
          </div>
          <div className="uk-margin-ssmall">
            <Field
              name="description"
              component={Textarea}
              labelId="forms.attr.contact.description"
              validate={[validate.required]}
              placeholder={getPureMessage(
                "forms.attr.contact.description_placeholder",
              )}
              ga={{ category: "input", action: "contact__description" }}
              required
            />
          </div>
        </div>
        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin-small uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>

        <Field name="gallery_id" type="hidden" component={TextInput} noError />
      </form>
    );
  };
}

export default reduxForm({
  form: "contact-form",
  enableReinitialize: true,
})(ContactForm);
