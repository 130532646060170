import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { addContact } from "../../actions/contact";
import { showAlert } from "../../actions/message";
import logo from "../../assets/images/logo/black.png";
import ContactForm from "../../components/forms/ContactForm";
import Meta from "../../components/layout/Meta";
import { getPureMessage, IntlText } from "../../components/util/Text";

class ContactScreen extends PureComponent {
  onSubmit = (values) => {
    return this.props.actions.addContact(values);
  };

  onSuccess = (data) => {
    this.props.actions.showAlert(
      getPureMessage("contact.thanks"),
      getPureMessage("contact.lead"),
    );
    this.form.wrappedInstance.props.reset();
  };

  render = () => {
    return (
      <div className="uk-text-center">
        <Meta title={getPureMessage("others.contact.meta")} />
        <div className="uk-width-xlarge@s uk-text-left uk-display-inline-block">
          <div className="uk-text-center">
            <img src={logo} className="uk-width-medium" />
          </div>
          <h1 className="uk-text-center">
            <IntlText id="others.contact.title" />
          </h1>
          <p className="uk-h2 uk-text-center">
            <IntlText id="others.contact.lead" />
          </p>
          {/* <ul className="uk-list uk-list-small uk-list-bullet">
            <li>
              <IntlText id="others.contact.about" />
            </li>
            <li>
              <IntlText id="others.contact.register" />
            </li>
          </ul> */}

          <div className="uk-margin-large">
            <ContactForm
              ref={(form) => (this.form = form)}
              onSubmit={this.onSubmit}
              onSuccess={this.onSuccess}
              initialValues={{
                name: this.props.collector.name,
                email: this.props.collector.email,
                tel: this.props.collector.tel,
              }}
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    collector: state.auth.role == "collector" ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showAlert: (title, message) => dispatch(showAlert(title, message)),
      addContact: (data) => dispatch(addContact(data)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactScreen),
);
