import React, { Component } from "react";
import Logger from "../../utils/logger";
import { getPureMessage, IntlText } from "../util/Text";

export default class AutoCompleteInput extends Component {
  onClick = () => {
    Logger.event(this.props.ga);
  };

  onChange = (value) => {
    this.props.input &&
      this.props.input.onChange &&
      this.props.input.onChange(value);
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;

    const label = this.props.labelId
      ? getPureMessage(this.props.labelId)
      : this.props.label;
    const placeholder = this.props.placeholder ? this.props.placeholder : label;

    return (
      <div>
        {(() => {
          if (label != null) {
            return (
              <label className="uk-form-label uk-text-nowrap">
                {label}
                <wbr />
                {(() => {
                  if (this.props.required) {
                    return (
                      <small className="uk-text-danger">
                        {" "}
                        ※<IntlText id="forms.attr.required" />
                      </small>
                    );
                  }
                })()}
              </label>
            );
          }
        })()}
        <div className="uk-form-controls">
          <div>
            <input
              onClick={this.onClick}
              style={this.props.style}
              className={
                "uk-input uk-input-custom " +
                (hasError ? "uk-input-error" : null) +
                " " +
                this.props.className
              }
              {...this.props.input}
              placeholder={placeholder}
              type={this.props.type}
              autoCorrect="off"
              autoCapitalize="off"
            />
          </div>
          <div>
            {this.props.options &&
              this.props.options
                .filter((option, index) => {
                  return option.label.indexOf(this.props.input.value) !== -1;
                })
                .filter((option, index) => {
                  return index < 10;
                })
                .map((option, index) => {
                  return (
                    <a
                      key={index}
                      className="uk-tag-autocomplete"
                      onClick={() => this.onChange(option.label)}
                    >
                      {option.label}
                    </a>
                  );
                })}
          </div>
        </div>
        <div className="uk-text-danger " style={{ minHeight: "18px" }}>
          <small>{hasError && this.props.meta.error}</small>
        </div>
      </div>
    );
  };
}
