import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import mark from "../../assets/images/logo/mark.svg";
import { getPureMessage as message } from "../../langs/messages";
import { Link } from "../util/Clickable";

export const Header2Text = (props) => {
  return (
    <h2 className={"uk-text-center uk-text-middle"}>
      <img
        src={mark}
        style={{ height: 28 }}
        className="uk-text-top uk-margin-small-right"
      />
      <span className="uk-text-top">{props.children}</span>
    </h2>
  );
};

export const Header3Text = (props) => {
  return <h3>{props.children}</h3>;
};

export const LinedText = (props) => {
  return (
    <p {...props}>
      {props.text &&
        props.text.split("\n").map((line, key) => {
          return (
            <span key={key}>
              {line}
              <br />
            </span>
          );
        })}
    </p>
  );
};

export const ViewAllHeader = (props) => {
  return (
    <div className="uk-grid-small uk-flex-middle" data-uk-grid>
      <div className="uk-width-expand">
        <h2 className="uk-heading-mark">
          {props.titleId ? <IntlText id={props.titleId} /> : props.title}
        </h2>
      </div>
      {props.url && (
        <div className="uk-width-auto uk-visible@s">
          <Link
            className="uk-button uk-button-default uk-button-plus uk-position-relative uk-padding-remove"
            to={props.url}
            ga={{
              category: "link",
              action: "root",
              label: "all_" + props.label,
            }}
          >
            <span className="uk-padding-ssmall">
              {props.buttonText ? (
                <span>{props.buttonText}</span>
              ) : (
                <IntlHTML id="word.view_all" />
              )}
              <span
                className="uk-margin-ssmall-left"
                data-uk-icon="icon:plus;ratio:0.8;"
              />
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export const IntlText = (props) => {
  return <FormattedMessage {...props} />;
};

export const IntlHTML = (props) => {
  return <FormattedHTMLMessage {...props} />;
};

export const getPureMessage = message;
