import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { search as searchFair } from "../../../actions/gallery/attend";
import { getData } from "../../../actions/gallery/index";
import { search as searchInfo } from "../../../actions/gallery/information";
import FairsList from "../../../components/lists/Fairs";
import InformationsList from "../../../components/lists/Informations";
import { Link } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import Plan from "../../../components/views/Plan";
import { Path, PathGenerator } from "../../../constants/path";

class TopScreen extends PureComponent {
  state = {
    informations: null,
    fairs: null,
    gallery: {},
  };

  componentWillMount = () => {
    this.props.actions
      .getData()
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            gallery: value.data,
          });
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchInfo()
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            informations: value.data,
          });
        }
      })
      .catch((error) => console.log(error));

    this.props.actions
      .searchFair({
        start_from: new Date(),
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            fairs: value.data.map((attend) => {
              attend.fair.attend = attend;
              return attend.fair;
            }),
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="gallery.dashboard.title" />
        </h1>

        {false && (
          <div className="uk-margin-medium">
            <h2 className="uk-heading-mark">
              <IntlText id="gallery.dashboard.plan" />
            </h2>
            <div className="uk-margin">
              <Plan
                gallery={this.state.gallery}
                onUpdated={this.onSuccessCard}
                editing={false}
              />
            </div>
          </div>
        )}

        <div className="uk-margin-medium" data-uk-grid>
          <div className="uk-width-2-3@s">
            <h2 className="uk-heading-mark">
              <IntlText id="gallery.dashboard.invitedFair" />
            </h2>
            <FairsList
              size={2}
              fairs={this.state.fairs}
              editing={true}
              onClick={(fair) =>
                this.props.history.push(
                  PathGenerator.getLocalPath(Path.gallery.fairs.edit.url, {
                    attendId: fair.attend.id,
                  }),
                )
              }
            />
          </div>
          <div className="uk-width-1-3@s">
            <h2 className="uk-heading-mark">
              <IntlText id="gallery.dashboard.transaction" />
            </h2>
            {this.props.gallery.purchases_count > 0 ? (
              <div className="uk-margin">
                <div className="uk-margin-small uk-text-center">
                  <span className="uk-text-danger uk-h3 uk-margin-remove">
                    <IntlText
                      id="gallery.dashboard.newRequest"
                      values={{ num: this.props.gallery.purchases_count }}
                    />
                  </span>
                </div>
                <div className="uk-margin-small uk-text-center">
                  <Link
                    className="uk-button uk-button-default"
                    to={Path.gallery.transaction.purchases.url}
                  >
                    <IntlText id="gallery.dashboard.check" />
                  </Link>
                </div>
              </div>
            ) : (
              <div className="uk-margin">
                <span className="uk-h4 uk-margin-remove">
                  <IntlText id="gallery.dashboard.nothing" />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.dashboard.information" />
          </h2>
          <div className="uk-margin">
            <InformationsList informations={this.state.informations} />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    gallery: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getData: () => dispatch(getData()),
      searchInfo: () => dispatch(searchInfo()),
      searchFair: (...param) => dispatch(searchFair(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopScreen),
);
