import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { deleteAttend, search } from "../../../actions/fair/invite";
import { showConfirm } from "../../../actions/message";
import AttendsList from "../../../components/lists/Attends";
import { Button } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class IndexScreen extends PureComponent {
  state = {
    attends: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        fair_id: this.props.fair.id,
        page: this.state.meta.current_page + 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            attends:
              this.state.attends && !refresh
                ? this.state.attends.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onDelete = (attend) => {
    this.props.actions.showConfirm(
      <IntlText id="util.confirm.title.delete" />,
      <IntlText id="fair.invites.delete_detail" />,
      (confirmed) => {
        if (!confirmed) {
          return;
        }

        this.props.actions
          .deleteAttend(attend.id)
          .then(({ value }) => {
            if (value.status < 300) {
              this.setState({
                attends: this.state.attends.filter((at) => {
                  return at.id != attend.id;
                }),
              });
              Notification.successIntl("util.success.delete");
            }
          })
          .catch((error) => console.log(error));
      },
    );
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="fair.invites.title" />
        </h1>
        <div className="uk-margin-medium">
          <AttendsList
            attends={this.state.attends}
            editing={true}
            buttonText={<IntlText id="forms.delete" />}
            onEdit={(attend) => this.onDelete(attend)}
          />
        </div>

        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-medium uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
              >
                <IntlText id="forms.more" />
              </Button>
            </div>
          )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    fair: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (param) => dispatch(search(param)),
      deleteAttend: (...param) => dispatch(deleteAttend(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
