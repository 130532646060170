import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Meta from "../../../components/layout/Meta";
import {
  getPureMessage,
  IntlText,
  LinedText,
} from "../../../components/util/Text";
import { terms } from "../../../constants/term/gallery";

class GalleryScreen extends PureComponent {
  render = () => {
    return (
      <div>
        <Meta title={getPureMessage("gallery.auth.login.term")} />
        <h1 className="uk-heading-mark uk-margin-remove">
          <IntlText id="gallery.auth.login.term" />
        </h1>
        <div className="uk-margin-large">
          <LinedText text={terms[this.props.language.view]} />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GalleryScreen),
);
