import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Path } from "../../constants/path";
import ForgetScreen from "./auth/ForgetScreen";
import LoginScreen from "./auth/LoginScreen";
import RegisterScreen from "./auth/RegisterScreen";
import ResetScreen from "./auth/ResetScreen";
import ThanksScreen from "./auth/ThanksScreen";
import ValidateScreen from "./auth/ValidateScreen";

class AuthScreen extends PureComponent {
  render = () => {
    return (
      <div className="uk-section uk-section-large">
        <div className="uk-container uk-container-small">
          <Switch>
            <Route
              exact
              path={Path.collector.auth.login.url}
              component={LoginScreen}
            />
            <Route
              exact
              path={Path.collector.auth.register.url}
              component={RegisterScreen}
            />
            <Route
              exact
              path={Path.collector.auth.forget.url}
              component={ForgetScreen}
            />
            <Route
              exact
              path={Path.collector.auth.reset.url}
              component={ResetScreen}
            />
            <Route
              exact
              path={Path.collector.auth.thanks.url}
              component={ThanksScreen}
            />
            <Route
              exact
              path={Path.collector.auth.validate.url}
              component={ValidateScreen}
            />
          </Switch>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthScreen),
);
