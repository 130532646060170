import gallery from "./screens/gallery";
import fair from "./screens/fair";

import views from "./components/views";
import forms from "./components/forms";
import lists from "./components/lists";
import util from "./components/util";
import option from "./components/option";
import common from "./common";

const messages = {
  gallery: gallery,
  fair: fair,
  forms: forms,
  views: views,
  lists: lists,
  util: util,
  option: option,
  ...common,
};

export default messages;
