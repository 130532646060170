import qs from "qs";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { resetSource } from "../../actions/collector/auth";
import { purchase as purchaseByCollector } from "../../actions/collector/purchase";
import { purchase } from "../../actions/purchase";
import { Link } from "../../components/util/Clickable";
import { Loading } from "../../components/util/Loading";
import { IntlHTML } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";

class AlipayScreen extends PureComponent {
  state = {
    result: null,
  };

  componentWillMount = () => {
    const artworkId = this.props.match.params.artworkId;
    const param = qs.parse(this.props.location.search.replace("?", ""));
    const source = param.source;

    if (source == this.props.source) {
      this.props.actions.resetSource();
      this.setState({ result: true });
      const requestParam = Object.assign({}, this.props.collector, {
        card_token: source,
        mode: "alipay",
      });
      this.doPurchase(artworkId, requestParam).then(({ value }) => {
        if (value.status < 300) {
          this.props.history.push(
            PathGenerator.getLocalPath(
              Path.artworks.view.thanks.url,
              { artworkId: artworkId },
              { gallery_id: this.props.collector.gallery_id },
            ),
          );
        } else {
          this.setState({ result: false });
        }
      });
    } else {
      this.setState({ result: false });
    }
  };

  doPurchase = (artworkId, requestParam) => {
    if (this.props.collector.id == null) {
      return this.props.actions.purchase(artworkId, requestParam);
    } else {
      return this.props.actions.purchaseByCollector(artworkId, requestParam);
    }
  };

  render = () => {
    const artworkId = this.props.match.params.artworkId;
    const galleryId = this.props.collector.gallery_id;

    if (this.state.result == null) {
      return <Loading />;
    } else if (!this.state.result) {
      return (
        <div>
          <h1 className="uk-text-center">
            <IntlHTML id="artworks.alipay.invaild" />
          </h1>
          <div className="uk-margin uk-text-center">
            <Link
              className="uk-button uk-button-default"
              to={PathGenerator.getLocalPath(
                Path.artworks.view.url,
                { artworkId: artworkId },
                { gallery_id: galleryId },
              )}
              ga={{ category: "link", action: "alipay", label: "back" }}
            >
              <IntlHTML id="artworks.alipay.back" />
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Loading />
          <h1 className="uk-text-center">
            <IntlHTML id="artworks.alipay.checking" />
          </h1>
        </div>
      );
    }
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    collector:
      state.auth.data && state.auth.role == "collector" ? state.auth.data : {},
    source: state.auth.source,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      purchase: (...param) => dispatch(purchase(...param)),
      purchaseByCollector: (...param) =>
        dispatch(purchaseByCollector(...param)),
      resetSource: () => dispatch(resetSource()),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AlipayScreen),
);
