import React, { Component } from "react";
import { connect } from "react-redux";
import { Link as NativeLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { PathGenerator } from "../../constants/path";
import Logger from "../../utils/logger";
import { IntlText } from "./Text";

declare var UIkit;

class LinkComponent extends Component {
  onClick = (event) => {
    if (this.props.ga != null) {
      Logger.event(this.props.ga);
    }
    const drop = UIkit.drop("#search-drop");
    if (drop) {
      drop.hide();
    }
    this.props.onClick && this.props.onClick(event);
  };

  getIntlLink = () => {
    return PathGenerator.getRealUrl(this.props.to, {
      lang: this.props.language.view,
    });
  };

  render = () => {
    var { onClick, to, refresh, ...props } = this.props;
    if (to == null) {
      return (
        <a onClick={this.onClick} {...props}>
          {this.props.children}
        </a>
      );
    } else if (refresh) {
      return (
        <a
          ref={(link) => (this.link = link)}
          onClick={(event) => {
            this.onClick(event);
            window.location.href =
              process.env.REACT_APP_URL + this.getIntlLink();
          }}
          {...props}
        >
          {this.props.children}
        </a>
      );
    } else {
      return (
        <NativeLink
          ref={(link) => (this.link = link)}
          onClick={this.onClick}
          {...props}
          to={this.getIntlLink()}
        >
          {this.props.children}
        </NativeLink>
      );
    }
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export const Link = connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(LinkComponent);

class ButtonComponent extends Component {
  onClick = (event) => {
    if (this.props.ga != null) {
      Logger.event(this.props.ga);
    }
    const drop = UIkit.drop("#search-drop");
    if (drop) {
      drop.hide();
    }
    this.props.onClick && this.props.onClick(event);
  };

  render = () => {
    var props = Object.assign({}, this.props);
    delete props.onClick;

    if (props.loading) {
      return (
        <button {...props} disabled>
          <span data-uk-uk-spinner className="uk-margin-small-right" />
          <IntlText id="forms.loading" />
        </button>
      );
    }

    return (
      <button onClick={this.onClick} {...props}>
        {this.props.children}
      </button>
    );
  };
}

export const Button = connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(ButtonComponent);
