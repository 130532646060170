export const numberOnly = (value, allValues) => {
  if (value == null) {
    return value;
  }
  return value
    .replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
    .replace(/[^-0-9]/g, "");
};

export const float = (value, allValues) => {
  if (value == null) {
    return value;
  }
  return value
    .replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
    .replace(/[^-0-9.]/g, "");
};
