export const iOS = "iOS";
export const Android = "Android";
export const PC = "PC";

export default class UA {
  static os() {
    var ua = navigator.userAgent;
    var iphone = ua.indexOf("iPhone") > 0;
    var androidSp = ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0;
    var ipad = ua.indexOf("iPad") > 0;
    var androidT = ua.indexOf("Android") > 0;

    if (iphone || ipad) {
      return iOS;
    } else if (androidSp || androidT) {
      return Android;
    } else {
      return PC;
    }
  }
}
