import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { get } from "../../actions/gallery";
import { Link } from "../../components/util/Clickable";
import { Image } from "../../components/util/Image";
import { LikeDetail } from "../../components/util/Like";
import { Loading } from "../../components/util/Loading";
import NotFound from "../../components/util/NotFound";
import { FollowMe } from "../../components/util/SNS";
import { IntlText } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import ArticlesScreen from "./ArticlesScreen";
import ArtistsScreen from "./ArtistsScreen";
import ArtworksScreen from "./ArtworksScreen";
import ContactScreen from "./ContactScreen";
import OverviewScreen from "./OverviewScreen";
import ShowsScreen from "./ShowsScreen";

const urls = [
  {
    id: "menu.overview",
    title: "OVERVIEW",
    url: (galleryId) =>
      PathGenerator.getLocalPath(Path.galleries.view.url, {
        galleryId: galleryId,
      }),
  },
  {
    id: "menu.shows",
    title: "SHOWS",
    url: (galleryId) =>
      PathGenerator.getLocalPath(Path.galleries.view.shows.url, {
        galleryId: galleryId,
      }),
  },
  {
    id: "menu.artworks",
    title: "ARTWORKS",
    url: (galleryId) =>
      PathGenerator.getLocalPath(Path.galleries.view.artworks.url, {
        galleryId: galleryId,
      }),
  },
  {
    id: "menu.artists",
    title: "ARTISTS",
    url: (galleryId) =>
      PathGenerator.getLocalPath(Path.galleries.view.artists.url, {
        galleryId: galleryId,
      }),
  },
  {
    id: "menu.articles",
    title: "ARTICLES",
    url: (galleryId) =>
      PathGenerator.getLocalPath(Path.galleries.view.articles.url, {
        galleryId: galleryId,
      }),
  },
  {
    id: "menu.contact",
    title: "CONTACT",
    url: (galleryId) =>
      PathGenerator.getLocalPath(Path.galleries.view.contact.url, {
        galleryId: galleryId,
      }),
  },
];

class ViewScreen extends PureComponent {
  state = {
    gallery: null,
  };

  componentWillMount = () => {
    this.reload(this.props.match.params.galleryId);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.match.params.galleryId != nextProps.match.params.galleryId) {
      this.reload(nextProps.match.params.galleryId);
    }
  };

  componentDidUpdate = () => {
    setTimeout(() => {
      if (this.state.gallery != null) {
        this.menuScroll();
      }
    }, 0);
  };

  menuScroll = () => {
    if (!this.scroll) {
      return;
    }

    Array.from(this.scroll.children[0].children).forEach((element) => {
      if (element.getAttribute("active")) {
        this.active = element;
      }
    });
    if (!this.active) {
      return;
    }
    const left =
      this.active.offsetLeft +
      this.active.offsetWidth / 2 -
      window.innerWidth / 2;
    this.scroll.scrollLeft = left;
  };

  reload = (galleryId) => {
    this.setState({
      gallery: null,
    });
    this.props.actions.get(galleryId).then(({ value }) => {
      if (value.status > 300) {
        return this.setState({
          gallery: false,
        });
      }
      this.setState({
        gallery: value.data,
      });
    });
  };

  render = () => {
    if (this.state.gallery == null) {
      return <Loading />;
    } else if (!this.state.gallery) {
      return <NotFound />;
    }

    const gallery = Formatter.mergeDesc(
      this.state.gallery,
      this.state.gallery.gallery_descs,
      this.props.language.view,
    );

    const defaultGallery = Formatter.defalutDesc(
      this.state.gallery,
      this.state.gallery.gallery_descs,
    );

    const isTop = urls[0].url(gallery.id) == this.props.location.pathname;

    var defTitle = gallery.name;
    var subTitle = null;
    if (this.props.language.view != "en") {
      let desc = this.state.gallery.gallery_descs.find((desc) => {
        return desc.lang == "en";
      });
      subTitle = desc.name;
    }
    return (
      <div>
        <div className="uk-margin-medium" data-uk-grid>
          <div className="uk-width-auto">
            <div className="uk-thumnail uk-image-wrapper">
              <Image src={gallery.logo_url} />
            </div>
          </div>
          <div className="uk-width-expand">
            {isTop ? (
              <h1 className="uk-h1 uk-margin-ssmall">{defTitle}</h1>
            ) : (
              <span className="uk-h1 uk-margin-ssmall">{defTitle}</span>
            )}
            {subTitle && <h4 className="uk-margin-ssmall">{subTitle}</h4>}
            <div
              className="uk-margin-small uk-width-xxlarge@s uk-grid-small uk-child-width-1-4@s uk-child-width-1-2"
              data-uk-grid="margin: uk-margin-ssmall"
            >
              {gallery.locations.map((location, index) => {
                var location = Formatter.mergeDesc(
                  location,
                  location.location_descs,
                  this.props.language.view,
                );
                return (
                  <div key={index}>
                    <small className="uk-text-mincho uk-text-muted">
                      ・{location.place}
                    </small>
                  </div>
                );
              })}
            </div>
            <div className="uk-margin-small">
              <LikeDetail type="gallery" data={gallery} />
            </div>
          </div>
        </div>

        <div
          className="uk-margin-medium uk-grid-min"
          data-uk-grid="margin: uk-margin-min;"
        >
          {urls.map((url, index) => {
            return (
              <div key={index} className="uk-width@m uk-width-1-3">
                <Link
                  className="uk-button uk-button-clear uk-width-1-1 uk-padding-remove"
                  to={url.url(gallery.id)}
                  active={
                    this.props.location.pathname == url.url(gallery.id)
                      ? "true"
                      : null
                  }
                  ga={{ category: "link", action: "gallery", label: url.title }}
                >
                  <IntlText id={url.id} />
                </Link>
              </div>
            );
          })}
        </div>

        <div
          className="uk-position-fixed uk-position-bottom uk-width-1-1 uk-hidden@s uk-background-white uk-position-z-index uk-overflow-auto uk-scrollbar-hidden"
          data-uk-scrollspy="cls: uk-animation-slide-bottom;"
          ref={(scroll) => (this.scroll = scroll)}
          onLoad={this.menuScroll}
        >
          <div
            className="uk-grid-collapse uk-view-menu-base"
            data-uk-grid
            style={{ flexWrap: "nowrap" }}
          >
            {urls.map((url, index) => {
              const isActive =
                this.props.location.pathname == url.url(gallery.id);
              return (
                <div
                  key={index}
                  className="uk-width-auto"
                  active={isActive ? "true" : null}
                >
                  <Link
                    className="uk-button uk-button-clear uk-width-1-1 uk-padding-small-horizontal"
                    to={url.url(gallery.id)}
                    active={isActive ? "true" : null}
                    ga={{
                      category: "link",
                      action: "gallery",
                      label: url.title,
                    }}
                  >
                    <IntlText id={url.id} />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        <div className="uk-margin-medium">
          <Switch>
            <Route
              exact
              path={Path.galleries.view.url}
              component={() => <OverviewScreen gallery={gallery} />}
            />
            <Route
              exact
              path={Path.galleries.view.shows.url}
              component={() => <ShowsScreen gallery={gallery} />}
            />
            <Route
              exact
              path={Path.galleries.view.artworks.url}
              component={() => <ArtworksScreen gallery={gallery} />}
            />
            <Route
              exact
              path={Path.galleries.view.artists.url}
              component={() => <ArtistsScreen gallery={gallery} />}
            />
            <Route
              exact
              path={Path.galleries.view.articles.url}
              component={() => <ArticlesScreen gallery={gallery} />}
            />
            <Route
              exact
              path={Path.galleries.view.contact.url}
              component={() => <ContactScreen gallery={gallery} />}
            />
          </Switch>
        </div>

        <div className="uk-margin-medium">
          <FollowMe />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (param) => dispatch(get(param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewScreen),
);
