import { SET_VIEW_LANG, SET_EDIT_LANG } from "../constants/type";
import Immutable from "immutable";

const initStatus = {
  view: "ja",
  edit: "ja",
};

export default function languageReducer(state = initStatus, action = {}) {
  switch (action.type) {
    case SET_VIEW_LANG:
      return Immutable.fromJS(state)
        .updateIn(["view"], (v) => action.lang)
        .toJS();

    case SET_EDIT_LANG:
      return Immutable.fromJS(state)
        .updateIn(["edit"], (v) => action.lang)
        .toJS();

    default:
      return state;
  }
}
