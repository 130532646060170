import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Meta from "../../components/layout/Meta";
import { Link } from "../../components/util/Clickable";
import { Loading } from "../../components/util/Loading";
import {
  getPureMessage,
  IntlText,
  LinedText,
} from "../../components/util/Text";

class InfoScreen extends PureComponent {
  renderContentSmall = (label, text, content = null) => {
    if (text == null) {
      return null;
    }
    return (
      <div
        data-uk-grid="margin:uk-margin-ssmall;"
        className="uk-grid-small uk-margin-small"
      >
        <div className="uk-width-small@s">
          <span className="uk-text-muted">{label}</span>
        </div>
        <div className="uk-width-expand">
          {content ? content(text) : <LinedText text={text} />}
        </div>
      </div>
    );
  };

  renderContentLarge = (label, text, content = null) => {
    if (text == null) {
      return null;
    }
    return (
      <div
        data-uk-grid="margin:uk-margin-ssmall;"
        className="uk-grid-small uk-margin-small"
      >
        <div className="uk-width-medium@s">
          <span className="uk-text-muted">{label}</span>
        </div>
        <div className="uk-width-expand">
          {content ? content(text) : <LinedText text={text} />}
        </div>
      </div>
    );
  };

  render = () => {
    if (this.props.fair == null) {
      return <Loading />;
    }

    return (
      <div>
        <Meta
          title={getPureMessage("fairs.info.meta") + this.props.fair.title}
          description={this.props.fair.description}
          image={this.props.fair.thumnail_url}
        />
        <h1 className="uk-heading-mark uk-margin-remove uk-h2">
          <IntlText
            id="fairs.info.title"
            values={{ title: this.props.fair.title }}
          />
        </h1>
        <div className="uk-margin-medium">
          <div className="uk-margin-medium">
            <span className="uk-h2 uk-margin-remove">
              {this.props.defaultFair.title}
            </span>
            <h2 className="uk-h4 uk-margin-remove">{this.props.fair.title}</h2>

            <div className="uk-margin">
              {this.renderContentSmall(
                <IntlText id="forms.attr.schedule" />,
                this.props.fair.schedule,
              )}

              {this.renderContentSmall(
                <IntlText id="forms.attr.address" />,
                this.props.fair.address,
              )}

              {/* this.renderContentSmall((<IntlText id="forms.attr.map" />), this.props.fair.address, (address) => {
                return (<MapBase isMarkerShown={true} address={address} />);
              }) */}
            </div>

            <div className="uk-margin">
              {this.renderContentSmall(
                <IntlText id="forms.attr.fair.ticket" />,
                this.props.fair.ticket_info,
                (text) => {
                  return (
                    <div>
                      <LinedText text={text} className="uk-margin-small" />
                      {this.props.fair.ticket_url && (
                        <div className="uk-margin-small">
                          <Link
                            className="uk-button uk-button-default"
                            href={this.props.fair.ticket_url}
                            target="_blank"
                            ga={{
                              category: "link",
                              action: "fair_info",
                              label: "check_link",
                            }}
                          >
                            <IntlText id="fairs.info.buy" />
                          </Link>
                        </div>
                      )}
                    </div>
                  );
                },
              )}
            </div>
          </div>

          {this.props.fair.reception_schedule && (
            <div className="uk-margin-medium">
              <h3>
                <IntlText
                  id="fairs.info.reception"
                  values={{ title: this.props.fair.title }}
                />
              </h3>

              <div className="uk-margin">
                {/*this.renderContentSmall((<IntlText id="forms.attr.email" />), this.props.fair.email, (email) => {
                    return (
                      <Link href={"mailto:" + email} ga={{category: 'link', action: 'fair_info', label: "email"}}>
                        { email }
                      </Link>
                    );
                  } ) */}
                {this.renderContentSmall(
                  <IntlText id="forms.attr.schedule" />,
                  this.props.fair.reception_schedule,
                )}
                {this.renderContentSmall(
                  <IntlText id="forms.attr.fair.reception_fee" />,
                  this.props.fair.reception_fee,
                )}
                {this.renderContentSmall(
                  <IntlText id="forms.attr.address" />,
                  this.props.fair.reception_address,
                )}

                {/* this.renderContentSmall((<IntlText id="forms.attr.map" />), this.props.fair.reception_address, (address) => {
                     return (<MapBase isMarkerShown={true} address={address} />);
                  }) */}
              </div>
            </div>
          )}
        </div>

        {(this.props.fair.organizer ||
          this.props.fair.chief_committee ||
          this.props.fair.committee) && (
          <div className="uk-margin-xlarge">
            <h2 className="uk-heading-mark">
              <IntlText
                id="fairs.info.committee"
                values={{ title: this.props.fair.title }}
              />
            </h2>
            <div className="uk-margin">
              {this.renderContentLarge(
                <IntlText id="forms.attr.fair.organizer" />,
                this.props.fair.organizer,
              )}
              {this.renderContentLarge(
                <IntlText id="forms.attr.fair.chief_committee" />,
                this.props.fair.chief_committee,
              )}
              {this.renderContentLarge(
                <IntlText id="forms.attr.fair.committee" />,
                this.props.fair.committee,
              )}
            </div>
          </div>
        )}

        {(this.props.fair.sponsorship ||
          this.props.fair.supporter ||
          this.props.fair.cooperator) && (
          <div className="uk-margin-xlarge">
            <h2 className="uk-heading-mark">
              <IntlText
                id="fairs.info.partner"
                values={{ title: this.props.fair.title }}
              />
            </h2>
            <div className="uk-margin-medium">
              {this.renderContentSmall(
                <IntlText id="forms.attr.fair.sponsorship" />,
                this.props.fair.sponsorship,
              )}
            </div>
            <div className="uk-margin-medium">
              {this.renderContentSmall(
                <IntlText id="forms.attr.fair.supporter" />,
                this.props.fair.supporter,
              )}
            </div>
            <div className="uk-margin-medium">
              {this.renderContentSmall(
                <IntlText id="forms.attr.fair.cooperator" />,
                this.props.fair.cooperator,
              )}
            </div>
          </div>
        )}

        {(this.props.fair.tel ||
          this.props.fair.email ||
          this.props.fair.hp) && (
          <div className="uk-margin-xlarge">
            <h2 className="uk-heading-mark">
              <IntlText
                id="fairs.info.information"
                values={{ title: this.props.fair.title }}
              />
            </h2>
            {this.renderContentSmall(
              <IntlText id="forms.attr.tel" />,
              this.props.fair.tel,
              (tel) => {
                return (
                  <Link
                    href={"tel:" + tel}
                    ga={{ category: "link", action: "fair_info", label: "tel" }}
                  >
                    {tel}
                  </Link>
                );
              },
            )}
            {/* this.renderContentSmall((<IntlText id="forms.attr.email" />), this.props.fair.email, (email) => {
                return (
                  <Link href={"mailto:" + email} ga={{category: 'link', action: 'fair_info', label: "bottom_email"}}>
                    { email }
                  </Link>
                );
              } ) */}
            {this.renderContentSmall(
              <IntlText id="forms.attr.hp" />,
              this.props.fair.hp,
              (hp) => {
                return (
                  <Link
                    href={hp}
                    target="_blank"
                    ga={{ category: "link", action: "fair_info", label: "hp" }}
                  >
                    {hp}
                  </Link>
                );
              },
            )}
          </div>
        )}

        {this.props.fair.detail && (
          <div className="uk-margin-xlarge">
            <div dangerouslySetInnerHTML={{ __html: this.props.fair.detail }} />
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfoScreen),
);
