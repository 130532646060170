import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  add,
  deleteSection,
  edit,
  getAll,
} from "../../../actions/fair/section";
import { showConfirm } from "../../../actions/message";
import SectionForm from "../../../components/forms/fair/SectionForm";
import { Button } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class SectionsScreen extends PureComponent {
  state = {
    sections: [],
    editing: {},
  };

  componentWillMount = () => {
    this.props.actions
      .getAll()
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            sections: value.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onAdded = (value) => {
    this.setState({
      sections: this.state.sections.concat([value]),
    });
    UIkit.modal(this.addModal).hide();
    Notification.successIntl("util.success.add");
  };

  onUpdate = (section) => {
    this.setState(
      {
        editing: section,
      },
      () => {
        UIkit.modal(this.editModal, { container: false }).show();
      },
    );
  };

  onUpdated = (section) => {
    this.setState({
      sections: this.state.sections.map((sec) => {
        return sec.id != section.id ? sec : section;
      }),
    });
    UIkit.modal(this.editModal).hide();
    Notification.successIntl("util.success.update");
  };

  onDelete = (section) => {
    this.props.actions.showConfirm(
      <IntlText id="util.confirm.title.delete" />,
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }

        this.props.actions
          .deleteSection(section.id)
          .then(({ value }) => {
            Notification.successIntl("util.success.delete");
            this.setState({
              sections: this.state.sections.filter((sec) => {
                return sec.id != section.id;
              }),
            });
          })
          .catch((error) => console.log(error));
      },
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin uk-flex-middle uk-flex-right" data-uk-grid>
          <div className="uk-width-auto">
            <h1>
              <IntlText id="fair.sections.title" />
            </h1>
          </div>
          <div className="uk-width-expand">
            <button
              className="uk-button uk-button-default"
              onClick={() =>
                UIkit.modal(this.addModal, {
                  container: false,
                }).show()
              }
            >
              <IntlText id="forms.add" />
              <span
                className="uk-margin-small-left"
                data-uk-icon="icon:plus;ratio:0.6;"
              ></span>
            </button>
          </div>
        </div>
        <div className="uk-margin-large">
          {this.state.sections.map((section, index) => {
            return (
              <div
                key={index}
                className="uk-margin uk-flex-middle"
                data-uk-grid
              >
                <div className="uk-width-expand">
                  <h3 className="uk-margin-remove">{section.label}</h3>
                  <p className="uk-margin-remove">
                    <IntlText id="fair.sections.artworks" />:{" "}
                    {section.exhibitions_count}
                  </p>
                </div>
                <div className="uk-width-medium">
                  <Button
                    className="uk-icon-button uk-button-default uk-margin-small-right"
                    data-uk-icon="icon:pencil"
                    onClick={() => this.onUpdate(section)}
                  ></Button>
                  <Button
                    className="uk-icon-button uk-button-danger"
                    data-uk-icon="icon:close"
                    onClick={() => this.onDelete(section)}
                  ></Button>
                </div>
              </div>
            );
          })}
        </div>

        <div ref={(addModal) => (this.addModal = addModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="fair.sections.add_section" />
              </h2>
            </div>
            <div className="uk-modal-body">
              <SectionForm
                form="section-add-form"
                onSubmit={(values) => this.props.actions.add(values)}
                onSuccess={this.onAdded}
              />
            </div>
          </div>
        </div>

        <div ref={(editModal) => (this.editModal = editModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="fair.sections.edit_section" />
              </h2>
            </div>
            <div className="uk-modal-body">
              <SectionForm
                form="section-edit-form"
                initialValues={this.state.editing}
                onSubmit={(values) =>
                  this.props.actions.edit(this.state.editing.id, values)
                }
                onSuccess={this.onUpdated}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    fair: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAll: (...param) => dispatch(getAll(...param)),
      add: (...param) => dispatch(add(...param)),
      edit: (...param) => dispatch(edit(...param)),
      deleteSection: (...param) => dispatch(deleteSection(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SectionsScreen),
);
