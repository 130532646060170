import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Link } from "../util/Clickable";
import { LikeDetail } from "../util/Like";
import ShippingStatus from "../util/ShippingStatus";
import SlideShow from "../util/SlideShow";
import { FollowMe } from "../util/SNS";
import { IntlText, LinedText } from "../util/Text";

declare var UIkit;

class Artwork extends PureComponent {
  render = () => {
    const artwork = this.props.artwork;
    const artists = this.props.artists;

    return (
      <div>
        <div className="uk-margin-medium" data-uk-grid>
          <div className="uk-width-expand">
            <SlideShow title={artwork.title} images={artwork.artwork_images} />
          </div>
          <div className="uk-width-large@m">
            <div className="uk-margin-medium">
              <h1 className="uk-h5 uk-margin-remove">
                {artwork.title}
                <br />
                <span className="uk-h3">
                  <span className="uk-text-small uk-margin-ssmall-right">
                    by{" "}
                  </span>
                  {artists.map((artist, index) => {
                    return (
                      <Link
                        key={index}
                        className="uk-link-reset uk-margin-ssmall-right uk-text-gothic uk-underline"
                        to={PathGenerator.getLocalPath(Path.artists.view.url, {
                          artistId: artist.id,
                        })}
                        ga={{
                          category: "link",
                          action: "artwork_detail",
                          label: "artist_" + artist.id,
                        }}
                      >
                        {artist.name}
                      </Link>
                    );
                  })}
                </span>
              </h1>
              <div className="uk-margin-ssmall">
                <div>
                  <small className="uk-text-muted">
                    {(() => {
                      if (artwork.creation_year == null) {
                        return "";
                      } else if (artwork.creation_year >= 0) {
                        return artwork.creation_year;
                      } else {
                        return "BC " + artwork.creation_year * -1;
                      }
                    })()}
                  </small>
                </div>
                <div>
                  <small className="uk-text-muted">{artwork.medium}</small>
                </div>
                <div>
                  <small className="uk-text-muted">
                    {[
                      { prefix: "H", data: artwork.height },
                      { prefix: "W", data: artwork.width },
                      { prefix: "D", data: artwork.depth },
                    ]
                      .filter((data) => {
                        return data.data != null && data.data != 0;
                      })
                      .map((data, index) => {
                        return data.prefix + data.data;
                      })
                      .join(" × ")}
                    {(artwork.height != 0 ||
                      artwork.width != 0 ||
                      artwork.depth != 0) && <span> {artwork.unit}</span>}
                  </small>
                </div>
              </div>
              {artwork.category && (
                <p className="uk-margin-small uk-text-muted">
                  {artwork.category.label}
                  <br />
                  {artwork.classification}
                </p>
              )}
              {/* MEMO: 日本語で、かつ国内送料が有料の場合 */}
              {artwork.sale && (
                <ShippingStatus
                  saleStatus={artwork.sale.status}
                  domesticShippingStatus={artwork.sale.domestic_shipping_status}
                />
              )}
              <div>
                <LikeDetail type="artwork" data={artwork} />
              </div>
            </div>
            {this.props.renderBuy && this.props.renderBuy()}
          </div>
        </div>
        {(artwork.description || artwork.history) && <hr />}
        {artwork.description && (
          <div className="uk-margin-medium">
            <h2 className="uk-margin-small">
              <IntlText
                id="details.artwork.about"
                values={{ title: artwork.title }}
              />
            </h2>
            <LinedText
              className="uk-margin-small uk-text-muted"
              text={artwork.description}
            />
          </div>
        )}
        {artwork.history && (
          <div className="uk-margin-medium">
            <h2 className="uk-margin-small">
              <IntlText
                id="details.artwork.history"
                values={{ title: artwork.title }}
              />
            </h2>
            <LinedText
              className="uk-margin-small uk-text-muted"
              text={artwork.history}
            />
          </div>
        )}
        <div className="uk-margin-medium">
          <FollowMe />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Artwork),
);
