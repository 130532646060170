import Immutable from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { deleteImage, get } from "../../../actions/gallery/attend";
import {
  add,
  deleteExhibition,
  edit,
  search,
} from "../../../actions/gallery/exhibition";
import ExhibitionSearchForm from "../../../components/forms/gallery/ExhibitionSearchForm";
import SectionForm from "../../../components/forms/gallery/SectionForm";
import Thumnail from "../../../components/inputs/Thumnail";
import { Button, Link } from "../../../components/util/Clickable";
import { FirstImage } from "../../../components/util/Image";
import { IntlText } from "../../../components/util/Text";
import { EndPoints } from "../../../constants/endpoints";
import { Path, PathGenerator } from "../../../constants/path";
import { Formatter } from "../../../utils/formatter";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class EditScreen extends PureComponent {
  state = {
    attend: {},
    sections: [],
    artworks: [],
    meta: {
      current_page: 0,
      last_page: null,
    },
    keyword: null,
    exhibited: false,
    loading: false,
    editing: {},
  };

  componentWillMount = () => {
    this.props.actions
      .get(this.props.match.params.attendId)
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            attend: value.data,
            sections: value.data.fair
              ? value.data.fair.sections.map((section) => {
                  return { label: section.label, value: section.id };
                })
              : [],
          });
        }
      })
      .catch((error) => console.log(error));

    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search(this.props.match.params.attendId, {
        keyword: this.state.keyword,
        page: this.state.meta.current_page + 1,
        exhibited: this.state.exhibited,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            artworks:
              this.state.artworks && !refresh
                ? this.state.artworks.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onSearch = (values) => {
    this.setState({
      keyword: values.keyword,
      exhibited: values.exhibited,
    });
    return this.props.actions.search(this.props.match.params.attendId, values);
  };

  onSearched = (data, response) => {
    this.setState({
      artworks: data,
      meta: response.data.meta,
      loading: false,
    });
  };

  onUploaded = (data) => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["attend", "thumnail_url"], (v) => data.thumnail_url)
        .toJS(),
    );
    Notification.successIntl("util.success.update");
  };

  onDeleteImage = () => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["attend", "thumnail_url"], (v) => null)
        .toJS(),
    );
    this.props.actions
      .deleteImage(this.state.attend.id)
      .then(({ value, action }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.delete");
        }
      })
      .catch((error) => console.log(error));
  };

  onAdd = (artwork) => {
    this.setState({ editing: artwork }, () => {
      UIkit.modal(this.addModal, { container: false }).show();
    });
  };

  onEdit = (artwork) => {
    this.setState({ editing: artwork }, () => {
      UIkit.modal(this.editModal, { container: false }).show();
    });
  };

  onEdited = (exhibition) => {
    Notification.successIntl("util.success.update");
    this.setState({
      artworks: this.state.artworks.map((art) => {
        if (art.id != exhibition.artwork_id) {
          return art;
        } else {
          art.exhibitions = [exhibition];
          return art;
        }
      }),
    });
    UIkit.modal(this.addModal).hide();
    UIkit.modal(this.editModal).hide();
  };

  onDelete = (artwork) => {
    const exhibition = artwork.exhibitions[0];
    artwork.exhibitions = [];
    this.setState({
      artworks: this.state.artworks.map((art) => {
        if (art.id != artwork.id) {
          return art;
        } else {
          art.exhibitions = [];
          return art;
        }
      }),
    });
    this.props.actions
      .deleteEx(this.state.attend.id, exhibition.id)
      .then(({ value }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.delete");
        }
      })
      .catch((error) => console.log(error));
  };

  render = () => {
    if (this.state.attend.fair == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio:3;" />
        </div>
      );
    }

    const fair = Formatter.mergeDesc(
      this.state.attend.fair,
      this.state.attend.fair.fair_descs,
      this.props.language.view,
    );
    var fairUrl = PathGenerator.getLocalPath(Path.fairs.view.url, {
      fairId: fair.id,
    });
    if (fair.slug != null) {
      fairUrl = PathGenerator.getLocalPath(Path.original.url, {
        fairId: fair.slug,
      });
    }

    return (
      <div>
        <div className="uk-margin uk-flex-middle" data-uk-grid>
          <div className="uk-width-expand@s">
            <h1>{fair.title}</h1>
          </div>
          <div className="uk-width-auto@s uk-text-right">
            <Link className="uk-button uk-button-default" to={fairUrl}>
              <IntlText id="gallery.fair.view_detail" />
            </Link>
          </div>
        </div>

        <div className="uk-margin-large">
          <h2 className="uk-heading-mark">
            <IntlText id="gallery.fair.thumnail" />
          </h2>
          <div className="uk-margin uk-width-large@s">
            <Thumnail
              url={EndPoints.galleryAttends(this.state.attend.id, "image")}
              src={this.state.attend.thumnail_url}
              onSuccess={this.onUploaded}
              onDelete={this.onDeleteImage}
              keyExtractor={(value) => value.thumnail_url}
            />
          </div>
        </div>

        <div className="uk-margin-large">
          <h2 className="uk-heading-mark uk-margin-small">
            <IntlText id="gallery.fair.artworks" />
          </h2>
          <div className="uk-margin-small">
            <ExhibitionSearchForm
              onSubmit={this.onSearch}
              onSuccess={this.onSearched}
            />
          </div>
          <div className="uk-margin-medium">
            <table className="uk-table uk-table-small uk-table-striped">
              <thead>
                <tr>
                  <th className="uk-table-shrink">
                    <IntlText id="gallery.fair.image" />
                  </th>
                  <th className="uk-table-expand">
                    <IntlText id="gallery.fair.description" />
                  </th>
                  <th className="uk-table-shrink">
                    <IntlText id="gallery.fair.actions" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.artworks.map((artwork, index) => {
                  var artwork = Formatter.mergeDesc(
                    artwork,
                    artwork.artwork_descs,
                    this.props.language.view,
                  );
                  return (
                    <tr key={artwork.id}>
                      <td>
                        <div className="uk-width-small">
                          <FirstImage
                            className="uk-width-1-1"
                            srcs={artwork.artwork_images}
                            finder={(item) => item.url}
                          />
                        </div>
                      </td>
                      <td>
                        <h3 className="uk-margin-remove">{artwork.title}</h3>
                        <p className="uk-margin-remove uk-text-muted">
                          {artwork.artists
                            .map((artist) => {
                              var artist = Formatter.mergeDesc(
                                artist,
                                artist.artist_descs,
                                this.props.language.view,
                              );
                              return artist.name;
                            })
                            .join("/")}
                        </p>
                        {artwork.exhibitions &&
                          artwork.exhibitions.length > 0 &&
                          artwork.exhibitions[0].section && (
                            <p className="uk-margin-remove">
                              <IntlText id="gallery.fair.section" />:{" "}
                              {artwork.exhibitions[0].section.label}
                            </p>
                          )}
                      </td>
                      <td>
                        <div className="uk-width-small uk-text-center">
                          {artwork.exhibitions.length > 0 ? (
                            <div>
                              <Link
                                className="uk-icon-button uk-button-default uk-margin-small-right"
                                data-uk-icon="icon:pencil;"
                                onClick={() => this.onEdit(artwork)}
                              />
                              <Link
                                className="uk-icon-button uk-button-danger"
                                data-uk-icon="icon:close;"
                                onClick={() => this.onDelete(artwork)}
                              />
                            </div>
                          ) : (
                            <Link
                              className="uk-icon-button uk-button-default uk-margin-small-right"
                              data-uk-icon="icon:plus;"
                              onClick={() => this.onAdd(artwork)}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {this.state.meta.last_page &&
            this.state.meta.last_page > this.state.meta.current_page && (
              <div className="uk-margin-medium uk-text-center">
                <Button
                  className="uk-button uk-button-default"
                  onClick={() => this.readMore(false)}
                  loading={this.state.loading ? 1 : 0}
                >
                  <IntlText id="forms.more" />
                </Button>
              </div>
            )}
        </div>

        <div ref={(addModal) => (this.addModal = addModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="gallery.fair.add" />
              </h2>
            </div>
            <div className="uk-modal-body">
              <SectionForm
                form="section-add-form"
                onSubmit={(values) =>
                  this.props.actions.addEx(this.state.attend.id, {
                    artwork_id: this.state.editing.id,
                    section_id: values.section_id,
                  })
                }
                sections={this.state.sections}
                onSuccess={this.onEdited}
              />
            </div>
          </div>
        </div>

        <div ref={(editModal) => (this.editModal = editModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="gallery.fair.edit" />
              </h2>
            </div>
            <div className="uk-modal-body">
              <SectionForm
                form="section-edit-form"
                initialValues={{
                  section_id:
                    this.state.editing.exhibitions &&
                    this.state.editing.exhibitions.length > 0
                      ? this.state.editing.exhibitions[0].section_id
                      : "",
                }}
                onSubmit={(values) =>
                  this.props.actions.editEx(
                    this.state.attend.id,
                    this.state.editing.exhibitions[0].id,
                    {
                      section_id: values.section_id,
                    },
                  )
                }
                sections={this.state.sections}
                onSuccess={this.onEdited}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      get: (...param) => dispatch(get(...param)),
      deleteImage: (...param) => dispatch(deleteImage(...param)),
      search: (...param) => dispatch(search(...param)),
      addEx: (...param) => dispatch(add(...param)),
      editEx: (...param) => dispatch(edit(...param)),
      deleteEx: (...param) => dispatch(deleteExhibition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditScreen),
);
