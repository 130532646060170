import {
  SEARCH_ARITCLES,
  GET_ARTICLE,
  ADD_ARTICLE,
  EDIT_ARTICLE,
  DELETE_ARTICLE,
  DELETE_ARTICLE_IMAGE,
} from "../../constants/type";

import Api from "../../utils/api";
import { EndPoints } from "../../constants/endpoints";

export const search = (param = {}) => {
  return (dispatch) => {
    return {
      type: SEARCH_ARITCLES,
      payload: () => {
        return Api.get(EndPoints.galleryArticles(), param);
      },
    };
  };
};

export const get = (articleId) => {
  return (dispatch) => {
    return {
      type: GET_ARTICLE,
      payload: () => {
        return Api.get(EndPoints.galleryArticles(articleId));
      },
    };
  };
};

export const add = (data) => {
  return (dispatch) => {
    return {
      type: ADD_ARTICLE,
      payload: () => {
        return Api.post(EndPoints.galleryArticles(), data);
      },
    };
  };
};

export const edit = (articleId, data) => {
  return (dispatch) => {
    return {
      type: EDIT_ARTICLE,
      payload: () => {
        return Api.put(EndPoints.galleryArticles(articleId), data);
      },
    };
  };
};

export const deleteArticle = (articleId) => {
  return (dispatch) => {
    return {
      type: DELETE_ARTICLE,
      payload: () => {
        return Api.delete(EndPoints.galleryArticles(articleId));
      },
    };
  };
};

export const deleteImage = (articleId) => {
  return (dispatch) => {
    return {
      type: DELETE_ARTICLE_IMAGE,
      payload: () => {
        return Api.delete(EndPoints.galleryArticles(articleId, "image"));
      },
    };
  };
};
