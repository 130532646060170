import anime from "animejs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { addContact } from "../actions/contact";
import logo from "../assets/images/logo/black.png";
import earth from "../assets/images/lp/earth.svg";
import forAll from "../assets/images/lp/forAll.png";
import sns from "../assets/images/lp/sns.png";
import top from "../assets/images/lp/top.png";
import triangle from "../assets/images/lp/triangle.svg";
import alipay from "../assets/images/vendor/alipay.svg";
import cards from "../assets/images/vendor/cards.svg";
import wechat from "../assets/images/vendor/wechat.svg";
import Meta from "../components/layout/Meta";
import { Link } from "../components/util/Clickable";
import { languages } from "../constants/option";
import { Path, PathGenerator } from "../constants/path";

class LandingScreen extends Component {
  componentDidMount = () => {
    anime({
      targets: this.earth,
      rotate: "1turn",
      duration: 24000,
      loop: true,
      easing: "linear",
    });
  };

  renderToContact = () => {
    return (
      <div className="uk-section uk-section-muted uk-section-large">
        <div className="uk-container uk-position-z-index">
          <div className="uk-margin uk-text-center">
            <img src={logo} className="uk-width-medium" />
          </div>
          <h2 className="uk-text-center uk-text-nowrap">
            Find and collect your favorite art.
          </h2>
          <p className="uk-text-center@s">
            Art Scenes is electric commerse platform where you can find and
            collect art all over the world.
          </p>
          <div className="uk-margin uk-text-center" data-uk-grid>
            {languages.map((language, index) => {
              return (
                <div key={index} className="uk-width-1-4@s uk-width-1-2">
                  <Link
                    key={index}
                    className="uk-button uk-button-large uk-button-default uk-width-1-1 uk-padding-remove"
                    to={PathGenerator.getRealUrl(Path.root.url, {
                      lang: language.value,
                    })}
                    ga={{
                      category: "link",
                      action: "landing",
                      label: language.label,
                    }}
                  >
                    {language.label}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <Meta />
        <div className="uk-section uk-section-large">
          <div className="uk-container">
            <div className="uk-flex-middle" {...{ "uk-grid": "" }}>
              <div className="uk-width-expand">
                <div className="uk-text-center">
                  <h2 className="uk-h1 uk-text-nowrap">
                    Find and collect
                    <wbr /> your favorite art.
                  </h2>
                </div>
              </div>
              <div className="uk-width-auto@m uk-text-center uk-text-right@m">
                <img src={top} className="uk-width-xxlarge@s" />
              </div>
            </div>
          </div>
        </div>

        {this.renderToContact()}

        <div className="uk-section uk-section-large">
          <div className="uk-container uk-position-z-index">
            <h2 className="uk-text-center">
              Art Scenes connect selected galleries all over the world to
              collectors all over the world.
            </h2>

            <div
              className="uk-margin-large uk-grid-large uk-flex-bottom"
              {...{ "uk-grid": "" }}
            >
              <div className="uk-width-expand uk-height-large@s">
                <p
                  className="uk-text-right@s"
                  {...{ "uk-scrollspy": "cls: uk-animation-slide-left;" }}
                >
                  Colelctors all over the world can find and collect arts all
                  over the world
                  <br /> on Art-Scenes.
                </p>
              </div>
              <div className="uk-width-large@m">
                <div className="uk-position-relative uk-height-large">
                  <div className="uk-position-center uk-height-large@s uk-width-large@s">
                    <img className="uk-width-1-1" src={triangle} />
                  </div>
                  <div
                    className="uk-position-center"
                    style={{ height: 150, width: 150, marginTop: 24 }}
                  >
                    <img
                      ref={(earth) => (this.earth = earth)}
                      className="uk-height-1-1"
                      src={earth}
                    />
                  </div>
                </div>
              </div>
              <div className="uk-width-expand">
                <p {...{ "uk-scrollspy": "cls: uk-animation-slide-right;" }}>
                  Galleries and artist can not only trade art <br />
                  but also dispatch of information
                  <br />
                  to all over the world
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-large uk-position-relative">
          <div className="uk-container uk-position-z-index">
            <div
              className="uk-grid-large uk-flex-middle uk-child-width-1-2@s"
              {...{ "uk-grid": "" }}
            >
              <div
                className="uk-text-right@s"
                {...{ "uk-scrollspy": "cls: uk-animation-slide-left;" }}
              >
                <h3 className="uk-h1 ">Multilingual</h3>
                <p className="uk-margin-large">
                  ART SCENES supports English, Japanese and chinese <br />
                  We can transrate your informations to all languages.
                </p>
              </div>
              <div
                {...{ "uk-scrollspy": "cls: uk-animation-fade;" }}
                className="uk-text-left@s uk-text-center"
              >
                <img src={forAll} className="uk-width-large@s" />
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-large uk-position-relative">
          <div className="uk-container uk-position-z-index">
            <div
              className="uk-grid-large uk-flex-middle uk-child-width-1-2@s"
              {...{ "uk-grid": "" }}
            >
              <div {...{ "uk-scrollspy": "cls: uk-animation-slide-right;" }}>
                <h3 className="uk-h1 ">Global payment</h3>
                <p className="uk-margin-large">
                  Art Scenes support Not only credit cards but also Alipay®︎ and
                  WeChatPay®︎
                </p>
              </div>
              <div
                className="uk-flex-first@s"
                {...{ "uk-scrollspy": "cls: uk-animation-fade;" }}
              >
                <div className="uk-margin-small uk-text-right@s uk-text-center">
                  <img src={cards} className="uk-width-xlarge@s" />
                </div>
                <div className="uk-margin-small uk-text-right@s uk-text-center">
                  <img
                    src={alipay}
                    className="uk-height-ssmall uk-margin-small-right"
                  />
                  <img
                    src={wechat}
                    className="uk-height-ssmall uk-margin-small-left"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-large uk-position-relative">
          <div className="uk-container uk-position-z-index">
            <div
              className="uk-grid-large uk-flex-middle uk-child-width-1-2@s"
              {...{ "uk-grid": "" }}
            >
              <div
                className="uk-text-right@s"
                {...{ "uk-scrollspy": "cls: uk-animation-slide-left;" }}
              >
                <h3 className="uk-h1 ">Dispatch of information</h3>
                <p className="uk-margin-large">
                  Galleries, artworks and artists can provide informations to
                  colelctors all over the world.
                </p>
              </div>
              <div
                {...{ "uk-scrollspy": "cls: uk-animation-fade;" }}
                className="uk-text-left@s uk-text-center"
              >
                <img src={sns} className="uk-width-large@s" />
              </div>
            </div>
          </div>
        </div>

        {this.renderToContact()}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addContact: (data) => dispatch(addContact(data)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LandingScreen),
);
