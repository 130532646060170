import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Formatter } from "../../utils/formatter";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { IntlText } from "../util/Text";

class Information extends Component {
  getUrl = (information) => {
    if (information.artwork_id) {
      return PathGenerator.getLocalPath(Path.artworks.view.url, {
        artworkId: information.artwork_id,
      });
    } else if (information.show_id) {
      return PathGenerator.getLocalPath(Path.shows.view.url, {
        showId: information.show_id,
      });
    } else if (information.artist_id) {
      return PathGenerator.getLocalPath(Path.artists.view.url, {
        artistId: information.artist_id,
      });
    } else if (information.fair_id) {
      return PathGenerator.getLocalPath(Path.fairs.view.url, {
        fairId: information.fair_id,
      });
    } else {
      return null;
    }
  };

  render = () => {
    const information = !this.props.information.title
      ? Formatter.mergeDesc(
          this.props.information,
          this.props.information.information_descs,
          this.props.language.view,
        )
      : this.props.information;

    const url = this.getUrl(this.props.information);

    return (
      <div>
        <div className="uk-image-wrapper uk-height-xlarge@s uk-height-large">
          <Image full src={information.thumnail_url} alt="" />
        </div>
        <div className="uk-position-z-index uk-position-large uk-position-bottom-left">
          <h3 className="uk-margin-small uk-heading-primary">
            {information.title}
          </h3>
          <p className="uk-margin-small uk-height-lines-3">
            {information.description}
          </p>
          {url && (
            <div>
              <Link
                className="uk-button uk-button-default"
                to={url}
                ga={{
                  category: "link",
                  action: "information",
                  label: "information_" + information.id,
                }}
              >
                <IntlText id="forms.more" />
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Information);
