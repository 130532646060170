import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Formatter } from "../../utils/formatter";
import Fair from "../views/FairCard";
import Show from "../views/Show";

class ExhibitionsList extends Component {
  render = () => {
    if (this.props.exhibitions == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.exhibitions.length <= 0) {
      return (
        <div>
          <h3>{/* <IntlText id="lists.shows.no" /> */}</h3>
        </div>
      );
    }

    const lang = this.props.editing
      ? this.props.language.edit
      : this.props.language.view;

    return (
      <div
        className={
          this.props.size
            ? "uk-child-width-1-" + this.props.size + "@s"
            : "uk-child-width-1-2@s"
        }
        data-uk-grid="masonry: true"
      >
        {this.props.exhibitions.map((exhibition, key) => {
          if (exhibition.show) {
            exhibition.show.gallery = Formatter.mergeDesc(
              exhibition.gallery,
              exhibition.gallery.gallery_descs,
              lang,
            );
            return (
              <div key={key}>
                <Show
                  show={exhibition.show}
                  lang={lang}
                  editing={this.props.editing}
                  small={this.props.size == 3}
                />
              </div>
            );
          } else if (exhibition.fair) {
            return (
              <div key={key}>
                <Fair
                  fair={exhibition.fair}
                  onEdit={(value) =>
                    this.props.onClick && this.props.onClick(exhibition.fair)
                  }
                  editing={this.props.editing}
                  lang={lang}
                  buttonText={
                    this.props.buttonText ? this.props.buttonText : "Edit"
                  }
                  large={this.props.size == 2}
                />
              </div>
            );
          }
        })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExhibitionsList),
);
