import { GET_NEWS, GET_NEW } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: GET_NEWS,
      payload: () => {
        return Api.get(EndPoints.news(), param);
      },
    };
  };
};

export const get = (newsId) => {
  return (dispatch) => {
    return {
      type: GET_NEW,
      payload: () => {
        return Api.get(EndPoints.news(newsId));
      },
    };
  };
};
