import Immutable from "immutable";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setCondition } from "../../actions/cache";
import { search as searchShows } from "../../actions/show";
import ShowFilterForm from "../../components/forms/ShowFilterForm";
import Meta from "../../components/layout/Meta";
import ShowCards from "../../components/lists/ShowCards";
import { Button } from "../../components/util/Clickable";
import { getPureMessage, IntlText } from "../../components/util/Text";

class IndexScreen extends Component {
  state = {
    queryParam: {
      timing: null,
      place: null,
    },
    shows: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
    loading: false,
  };

  componentWillMount = () => {
    if (this.props.condition[window.location.href]) {
      this.setState(this.props.condition[window.location.href]);
    } else {
      this.readMore(true);
    }
  };

  componentDidUpdate = () => {
    if (this.state.shows != null) {
      this.props.actions.setCondition(this.state);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.state.loading != nextState.loading;
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    const param = Object.assign({}, this.state.queryParam, {
      pagesize: 24,
      page: this.state.meta.current_page + 1,
      sort_method: "latest",
    });

    this.props.actions
      .searchShows(param)
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            shows:
              this.state.shows && !refresh
                ? this.state.shows.concat(value.data)
                : value.data,
            meta: value.response.data.meta
              ? value.response.data.meta
              : {
                  total: value.data.length,
                },
            loading: false,
          });
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  };

  onSearch = (values) => {
    this.setState(
      Immutable.fromJS(this.state)
        .updateIn(["queryParam"], (v) => values)
        .updateIn(["shows"], (v) => null)
        .updateIn(["meta"], (v) => {
          return { current_page: 0 };
        })
        .toJS(),
      () => {
        this.readMore(true);
      },
    );
    this.forceUpdate();
  };

  renderShowsCount = () => {
    var count = this.state.meta.total;
    const place = this.state.queryParam.place
      ? this.state.queryParam.place
      : null;
    if (this.state.queryParam.timing) {
      return (
        <IntlText
          id={"shows." + this.state.queryParam.timing}
          values={{
            count: count,
            place: place ? (
              <IntlText id="shows.inplace" values={{ place: place }} />
            ) : null,
          }}
        />
      );
    } else {
      return (
        <IntlText
          id={"shows.count"}
          values={{
            count: count,
            place: place ? (
              <IntlText id="shows.inplace" values={{ place: place }} />
            ) : null,
          }}
        />
      );
    }
  };

  render = () => {
    return (
      <div>
        <Meta
          title={getPureMessage("shows.meta")}
          description={getPureMessage("shows.description")}
        />
        <h1 className="uk-heading-mark uk-margin-remove">
          <IntlText id="shows.title" />
        </h1>
        {this.state.shows && (
          <div className="uk-margin-small">
            <span className="uk-text-muted uk-h4">
              {this.renderShowsCount()}
            </span>
          </div>
        )}
        <div className="uk-margin-medium">
          <ShowFilterForm
            initialValues={this.state.queryParam}
            onChange={this.onSearch}
          />
        </div>
        <div className="uk-margin-medium">
          <div className="uk-margin">
            <ShowCards column={2} shows={this.state.shows} />
          </div>
          {this.state.meta.last_page &&
            this.state.meta.last_page > this.state.meta.current_page && (
              <div className="uk-margin uk-text-center">
                <Button
                  className="uk-button uk-button-default"
                  onClick={() => this.readMore(false)}
                  loading={this.state.loading ? 1 : 0}
                  ga={{ category: "link", action: "shows", label: "top" }}
                >
                  <IntlText id="forms.more" />
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    condition: state.condition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchShows: (...param) => dispatch(searchShows(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IndexScreen),
);
