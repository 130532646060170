export const OtherPath = {
  url: "/:lang/other",
  term: {
    url: "/:lang/other/term",
    gallery: {
      url: "/:lang/other/term/gallery",
    },
  },
  privacy: {
    url: "/:lang/other/privacy",
  },
  press: {
    url: "/:lang/other/press",
  },
  contact: {
    url: "/:lang/other/contact",
  },
  law: {
    url: "/:lang/other/law",
  },
  estimated_delivery_charge: {
    url: "/:lang/other/estimated_delivery_charge",
  },
};

export const AboutPath = {
  url: "/:lang/about",
  galleries: {
    url: "/:lang/about/galleries",
  },
  auctions: {
    url: "/:lang/about/auctions",
  },
  meuseums: {
    url: "/:lang/about/meuseums",
  },
};
