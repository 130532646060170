import { SEARCH_ARITCLES, GET_ARTICLE } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";

export const search = (param = {}) => {
  return (dispatch) => {
    return {
      type: SEARCH_ARITCLES,
      payload: () => {
        return Api.get(EndPoints.articles(), param);
      },
    };
  };
};

export const get = (articleId) => {
  return (dispatch) => {
    return {
      type: GET_ARTICLE,
      payload: () => {
        return Api.get(EndPoints.articles(articleId));
      },
    };
  };
};
