import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearAlert } from "../../actions/message";
import { IntlText } from "./Text";

declare var UIkit;

class Confirm extends PureComponent {
  state = {};

  componentDidMount = () => {
    UIkit.util.on(this.modal, "hidden", () => {
      this.props.actions.clearAlert();
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.message.confirm.title == null) {
      UIkit.modal(this.modal).hide();
      return;
    }
    this.setState(nextProps.message.confirm, () => {
      UIkit.modal(this.modal).show();
    });
  };

  onClose = () => {
    this.props.actions.clearAlert();
    UIkit.modal(this.modal).hide();
    this.state.callback && this.state.callback(false);
  };

  onOk = () => {
    this.props.actions.clearAlert();
    UIkit.modal(this.modal).hide();
    this.state.callback && this.state.callback(true);
  };

  render = () => {
    return (
      <div
        ref={(modal) => (this.modal = modal)}
        className="uk-flex-top"
        {...{ "uk-modal": "container: false;" }}
      >
        <div className="uk-modal-dialog uk-width-xlarge@s uk-modal-body uk-margin-auto-vertical">
          <button
            className="uk-modal-close-default"
            type="button"
            {...{ "uk-close": "" }}
          ></button>
          <div className="">
            <h3 className="uk-text-center ">{this.state.title}</h3>
            <p>{this.state.message}</p>
            <div className="uk-grid-small uk-child-width-1-2" data-uk-grid>
              <div>
                <button
                  className="uk-button uk-button-muted uk-width-1-1"
                  onClick={this.onClose}
                >
                  {this.state.cancel ? (
                    this.state.cancel
                  ) : (
                    <IntlText id="util.confirm.cancel" />
                  )}
                </button>
              </div>
              <div>
                <button
                  className="uk-button uk-button-default uk-width-1-1"
                  onClick={this.onOk}
                >
                  {this.state.ok ? (
                    this.state.ok
                  ) : (
                    <IntlText id="util.confirm.ok" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    message: state.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      clearAlert: () => dispatch(clearAlert()),
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
