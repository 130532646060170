import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { keywordOnly } from "../../constants/option";
import Select from "../inputs/Select";
import { IntlText } from "../util/Text";

class KeywordFilterForm extends PureComponent {
  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        action={this.props.action}
        method="post"
      >
        <div
          className="uk-grid-small uk-flex-middle uk-flex-right"
          data-uk-grid
        >
          <div className="uk-width-auto">
            <span className="uk-text-bold">
              <IntlText id="forms.attr.only.label" />
            </span>
          </div>
          <div className="uk-width-medium">
            <Field
              name="only"
              component={Select}
              className="uk-form-small uk-background-white"
              noError
              notNull
              options={keywordOnly}
            />
          </div>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "keyword-filter-form",
  // enableReinitialize : true
})(KeywordFilterForm);
