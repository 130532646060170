import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import {
  fairOnlyStatus,
  priceUnits,
  salesStatusForEdit,
} from "../../../constants/option";
import { Formatter } from "../../../utils/formatter";
import Select from "../../inputs/Select";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import * as normalize from "../../util/Normalizer";
import { getPureMessage, IntlText } from "../../util/Text";
import * as validate from "../../util/Validator";

class SaleForm extends Component {
  state = {
    loading: false,
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <div
          className="uk-margin-small uk-grid-small uk-child-width-1-3@s"
          data-uk-grid
        >
          <div>
            <Field
              name="price"
              component={TextInput}
              labelId="forms.attr.price"
              type="number"
              validate={[validate.num]}
              normalize={normalize.numberOnly}
            />
          </div>
          <div>
            <Field
              name="price_unit"
              component={Select}
              labelId="forms.attr.price_unit"
              options={priceUnits}
            />
          </div>
          <div>
            <Field
              name="sales_priority"
              component={TextInput}
              labelId="forms.attr.priority"
              type="number"
              normalize={normalize.numberOnly}
            />
          </div>
          <div>
            <Field
              name="status"
              component={Select}
              labelId="forms.attr.sales_status"
              options={salesStatusForEdit}
              suppyId="forms.supply.sales_status"
            />
          </div>
          <div>
            <Field
              name="is_fair_only"
              component={Select}
              labelId="forms.attr.fair_only_status"
              options={fairOnlyStatus}
            />
          </div>
          <div>
            <Field
              name="location_id"
              component={Select}
              labelId="forms.attr.location.name"
              placeholder={getPureMessage("forms.attr.no_select")}
              options={this.props.gallery.locations.map((location) => {
                var location = Formatter.mergeDesc(
                  location,
                  location.location_descs,
                  this.props.language.view,
                );
                return {
                  label: location.name,
                  value: location.id,
                };
              })}
            />
          </div>
        </div>

        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    constant: state.constant,
    gallery: state.auth.data ? state.auth.data : { locations: [] },
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default reduxForm({
  form: "gallery-sale-form",
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(SaleForm));
