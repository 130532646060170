import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Path } from "../../constants/path";
import LoginScreen from "./auth/LoginScreen";

class AuthScreen extends PureComponent {
  render = () => {
    return (
      <div className="uk-section uk-section-large">
        <div className="uk-container uk-container-small">
          <Switch>
            <Route
              exact
              path={Path.medium.auth.login.url}
              component={LoginScreen}
            />
          </Switch>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthScreen),
);
