import RCMultiSelect from "@khanacademy/react-multi-select";
import React, { PureComponent } from "react";
import Logger from "../../utils/logger";

export default class MultiSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  componentWillMount = () => {
    this.setState({
      selected: this.props.selected ? this.props.selected : [],
    });
  };

  onSelectedChanged = (selected) => {
    this.setState({ selected: selected });
    this.props.input && this.props.input.onChange(selected);
    this.props.onChange && this.props.onChange(selected);
  };

  onClick = () => {
    Logger.event(this.props.ga);
  };

  valueRenderer = (selected, options) => {
    if (selected.length === 0) {
      return this.props.placeholder ? this.props.placeholder : this.props.label;
    }
    return selected
      .map((sel) => {
        let d = options.find((option) => {
          return option.value == sel;
        });
        return d ? d.label : null;
      })
      .join(", ");
  };

  filterOptions = (options, filter) => {
    return options.filter((option) => {
      return option.label.indexOf(filter) !== -1;
    });
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;
    return (
      <div>
        {(() => {
          if (this.props.label != null) {
            return (
              <label className="uk-form-label uk-text-nowrap">
                {this.props.label}
                <wbr />
                {(() => {
                  if (this.props.required) {
                    return <small className="uk-text-danger"> ※require</small>;
                  }
                })()}
              </label>
            );
          }
        })()}
        <div className="uk-form-controls">
          <RCMultiSelect
            options={this.props.options}
            onSelectedChanged={this.onSelectedChanged}
            selected={this.state.selected}
            valueRenderer={this.valueRenderer}
            filterOptions={this.filterOptions}
            selectAllLabel={"Select All"}
            isLoading={false}
            disabled={false}
            disableSearch={false}
          />
        </div>
        <div className="uk-text-danger " style={{ minHeight: "18px" }}>
          <small>{hasError && this.props.meta.error}</small>
        </div>
      </div>
    );
  };
}
