import { ADD_ARTWORK_HISTORY, CLEAR_HISTORY } from "../constants/type";
import Immutable from "immutable";

const initStatus = {
  artworks: [],
};

export default function historyReducer(state = initStatus, action = {}) {
  switch (action.type) {
    case ADD_ARTWORK_HISTORY:
      return Immutable.fromJS(state)
        .updateIn(["artworks"], (v) => {
          if (state.artworks.length > 0) {
            const last = state.artworks[state.artworks.length - 1];
            if (last.id != action.content.id) {
              state.artworks.push(action.content);
            }
          } else {
            state.artworks.push(action.content);
          }
          if (state.artworks.length > 12) {
            state.artworks.shift();
          }
          return state.artworks;
        })
        .toJS();

    case CLEAR_HISTORY:
      return initStatus;

    default:
      return state;
  }
}
