import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearAlert } from "../../actions/message";
import { IntlText } from "./Text";

declare var UIkit;

class Alert extends PureComponent {
  state = {};

  componentDidMount = () => {
    UIkit.util.on(this.modal, "hidden", () => {
      this.props.actions.clearAlert();
      this.state.callback && this.state.callback(true);
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.message.alert.title == null) {
      UIkit.modal(this.modal).hide();
      return;
    }
    this.setState(nextProps.message.alert, () => {
      UIkit.modal(this.modal).show();
    });
  };

  onOk = () => {
    this.props.actions.clearAlert();
    UIkit.modal(this.modal).hide();
  };

  render = () => {
    return (
      <div
        ref={(modal) => (this.modal = modal)}
        className="uk-flex-top"
        {...{ "uk-modal": "container: false;" }}
      >
        <div className="uk-modal-dialog uk-width-xlarge@s uk-modal-body uk-margin-auto-vertical">
          <button
            className="uk-modal-close-default"
            type="button"
            {...{ "uk-close": "" }}
          ></button>
          <div className="">
            <h3 className="uk-text-center ">{this.state.title}</h3>
            <p className="uk-margin-small">{this.state.message}</p>
            <div className="uk-text-center">
              <button
                className="uk-button uk-button-default"
                onClick={this.onOk}
              >
                {this.state.ok ? (
                  this.state.ok
                ) : (
                  <IntlText id="util.alert.ok" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    message: state.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      clearAlert: () => dispatch(clearAlert()),
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
