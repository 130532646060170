const messages = {
  layout: {
    menu: {
      dashboard: "Dashboard",
      articles: "Articles",
      setting: "Settings",
      sign_out: "Sign Out",

      fair_information: "Fair Information",
      information: "Information",
      sections: "Sections",
      related: "Related Fairs",

      invite: "Invitation",
      inviting: "Galleryies",
      add_invitation: "Send Invitation",
    },
  },
  auth: {
    login: {
      header: "Fair's Login",
      ifyouforget: "If you have forgotten your password, ",
      resethere: "reset it here.",
      donthave: "You don't have an account?",
      contact: "Please contact us by filling in this form.",
      contactus: "Contact Us",
      term: "Terms for gallery",
      agree: "Agree",
    },
    reset: {
      header: "Reset Password",
      back: "Back to login",
      completed: "Reset Completed",
      please: "Please login with new password.",
    },
    forget: {
      header: "Reset Password",
      back: "Back to login",
      completed: "Send reset mail",
      please:
        "We send mail with password reset link to {email}. Please check it.",
    },
  },
  dashboard: {
    title: "Dashboard",
    galleries: "Inviting Galleries",
  },
  settings: {
    title: "Settings",
    authorize: "Authorization Information",
  },
  articles: {
    title: "All Articles",
    add: "Add New Article",
  },
  article: {
    title: "Edit Article",
    basic: "Article information",
    thumnail: "Thumnail",
    description: "Description",
  },
  invites: {
    title: "Inviting Galleries",
    add_confirm: "Are you ok to invite?",
    add_title: "Invite Galleies",
    galleries: "Galleries",
    delete_detail:
      "All exhibition information for this fair by this gallery will be removed.",
    select: "Selecting Galleies",
    invite: "Invite",
    remove: "Remove",
  },
  related: {
    title: "Related Fairs",
    others: "Other Fairs",
    related: "Ralated",
    remove: "Remove",
  },
  sections: {
    title: "Fair Sections",
    artworks: "Artworks: ",
    add_section: "Add New Section",
    edit_section: "Edit Section",
  },
  profile: {
    title: "Fair Information",
    info: "Fair Info",
    images: "Images",
    cover: "Cover",
    logo: "Logo",
  },
};

export default messages;
