const messages = {
  artwork: {
    about: "作品概要 - {title}",
    history: "受賞・出展歴 - {title}",
    delivery_price: "配送料について",
    delivery_price_detail:
      "送料抜きの価格で表示されており、送料は着払いでお届けしております。<br/>ご注文やお問い合わせの後に、 送料についてご連絡いたします。<br/><br/>配送料にご納得いただけない場合は、 すでにご注文されていても決済 / 発送は行われず、 ご注文はキャンセルとなります。<br/><br/>アート作品は梱包の仕方でサイズが変化したり、 保険料の変動など、 配送料が複雑なため、 送料の自動計算ができないことご了承ください。",
    delivery_price_free: "送料無料",
  },
  article: {
    about: "About",
    written_by: "Written by",
  },
};

export default messages;
