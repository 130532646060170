import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setEditLang, setViewLang } from "../../../actions/language";
import { Path, PathGenerator } from "../../../constants/path";

class TopScreen extends PureComponent {
  state = {};

  componentWillMount = () => {
    const lang = "ja";
    this.props.actions.setViewLang(lang);
    this.props.actions.setEditLang(lang);
    this.props.history.push(
      PathGenerator.getRealUrl(Path.medium.articles.url, { lang: lang }),
    );
  };

  render = () => {
    return <div />;
  };
}

const mapStateToProps = (state) => {
  return {
    medium: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setViewLang: (...param) => dispatch(setViewLang(...param)),
      setEditLang: (...param) => dispatch(setEditLang(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopScreen),
);
