import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { IntlText } from "../util/Text";
import News from "../views/News";

class NewsCards extends Component {
  render = () => {
    if (this.props.news == null) {
      return (
        <div className="uk-text-center">
          <span data-uk-spinner="ratio: 3"></span>
        </div>
      );
    } else if (this.props.news.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.news.no" />
          </h3>
        </div>
      );
    }

    return (
      <div data-uk-grid>
        {this.props.news.map((news, index) => {
          return (
            <div className="uk-width-1-3@s" key={index}>
              <News news={news} />
            </div>
          );
        })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewsCards),
);
