import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const APP_MODE = process.env.REACT_APP_MODE;

export const configureSentry = () => {
  Sentry.init({
    dsn: "https://aed447a0a9494663a2b786748d957776@o1081397.ingest.sentry.io/4504217474826240",
    integrations: [new Integrations.BrowserTracing()],
    enabled: APP_MODE !== "local",
    environment: APP_MODE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
};
