import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { fullUrl, Image, imageUrl } from "../util/Image";

declare var UIkit;

class SlideShow extends PureComponent {
  moveSlideshow = (index) => {
    UIkit.slideshow(this.slideshow).show(index);
  };

  render = () => {
    if (this.props.images == null || this.props.images.length == 0) {
      return null;
    }
    return (
      <div>
        <div
          ref={(slideshow) => (this.slideshow = slideshow)}
          data-uk-slideshow="animation: slide;"
          className="uk-slider-outside  uk-visible-toggle uk-margin-large"
        >
          <div className="uk-position-relative uk-light">
            <ul
              className="uk-slideshow-items uk-artwork-images"
              data-uk-lightbox="animation: slide"
              data-uk-height-viewport="offset-top: true; offset-bottom: 20"
            >
              {this.props.images.map((image, index) => {
                return (
                  <li key={index}>
                    <a
                      className="uk-display-block uk-height-1-1 uk-image-contain"
                      data-caption={this.props.title}
                      href={fullUrl(imageUrl(image.url))}
                    >
                      <Image full src={image.url} alt={this.props.title} />
                    </a>
                  </li>
                );
              })}
            </ul>
            <a
              className="uk-slidenav-left uk-hidden-hover"
              href="#"
              {...{ "uk-slidenav-previous": "" }}
              {...{ "uk-slideshow-item": "previous" }}
            ></a>
            <a
              className="uk-slidenav-right uk-hidden-hover"
              href="#"
              {...{ "uk-slidenav-next": "" }}
              {...{ "uk-slideshow-item": "next" }}
            ></a>
          </div>
          <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
        </div>
        {this.props.selector && (
          <div
            className="uk-margin-large uk-child-width-1-4@s uk-child-width-1-3 uk-grid-collapse"
            data-uk-grid
          >
            {this.props.images.map((image, index) => {
              return (
                <div key={index}>
                  <a
                    className="uk-height-small uk-image-wrapper uk-display-block"
                    onClick={() => this.moveSlideshow(index)}
                  >
                    <Image src={image.url} alt={this.props.title} />
                  </a>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SlideShow),
);
