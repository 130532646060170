import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Path, PathGenerator } from "../../constants/path";
import { Link } from "../util/Clickable";
import { Image } from "../util/Image";
import { Loading } from "../util/Loading";
import { IntlText } from "../util/Text";

class LikesImages extends Component {
  render = () => {
    if (this.props.likes == null) {
      return <Loading />;
    } else if (this.props.likes.length <= 0) {
      return (
        <div>
          <h3>
            <IntlText id="lists.likes.no" />
          </h3>
        </div>
      );
    }

    return (
      <div className="uk-grid-small" data-uk-grid>
        {this.props.likes
          .filter((result, index) => {
            return this.props.max ? index < this.props.max : true;
          })
          .map((result, index) => {
            var imageUrl = null;
            var url = null;

            if (result.artist) {
              imageUrl = result.artist.thumnail_url;
              url = PathGenerator.getLocalPath(Path.artists.view.url, {
                artistId: result.artist.id,
              });
            } else if (result.show) {
              imageUrl =
                result.show.show_images && result.show.show_images.length > 0
                  ? result.show.show_images[0].url
                  : null;
              url = PathGenerator.getLocalPath(Path.shows.view.url, {
                showId: result.show.id,
              });
            } else if (result.artwork) {
              imageUrl =
                result.artwork.artwork_images &&
                result.artwork.artwork_images.length > 0
                  ? result.artwork.artwork_images[0].url
                  : null;

              url = PathGenerator.getLocalPath(Path.artworks.view.url, {
                artworkId: result.artwork.id,
              });
            } else if (result.article) {
              imageUrl = result.article.thumnail_url;
              url = PathGenerator.getLocalPath(Path.articles.view.url, {
                articleId: result.article.id,
              });
            } else if (result.gallery) {
              imageUrl = result.gallery.thumnail_url;
              url = PathGenerator.getLocalPath(Path.galleries.view.url, {
                galleryId: result.gallery.id,
              });
            } else if (result.fair) {
              imageUrl = result.fair.thumnail_url;
              url = PathGenerator.getLocalPath(Path.fairs.view.url, {
                fairId: result.fair.id,
              });
              if (result.fair.slug != null) {
                url = PathGenerator.getLocalPath(Path.original.url, {
                  fairId: result.fair.slug,
                });
              }
            } else {
              return;
            }
            return (
              <div className="uk-width-auto" key={index}>
                <Link
                  to={url}
                  className="uk-link-reset uk-icon-image-circle uk-image-wrapper"
                >
                  <Image src={imageUrl} alt="" />
                </Link>
              </div>
            );
          })}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LikesImages),
);
