import { SEARCH_CONTENT } from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";

export const search = (param) => {
  return (dispatch) => {
    return {
      type: SEARCH_CONTENT,
      payload: () => {
        return Api.get(EndPoints.root() + "/search", param);
      },
    };
  };
};
