import React, { Component } from "react";

export default class MultiSelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  componentWillMount = () => {
    this.setState({
      selected: this.props.selected ? this.props.selected : [],
    });
  };

  onSelect = (option) => {
    this.state.selected.push(this.keyExtractor(option));
    this.setState({
      selected: this.state.selected,
    });
    this.props.onChange && this.props.onChange(this.state.selected);
    this.props.input &&
      this.props.input.onChange &&
      this.props.input.onChange(this.state.selected);
  };

  onUnselect = (option) => {
    const selected = this.state.selected.filter((sel) => {
      return sel != this.keyExtractor(option);
    });
    this.setState({
      selected: selected,
    });
    this.props.onChange && this.props.onChange(selected);
    this.props.input &&
      this.props.input.onChange &&
      this.props.input.onChange(selected);
  };

  keyExtractor = (option) => {
    return this.props.keyExtractor
      ? this.props.keyExtractor(option)
      : option.value;
  };

  render = () => {
    const hasError =
      this.props.meta && this.props.meta.touched && this.props.meta.error;
    return (
      <div>
        {(() => {
          if (this.props.label != null) {
            return (
              <label className="uk-form-label uk-text-nowrap">
                {this.props.label}
                <wbr />
                {(() => {
                  if (this.props.required) {
                    return <small className="uk-text-danger"> ※require</small>;
                  }
                })()}
              </label>
            );
          }
        })()}
        <div
          className="uk-form-controls uk-flex-middle uk-grid-small"
          data-uk-grid
        >
          <div className="uk-width-expand@s uk-height-medium">
            <div className="uk-border-muted uk-height-1-1 uk-overflow-auto">
              <div className="uk-padding-small">
                {this.props.options &&
                  this.props.options
                    .filter((option) => {
                      const key = this.keyExtractor(option);
                      return !this.state.selected.find((sel) => {
                        return sel == key;
                      });
                    })
                    .map((option, key) => {
                      return (
                        <a
                          key={key}
                          className="uk-hover uk-link-reset uk-margin-small uk-display-block"
                          onClick={() => this.onSelect(option)}
                        >
                          {this.props.itemRenderer ? (
                            this.props.itemRenderer(option)
                          ) : (
                            <span>{option.label}</span>
                          )}
                        </a>
                      );
                    })}
              </div>
            </div>
          </div>
          <div className="uk-width-auto@s uk-text-center">
            <span
              className="uk-visible@s"
              data-uk-icon="icon:triangle-right; ratio:2;"
            />
            <span
              className="uk-hidden@s"
              data-uk-icon="icon:triangle-down; ratio:2;"
            />
          </div>
          <div className="uk-width-expand@s uk-height-medium">
            <div className="uk-border-muted uk-height-1-1 uk-overflow-auto">
              <div className="uk-padding-small">
                {this.props.options &&
                  this.props.options
                    .filter((option) => {
                      const key = this.keyExtractor(option);
                      return this.state.selected.find((sel) => {
                        return sel == key;
                      });
                    })
                    .map((option, key) => {
                      return (
                        <a
                          key={key}
                          className="uk-hover uk-link-reset uk-margin-small uk-display-block"
                          onClick={() => this.onUnselect(option)}
                        >
                          {this.props.itemRenderer ? (
                            this.props.itemRenderer(option)
                          ) : (
                            <span>{option.label}</span>
                          )}
                        </a>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div className="uk-text-danger " style={{ minHeight: "18px" }}>
          <small>{hasError && this.props.meta.error}</small>
        </div>
      </div>
    );
  };
}
