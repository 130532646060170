import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Meta from "../../components/layout/Meta";
import { getPureMessage } from "../../components/util/Text";
import { Path, PathGenerator } from "../../constants/path";
import EditAuthScreen from "./other/EditAuthScreen";
import EditProfileScreen from "./other/EditProfileScreen";
import TopScreen from "./TopScreen";

class BaseScreen extends PureComponent {
  componentWillMount = () => {
    this.redirectIfNotAuthed(this.props);
  };

  componentWillReceiveProps = (nextProps) => {
    this.redirectIfNotAuthed(nextProps);
  };

  redirectIfNotAuthed = (props) => {
    if (props.auth.role != "collector") {
      props.history.push(
        PathGenerator.getLocalPath(Path.collector.auth.login.url),
      );
    }
  };

  render = () => {
    return (
      <div>
        <Meta title={getPureMessage("collector.title")} />
        <div className="uk-section uk-section-large">
          <div className="uk-container">
            <Switch>
              <Route
                exact
                path={Path.collector.top.url}
                component={TopScreen}
              />
              <Route
                exact
                path={Path.collector.edit_auth.url}
                component={EditAuthScreen}
              />
              <Route
                exact
                path={Path.collector.edit.url}
                component={EditProfileScreen}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseScreen),
);
