import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { mediums, prices, sizes, sorts } from "../../constants/option";
import CheckBox from "../inputs/CheckBox";
import Select from "../inputs/Select";
import { getPureMessage, IntlText } from "../util/Text";

class ArtworkFilterForm extends PureComponent {
  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        action={this.props.action}
        method="post"
      >
        <div className="uk-flex-middle uk-grid-small" data-uk-grid>
          <div className="uk-width-auto@s">
            <Field
              name="selling"
              labelId="forms.attr.artwork_filter.for_sale"
              component={CheckBox}
              className="uk-form-small"
            />
          </div>
          <div className="uk-width-expand@s">
            <div className="uk-grid-collapse" data-uk-grid>
              <div className="uk-width-small@s uk-width-1-3">
                <Field
                  name="size"
                  placeholder={getPureMessage("forms.attr.artwork_filter.size")}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  noError
                  options={sizes}
                />
              </div>
              <div className="uk-width-small@s uk-width-1-3">
                <Field
                  name="price"
                  placeholder={getPureMessage(
                    "forms.attr.artwork_filter.price",
                  )}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  noError
                  options={prices}
                />
              </div>
              <div className="uk-width-small@s uk-width-1-3">
                <Field
                  name="medium"
                  placeholder={getPureMessage(
                    "forms.attr.artwork_filter.medium",
                  )}
                  component={Select}
                  className="uk-form-small uk-form-clear"
                  options={mediums}
                  noError
                />
              </div>
            </div>
          </div>
          <div className="uk-width-auto@s">
            <div className="uk-grid-small uk-flex-middle" data-uk-grid>
              <div className="uk-width-auto">
                <span className="uk-text-bold">
                  <IntlText id="forms.attr.artwork_filter.sort" />
                </span>
              </div>
              <div className="uk-width-medium">
                <Field
                  name="sort_method"
                  component={Select}
                  className="uk-form-small uk-background-white"
                  noError
                  notNull
                  options={sorts}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };
}

export default reduxForm({
  form: "artwork-filter-form",
  // enableReinitialize : true
})(ArtworkFilterForm);
