import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { add, deleteArticle, search } from "../../../actions/gallery/article";
import { showConfirm } from "../../../actions/message";
import ArticleForm from "../../../components/forms/ArticleForm";
import ArticlesList from "../../../components/lists/Articles";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Button, Link } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import { Path, PathGenerator } from "../../../constants/path";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class ArticlesScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      articles: null,
      meta: {
        current_page: 0,
        last_page: null,
      },
      loading: false,
    };
  }

  componentWillMount = () => {
    this.readMore(true);
  };

  readMore = (refresh = false) => {
    if (!refresh) {
      this.setState({ loading: true });
    }
    this.props.actions
      .search({
        gallery_id: this.props.gallery.id,
        page: this.state.meta.current_page + 1,
      })
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            articles:
              this.state.articles && !refresh
                ? this.state.articles.concat(value.data)
                : value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onAdded = (values) => {
    UIkit.modal(this.addModal).hide();
    Notification.successIntl("util.success.add");
    this.setState({
      articles: this.state.articles.concat([values]),
    });
  };

  onDelete = (article) => {
    this.props.actions.showConfirm(
      <IntlText id="util.confirm.title.delete" />,
      null,
      (confirmed) => {
        if (!confirmed) {
          return;
        }

        this.props.actions
          .deleteArticle(article.id)
          .then(({ value, action }) => {
            if (value.status < 300) {
              this.setState({
                articles: this.state.articles.filter((a) => {
                  return a.id != article.id;
                }),
              });
              Notification.successIntl("util.success.delete");
            }
          })
          .catch((error) => console.log(error));
      },
    );
  };

  render = () => {
    return (
      <div>
        <div className="uk-margin-small" data-uk-grid>
          <div className="uk-width-auto">
            <h1>
              <IntlText id="gallery.articles.title" />
            </h1>
          </div>
          <div className="uk-width-expand">
            <Link
              className="uk-button uk-button-default"
              onClick={() => {
                UIkit.modal(this.addModal, { container: false }).show();
              }}
            >
              <IntlText id="forms.add" />
              <span
                className="uk-margin-small-left"
                data-uk-icon="icon:plus; ratio:0.8;"
              />
            </Link>
          </div>
          <div className="uk-width-medium@s uk-width-1-1 uk-text-right">
            <ChangeEditLang />
          </div>
        </div>

        <div className="uk-margin-large">
          <ArticlesList
            lang={this.props.language.edit}
            editing={true}
            articles={this.state.articles}
            onEdit={(article) => {
              this.props.history.push(
                PathGenerator.getLocalPath(Path.gallery.articles.edit.url, {
                  articleId: article.id,
                }),
              );
            }}
            onDelete={this.onDelete}
          />
        </div>

        {this.state.meta.last_page &&
          this.state.meta.last_page > this.state.meta.current_page && (
            <div className="uk-margin-large uk-text-center">
              <Button
                className="uk-button uk-button-default"
                onClick={() => this.readMore(false)}
                loading={this.state.loading ? 1 : 0}
              >
                <IntlText id="forms.more" />
              </Button>
            </div>
          )}

        <div ref={(addModal) => (this.addModal = addModal)} data-uk-modal>
          <div className="uk-modal-dialog uk-width-xxlarge@s">
            <button
              className="uk-modal-close-default"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                <IntlText id="gallery.articles.add" />
              </h2>
            </div>
            <div className="uk-modal-body">
              <ArticleForm
                ref={(addForm) => (this.addForm = addForm)}
                onSubmit={(values) => this.props.actions.add(values)}
                onSuccess={this.onAdded}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    gallery: state.auth.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      search: (...param) => dispatch(search(...param)),
      add: (...param) => dispatch(add(...param)),
      deleteArticle: (...param) => dispatch(deleteArticle(...param)),
      showConfirm: (...param) => dispatch(showConfirm(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArticlesScreen),
);
