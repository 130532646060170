const messages = {
  plan: {
    current_id:
      "<small>現在のプランは、</small>{plan_name}({price})<small>です</small>",
    current_card: "登録中のクレジットカード",
    expired: "有効期限",
    edit_payment: "支払い情報の編集",
    caution_header:
      'お試しプランは<span className="uk-text-bold">{date}</span>に期限切れとなります。',
    caution_text:
      "期限切れになると、無料プランへ自動的に移行し、作品・アーティスト・NEWS・記事の追加制限や販売に制限がかかります。<br/>期限内にスタンダードプランへアップグレードすることをおすすめします。<br/> {plan_name}: 初期費用{init_price}, {price}",
    caution_button: "スタンダードプランに申し込む",
    trial: "お試しプラン",
    free: "無料",
    basic: "スタンダードプラン",
    unit: "円/月",
    init_unit: "円",
    initial: "初期費用",
    campaign: {
      release:
        "※キャンペーン申込み済み({campaign_end_at}まで10コンテンツ掲載で利用料無料)",
    },
  },
  location: {
    address: "住所",
    tel: "電話番号",
    hp: "公式HP",
    email: "Email",
  },
  fair: {
    artworks: "作品一覧",
  },
  purchase: {
    title: "この作品を購入する",
    lead_contact:
      "この作品の詳細情報をご要望の方は、お気軽にお問い合わせください。",
    contact: "問い合わせ",
    contact_title: "問合せフォーム",
    form: "ご購入フォーム",
    ifyouhaveaccount: "Art Scenesのアカウントをお持ちの方は",
    clickme: "ログインして問い合わせる",
    notlogin: "ログインせずに問い合わせる",
    ifyoudonthaveaccount: "Art Scenesのアカウントをお持ちでない方は",
    tax: "(税込)",
  },
};

export default messages;
