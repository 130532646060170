import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { deleteImage, edit, getData } from "../../../actions/fair/index";
import FairForm from "../../../components/forms/fair/FairForm";
import EditorInput from "../../../components/inputs/EditorInput";
import Thumnail from "../../../components/inputs/Thumnail";
import ChangeEditLang from "../../../components/util/ChangeEditLang";
import { Button } from "../../../components/util/Clickable";
import { IntlText } from "../../../components/util/Text";
import { EndPoints } from "../../../constants/endpoints";
import { Formatter } from "../../../utils/formatter";
import { Notification } from "../../../utils/notification";

declare var UIkit;

class ProfileScreen extends PureComponent {
  state = {
    fair: null,
    editing: {},
    editing_start: false,
    loading: false,
  };

  componentWillMount = () => {
    this.props.actions
      .getData()
      .then(({ value, action }) => {
        if (value.status < 300) {
          let editing = Formatter.mergeDesc(
            value.data,
            value.data.fair_descs,
            this.props.language.edit,
          );
          this.setState({
            fair: value.data,
            editing: editing,
            editing_start: true,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onChangeLang = (lang) => {
    this.setState(
      {
        editing_start: false,
      },
      () => {
        this.setState(
          {
            editing: Formatter.mergeDesc(
              this.state.fair,
              this.state.fair.fair_descs,
              lang,
            ),
          },
          () => {
            this.setState({
              editing_start: true,
            });
          },
        );
      },
    );
  };

  onSubmitDesc = (values) => {
    return this.props.actions.edit(values);
  };

  onSuccessDesc = (data) => {
    Notification.successIntl("util.success.update");
    this.setState({
      fair: data,
    });
  };

  onUploaded = (data) => {
    Notification.successIntl("util.success.update");
  };

  onDeleted = (isLogo = null) => {
    this.props.actions
      .deleteImage({ logo: isLogo })
      .then(({ value }) => {
        if (value.status < 300) {
          Notification.successIntl("util.success.delete");
        }
      })
      .catch((error) => console.log(error));
  };

  onDetailChange = (detail) => {
    var editing = this.state.editing;
    editing.detail = detail;
    this.setState({
      editing: editing,
    });
  };

  onDetailSubmit = () => {
    this.setState({
      loading: true,
    });
    this.props.actions.edit(this.state.editing).then(() => {
      this.setState({
        loading: false,
      });
    });
  };

  render = () => {
    return (
      <div>
        <h1>
          <IntlText id="fair.profile.title" />
        </h1>
        <div className="uk-margin-medium">
          <div className="uk-margin uk-flex-middle uk-flex-right" data-uk-grid>
            <div className="uk-width-expand@s uk-width-1-1">
              <h2 className="uk-heading-mark">
                <IntlText id="fair.profile.info" />
              </h2>
            </div>
            <div className="uk-width-medium@s uk-text-right">
              <ChangeEditLang onChange={this.onChangeLang} />
            </div>
          </div>
          <div className="uk-margin">
            <FairForm
              initialValues={this.state.editing}
              onSuccess={this.onSuccessDesc}
              onSubmit={this.onSubmitDesc}
            />
          </div>
        </div>

        <hr />

        <div className="uk-margin-medium">
          <div className="uk-margin">
            {this.state.editing_start && (
              <EditorInput
                uploadUrl={EndPoints.fairImages()}
                onChange={this.onDetailChange}
                value={this.state.editing.detail}
              />
            )}
          </div>
          <div className="uk-margin uk-text-center">
            <Button
              onClick={this.onDetailSubmit}
              className="uk-button uk-button-default"
              type="submit"
              ga={{ category: "fair_profile_detail", action: "submit" }}
              loading={this.state.loading ? 1 : 0}
            >
              <IntlText id="forms.submit" />
            </Button>
          </div>
        </div>

        <div className="uk-margin-medium">
          <h2 className="uk-heading-mark">
            <IntlText id="fair.profile.images" />
          </h2>
          <div className="uk-margin" data-uk-grid>
            <div className="uk-width-2-3@s">
              <h3>
                <IntlText id="fair.profile.cover" />
              </h3>
              <div className="uk-margin-small">
                <Thumnail
                  url={EndPoints.fair("image")}
                  src={this.state.editing.thumnail_url}
                  onSuccess={this.onUploaded}
                  onDelete={() => this.onDeleted()}
                  keyExtractor={(value) => value.thumnail_url}
                />
              </div>
            </div>
            <div className="uk-width-1-3@s">
              <h3>
                <IntlText id="fair.profile.logo" />
              </h3>
              <div className="uk-margin-small">
                <Thumnail
                  url={EndPoints.fair("image") + "?logo=true"}
                  src={this.state.editing.logo_url}
                  onSuccess={this.onUploaded}
                  onDelete={() => this.onDeleted(true)}
                  keyExtractor={(value) => value.logo_url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    fair: state.auth.data ? state.auth.data : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getData: () => dispatch(getData()),
      edit: (...param) => dispatch(edit(...param)),
      deleteImage: (...param) => dispatch(deleteImage(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileScreen),
);
