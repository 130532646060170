const messages = {
  word: {
    show: "展示",
    artwork: "作品",
    fair: "フェア",
    artworks: "Artworks",
    galleries: "Galleries",
    fairs: "Fairs",
    artists: "Artists",
    shows: "Shows",
    articles: "Articles",
    view_all:
      '<span><span className="uk-visible@s">VIEW </span><span>ALL</span></span>',
    view_all_text: "VIEW ALL",
  },
  contact: {
    thanks: "お問い合わせありがとうございます",
    lead: "3営業日以内に担当者から電話またはメールにて改めてご連絡させていただきます。",
  },
  meta: {
    name: "Art Scenes アートシーンズ",
    title: "Art Scenes アートシーンズ | お気に入りのアートが探せる、見つかる",
    description:
      "Art Scenes(アートシーンズ)はアートコレクターやギャラリーのためのWebサービスです。お気に入りのアートを見つけて購入、収集できます。アート作品、ギャラリー、アーティスト、フェア、博物館の情報を掲載しています",
  },
};

export default messages;
