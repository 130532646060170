import {
  ADD_LIKE,
  CLEAR_LIKE,
  SET_LIKES,
  CHECK_LIKES,
} from "../constants/type";

import Api from "../utils/api";
import { EndPoints } from "../constants/endpoints";
import stores from "../stores";

export const addLike = (like) => {
  return (dispatch) => {
    if (stores.getState().auth.role == "collector") {
      Api.post(EndPoints.collector("likes"), like)
        .then(() => {})
        .catch((error) => console.log(error));
    } else {
      Api.post(EndPoints.likes(), like)
        .then(() => {})
        .catch((error) => console.log(error));
    }
    return {
      type: ADD_LIKE,
      like: like,
    };
  };
};

export const clearLike = (like) => {
  return (dispatch) => {
    if (stores.getState().auth.role == "collector") {
      Api.post(EndPoints.collector("likes"), like)
        .then(() => {})
        .catch((error) => console.log(error));
    }
    return {
      type: CLEAR_LIKE,
      like: like,
    };
  };
};

export const setLikes = (likes = null) => {
  return {
    type: SET_LIKES,
    likes: likes,
  };
};

export const checkLikes = () => {
  return {
    type: CHECK_LIKES,
  };
};
