export const MediumPath = {
  url: "/:lang/medium",
  auth: {
    url: "/:lang/medium/auth",
    login: {
      url: "/:lang/medium/auth/login",
    },
  },
  top: {
    url: "/:lang/medium",
  },
  articles: {
    url: "/:lang/medium/articles",
    edit: {
      url: "/:lang/medium/articles/:articleId/edit",
    },
  },
  edit: {
    url: "/:lang/medium/edit",
  },
};
