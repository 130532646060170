const messages = {
  layout: {
    more: "More",
    search: "Search",
    contents: "Contents",
    terms: "Terms",
    term_of_use: "Terms of use",
    privacy: "Privacy policy",
    law: "Act on SCT",
    partner: "Partnering w Art Scenes",
    login: {
      gallery: "Gallery Login",
      fair: "Fair Login",
      medium: "Medium Login",
    },
    about_menu: "Art Scenes",
    about: "about",
    contact: "Contact",
    estimated_delivery_charge: "Estimated Delivery Charge",
    company: "Company",
    recently: "Recently viewed Artworks",
    collectors: "For Collectors",
    collector_login: "Collector Login",
    likes: "Likes",
    footer_text:
      "Art Scenes is the web service for art collectors, galleries and people involving art.<br/>You can not only collect and buy art in the world but also get every information about art such as artists, artworks, shows, fairs and galleries.",
  },

  menu: {
    overview: "OVERVIEW",
    shows: "SHOWS",
    artworks: "ARTWORKS",
    artists: "ARTISTS",
    articles: "ARTICLES",
    contact: "CONTACT",
    galleries: "GALLERIES",
    info: "INFO",
    history: "HISTORY",
    photos: "PHOTOS",
  },

  root: {
    news: "News",
    fairs: "Recently Fairs",
    artworks: "Artworks for Sale",
    shows: "Featured shows",
    articles: "Articles",
  },

  artworks: {
    count: "{num} Artworks",
    search: "Detail Search",
    title: "All Artworks in Art Scenes",
    description:
      "Art Scenes is publishing artworks in the world. You can find and buy favorite artworks form all artwork list.",
    overview: "Overview",
    other_by_artists: "Other Artworks by {artists}",
    other_by_gallery: "Other Artworks by {gallery}",
    artists: "Artist of {title}",
    gallery: "Gallery of {title}",
    inquiry: {
      title: "Thank you for contact!",
      lead: "We send an email for confirmation.<br/>Please check your email box!<br/>If you won't get email within 30 min, please ",
      next_step: "Next Step",
      next_lead: "Please wait for the gallery's response.",
      will_send: " will send back message within about a week.",
      not_get: "If you won't get email, please ",
      contact_us: "contact Art Scenes.",
      sorry: "I'm sorry...",
      soldout: "The artwork you contacted is already sold!",
      back: "Back to artwork page",
      artwork: "Contacted Artwork",
    },
    alipay: {
      invaild: "Invalid Credit Information.<br/>Please try again.",
      checking: "Checking credit ...<br/>Don't close this window.",
      back: "Back to Artwork",
    },
    thanks: {
      title: "Thank you for your purchasing!",
      lead: "We send an email for confirmation.<br/>Please check your email box!<br/>If you won't get email within 30 min, please ",
      next_step: "Next Step",
      next_lead: "Please wait for the gallery's confirmation.",
      will_send: " will send back message within about a week.",
      not_get: "If you won't get email, please ",
      contact_us: "contact Art Scenes.",
      sorry: "I'm sorry...",
      soldout: "The artwork you contacted is already sold!",
      back: "Back to artwork page",
      artwork: "Contacted Artwork",
    },
  },
  galleries: {
    title: "All Galleries in Art Scenes",
    description:
      "Art Scenes is publishing galleries' information in the world. You can find and buy favorite artworks form gallerie list.",
    count: "{num} Galleries",
    near: " near {place}",
    about: "About {name}",
    show: "Shows by {name}",
    fair: "Fair Booth of {name}",
    represented_artists: "Represented Artists in {name}",
    articles_about: "Articles about {name}",
    shows: {
      meta: "All Shows by ",
      title: "Shows by {name}",
      current: "Current Shows by {name}",
      upcoming: "Upcoming Shows by {name}",
      past: "Past Shows by {name}",
    },
    artworks: {
      meta: "All Artwroks by ",
      title: "Artworks by {name}",
      count: "{num} Artworks",
    },
    artists: {
      meta: "Artists of ",
      represents: "Represented Artists in {name}",
      available: "Available artists in {name}",
    },
    articles: {
      meta: "Articles about ",
      title: "Articles about {name}",
    },
    contact: {
      meta: "Contact for ",
      title: "Contact",
      location: "Locations - {name}",
    },
  },
  fairs: {
    title: "All Fairs",
    description:
      "Art Scenes is publishing fairs' information in the world. You can find and buy favorite artworks form fairs list.",
    current: "Current Fairs in Art Scenes",
    past_upcomming: "Past & Upcomming Fairs",
    past: "Past Fairs",
    upcoming: "Upcomming Fairs",
    overview: {
      about: "About {title}",
      schedule: "Schedule",
      place: "Place",
      articles: "Articles about {title}",
      related: "Related Fairs of {title}",
      photos: "Photos of {title}",
    },
    galleries: {
      meta: "Galleries in ",
      title: "Galleries in {title}",
    },
    artworks: {
      meta: "Artworks in ",
      title: "Artworks in {title}",
      display: "display :　",
      default: "default",
      gallery: "gallery",
    },
    photos: {
      meta: "Photos of ",
      title: "Photos of {title}",
    },
    articles: {
      meta: "Articles about ",
      title: "Articles about {title}",
    },
    info: {
      meta: "Outline of ",
      title: "Outline of {title}",
      buy: "Buy Ticket",
      reception: "Reception for {title}",
      committee: "Committees of {title}",
      partner: "Partners of {title}",
      information: "Infomation of {title}",
    },
  },
  artists: {
    meta: "All Artists",
    title: "All Artists in Art Scenes",
    description:
      "Art Scenes is publishing artists' information in the world. You can find and buy favorite artworks form artists list.",
    feature: "FEATURED ARTISTS",
    overview: {
      biography: "Biography of {name}",
      history: "History of {name}",
      artworks: "Artworks by {name}",
    },
    artworks: {
      meta: "'s Artworks",
      title: "Artworks by {name}",
    },
    history: {
      meta: "'s History",
      title: "History of {name}",
      shows: "SHOWS",
      fairs: "FAIRS",
    },
    articles: {
      meta: "'s Articles",
      title: "Articles about {name}",
    },
    shows: {
      meta: "'s Shows and Fairs",
      current: "Current Shows & Fairs attended by {name}",
      upcoming: "Upcoming Shows & Fairs attended by {name}",
      past: "Past Shows & Fairs attended by {name}",
    },
  },
  shows: {
    meta: "All Shows",
    title: "All Shows in Art Scenes",
    description:
      "Art Scenes is publishing shows' information in the world. You can find and buy favorite artworks form shows list.",
    count: "{count} Shows {place}",
    inplace: "in {place}",
    current: "{count} Current Show {place}",
    upcoming: "{count} Upcoming Show {place}",
    past: "{count} Past Show {place}",
    view: {
      about: "About {title}",
      artworks: "Artworks about {title}",
      artists: "Artists on {title}",
      photos: "Photos of {title}",
      others: "Other shows at  {gallery}",
    },
  },
  articles: {
    meta: "All Articles",
    title: "All Articles in Art Scenes",
    description:
      "Art Scenes is publishing articles about art in the world. You can find and buy favorite artworks form articles.",
    related: "Related Articles",
  },
  others: {
    term: "Term of service",
    estimated_delivery_charge: {
      title: "Estimated Delivery Charge",
      text: `The price of artworks on Art Scenes generally DO NOT include shipping fees unless stated otherwise, and shipping fees must be paid upon delivery.
      <br />When determining the shipping fee, we compare regional shipping fees for each shipping company and present the comparison results as a shipping outline.
      Therefore, please note that the actual shipping fee may vary slightly.
      <br /><br />
      If there are no shipping fees, we will proceed with the shipping procedure immediately.
      <br />If the shipping fees are to be paid upon delivery, we will contact you about the shipping fee after your order or inquiry.
      <br />If you are not satisfied with the shipping fee, payment/shipping will not be carried out even if you have already placed an order, and your order will be cancelled.
      <br /><br />
      Please note that shipping fees cannot be calculated automatically for artworks, as it is determined depending on variables such as the size of the artwork which changes depending on how it is packaged,
      the fluctuation of insurance fees, and other factors.`,
      table_title: "Shipping Address / Size",
      prefecture: {
        hokkaido: "Hokkaido",
        tohoku: "Tohoku",
        kanto: "Kanto",
        chubu: "Shinetsu, Hokuriku, Tokai",
        kansai: "Kansai",
        chugoku: "Chugoku, Shikoku",
        kyushu: "kyushu",
        okinawa: "Okinawa",
      },
      size: {
        sixty: "60 Size",
        eighty: "80 Size",
        one_hundred_forty: "140 Size",
        one_hundred_eighty: "180 Size",
        two_hundred_twenty: "220 Size",
        two_hundred_sixty: "260 Size",
        table_title: "Size",
        total_dimension: {
          title: "Total dimension",
          sixty: "Up to 60cm",
          eighty: "Up to 80cm",
          hundred: "Up to 100cm",
          one_hundred_forty: "Up to 140cm",
          one_hundred_eighty: "Up to 180cm",
          two_hundred_twenty: "Up to 220cm",
          two_hundred_sixty: "Up to 260cm",
        },
        weight: {
          title: "Weight",
          two: "Up to 2kg",
          five: "Up to 5kg",
          ten: "Up to 10kg",
          twenty: "Up to 20kg",
          sixty: "Up to 60kg",
          hundred: "Up to 100kg",
          hundred_eighty: "Up to 180kg",
        },
      },
    },
    privacy: "Privacy policy",
    law: "About Specified Commercial Transactions",
    contact: {
      meta: "Contact",
      title: "Contact Form",
      lead: "Please feel free to contact us !",
      about: "If you want to know about Art-scenes",
      register: "If you want to register as gallery",
    },
    search: {
      meta: "Result for ",
      keyword: 'Keyword "{keyword}" is too shoot.',
      more: "Please input more words.",
      result: "Search results for ",
      count: "Found {count} cases",
    },
    likes: {
      title: "Likes",
    },
  },
};

export default messages;
