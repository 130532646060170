import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import {
  DomesticShippingStatus,
  fairOnlyStatus,
  mediums,
  priceUnits,
  salesStatusForEdit,
  sizeUnits,
} from "../../../constants/option";
import { Formatter } from "../../../utils/formatter";
import ColorPicker from "../../inputs/ColorPicker";
import Select from "../../inputs/Select";
import Textarea from "../../inputs/Textarea";
import TextInput from "../../inputs/TextInput";
import { Button } from "../../util/Clickable";
import * as normalize from "../../util/Normalizer";
import { getPureMessage, IntlText } from "../../util/Text";
import * as validate from "../../util/Validator";

class ArtworkForm extends Component {
  state = {
    loading: false,
    showClassification: false,
  };

  componentDidMount = () => {
    if (this.props.initialValues != null) {
      this.shouldShowClassification(this.props.initialValues.category_id);
    }
  };

  shouldShowClassification = (categoryId) => {
    const category = this.props.constant.categories.find((category) => {
      return category.id == categoryId;
    });
    if (category && category.edit_detail == 1) {
      this.setState({ showClassification: true });
    } else {
      this.setState({ showClassification: false });
    }
  };

  onSubmit = (values) => {
    this.setState({
      loading: true,
    });
    return this.props.onSubmit(values).then(({ value, action }) => {
      if (value.status < 300) {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.onSuccess &&
              this.props.onSuccess(value.data, value.response);
          },
        );
      } else {
        this.setState({
          loading: false,
        });
        const errors = Object.keys(value.errors).reduce((dic, key) => {
          dic[key] = Array.isArray(value.errors[key])
            ? value.errors[key].join("\n")
            : null;
          return dic;
        }, {});
        throw new SubmissionError(errors);
      }
    });
  };

  render = () => {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        action={this.props.action}
        method="post"
      >
        <h2>
          <IntlText id="forms.attr.artwork.basic" />
        </h2>
        <div className="uk-margin-small">
          <Field
            name="title"
            type="text"
            component={TextInput}
            labelId="forms.attr.artwork.title"
            validate={[validate.required]}
            required
            onChange={(event) =>
              this.props.onChangeName &&
              this.props.onChangeName(event.target.value)
            }
          />
        </div>

        <div className="uk-margin-small">
          <Field
            name="description"
            component={Textarea}
            labelId="forms.attr.artwork.description"
          />
        </div>

        <div className="uk-margin-small">
          <Field
            name="history"
            component={Textarea}
            labelId="forms.attr.artwork.history"
          />
        </div>

        <div
          className="uk-margin-small uk-grid-small uk-child-width-1-4@s uk-child-width-1-2"
          data-uk-grid
        >
          <div>
            <Field
              name="width"
              type="text"
              component={TextInput}
              labelId="forms.attr.width"
              normalize={normalize.float}
            />
          </div>
          <div>
            <Field
              name="height"
              type="text"
              component={TextInput}
              labelId="forms.attr.height"
              normalize={normalize.float}
            />
          </div>
          <div>
            <Field
              name="depth"
              type="text"
              component={TextInput}
              labelId="forms.attr.depth"
              normalize={normalize.float}
            />
          </div>
          <div>
            <Field
              name="unit"
              component={Select}
              labelId="forms.attr.size_unit"
              options={sizeUnits}
            />
          </div>
        </div>

        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-3@s">
            <Field
              name="creation_year"
              type="number"
              component={TextInput}
              labelId="forms.attr.creation_year"
              normalize={normalize.numberOnly}
            />
          </div>
          <div className="uk-width-1-3@s">
            <Field
              name="category_id"
              component={Select}
              labelId="forms.attr.category_id"
              validate={[validate.required]}
              required
              options={this.props.constant.categories.map((category) => {
                return {
                  label: category.label,
                  value: category.id,
                };
              })}
              onChange={(event) =>
                this.shouldShowClassification(event.target.value)
              }
            />
          </div>
          {this.state.showClassification && (
            <div className="uk-width-1-3@s">
              <Field
                name="classification"
                type="text"
                component={TextInput}
                labelId="forms.attr.classification"
              />
            </div>
          )}
        </div>

        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div className="uk-width-1-3@s">
            <Field
              name="medium"
              component={Select}
              label="Medium"
              labelId="forms.attr.medium"
              options={mediums}
            />
          </div>
          <div className="uk-width-2-3@s">
            <Field
              name="color"
              component={ColorPicker}
              labelId="forms.attr.color"
            />
          </div>
        </div>

        <h2>
          <IntlText id="forms.attr.artwork.sale" />
        </h2>

        <div
          className="uk-margin-small uk-grid-small uk-child-width-1-3@s"
          data-uk-grid
        >
          <div>
            <Field
              name="price"
              component={TextInput}
              labelId="forms.attr.price"
              type="number"
              // validate={[ validate.num, validate.requiredIf("status", (value) => {
              //   return value == SALE_FOR_SALE || value == SALE_AVAILABLE;
              // } ) ]}
              normalize={normalize.numberOnly}
            />
          </div>
          <div>
            <Field
              name="price_unit"
              component={Select}
              labelId="forms.attr.price_unit"
              options={priceUnits}
            />
          </div>
          <div>
            <Field
              name="sales_priority"
              component={TextInput}
              labelId="forms.attr.priority"
              type="number"
              normalize={normalize.numberOnly}
            />
          </div>
        </div>
        <div
          className="uk-margin-small uk-grid-small uk-child-width-1-3@s"
          data-uk-grid
        >
          <div>
            <Field
              name="status"
              component={Select}
              labelId="forms.attr.sales_status"
              options={salesStatusForEdit}
              suppyId="forms.supply.sales_status"
            />
          </div>
          <div>
            <Field
              name="is_fair_only"
              component={Select}
              labelId="forms.attr.fair_only_status"
              options={fairOnlyStatus}
            />
          </div>
          <div>
            <Field
              name="location_id"
              component={Select}
              labelId="forms.attr.location.name"
              placeholder={getPureMessage("forms.attr.no_select")}
              options={this.props.gallery.locations.map((location) => {
                var location = Formatter.mergeDesc(
                  location,
                  location.location_descs,
                  this.props.language.view,
                );
                return {
                  label: location.name,
                  value: location.id,
                };
              })}
            />
          </div>
          <div>
            <Field
              name="domestic_shipping_status"
              component={Select}
              labelId="forms.attr.domestic_shipping_status.status"
              options={DomesticShippingStatus}
            />
          </div>
        </div>

        {(() => {
          if (this.props.error) {
            return (
              <div className="uk-margin-small uk-text-danger uk-text-center@s">
                <span>{this.props.error}</span>
              </div>
            );
          }
        })()}
        <div className="uk-margin uk-text-center">
          <Button
            className="uk-button uk-button-default"
            type="submit"
            disabled={this.props.invalid}
            ga={{ category: this.props.form, action: "submit" }}
            loading={this.state.loading ? 1 : 0}
          >
            <IntlText id="forms.submit" />
          </Button>
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    constant: state.constant,
    gallery: state.auth.data ? state.auth.data : { locations: [] },
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default reduxForm({
  form: "gallery-artwork-form",
  enableReinitialize: true,
  initialValues: {
    is_fair_only: 0,
  },
})(connect(mapStateToProps, mapDispatchToProps)(ArtworkForm));
