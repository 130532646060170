const messages = {
  artwork: {
    about: "About {title}",
    history: "History of {title}",
    delivery_price: "Delivery Charge",
    delivery_price_detail:
      "The prices shown are NOT including the shipping fee. We demands the fee when you receive. <br />Because of the various size and the insurance cost, the shipping fee will depend on each artworks. <br /><br />After your orders, we will contact you about the shipping fee. It is possible for you to canel the order when you could not agree with the fee. <br />we ask for your understanding.",
    delivery_price_free: "Shipping Free",
  },
  article: {
    about: "About",
    written_by: "Written by",
  },
};

export default messages;
