import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setCondition } from "../../actions/cache";
import { search as searchArtworks } from "../../actions/artwork";
import ArtworkCards from "../lists/ArtworkCards";
import { Path, PathGenerator } from "../../constants/path";

class Gallery extends Component {
  state = {
    artworks: null,
    meta: {
      current_page: 0,
      last_page: null,
    },
  };

  componentDidMount() {
    const param = Object.assign({}, this.state.queryParam, {
      fair_id: this.props.fair_id,
      gallery_id: this.props.gallery.id,
    });

    this.props.actions
      .searchArtworks(param)
      .then(({ value, action }) => {
        if (value.status < 300) {
          this.setState({
            artworks: value.data,
            meta: value.response.data.meta,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  render = () => {
    return (
      <>
        {this.state.artworks?.length ? (
          <div className="uk-margin-medium">
            <h3 className="uk-margin-small-right uk-underline">
              <Link
                className="uk-link-reset"
                to={PathGenerator.getLocalPath(Path.galleries.view.url, {
                  galleryId: this.props.gallery.id,
                })}
                ga={{
                  category: "link",
                  action: "gallery_detail",
                  label: "gallery_" + this.props.gallery.id,
                }}
              >
                {this.props.gallery.name}
              </Link>
            </h3>
            <ArtworkCards artworks={this.state.artworks} />
          </div>
        ) : null}
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchArtworks: (...param) => dispatch(searchArtworks(...param)),
      setCondition: (...param) => dispatch(setCondition(...param)),
    },
    dispatch,
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Gallery),
);
